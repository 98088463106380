import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpService } from 'src/app/component/helper/http.service'
import { environment as env } from 'src/environments/environment'

interface ClientRegistration {
	phone?: string
	email?: string
	code?: number
	name?: string
	cpf?: string
	category?: number
	eula?: string
	step: 'first' | 'second' | 'third'
}

@Injectable({
	providedIn: 'root',
})
export class RegisterRightbarService {
	constructor(private httpService: HttpService) {}

	postClients(body: ClientRegistration): Observable<any> {
		return this.httpService.post('api/v1/clients', { ...body, partnerId: env.partnerID })
	}

	checkIfCategoryExists(category: number) {
		return this.httpService.get(`api/v1/categories/range/${category}`)
	}
}

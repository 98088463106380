<form [formGroup]="form" (ngSubmit)="handleSubmit()" class="waitlistTerms">
	<!-- WAITLIST TERMS HEADER -->
	<section class="waitlistTerms__header">
		<div class="waitlistTerms__header__top">
			<a
				class="waitlistTerms__header__return"
				(click)="navigationService.setActivatedRightbarBySlug('waitlist-form')"
			>
				<i class="waitlistTerms__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="waitlistTerms__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="waitlistTerms__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- WAITLIST TERMS CONTENT -->
	<div class="waitlistTerms__content">
		<p class="waitlistTerms__content__title">Termos de serviço e lista de espera online.</p>
		<p class="waitlistTerms__content__text">
			<strong>Informações Gerais</strong><br />

			Bem-vindo à Lista de Espera online! Pedimos atentar às seguintes informações:<br />
			Nossa Lista de Espera online permite que você monitore em tempo real sua posição sem que seja necessário
			aguardar na fila, por meio de um link enviado por SMS para seu número de telefone celular.<br />
			A quantidade de pessoas deve ser preenchida corretamente, pois a seleção da mesa está baseada nessa
			informação.<br />
			A seleção da mesa está relacionada à quantidade de pessoas informada por você. O restaurante não garante
			uma mesa quando a quantidade de pessoas for superior àquela informada. É necessário que pelo menos
			metade das pessoas estejam presentes para ingresso no restaurante.<br />
			O restaurante enviará um SMS para o seu telefone cadastrado quando chegar a sua vez. Ao ser chamado,
			procure o(a) recepcionista(a) para ser alocado em sua mesa.<br />
			A tolerância de espera pode variar de acordo com o tamanho da lista. Permaneça próximo ao balcão de
			atendimento quando tiver poucas pessoas na sua frente.<br />
			Verifique diretamente com o restaurante a possibilidade quanto a observações e condições específicas de
			reserva de mesa.<br />
			As observações ou solicitações não são garantidas que serão atendidas devido a grande procura.
		</p>
		<p class="waitlistTerms__content__text">
			<strong>Informações de Segurança</strong><br />

			Ao utilizar o serviço de Lista de Espera online você reconhece que nenhum sistema de informação é
			inviolável. No entanto, a Tagme envida os melhores esforços para manter a segurança e a
			confidencialidade de seus dados pessoais, por meio da adoção de medidas de segurança, técnicas e
			administrativas, suficientes para protegê-los de acessos não autorizados e de situações acidentais ou
			ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou
			ilícito. Estas cautelas se estendem aos parceiros, clientes e fornecedores da Tagme.<br />
			O uso do serviço de Lista de Espera online implica aceite e concordância quanto à coleta e tratamento de
			seus dados pessoais pela Tagme, incluindo, mas sem se limitar a (i) nome, (ii) telefone celular, (iii)
			navegação e geolocalização e (iv) dados de consumo no restaurante. <br />
			Os dados pessoais são coletados e tratados de acordo com a Lei Geral de Proteção de Dados e com as
			finalidades de (i) criar e gerenciar o registro de uma conta e perfil de usuário, (ii) administrar a
			reserva e a lista de espera, (iii) administrar os pedidos realizados ao restaurante, (iv) administrar os
			programas de fidelidade da Tagme e de seus parceiros, (v) permitir o desenvolvimento e aprimoramento dos
			produtos e serviços da Tagme, restaurantes e terceiros autorizados, (vi) personalização da sua
			experiência como cliente, incluindo o autopreenchimento de cadastros, oferta de benefícios e comunicação
			de marketing da Tagme, restaurantes e parceiros autorizados, (vii) envio de convite para participação de
			estudos e pesquisas, (viii) atendimento a solicitações de autoridades competentes e (ix) condução das
			atividades empresariais da Tagme, restaurantes e parceiros autorizados.<br />
			Os dados pessoais serão armazenados por um prazo razoável e poderão ser excluídos da base de dados por
			sua solicitação, desde que tal exclusão não seja vedada por legislação aplicável.<br />
			Você pode entrar em contato com a Tagme por meio do e-mail
			<a mailto="lgdp@tagme.com.br" target="_blank">lgdp@tagme.com.br</a> em caso de dúvidas e também exercer
			quaisquer de seus direitos, incluindo, mas não se limitando à (i) confirmação de tratamento de seus
			dados pessoais, (ii) acesso, correção, portabilidade e eliminação dos dados pessoais e (iii) informações
			sobre compartilhamento de dados pessoais com terceiros. A Tagme responderá sua solicitação em prazo
			razoável, de forma transparente e fundamentada.<br />
			<strong
				>A Tagme não se responsabiliza pelo uso indevido das informações captadas diretamente pelo restaurante
				ou terceiros.</strong
			>
		</p>
		<div class="waitlistTerms__content__checkbox">
			<input
				class="waitlistTerms__content__input"
				id="terms"
				type="checkbox"
				formControlName="waitlist"
				ngModel
				[checked]="form.value.waitlist || null"
			/>
			<label class="waitlistTerms__content__label" for="terms">
				<span class="waitlistTerms__content__label__span">Li e concordo</span> com os termos e condições da
				Lista de Espera, Reservas e LiveMenu.
			</label>
		</div>
	</div>

	<!-- WAITLIST TERMS FOOTER -->
	<section class="waitlistTerms__footer">
		<button
			class="waitlistTerms__footer__button button gradientHorizontal"
			[disabled]="!form.value.waitlist || form.invalid || loadingService.getIsLoadingBySlug('rightbar')"
		>
			<span class="waitlist__footer__button__text">Confirmar fila de espera</span>
			<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
		</button>
	</section>
</form>

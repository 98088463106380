import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'ch-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	@Input() slug: string = '001'
	@Input() title: string = ''

	constructor() {}

	ngOnInit(): void {}
}

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { LoadingService } from '../../loading/loading.service'
import { LoginPhoneService } from '../login-phone.service'

@Component({
	selector: 'ch-login-phone001',
	templateUrl: './login-phone001.component.html',
	styleUrls: ['./login-phone001.component.scss'],
})
export class LoginPhone001Component implements OnInit {
	public form: FormGroup = new FormGroup({
		phone: new FormControl(null, Validators.required),
	})

	constructor(public loginPhoneService: LoginPhoneService, public loadingService: LoadingService) {}

	ngOnInit(): void {}

	handleSubmit() {
		this.loadingService.setIsLoadingBySlug('generic', true)
		return this.loginPhoneService.sendPhone(this.form.value.phone)
	}
}

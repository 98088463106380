import { Component, OnInit } from '@angular/core'
import { Client } from 'src/app/component/client/client.interface'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'

@Component({
	selector: 'ch-my-profile',
	templateUrl: './my-profile.component.html',
	styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
	constructor(public navigationService: NavigationService, public clientAuthService: ClientAuthService) {}

	ngOnInit(): void {}
}

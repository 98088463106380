<ch-mosaic002
	*ngIf="slug === '002'"
	[mosaicSlug]="mosaicSlug"
	[notFound]="notFound"
	[beforeVenues]="beforeVenues"
	[voucherValue]="voucherValue"
></ch-mosaic002>
<ch-mosaic001
	*ngIf="slug === '001'"
	[mosaicSlug]="mosaicSlug"
	[notFound]="notFound"
	[beforeVenues]="beforeVenues"
></ch-mosaic001>

import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'
import { NavigationService } from 'src/app/component/navigation/navigation.service'

@Component({
	selector: 'app-terms-of-user',
	templateUrl: './terms-of-user.component.html',
	styleUrls: ['./terms-of-user.component.scss'],
})
export class TermsOfUserComponent implements OnInit {
	constructor(public navigationService: NavigationService, private title: Title) {}

	ngOnInit(): void {
		window.scrollTo(0, 0)

		this.title.setTitle(`${PREFIX_TITLE} - Termos de uso`)
		this.navigationService.setActivatedRightbarBySlug('')
	}
}

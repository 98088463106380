<div class="orderVenue" *ngIf="order">
	<div class="orderVenue__left">
		<div class="orderVenue__left__picture desktopOnly">
			<img
				class="orderVenue__left__picture__image"
				[src]="env.tagmepub + '/pubimg/' + order.venue.partner.images.thumb[0]?.coverUrl"
			/>
		</div>
		<div class="orderVenue__left__date">
			<p class="orderVenue__left__date__number">{{ day }}</p>
			<p class="orderVenue__left__date__month">{{ month }}</p>
		</div>
	</div>
	<div class="orderVenue__right">
		<h3 class="orderVenue__right__name">{{ order.venue.name }}</h3>
		<p class="orderVenue__right__localization">
			{{ order.venue.address.neighborhood }} - {{ order.venue.address.city }}
		</p>
		<p class="orderVenue__right__service">{{ getFormatedIntent() }}</p>
		<div class="orderVenue__right__line">
			<a class="orderVenue__right__details" (click)="seeDetails()">Ver detalhes</a>
			<!-- <button
				class="orderVenue__right__button button"
				(click)="handlePayment(order)"
				*ngIf="canPay$ | async"
			>
				Pagar a conta
			</button> -->
			<button class="orderVenue__right__button buttonDisabled" *ngIf="wasPaid">Conta Paga</button>
		</div>
	</div>
</div>

<!-- MODAL COMPLETE SEU CADASTRO -->
<ch-modal slug="001" modalSlug="modal-confirm-registration_{{ order?._id }}">
	<div class="modalContent" content>
		<div class="modalContent__header">
			<h2 class="modalContent__title">Complete o cadastro do cartão</h2>
		</div>
		<div class="modalContent__body">
			<p class="modalContent__text">
				Você tem os 6 primeiros dígitos do cartão preenchido. É necessário preencher os outros campos de
				cadastro para pagar a conta online e ganhar seus benefícios!
			</p>
		</div>
		<div class="modalContent__footer">
			<button class="modalContent__button button" (click)="handleConfirmRegisration()">
				Completar cadastro
			</button>
			<button class="modalContent__buttonGhost buttonGhost" (click)="closeModal()">
				Não quero os benefícios
			</button>
		</div>
	</div>
</ch-modal>

<div class="page">
	<div class="page__header">
		<ch-search slug="001"></ch-search>
		<app-bradesco-navbar></app-bradesco-navbar>
		<ch-rightbar></ch-rightbar>
	</div>
	<div class="page__body" *ngIf="venue">
		<section class="menuDigital">
			<div class="menuDigital__wrapper wrapper">
				<a class="menuDigital__return" (click)="goBack()">
					<i class="menuDigital__return__icon tagmeicon tagmeicon-voltar"></i>
					Voltar
				</a>

				<div class="menuDigital__venue">
					<div class="menuDigital__venue__box">
						<h1 class="menuDigital__venue__box__name">{{ venue.name }}</h1>
						<div class="menuDigital__venue__box__infos">
							<p class="menuDigital__venue__box__infos__city">
								{{ venue.address.city }} - {{ venue.address.state }} • {{ venue.address.neighborhood }}
							</p>
							<p class="menuDigital__venue__box__infos__address">{{ venue.address.address }}</p>
							<p class="menuDigital__venue__box__infos__phone">{{ venue.phone[0].value }}</p>
						</div>
					</div>
				</div>

				<iframe [src]="liveMenuURL" width="100%" height="768" frameborder="0"></iframe>
			</div>
		</section>
	</div>
	<div class="page__footer">
		<ch-footer slug="001"></ch-footer>
	</div>
</div>

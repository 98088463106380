export function createBrands(maxPartySize: number | undefined) {
	return [...Array(maxPartySize).keys()].map(id => {
		const number = id + 1
		if (number === 1) {
			return { title: `Mesa para 1 pessoa`, id: number }
		}
		return {
			title: `Mesa para ${number} pessoas`,
			id: number,
		}
	})
}

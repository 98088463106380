import { Component, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'
import { BenefitService } from 'src/app/component/benefit/benefit.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { ModalService } from 'src/app/component/modal/modal.service'
import { PillService } from 'src/app/component/pill/pill.service'
import { ConciergeAuthService } from 'src/app/helper/concierge-auth.service'
import { ConciergeService } from './concierge.service'

@Component({
	selector: 'app-concierge',
	templateUrl: './concierge.component.html',
	styleUrls: ['./concierge.component.scss'],
})
export class ConciergeComponent implements OnInit {
	public form: FormGroup = new FormGroup({
		password: new FormControl(null, [Validators.required]),
		newPassword: new FormControl(null, [Validators.required]),
		passwordConfirmation: new FormControl(null),
	})

	public formErrors: any = {
		required: 'Campo obrigatório.',
		passwordConfirmation: 'Senhas não estão iguais.',
	}

	constructor(
		public pillService: PillService,
		public modalService: ModalService,
		public loadingService: LoadingService,
		public conciergeAuthService: ConciergeAuthService,
		public conciergeService: ConciergeService,
		public sharedService: SharedService,
		public benefitService: BenefitService,
		private title: Title
	) {}

	ngOnInit(): void {
		window.scrollTo(0, 0)
		this.benefitService.setIsAcessingFromCelebration(false)
		this.form.get('passwordConfirmation')?.setValidators([Validators.required, this.newPasswordConfirm])
		this.title.setTitle(`${PREFIX_TITLE} - Concierge Home`)
	}

	newPasswordConfirm(control: AbstractControl): { [key: string]: any } | null {
		// TODO: Tentar acessar do this.form para pegar o valor de ['newPassword']
		const form: any = control.parent?.controls
		const newPassword: any = form['newPassword']

		if (!control.value || control.value !== newPassword.value) return { passwordConfirmation: true }

		return null
	}

	getErrorBySlug(slug: string) {
		if (!this.form.controls[slug].touched) return

		const error = Object.keys(this.form.controls[slug].errors || {})
		return error.length ? this.formErrors[error[0]] : ''
	}

	handleSubmit() {
		if (this.form.invalid)
			return this.sharedService.swal.error('Ocorreu um erro no preenchimento do formulário.')

		this.loadingService.setIsLoadingBySlug('generic', true)

		const concierge = this.sharedService.localStorage.get('concierge')

		const form = { ...this.form.value, username: concierge.username }
		return this.conciergeService.changePassword(form, concierge._id).subscribe(
			res => {
				this.sharedService.swal.success('Senha alterada com sucesso.')
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
			error => {
				this.sharedService.swal.error(error.error?.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			}
		)
	}
}

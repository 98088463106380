import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'ch-footer002',
	templateUrl: './footer002.component.html',
	styleUrls: ['./footer002.component.scss'],
})
export class Footer002Component implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}

enum BRAND_PAGARME {
	Elo = 'elo',
	Mastercard = 'mastercard',
	Visa = 'visa',
	Diners = 'diners',
	Amex = 'amex',
	Hipercard = 'hipercard',
	VR = 'vr',
	Sodexo = 'sodexo',
	Alelo = 'alelo',
}

function chooseBrand(value: any) {
	const chooseBrand: any = {
		[BRAND_PAGARME.Alelo]: {
			src: 'card-alelo.svg',
			alt: 'Alelo',
		},
		[BRAND_PAGARME.Amex]: {
			src: 'card-amex.svg',
			alt: 'Amex',
		},
		[BRAND_PAGARME.Diners]: {
			src: 'card-diners.svg',
			alt: 'Diners',
		},
		[BRAND_PAGARME.Elo]: {
			src: 'card-elo.svg',
			alt: 'Elo',
		},
		[BRAND_PAGARME.Hipercard]: {
			src: 'card-hipercard.svg',
			alt: 'Hipercard',
		},
		[BRAND_PAGARME.Mastercard]: {
			src: 'card-mastercard.svg',
			alt: 'Mastercard',
		},
		[BRAND_PAGARME.Sodexo]: {
			src: 'card-sodexo.svg',
			alt: 'Sodexo',
		},
		[BRAND_PAGARME.Visa]: {
			src: 'card-visa.svg',
			alt: 'Visa',
		},
		[BRAND_PAGARME.VR]: {
			src: 'card-hipercard.svg',
			alt: 'Hipercard',
		},
	}
	const result = chooseBrand[value] ? chooseBrand[value] : undefined
	return result
}

function formatCPF(cpf: string) {
	return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const PaymentCardHelper = {
	chooseBrand,
	formatCPF,
}

<!-- MODAL CONFIRMAÇÃO CADASTRO -->
<div class="modal" [ngClass]="{ opened }">
	<div class="modal__content">
		<div class="modal__header">
			<h2 class="modal__title">Complete o cadastro do cartão</h2>
		</div>
		<div class="modal__body">
			<p class="modal__text">
				Você tem os 6 primeiros dígitos do cartão preenchido. É necessário preencher os outros campos de
				cadastro para pagar a conta online e ganhar seus benefícios!
			</p>
		</div>
		<div class="modal__footer">
			<button class="modal__button button" (click)="onConfirm.emit()">Completar cadastro</button>
			<button class="modal__button modal__button--ghost buttonGhost" (click)="onClose.emit()">
				Não quero os benefícios
			</button>
		</div>
	</div>
</div>

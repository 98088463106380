import { AbstractControl, AsyncValidatorFn } from '@angular/forms'
import { catchError, map, of, shareReplay, Subject } from 'rxjs'
import { RegisterRightbarService } from './register-rightbar.service'

// Custom Validation
function nameIsValid(control: AbstractControl): { [key: string]: boolean } | null {
	const regex = new RegExp(/^([A-zÁ-ú]){2,}\s+([A-zÁ-ú])+/)
	if (!regex.test(control.value)) {
		return { invalidName: true }
	}
	return null
}

// Async Validation for Code
function categoryIsValid(
	registerRightBarService: RegisterRightbarService,
	cardName: Subject<string | undefined>
): AsyncValidatorFn {
	return (control: AbstractControl) => {
		return registerRightBarService.checkIfCategoryExists(control.value).pipe(
			catchError(() => {
				return of({})
			}),
			map((res: any) => {
				// category is valid
				if (res.name) {
					cardName.next(res.name)
					return null
				}
				// category is invalid
				cardName.next(undefined)
				return { invalidCategory: true }
			}),
			shareReplay(1)
		)
	}
}

// Export custom validations
export const Validation = {
	nameIsValid,
	categoryIsValid,
}

// Helper functions
function normalizeEmail(email: string) {
	return email.toLowerCase()
}

function normalizePhone(phone: string) {
	return `55${phone}`
}

// Export Helper functions
export const Helper = {
	normalizeEmail,
	normalizePhone,
}

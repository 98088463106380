import { Injectable } from '@angular/core'
import * as _ from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { Venue } from '../venue/venue.interface'
import { Mosaic } from './mosaic.interface'

@Injectable({
	providedIn: 'root',
})
export class MosaicService {
	public mosaic = new BehaviorSubject<Mosaic[]>([
		{
			page: 'home',
			slug: 'guiaMichelin',
			type: ['Guia Michelin'],
			title: 'Restaurantes Guia MICHELIN',
			hasSearch: false,
			hasStar: false,
			venues: [],
			name: '',
			limit: 12,
			pagination: 1,
			venuesAlreadyInView: [],
		},
		{
			page: 'home',
			slug: 'momento_bbi',
			type: ['Momento BBI'],
			title: 'Momento Outback e Abbraccio',
			hasSearch: false,
			hasStar: false,
			venues: [],
			name: '',
			limit: 12,
			pagination: 1,
			venuesAlreadyInView: [],
		},
		{
			page: 'home',
			slug: 'sofisticados',
			type: ['Sofisticado'],
			title: 'Restaurantes sofisticados',
			hasSearch: false,
			hasStar: false,
			venues: [],
			name: '',
			limit: 12,
			pagination: 1,
			venuesAlreadyInView: [],
		},
		{
			page: 'home',
			slug: 'aspiracional',
			type: ['Aspiracional'],
			title: 'Restaurantes Aspiracionais',
			hasSearch: false,
			hasStar: false,
			venues: [],
			name: '',
			limit: 12,
			pagination: 1,
			venuesAlreadyInView: [],
		},
		{
			page: 'home',
			slug: 'casual',
			type: ['Casual'],
			title: 'Restaurantes casuais',
			hasSearch: false,
			hasStar: false,
			venues: [],
			name: '',
			limit: 12,
			pagination: 1,
			venuesAlreadyInView: [],
		},
		{
			page: 'home',
			slug: 'bares',
			type: ['Bar'],
			title: 'Bares',
			hasSearch: false,
			hasStar: false,
			venues: [],
			name: '',
			limit: 12,
			pagination: 1,
			venuesAlreadyInView: [],
		},
		{
			page: 'home',
			slug: 'fast',
			type: ['Fast'],
			title: 'Restaurantes Fast',
			hasSearch: false,
			hasStar: false,
			venues: [],
			name: '',
			limit: 12,
			pagination: 1,
			venuesAlreadyInView: [],
		},
		{
			page: 'favorite',
			slug: 'favorite',
			type: ['Casual', 'Sofisticado', 'Celebrations', 'Aspiracional'],
			title: 'Favoritos',
			hasSearch: false,
			hasStar: false,
			venues: [],
			name: '',
			limit: 12,
			pagination: 1,
			venuesAlreadyInView: [],
			favOnly: true,
		},

		{
			page: 'celebration_home',
			slug: 'celebration',
			type: ['Celebrations'],
			title: 'Restaurantes Celebrations',
			hasSearch: true,
			hasStar: false,
			venues: [],
			name: '',
			limit: 12,
			pagination: 1,
			venuesAlreadyInView: [],
		},
		{
			page: 'search_result',
			slug: 'search_result',
			// The correct way should be an empty array to get all types, but we need to fix for not searching Celebrations Type
			// At the moment of this code, is everyone except Celebrations because we should only show venues that only has type celebrations
			// to those who has voucher celebrations
			type: ['Casual', 'Sofisticado', 'Aspiracional', 'Momento BBI', 'Guia Michelin', 'Bar'],
			title: 'Resultado de busca',
			hasSearch: false,
			hasStar: false,
			venues: [],
			name: '',
			limit: 12,
			pagination: 1,
			venuesAlreadyInView: [],
		},
	])

	constructor() {}

	public setMosaic(mosaic: Mosaic[]) {
		const newMosaic = mosaic.map(item => this.withUniqueVenues(item))
		this.mosaic.next(newMosaic)
	}

	public getMosaic() {
		return this.mosaic.asObservable()
	}

	setMosaicBySlug(slug: string, mosaic: Mosaic) {
		const newMosaic = this.mosaic
			.getValue()
			.map(item => (item.slug === slug ? this.withUniqueVenues(mosaic) : item))
		this.mosaic.next(newMosaic)
	}

	withUniqueVenues(mosaic: Mosaic): Mosaic {
		mosaic.venues = _.uniqBy(mosaic.venues, '_id')
		return mosaic
	}

	getMosaicBySlug(slug: string) {
		return this.mosaic.getValue().find(item => item.slug === slug)
	}

	getMosaicByPage(page: string) {
		return this.mosaic.getValue().filter(item => item.page === page)
	}

	setVenue(venue: Venue) {
		const mosaic = this.mosaic.getValue().map(item => {
			item.venues = item.venues.map(current => (venue._id === current._id ? venue : current))
			return item
		})
		this.mosaic.next(mosaic)
	}
}

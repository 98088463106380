import { Injectable } from '@angular/core'
import * as CryptoJS from 'crypto-js'
import { environment } from 'src/environments/environment'
import Swal from 'sweetalert2'

@Injectable({
	providedIn: 'root',
})
export class SharedService {
	public swalConfig = {
		confirmButtonText: 'Entendi',
		customClass: {
			confirmButton: 'button confirm',
			cancelButton: 'button cancel',
		},
		buttonsStyling: false,
	}
	constructor() {}

	public cryptoJS = {
		encode: (context: any) => {
			return CryptoJS.AES.encrypt(JSON.stringify(context), environment.cryptoJSKey).toString()
		},
		decode: (token: string) => {
			const bytes = CryptoJS.AES.decrypt(token, environment.cryptoJSKey)
			if (bytes.toString()) {
				return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
			}
			return token
		},
	}

	public localStorage = {
		get: (name: string) => {
			let data = JSON.parse(localStorage.getItem(name) || '{}')
			data = this.isHttps() ? this.cryptoJS.decode(data) : data
			return Object.keys(data).length ? data : false
		},
		set: (name: string, data: any) => {
			data = JSON.stringify(this.isHttps() ? this.cryptoJS.encode(data) : data)
			localStorage.setItem(name, data)
		},
		remove: (name: string) => {
			localStorage.removeItem(name)
		},
	}

	public isHttps() {
		return location.protocol === 'https:'
	}

	public swal = {
		error: (title: string = '', text: string = '') => {
			Swal.fire({ ...this.swalConfig, title: title || 'Ocorreu um erro. Tente novamente mais tarde.', text })
		},
		success: (title: string = '', text: string = '') => Swal.fire({ ...this.swalConfig, title, text }),
	}
}

import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { BehaviorSubject, Subject, Subscription, filter, switchMap, take, tap } from 'rxjs'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { ReservationService } from 'src/app/component/reservation/reservation.service'
import { Venue } from 'src/app/component/venue/venue.interface'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { NavigationHandle } from '../../profile/new-card/navigation-handle.service'
import { VenuePageService } from '../../venue-page/venue-page.service'

@Component({
	selector: 'ch-reservation-form',
	templateUrl: './reservation-form.component.html',
	styleUrls: ['./reservation-form.component.scss'],
})
export class ReservationFormComponent implements OnInit, OnDestroy {
	public availabilities: any = []
	public selectedReservationDay: any | undefined
	public selectedSection: any | undefined
	public selectedSchedule: any | undefined
	public selectedReservationTime: any | undefined
	public subs = new Subscription()
	public venue: Venue | null = this.reservationService.reservation.getValue().venue
	public openRegisterCard = false
	public useCelebrations = true
	private triggerCheckAvailability = new BehaviorSubject<null | void>(null)
	public voucherValue!: number
	public venueHasCelebrations = false

	private destroy$ = new Subject<void>()

	public form: FormGroup = new FormGroup({
		id: new FormControl(null, [Validators.required]),
		conciergeUser: new FormControl(null, []),
		people_amount: new FormControl(null, [Validators.required]),
		environment: new FormControl(null, [Validators.required]),
		date: new FormControl(null, [Validators.required]),
		time: new FormControl(null, [Validators.required]),
		credit_card: new FormControl(null, [Validators.required]),
	})

	constructor(
		public navigationService: NavigationService,
		public reservationService: ReservationService,
		public loadingService: LoadingService,
		public sharedService: SharedService,
		public venuePageService: VenuePageService,
		public clientAuthService: ClientAuthService,
		private navigationHandle: NavigationHandle
	) {}

	ngOnInit(): void {
		const reservation = this.reservationService.reservation.getValue()
		const concierge = this.sharedService.localStorage.get('concierge')
		const body = {
			venueId: reservation.venue?._id,
			conciergeUser: concierge._id || null,
		}
		this.subs.add(
			this.triggerCheckAvailability
				.pipe(
					switchMap(() => this.clientAuthService.client$.pipe(take(1))),
					tap(() => this.resetState()),
					switchMap(client => {
						const voucher = client?.vouchers.length ? client.vouchers[0] : null
						const selectedCategory = client?.categories?.find(category => category.selected)
						this.venueHasCelebrations = this.venuePageService.hasCelebrations()
						if (this.venueHasCelebrations && voucher?._id && this.useCelebrations && selectedCategory) {
							this.voucherValue = voucher.value
							return this.reservationService.checkAvailability({
								...body,
								voucherId: voucher._id,
								categoryId: selectedCategory.categoryId,
							})
						}
						if (selectedCategory && body?.venueId) {
							return this.reservationService
								.checkAvailability({
									...body,
									categoryId: selectedCategory.categoryId,
								})
								.pipe(take(1))
						}
						throw new Error('Nenhum cartão selecionado')
					})
				)
				.subscribe({
					next: res => {
						this.availabilities =
							res.availabilities.sort((a: any, b: any) => {
								// Convertendo as strings para objetos Date para comparação
								const dateA = new Date(a.reservationDay)
								const dateB = new Date(b.reservationDay)

								// Comparando as datas
								return dateA.getTime() - dateB.getTime()
							}) || []
						this.form.patchValue({ id: res.order._id })
					},
					error: error => {
						this.navigationService.setActivatedRightbarBySlug('')
						this.sharedService.swal.error(error.error?.message)
					},
				})
		)

		this.subs.add(
			this.clientAuthService.selectedCategory$
				.pipe(
					filter(selectedCategory => !Boolean(selectedCategory)),
					tap(() => (this.openRegisterCard = true))
				)
				.subscribe()
		)

		this.subs.add(
			this.clientAuthService.receiveClient().subscribe(() => {
				this.handleCreditCardField(null, this.clientAuthService.getSelectedCategory()?.categoryId)
			})
		)

		this.subs.add(
			this.venuePageService.receiveVenue().subscribe(res => {
				this.reservationService.setReservationByKey('venue', res)
				this.venue = res
			})
		)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	toggleUseCelebrations(event: boolean) {
		this.useCelebrations = event
		this.triggerCheckAvailability.next()
	}

	resetState() {
		this.availabilities = []
		this.selectedReservationDay = undefined
		this.selectedSection = undefined
		this.selectedSchedule = undefined
		this.selectedReservationTime = undefined
		this.form.controls['date'].reset()
		this.form.controls['time'].reset()
	}

	getFormatedDate(date: string) {
		return date ? date.split('-').reverse().join('/') : ''
	}

	handleDateField(el: any, item: any) {
		if (item.reservationDay !== this.form.value.date) {
			this.form.patchValue({ environment: null })
			this.form.patchValue({ people_amount: null })
			this.form.patchValue({ time: null })
			this.selectedSection = null
			this.selectedSchedule = null
			this.selectedReservationTime = null
		}

		this.selectedReservationDay = item
		this.form.patchValue({ date: item.reservationDay })
		this.reservationService.sendReservation(this.form.value)
		if (el) el.target.blur()
	}

	handleSectionField(el: any, item: any) {
		if (item.id !== this.form.value.environment) {
			this.form.patchValue({ people_amount: null })
			this.form.patchValue({ time: null })
			this.selectedSchedule = null
			this.selectedReservationTime = null
		}

		this.selectedSection = { ...item, schedules: item.schedules.filter((section: any) => section.available) }
		this.form.patchValue({ environment: item.id })
		this.reservationService.sendReservation(this.form.value)
		if (el) el.target.blur()
	}

	handleScheduleField(el: any, item: any) {
		if (item.partySize !== this.form.value.people_amount) {
			this.form.patchValue({ time: null })
			this.selectedReservationTime = null
		}

		this.selectedSchedule = {
			...item,
			reservationTimes: item.reservationTimes.filter((reservationTime: any) => reservationTime.available),
		}
		this.form.patchValue({ people_amount: item.partySize })
		this.reservationService.sendReservation(this.form.value)
		if (el) el.target.blur()
	}

	handleReservationTimeField(el: any, item: any) {
		this.selectedReservationTime = item
		this.form.patchValue({ time: item.reservationTime })
		this.reservationService.sendReservation(this.form.value)
		if (el) el.target.blur()
	}

	handleCreditCardField(el: any, value: any) {
		this.form.patchValue({ credit_card: value })
		this.reservationService.sendReservation(this.form.value)
		if (el) el.target.blur()
	}

	handleSubmit() {
		if (this.form.invalid) return
		this.clientAuthService.hasRealCard$.pipe(take(1)).subscribe(value => {
			if (!value) {
				this.openRegisterCard = true
			} else {
				const concierge = this.sharedService.localStorage.get('concierge')
				this.reservationService.sendReservation({
					...this.form.value,
					environment_label: this.selectedSection.label,
					conciergeUser: concierge._id || null,
					voucherValue: this.useCelebrations ? this.voucherValue : null,
				})
				this.navigationService.setActivatedRightbarBySlug('reservation-data')
			}
		})
	}

	closeModal() {
		this.openRegisterCard = false
	}

	handleConfirmRegisration() {
		this.navigationHandle.setLastLocation('reservation')
		this.navigationService.setActivatedRightbarBySlug('new-card')
		this.closeModal()
	}
}

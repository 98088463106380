import { Injectable } from '@angular/core'
import { delay, Observable, of } from 'rxjs'
import { HttpService } from 'src/app/component/helper/http.service'
import { SharedService } from 'src/app/component/helper/shared.service'

@Injectable({
	providedIn: 'root',
})
export class EditProfileService {
	constructor(private httpservice: HttpService, private sharedService: SharedService) {}

	public getByLogged(): Observable<any> {
		const client = this.sharedService.localStorage.get('client')
		return this.httpservice.get(`api/v1/clients/${client._id}`)
	}

	public update(form: any): Observable<any> {
		const client = this.sharedService.localStorage.get('client')
		return this.httpservice.patch(`api/v1/clients/${client._id}`, form)
	}
}

<div class="register">
	<section class="register__header">
		<div class="register__header__top">
			<a class="register__header__return" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="register__header__return__icon tagmeicon tagmeicon-voltar"></i>
				Voltar
			</a>
			<div class="register__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="register__header__close__icon tagmeicon tagmeicon-close"></i>
			</div>
		</div>
	</section>

	<div class="register__top">
		<h2 class="register__top__welcome">Seja Bem-vindo(a)!</h2>
		<p class="register__top__desc">
			Faça seu Login ou crie uma conta no Programa Menu para reservar uma mesa ou entrar na fila de espera e
			ter os benefícios do seu cartão!
		</p>
	</div>

	<section class="register__body">
		<span class="register__form__title">Cadastre-se</span>

		<ng-container *ngIf="step[0].isActive">
			<form class="register__form" [formGroup]="firstStepForm" (ngSubmit)="handleFirstSubmit()">
				<div class="field">
					<div class="field__wrapper">
						<div class="field__icon left fa fa-celular"></div>
						<input
							type="text"
							class="field__input"
							formControlName="phone"
							placeholder="Número do celular"
							mask="(00) 00000-0000"
						/>
					</div>

					<span class="field__feedback" *ngIf="getErrorBySlug('first', 'phone')"
						>Por favor, preencha o seu número de telefone.</span
					>
				</div>
				<div class="field">
					<div class="field__wrapper">
						<div class="field__icon left fa fa-email"></div>
						<input type="email" class="field__input" formControlName="email" placeholder="Seu email" />
					</div>
					<span class="field__feedback" *ngIf="getErrorBySlug('first', 'email')">
						Por favor, preencha o seu e-mail.
					</span>
				</div>
				<button
					type="submit"
					class="register__form__button buttonWhite"
					(click)="handleFirstSubmit()"
					[disabled]="!firstStepForm.valid || loadingService.getIsLoadingBySlug('rightbar')"
				>
					<span class="register__form__button__text">Receber Código</span>
					<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
				</button>
			</form>
		</ng-container>

		<ng-container *ngIf="step[1].isActive">
			<form class="register__form" [formGroup]="secondStepForm" (ngSubmit)="handleSecondSubmit()">
				<p class="register__form__text">
					Enviamos para você um código de verificação através do email informado.
				</p>
				<div class="field">
					<div class="field__wrapper">
						<div class="field__icon left fa fa-chave-acesso"></div>
						<input
							type="code"
							class="field__input"
							formControlName="code"
							placeholder="Digite o código de verificação"
						/>
					</div>
					<span class="field__feedback" *ngIf="getErrorBySlug('second', 'code')">
						Por favor, preencha o código de verificação.
					</span>
				</div>
				<div class="update-password__form" formGroupName="passwordGroup">
					<div class="field">
						<div class="field__wrapper">
							<label for="password" class="field__icon left tagmeicon tagmeicon-block-on"></label>
							<input
								type="password"
								id="password"
								class="field__input"
								formControlName="password"
								placeholder="senha"
								[minlength]="8"
								[maxlength]="20"
							/>
						</div>
						<div
							class="field__feedback"
							*ngIf="
								secondStepForm.get('passwordGroup')?.get('password')?.invalid &&
								(secondStepForm.get('passwordGroup')?.get('password')?.dirty ||
									secondStepForm.get('passwordGroup')?.get('password')?.touched)
							"
						>
							<div *ngIf="secondStepForm.get('passwordGroup')?.get('password')?.hasError('required')">
								A senha é obrigatória.
							</div>
							<div *ngIf="secondStepForm.get('passwordGroup')?.get('password')?.hasError('passwordLength')">
								A senha precisa ter entre 8 a 20 caracteres.
							</div>
							<div
								*ngIf="secondStepForm.get('passwordGroup')?.get('password')?.hasError('passwordUppercase')"
							>
								A senha precisa ter no mínimo um digito em letra maiúscula.
							</div>
							<div
								*ngIf="secondStepForm.get('passwordGroup')?.get('password')?.hasError('passwordLowercase')"
							>
								A senha precisa ter no mínimo uma letra minúscula.
							</div>
							<div *ngIf="secondStepForm.get('passwordGroup')?.get('password')?.hasError('passwordSpecial')">
								A senha precisa ter no mínimo um caractere especial (!, @, #, $, &, *)
							</div>
						</div>
					</div>
					<div class="field">
						<div class="field__wrapper">
							<label for="confirmPassword" class="field__icon left tagmeicon tagmeicon-block-on"></label>
							<input
								type="password"
								class="field__input"
								id="confirmPassword"
								formControlName="confirmPassword"
								placeholder="confirmar senha"
								[minlength]="8"
								[maxlength]="20"
							/>
						</div>
						<div
							class="field__feedback"
							*ngIf="
								secondStepForm.get('passwordGroup')?.get('confirmPassword')?.invalid &&
								(secondStepForm.get('passwordGroup')?.get('confirmPassword')?.dirty ||
									secondStepForm.get('passwordGroup')?.get('confirmPassword')?.touched)
							"
						>
							<div *ngIf="secondStepForm.get('passwordGroup')?.get('confirmPassword')?.hasError('required')">
								A confirmação de senha é obrigatória.
							</div>
							<div
								*ngIf="
									secondStepForm.get('passwordGroup')?.get('confirmPassword')?.hasError('passwordMismatch')
								"
							>
								As senhas não coincidem.
							</div>
						</div>
					</div>
				</div>
				<div class="register__form__buttons">
					<button
						class="register__form__button buttonWhite"
						(click)="handleSecondSubmit()"
						[disabled]="!secondStepForm.valid || loadingService.getIsLoadingBySlug('rightbar')"
					>
						<span class="register__form__button__text">Confirmar</span>
						<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
					</button>
					<ng-container *ngIf="countdown$ | async as value; else resend">
						<button type="button" class="register__form__button buttonWhite" disabled>
							<span class="register__form__button__text">Reenviar o código em {{ value }} segundos.</span>
						</button>
					</ng-container>

					<ng-template #resend>
						<button type="button" class="register__form__button buttonWhite" (click)="resendCode()">
							<span class="register__form__button__text">Reenviar o código</span>
						</button>
					</ng-template>
				</div>
			</form>
		</ng-container>

		<ng-container *ngIf="step[2].isActive">
			<form class="register__form" [formGroup]="thirdStepForm" (ngSubmit)="handleThirdSubmit()">
				<div class="field">
					<div class="field__wrapper">
						<div class="field__icon left tagmeicon tagmeicon-nome"></div>
						<input type="text" class="field__input" formControlName="name" placeholder="Nome e sobrenome" />
					</div>
					<span class="field__feedback" *ngIf="getErrorBySlug('third', 'name')">
						Por favor, preencha o nome e sobrenome.
					</span>
				</div>
				<div class="field">
					<div class="field__wrapper">
						<div class="field__icon left tagmeicon tagmeicon-cpf"></div>
						<input
							type="text"
							class="field__input"
							formControlName="cpf"
							placeholder="Seu CPF"
							mask="000.000.000-00"
						/>
					</div>
					<span class="field__feedback" *ngIf="getErrorBySlug('third', 'cpf')">
						Por favor, preencha o CPF.
					</span>
				</div>
				<div class="field">
					<div class="field__wrapper">
						<div class="field__icon left tagmeicon tagmeicon-cartao-de-credito"></div>
						<input
							type="text"
							class="field__input"
							formControlName="category"
							placeholder="6 primeiros dígitos do cartão"
							mask="000000"
						/>
					</div>
					<span class="field__feedback" *ngIf="getErrorBySlug('third', 'category')">
						Por favor, preencha os primeiros 6 dígitos do cartão.
					</span>
					<span class="field__feedback" *ngIf="cardName$ | async as cardName">
						Cartão encontrado: {{ cardName }}
					</span>
				</div>
				<div class="field">
					<div class="field__wrapper">
						<label for="password" class="field__icon left tagmeicon tagmeicon-block-on"></label>
						<input
							type="password"
							id="password"
							class="field__input"
							formControlName="password"
							placeholder="senha"
							[minlength]="8"
							[maxlength]="20"
						/>
					</div>
					<span class="field__feedback" *ngIf="getErrorBySlug('third', 'password')">
						Por favor, digite uma senha.
					</span>
				</div>

				<div class="confirm {{ thirdStepForm.get('eula')?.value ? 'confirmed' : '' }}">
					<input type="radio" formControlName="eula" id="eula" [value]="true" class="none" />
					<label class="confirm__check" for="eula"></label>
					<span class="confirm__label">
						<span class="confirm__label__strong">Li e concordo com os</span>
						<span class="confirm__label__link"> termos de uso</span>
						e
						<span class="confirm__label__link"> política de privacidade</span>
						do Programa Menu
					</span>
				</div>
				<button
					class="register__form__button buttonWhite"
					(click)="handleThirdSubmit()"
					[disabled]="!thirdStepForm.valid || loadingService.getIsLoadingBySlug('rightbar')"
				>
					<span class="register__form__button__text">Concluir cadastro</span>
					<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
				</button>
			</form>
		</ng-container>
		<p class="register__form__new_account">
			Já possui um cadastro,
			<span
				class="register__form__new_account__span"
				(click)="navigationService.setActivatedRightbarBySlug('login-rightbar')"
			>
				clique aqui
			</span>
		</p>
	</section>

	<section class="register__footer">
		<i class="register__footer__icon bradesco fa fa-logo-bradesco"></i>
		<p class="register__footer__txt">powered by</p>
		<i class="register__footer__icon tagme fa fa-logo-tagme"></i>
	</section>
</div>

import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { BenefitService } from '../component/benefit/benefit.service'

@Injectable({
	providedIn: 'root',
})
export class GlobalMiddlewareService implements CanActivate {
	constructor(private benefitService: BenefitService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		;(route.url || []).length
			? this.benefitService.setIsAcessingFromCelebration(route.url[0].path === 'celebrations')
			: this.benefitService.setIsAcessingFromCelebration(false)

		return true
	}
}

<div class="page">
	<div class="page__header">
		<ch-search slug="001"></ch-search>
		<app-bradesco-navbar></app-bradesco-navbar>
		<ch-rightbar></ch-rightbar>
	</div>
	<div class="page__body">
		<ch-sub-header slug="001" title="FAQ" bgImage="./../../../assets/images/faq-image.jpg"></ch-sub-header>
		<section class="faq">
			<div class="faq__wrapper wrapper">
				<div class="faq__row">
					<div class="faq__col">
						<div class="faq__item" *ngFor="let item of faq[0]">
							<div class="faq__item__header">
								<span class="faq__item__question">{{ item.question }}</span>
							</div>
							<div class="faq__item__body">
								<span class="faq__item__answer" [innerHTML]="item.answer"></span>
							</div>
						</div>
					</div>
					<div class="faq__col">
						<div class="faq__item" *ngFor="let item of faq[1]">
							<div class="faq__item__header">
								<span class="faq__item__question">{{ item.question }}</span>
							</div>
							<div class="faq__item__body">
								<span class="faq__item__answer" [innerHTML]="item.answer"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
	<div class="page__footer">
		<ch-footer slug="001"></ch-footer>
	</div>
</div>

import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { WaitlistService } from 'src/app/component/waitlist/waitlist.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'

@Component({
	selector: 'ch-waitlist-terms',
	templateUrl: './waitlist-terms.component.html',
	styleUrls: ['./waitlist-terms.component.scss'],
})
export class WaitlistTermsComponent implements OnInit, AfterViewInit {
	public form: FormGroup = this.fb.group({
		waitlist: [null, [Validators.required]],
	})

	constructor(
		public navigationService: NavigationService,
		public fb: FormBuilder,
		public sharedService: SharedService,
		public waitlistService: WaitlistService,
		public loadingService: LoadingService,
		public clientAuthService: ClientAuthService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.form.patchValue({ waitlist: true })
		this.cdr.detectChanges()
	}

	handleSubmit() {
		if (this.form.invalid || !this.form.value.waitlist)
			return this.sharedService.swal.error('Preencha o formulário corretamente.')

		const client = this.sharedService.localStorage.get('client')
		const form = { agreements: { ...client.agreements, waitlist: this.form.value.waitlist } }

		return this.waitlistService.agreeWithWaitlistTerms(form, client._id).subscribe(
			res => {
				this.clientAuthService.setClient(res)
				this.navigationService.setActivatedRightbarBySlug('waitlist-confirm')
			},
			error => {
				return this.sharedService.swal.error(error.error?.message)
			}
		)
	}
}

import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject, Subscription, switchMap, take, tap } from 'rxjs'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { ReservationService } from 'src/app/component/reservation/reservation.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { NavigationHandle } from '../../profile/new-card/navigation-handle.service'
import { VenuePageService } from '../../venue-page/venue-page.service'
import { CheckInService } from '../check-in.service'

@Component({
	selector: 'ch-check-in-form',
	templateUrl: './check-in-form.component.html',
	styleUrls: ['./check-in-form.component.scss'],
})
export class CheckInFormComponent implements OnInit, OnDestroy {
	form!: FormGroup
	venue$ = this.venuePageService.receiveVenue()
	today = new Date()
	partySizes: number[] | null = null
	voucherValue = null
	openRegisterCard = false
	subs = new Subscription()
	private triggerCheckAvailability = new BehaviorSubject<null | void>(null)
	venueHasCelebrations = false
	venue = this.venuePageService.getVenue()
	useCelebrations = true

	constructor(
		public navigationService: NavigationService,
		public reservationService: ReservationService,
		public loadingService: LoadingService,
		public sharedService: SharedService,
		private venuePageService: VenuePageService,
		private fb: FormBuilder,
		private clientAuth: ClientAuthService,
		private navigationHandle: NavigationHandle,
		private checkInService: CheckInService,
		private router: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			partySize: [null, Validators.required],
			confirmSeat: [false, Validators.requiredTrue],
		})

		const venueId = this.router.snapshot.paramMap.get('id') || ''

		this.subs.add(
			this.triggerCheckAvailability
				.pipe(
					switchMap(() => this.clientAuth.client$),
					tap(() => this.resetState()),
					switchMap(client => {
						const voucher = client?.vouchers.length ? client.vouchers[0] : null
						const selectedCategory = client?.categories?.find(category => category.selected)
						this.venueHasCelebrations = this.venuePageService.hasCelebrations()
						if (this.venueHasCelebrations && voucher?._id && this.useCelebrations && selectedCategory) {
							this.voucherValue = voucher.value
							return this.reservationService.checkAvailability({
								venueId,
								intent: 'checkin',
								voucherId: voucher._id,
								categoryId: selectedCategory.categoryId,
							})
						}
						if (selectedCategory) {
							return this.reservationService.checkAvailability({
								venueId,
								intent: 'checkin',
								categoryId: selectedCategory.categoryId,
							})
						}
						throw new Error('Nenhum cartão selecionado')
					})
				)
				.subscribe({
					next: res => {
						if (res.success) {
							this.checkInService.orderId = res.order._id
							this.partySizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
						} else {
							this.navigationService.setActivatedRightbarBySlug('')
							let messageError = res.error?.message + '<br/><br/>'
							messageError += `Horário de início: ${res.availabilities.startTime} <br/>`
							messageError += `Horário de fim: ${res.availabilities.endTime} <br/>`
							this.sharedService.swal.error(messageError)
						}
					},
					error: error => {
						this.navigationService.setActivatedRightbarBySlug('')
						this.sharedService.swal.error(error.error?.message)
					},
				})
		)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	selectPartySize(el: any, partySize: number) {
		this.form.patchValue({ partySize })
		if (el) el.target.blur()
	}

	goBack() {
		this.navigationService.setActivatedRightbarBySlug('')
	}

	toggleUseCelebrations(event: boolean) {
		this.useCelebrations = event
		this.triggerCheckAvailability.next()
	}
	resetState() {
		this.partySizes = null
	}

	handleSubmit() {
		if (this.form.invalid) {
			return
		}

		this.clientAuth.hasRealCard$.pipe(take(1)).subscribe(value => {
			if (!value) {
				this.openRegisterCard = true
			} else {
				this.checkInService.checkinData.reservationDay = this.checkInDate.reservationDay
				this.checkInService.checkinData.reservationTime = this.checkInDate.reservationTime
				this.checkInService.checkinData.partySize = this.form.value.partySize
				this.checkInService.checkinData.clientId = this.clientAuth.getClient()?._id as string
				this.nextNavigationHandle()
			}
		})
	}

	private nextNavigationHandle() {
		const hasAcceptedTerms = this.clientAuth.getClient()?.agreements?.checkin

		this.navigationService.setActivatedRightbarBySlug(
			hasAcceptedTerms ? 'check-in-confirm' : 'check-in-terms'
		)
	}

	closeModal() {
		this.openRegisterCard = false
	}

	handleConfirmRegistration() {
		this.navigationHandle.setLastLocation('checkin')
		this.navigationService.setActivatedRightbarBySlug('new-card')
		this.closeModal()
	}

	get checkInDate() {
		const tzoffset = this.today.getTimezoneOffset() * 60000 //offset in milliseconds
		const [day, time] = new Date(Date.now() - tzoffset).toISOString().slice(0, -8).split('T')
		return { reservationDay: day, reservationTime: time }
	}
}

import { Component, OnInit } from '@angular/core'
import { CelebrationReservation } from 'src/app/component/celebration-reservation/celebration-reservation.interface'
import { CelebrationReservationService } from 'src/app/component/celebration-reservation/celebration-reservation.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { Client } from './../../../component/client/client.interface'

@Component({
	selector: 'ch-celebration-reservation-confirm',
	templateUrl: './celebration-reservation-confirm.component.html',
	styleUrls: ['./celebration-reservation-confirm.component.scss'],
})
export class CelebrationReservationConfirmComponent implements OnInit {
	public confirmedReservation: any
	public reservation: CelebrationReservation = this.celebrationReservationService.reservation.getValue()
	public voucherValue: any

	constructor(
		public navigationService: NavigationService,
		public celebrationReservationService: CelebrationReservationService,
		public sharedService: SharedService,
		public clientAuthService: ClientAuthService
	) {}

	ngOnInit(): void {
		if (!this.reservation.id) return

		const concierge = this.sharedService.localStorage.get('concierge')

		const body = {
			partySize: this.reservation.people_amount,
			section: this.reservation.environment,
			sectionLabel: this.reservation.environment_label,
			reservationDay: this.reservation.date,
			reservationTime: this.reservation.time,
			clientId: this.clientAuthService.getClient()?._id,
		}

		this.celebrationReservationService.confirm(body, this.reservation.id).subscribe({
			next: res => {
				this.voucherValue = res.voucher?.value
				this.confirmedReservation = { ...this.celebrationReservationService.reservation.getValue(), ...res }
				this.celebrationReservationService.sendReservation({
					voucherId: null,
					venue: null,
					client: null,
					id: null,
					conciergeUser: null,
					people_amount: null,
					environment: null,
					environment_label: null,
					date: null,
					time: null,
					credit_card: null,
				})
				this.sharedService.localStorage.remove('celebration-reservation')

				const client = this.clientAuthService.getClient()

				if (client) {
					client.vouchers = []
					this.clientAuthService.setClient(client)
				}
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
			},
		})
	}

	getFormatedDate(date: string) {
		return date.split('-').reverse().join('/')
	}
}

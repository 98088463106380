import { Component, Input, OnInit, TemplateRef } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { Subscription, switchMap, take } from 'rxjs'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { BenefitService } from '../../benefit/benefit.service'
import { FilterService } from '../../filter/filter.service'
import { SharedService } from '../../helper/shared.service'
import { LoadingService } from '../../loading/loading.service'
import { VenueService } from '../../venue/venue.service'
import { Mosaic } from '../mosaic.interface'
import { MosaicService } from '../mosaic.service'

@Component({
	selector: 'ch-mosaic002',
	templateUrl: './mosaic002.component.html',
	styleUrls: ['./mosaic002.component.scss'],
})
export class Mosaic002Component implements OnInit {
	public reachedEnd: boolean = false
	public searchToggler: boolean = false
	public form: FormGroup = new FormGroup({
		name: new FormControl(null),
	})

	public $subs: Array<Subscription> = []
	@Input() mosaicSlug: string = ''
	@Input() mosaic: Mosaic | undefined
	@Input() notFound: TemplateRef<any> | null = null
	@Input() beforeVenues: TemplateRef<any> | null = null
	@Input() voucherValue = ''

	public loadingVenue: Array<any> = Array(16).fill(null)

	constructor(
		public loadingService: LoadingService,
		public filterService: FilterService,
		public mosaicService: MosaicService,
		public venueService: VenueService,
		public sharedService: SharedService,
		public clientAuthService: ClientAuthService,
		public benefitService: BenefitService
	) {}

	ngOnInit(): void {
		this.loadingService.addLoading({ slug: this.mosaicSlug, isLoading: true })
		this.mosaic = this.mosaicService.getMosaicBySlug(this.mosaicSlug)

		this.$subs.push(
			this.filterService.filter$.subscribe(() => {
				this.reachedEnd = false
				if (this.mosaic?.slug !== this.mosaicSlug) return

				this.mosaic = this.mosaicService.getMosaicBySlug(this.mosaicSlug)
				this.mosaicService.setMosaicBySlug(this.mosaicSlug, {
					...this.mosaic!,
					venues: [],
					venuesAlreadyInView: [],
					pagination: 1,
				})
				this.mosaic = this.mosaicService.getMosaicBySlug(this.mosaicSlug)
				this.getVenues()
			})
		)
	}

	ngOnDestroy(): void {
		this.mosaicService.setMosaicBySlug(this.mosaicSlug, { ...this.mosaic!, venues: [], pagination: 1 })
		this.$subs.forEach(sub => sub.unsubscribe())
	}

	resetedGetVenues() {
		this.filterService.updateVenues()
	}

	getVenues() {
		if (this.reachedEnd) return

		if (this.mosaic?.slug !== this.mosaicSlug)
			return this.loadingService.setIsLoadingBySlug(this.mosaicSlug, false)

		this.filterService
			.getSelectedAddressForFilter()
			.pipe(
				take(1),
				switchMap(address => {
					const filter = { address, creditCard: this.clientAuthService.getSelectedCategory() }
					this.loadingService.setIsLoadingBySlug(this.mosaicSlug, true)
					return this.venueService.getByPartner({ ...this.mosaic, ...filter })
				})
			)
			.subscribe({
				next: res => {
					this.reachedEnd = res.length < this.mosaic!.limit
					const venues = [...(this.mosaic?.venues || []), ...res]
					const pagination = this.mosaic?.pagination! + 1
					const venuesAlreadyInView = [
						...this.mosaic!.venues.map(item => item._id),
						...res.map((item: any) => item._id),
					]

					this.mosaicService.setMosaicBySlug(this.mosaicSlug, {
						...this.mosaic!,
						venues,
						pagination,
						venuesAlreadyInView,
					})
					this.mosaic = this.mosaicService.getMosaicBySlug(this.mosaicSlug)
					this.loadingService.setIsLoadingBySlug(this.mosaicSlug, false)
				},
				error: error => {
					this.sharedService.swal.error(error.error?.message)
				},
			})
	}
}

import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class CheckInService {
	checkinData = {
		partySize: 0,
		reservationDay: '',
		reservationTime: '',
		clientId: '',
	}

	orderId = ''
	venueName = ''
	clientName = ''
}

import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'ch-sub-header',
	templateUrl: './sub-header.component.html',
	styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent implements OnInit {
	@Input() slug: string = '001'
	@Input() title: string = ''
	@Input() bgImage: string = ''

	constructor() {}

	ngOnInit(): void {}
}

import { Component, OnInit } from '@angular/core'
import { NavigationService } from 'src/app/component/navigation/navigation.service'

@Component({
	selector: 'ch-about-tagme',
	templateUrl: './about-tagme.component.html',
	styleUrls: ['./about-tagme.component.scss'],
})
export class AboutTagmeComponent implements OnInit {
	constructor(public navigationService: NavigationService) {}

	ngOnInit(): void {}
}

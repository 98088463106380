<!-- STEP 3 - CÓDIGO DE 4 DÍGITOS-->
<section class="box">
	<div class="box__wrapper wrapper">
		<p class="box__title">
			Digite o código de 4 dígitos enviado<br />
			para o email joaosilva@gmail.com
		</p>
		<form class="box__form" [formGroup]="form" (ngSubmit)="handleSubmit()">
			<div class="box__form__group">
				<input
					class="box__form__input"
					formControlName="code01"
					maxlength="1"
					ngModel
					(keyup)="handleKeyup($event)"
				/>
				<input
					class="box__form__input"
					formControlName="code02"
					maxlength="1"
					ngModel
					(keyup)="handleKeyup($event)"
				/>
				<input
					class="box__form__input"
					formControlName="code03"
					maxlength="1"
					ngModel
					(keyup)="handleKeyup($event)"
				/>
				<input
					class="box__form__input"
					formControlName="code04"
					maxlength="1"
					ngModel
					(keyup)="handleKeyup($event)"
				/>
			</div>
			<p class="box__form__text">Você está quase lá! :D</p>
			<button
				class="box__form__button button"
				[disabled]="form.invalid || loadingService.getIsLoadingBySlug('generic')"
			>
				<span class="box__form__button__text">Entrar</span>
				<ch-loading *ngIf="loadingService.getIsLoadingBySlug('generic')" slug="button001"></ch-loading>
			</button>
			<a href="#" class="box__form__link">Não recebi o email</a>
		</form>
	</div>
</section>

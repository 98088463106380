import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs'
import { HttpService } from 'src/app/component/helper/http.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { OrderVenue } from 'src/app/component/order-venue/order-venue.interface'
import { environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root',
})
export class OrderService {
	private env = environment

	private selectedOrderSub = new BehaviorSubject<any>('')
	public selectedOrder$ = this.selectedOrderSub.asObservable()

	private updateOrderSub = new ReplaySubject<void>()
	public updateOrders$ = this.updateOrderSub.asObservable()

	constructor(private httpService: HttpService, private sharedService: SharedService) {}

	getByPartner(query?: any): Observable<OrderVenue[]> {
		const client = this.sharedService.localStorage.get('client')

		return this.httpService.get<OrderVenue[]>(`api/v1/orders/history`, {
			...query,
			clientId: client._id,
		})
	}

	getByID(id: string): Observable<any> {
		return this.httpService.get<any>(`api/v1/orders/${id}`)
	}

	setOrder(order: any) {
		this.selectedOrderSub.next(order)
	}

	updateOrder() {
		this.updateOrderSub.next()
	}
}

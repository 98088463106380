import { Component } from '@angular/core'

@Component({
	selector: 'app-or-divisor',
	template: ` <article>
		<div class="orDivisor__wrapper">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="100%"
				height="2"
				viewBox="0 0 100 2"
				fill="none"
				preserveAspectRatio="none"
			>
				<path d="M0 1H247" stroke="#EAEAEA" />
			</svg>
			<span class="orDivisor__text">OU</span>
		</div>
	</article>`,
	styles: [
		`
			.orDivisor {
				&__wrapper {
					position: relative;
					margin-bottom: 14px;
				}
				&__text {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%);
					padding: 0px 4px;
					display: inline-block;
					background-color: var(--bg);
					color: var(--text-primary);
					font-family: 'BradescoSans Variable';
					font-size: 10px;
					letter-spacing: 1px;
				}
			}
		`,
	],
})
export class OrDivisorComponent {}

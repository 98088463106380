import { Component, Input, OnInit } from '@angular/core'
import { LoadingService } from './loading.service'

@Component({
	selector: 'ch-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
	@Input() slug: string = ''

	constructor(public loadingService: LoadingService) {}

	ngOnInit(): void {}
}

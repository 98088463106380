import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { ConciergeWaitlistService } from './concierge-waitlist.service'
import { OrderVenue } from './../../../component/order-venue/order-venue.interface'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-concierge-waitlist',
	templateUrl: './concierge-waitlist.component.html',
	styleUrls: ['./concierge-waitlist.component.scss'],
})
export class ConciergeWaitlistComponent implements OnInit {
	public env = environment
	public waitlist: OrderVenue[] | null = []
	public form: FormGroup = new FormGroup({
		cpf: new FormControl(null, [Validators.required]),
	})

	constructor(
		public conciergeWaitlistService: ConciergeWaitlistService,
		public loadingService: LoadingService,
		public sharedService: SharedService
	) {}

	ngOnInit(): void {}

	getFormatedCreatedAtDay(waitlist: any) {
		const date = new Date(waitlist!.createdAt)
		return date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
	}

	getFormatedStatus(waitlist: any) {
		switch (waitlist?.status) {
			case 'CHK_DONE':
				return 'Confirmado'
			default:
				return ''
		}
	}

	getFormatedPartySize(waitlist: any) {
		const partySize = waitlist!.partySize
		return partySize <= 1 ? `${partySize} pessoa` : `${partySize} pessoas`
	}

	getFormatedDate(waitlist: any) {
		const date = new Date(waitlist!.createdAt)
		const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
		const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
		const year = date.getFullYear()
		return `${day}/${month}/${year}`
	}

	getFormatedTime(waitlist: any) {
		const date = new Date(waitlist!.createdAt)
		const hour = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`
		const minute = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`
		return `${hour}:${minute}`
	}

	handleSubHeaderForm() {
		if (!this.form.value.cpf) return this.sharedService.swal.error('Preencha o formulário corretamente.')

		let form = {
			cpf: this.form.value.cpf,
			intent: 'waitlist',
		}
		return this.conciergeWaitlistService.getWaitlist(form).subscribe({
			next: res => {
				this.waitlist = res
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
		})
	}
}

import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, NgModule, OnInit } from '@angular/core'

@Component({
	selector: 'ch-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	@Input() slug: string = '001'

	constructor() {}

	ngOnInit(): void {}
}

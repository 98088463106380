import { Component, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { Mosaic } from '../mosaic.interface'

import SwiperCore, { SwiperOptions, Autoplay } from 'swiper'
import { SwiperComponent } from 'swiper/angular'
import { LoadingService } from '../../loading/loading.service'
import { VenueService } from '../../venue/venue.service'
import { MosaicService } from '../mosaic.service'
import { FilterService } from '../../filter/filter.service'
import { SharedService } from '../../helper/shared.service'
import { Subscription, switchMap, take } from 'rxjs'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { GeoLocationService } from 'src/app/helper/geo-location.service'

SwiperCore.use([Autoplay])

@Component({
	selector: 'ch-mosaic001',
	templateUrl: './mosaic001.component.html',
	styleUrls: ['./mosaic001.component.scss'],
})
export class Mosaic001Component implements OnInit, OnChanges, OnDestroy {
	public $subs: Array<Subscription> = []
	@Input() mosaicSlug: string = ''
	@Input() mosaic: Mosaic | undefined
	@Input() notFound: TemplateRef<any> | null = null
	@Input() beforeVenues: TemplateRef<any> | null = null

	public loadingVenue: Array<any> = Array(4).fill(null)
	public isEnd: boolean = false

	@ViewChild('swiper', { static: false }) swiper?: SwiperComponent

	public config: SwiperOptions = {
		slidesPerView: 1,
		width: 291,
		spaceBetween: 20,
		navigation: false,
		freeMode: true,
		scrollbar: { draggable: true },
		speed: 1000,
		breakpoints: {
			'576': {
				slidesPerView: 2,
				width: null,
				freeMode: false,
				slidesPerGroup: 2,
			},
			'768': {
				slidesPerView: 3,
				width: null,
				freeMode: false,
				slidesPerGroup: 3,
			},
			'992': {
				slidesPerView: 4,
				width: null,
				freeMode: false,
				slidesPerGroup: 4,
			},
		},
	}

	constructor(
		public loadingService: LoadingService,
		public venueService: VenueService,
		public mosaicService: MosaicService,
		public filterService: FilterService,
		public sharedService: SharedService,
		public clientAuthService: ClientAuthService,
		private geoLocationService: GeoLocationService
	) {}

	ngOnInit(): void {
		this.loadingService.addLoading({ slug: this.mosaicSlug, isLoading: true })
		this.mosaic = this.mosaicService.getMosaicBySlug(this.mosaicSlug)

		// chamando get veneus após receber as cidades/bairro do programa
		// e toda vez que alguém alterar o filtro, ele chama novamente o getVenues
		this.$subs.push(
			this.filterService.filter$.subscribe(filter => {
				if (filter.address.length) {
					if (this.mosaic?.slug !== this.mosaicSlug)
						return this.loadingService.setIsLoadingBySlug(this.mosaicSlug, false)

					this.isEnd = false
					this.mosaicService.setMosaicBySlug(this.mosaicSlug, {
						...this.mosaic!,
						venues: [],
						venuesAlreadyInView: [],
					})
					this.mosaic = this.mosaicService.getMosaicBySlug(this.mosaicSlug)
					this.getVenues()
				}
			})
		)
	}

	ngOnChanges(): void {
		this.swiper?.swiperRef.update()
	}

	ngOnDestroy(): void {
		this.mosaicService.setMosaicBySlug(this.mosaicSlug, {
			...this.mosaic!,
			venues: [],
			venuesAlreadyInView: [],
		})
		this.$subs.forEach(sub => sub.unsubscribe())
	}

	onSwiper(swiper: any) {}

	onSlideChange() {}

	onReachBeginning(swiper: any) {}

	onReachEnd(swiper: any) {
		if (!this.loadingService.getIsLoadingBySlug(this.mosaicSlug)) this.getVenues()
	}

	slideNext() {
		this.swiper?.swiperRef.slideNext(1000)
	}

	slidePrev() {
		this.swiper?.swiperRef.slidePrev(1000)
	}

	getVenues() {
		if (this.isEnd) return

		if (this.mosaic?.slug !== this.mosaicSlug)
			return this.loadingService.setIsLoadingBySlug(this.mosaicSlug, false)

		this.filterService
			.getSelectedAddressForFilter()
			.pipe(
				take(1),
				switchMap(address => {
					const filter = { address, creditCard: this.clientAuthService.getSelectedCategory() }
					this.loadingService.setIsLoadingBySlug(this.mosaicSlug, true)
					return this.venueService.getByPartner({ ...this.mosaic, ...filter })
				})
			)
			.subscribe({
				next: res => {
					this.isEnd = !res.length
					const venues = [...(this.mosaic?.venues || []), ...res]
					const venuesAlreadyInView = [
						...this.mosaic!.venues.map(item => item._id),
						...res.map((item: any) => item._id),
					]
					const previousIndex: number = this.swiper?.swiperRef.previousIndex || 0

					this.mosaicService.setMosaicBySlug(this.mosaicSlug, {
						...this.mosaic!,
						venues,
						venuesAlreadyInView,
					})
					this.mosaic = this.mosaicService.getMosaicBySlug(this.mosaicSlug)
					this.swiper?.swiperRef.slideTo(previousIndex, 0)
					this.swiper?.swiperRef.update()
					this.loadingService.setIsLoadingBySlug(this.mosaicSlug, false)
				},
				error: error => {
					this.sharedService.swal.error(error.error?.message)
				},
			})
	}
}

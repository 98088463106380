import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { SharedService } from '../component/helper/shared.service'

@Injectable({
	providedIn: 'root',
})
export class AuthService implements CanActivate {
	constructor(private router: Router, private sharedService: SharedService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		const client = this.sharedService.localStorage.get('client')
		return !client ? this.router.navigate(['/']) : true
	}
}

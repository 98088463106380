import { Component, OnInit } from '@angular/core'
import { catchError, switchMap, tap } from 'rxjs'
import { SharedService } from 'src/app/component/helper/shared.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { Reservation } from 'src/app/component/reservation/reservation.interface'
import { ReservationService } from 'src/app/component/reservation/reservation.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'

@Component({
	selector: 'ch-reservation-confirm',
	templateUrl: './reservation-confirm.component.html',
	styleUrls: ['./reservation-confirm.component.scss'],
})
export class ReservationConfirmComponent implements OnInit {
	public confirmedReservation: any
	public reservation: Reservation = this.reservationService.reservation.getValue()
	public voucherValue = this.reservation.voucherValue

	constructor(
		public navigationService: NavigationService,
		public reservationService: ReservationService,
		public sharedService: SharedService,
		public clientAuthService: ClientAuthService
	) {}

	ngOnInit(): void {
		if (!this.reservation.id) return

		const body = {
			partySize: this.reservation.people_amount,
			section: this.reservation.environment,
			sectionLabel: this.reservation.environment_label,
			reservationDay: this.reservation.date,
			reservationTime: this.reservation.time,
			clientId: this.clientAuthService.getClient()?._id,
		}

		this.reservationService
			.confirm(body, this.reservation.id)
			.pipe(
				tap(res => {
					this.confirmedReservation = { ...this.reservationService.reservation.getValue(), ...res }
					this.voucherValue = res.voucher?.value ?? null
					this.reservationService.sendReservation({
						venue: null,
						client: null,
						id: null,
						conciergeUser: null,
						people_amount: null,
						environment: null,
						environment_label: null,
						date: null,
						time: null,
						credit_card: null,
					})
					this.sharedService.localStorage.remove('reservation')
				}),
				switchMap(() => this.clientAuthService.updateClientFront())
			)
			.subscribe({
				error: error => {
					this.sharedService.swal.error(error.error?.message)
					this.navigationService.setActivatedRightbarBySlug('')
				},
			})
	}

	getFormatedDate(date: string) {
		return date.split('-').reverse().join('/')
	}
}

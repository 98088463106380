<div class="aboutTagme">
	<!-- MY PROFILE HEADER -->
	<section class="aboutTagme__header">
		<div class="aboutTagme__header__top">
			<a class="aboutTagme__header__return" (click)="navigationService.setActivatedRightbarBySlug('profile')">
				<i class="aboutTagme__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="aboutTagme__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="aboutTagme__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- MY PROFILE BODY -->
	<section class="aboutTagme__body">
		<!-- breadcrumb
		<ul class="aboutTagme__body__breadcrumb">
			<li class="aboutTagme__body__breadcrumb__item">
				<p class="aboutTagme__body__breadcrumb__txt red">Perfil /</p>
			</li>
			<li class="aboutTagme__body__breadcrumb__item">
				<p class="aboutTagme__body__breadcrumb__txt red">Sobre a Tagme</p>
			</li>
		</ul>
		 -->

		<!-- title -->
		<div class="aboutTagme__body__title">Sobre a Tagme</div>

		<!-- content -->
		<div class="aboutTagme__body__content">
			<img src="./../../../../assets/images/about_tagme.svg" class="aboutTagme__body__content__image" />

			<h2 class="aboutTagme__body__content__title">
				Uma nova perspectiva de eficiência no segmento de restaurantes
			</h2>

			<p class="aboutTagme__body__content__text">
				Atuando desde 2009, a Tagme oferece canais e soluções de reserva, lista de espera, delivery, menu
				digital e plataformas de marketing para restaurantes, com o objetivo de criar experiências
				personalizadas do cliente.
			</p>
		</div>
	</section>

	<!-- MY PROFILE FOOTER -->
	<section class="aboutTagme__footer">
		<i class="aboutTagme__footer__icon bradesco fa fa-logo-bradesco"></i>
		<p class="aboutTagme__footer__txt">powered by</p>
		<i class="aboutTagme__footer__icon tagme fa fa-logo-tagme"></i>
	</section>
</div>

import { Injectable } from '@angular/core'
import { Observable, catchError, map, of } from 'rxjs'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { environment } from 'src/environments/environment'
import { HttpService } from '../helper/http.service'
import { SharedService } from '../helper/shared.service'
import { MosaicService } from '../mosaic/mosaic.service'
import { Venue } from './venue.interface'

@Injectable({
	providedIn: 'root',
})
export class VenueService {
	private env = environment

	constructor(
		private mosaicService: MosaicService,
		public httpService: HttpService,
		private clientAuthService: ClientAuthService,
		private sharedService: SharedService
	) {}

	public getByPartner(body: any): Observable<any> {
		const client = this.sharedService.localStorage.get('client')

		body = {
			clientId: client._id || '',
			categoryId: this.clientAuthService.getSelectedCategory()?.categoryId,
			partner: this.env.partnerID,
			limit: body.limit,
			name: body.name,
			type: body.type,
			venuesAlreadyInView: body.venuesAlreadyInView,
			favOnly: body.favOnly,
			address: body.address || {},
		}

		if (!body.categoryId) delete body.categoryId
		if (!body.clientId) delete body.clientId

		if (Object.keys(body.address || {}).length <= 0) delete body.address

		return this.httpService.post<Venue[]>('api/v1/venues/filter', body).pipe(map(res => res?.filter(Boolean)))
	}

	favoriteToggler(venue: Venue | null) {
		if (!venue) return null

		const client = this.sharedService.localStorage.get('client')

		const isFavorited = (client.favorites || []).find((venueID: string) => venueID === venue._id)

		const favorites = !isFavorited
			? [...(client.favorites || []), venue._id]
			: (client.favorites || []).filter((venueID: string) => venueID !== venue._id)

		return this.clientAuthService.update({ favorites }, client._id).subscribe(
			res => {
				this.clientAuthService.setClient(res)
				this.mosaicService.setVenue({ ...venue, favorite: !venue.favorite })
			},
			error => {
				this.sharedService.swal.error(error.error?.message)
			}
		)
	}
}

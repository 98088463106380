import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'ch-login-phone',
	templateUrl: './login-phone.component.html',
	styleUrls: ['./login-phone.component.scss'],
})
export class LoginPhoneComponent implements OnInit {
	@Input() slug: string = '001'

	constructor() {}

	ngOnInit(): void {}
}

<div class="profile">
	<!-- PROFILE HEADER -->
	<section class="profile__header">
		<div class="profile__header__top">
			<a class="profile__header__return" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="profile__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="profile__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="profile__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- PROFILE BODY -->
	<section class="profile__body">
		<ul class="profile__body__list">
			<li class="profile__body__list__item" *ngIf="!client">
				<a
					class="profile__body__list__link"
					(click)="navigationService.setActivatedRightbarBySlug('login-rightbar')"
				>
					<i class="profile__body__list__icon fa fa-login"></i>
					<p class="profile__body__list__txt">Login</p>
				</a>
			</li>
			<li class="profile__body__list__item" *ngIf="client">
				<a
					class="profile__body__list__link"
					(click)="navigationService.setActivatedRightbarBySlug('my-profile')"
				>
					<i class="profile__body__list__icon fa fa-perfil"></i>
					<p class="profile__body__list__txt">Meu perfil</p>
				</a>
			</li>
			<li class="profile__body__list__item" *ngIf="client">
				<a
					class="profile__body__list__link"
					(click)="navigationService.setActivatedRightbarBySlug('my-cards')"
				>
					<i class="profile__body__list__icon fa fa-cartao-de-credito"></i>
					<p class="profile__body__list__txt">Meus cartões</p>
				</a>
			</li>
			<li class="profile__body__list__item" *ngIf="client">
				<a class="profile__body__list__link" (click)="navigationService.setActivatedNavbarBySlug('order')">
					<i class="profile__body__list__icon fa fa-data"></i>
					<p class="profile__body__list__txt">Histórico de pedidos</p>
				</a>
			</li>
			<li class="profile__body__list__item">
				<a class="profile__body__list__link" routerLink="/como-funciona">
					<i class="profile__body__list__icon fa fa-info-e-como-funciona"></i>
					<p class="profile__body__list__txt">Como funciona</p>
				</a>
			</li>
			<li class="profile__body__list__item">
				<a class="profile__body__list__link" routerLink="/faq">
					<i class="profile__body__list__icon fa fa-faq"></i>
					<p class="profile__body__list__txt">Faq</p>
				</a>
			</li>
			<li class="profile__body__list__item">
				<a class="profile__body__list__link" routerLink="/politica-de-privacidade">
					<i class="profile__body__list__icon fa fa-politica-privacidade"></i>
					<p class="profile__body__list__txt">Política de privacidade</p>
				</a>
			</li>
			<li class="profile__body__list__item">
				<a class="profile__body__list__link" routerLink="/termos-de-uso">
					<i class="profile__body__list__icon fa fa-dados"></i>
					<p class="profile__body__list__txt">Termos de uso</p>
				</a>
			</li>
			<li class="profile__body__list__item">
				<a
					class="profile__body__list__link"
					(click)="navigationService.setActivatedRightbarBySlug('about-tagme')"
				>
					<i class="profile__body__list__icon fa fa-cozinha"></i>
					<p class="profile__body__list__txt">Sobre a Tagme</p>
				</a>
			</li>
			<li class="profile__body__list__item" *ngIf="client">
				<a (click)="showingLogoutModal = true" class="profile__body__list__link">
					<i class="profile__body__list__icon fa fa-log-out"></i>
					<p class="profile__body__list__txt">Sair</p>
				</a>
			</li>
		</ul>
	</section>

	<!-- INICIO: LOGOUT -->
	<div class="profile__logout {{ showingLogoutModal ? 'opened' : '' }}">
		<div class="profile__logout__content">
			<p class="profile__logout__text">
				Você está logado como
				<span class="profile__logout__text__name"
					>{{ clientAuthService.getClient()?.name || '' }}
					{{ clientAuthService.getClient()?.lastName || '' }}</span
				>.
			</p>
			<p class="profile__logout__question">Tem certeza que deseja sair?</p>
			<div class="profile__logout__action">
				<button class="profile__logout__button buttonLight" (click)="clientAuthService.logout()">Sim</button>
				<button class="profile__logout__button button" (click)="showingLogoutModal = false">Não</button>
			</div>
			<a class="profile__logout__link" (click)="clientAuthService.logout()">Logar com outro perfil?</a>
		</div>
	</div>

	<!-- PROFILE FOOTER -->
	<section class="profile__footer">
		<i class="profile__footer__icon bradesco fa fa-logo-bradesco"></i>
		<p class="profile__footer__txt">powered by</p>
		<i class="profile__footer__icon tagme fa fa-logo-tagme"></i>
	</section>
</div>

import { Injectable, OnDestroy } from '@angular/core'
import {
	BehaviorSubject,
	combineLatest,
	delay,
	map,
	Observable,
	of,
	shareReplay,
	skip,
	Subject,
	Subscription,
	switchMap,
	take,
	tap,
} from 'rxjs'
import { CreditCard } from '../credit-card/credit-card.interface'
import { GeoLocationService } from 'src/app/helper/geo-location.service'
import { HttpService } from '../helper/http.service'
import { Filter, AddressApi } from './filter.interface'

@Injectable({
	providedIn: 'root',
})
export class FilterService implements OnDestroy {
	private filter = new BehaviorSubject<Filter>({ address: [], initialCity: 'idle', location: null })
	public filter$ = this.filter.asObservable()
	private sub = new Subscription()

	constructor(private httpService: HttpService, private geoLocationService: GeoLocationService) {
		this.sub.add(
			this.geoLocationService.location$.subscribe({
				next: location => {
					const value = this.filter.value
					this.filter.next({ address: value.address, initialCity: value.initialCity, location })
				},
			})
		)
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe()
	}

	loadFilter(refresh: boolean = false, city: string | undefined) {
		if (this.filter.getValue().address.length > 0 && !refresh) return
		this.getAddressByPartner()
			.pipe(
				map(res => {
					const uniqueCities = new Map<string, any>()
					for (const address of res) {
						const cityKey = `${address._id.city}`
						if (!uniqueCities.has(cityKey)) {
							uniqueCities.set(cityKey, {
								id: `${uniqueCities.size}`,
								name: address._id.city,
								selected: city ? city.toLowerCase() === cityKey.toLowerCase() : false,
								isOpen: false,
								neighborhoods: [],
							})
						}

						const existingCity = uniqueCities.get(cityKey)

						for (const { neighborhood } of address.neighborhoods) {
							existingCity.neighborhoods.push({
								id: `${existingCity.id}_${existingCity.neighborhoods.length}`,
								city: existingCity.name,
								name: neighborhood,
								state: address._id.state,
								selected: false,
								isOpen: false,
							})
						}
					}

					return {
						address: [
							{
								id: `0`,
								name: 'Todas as cidades',
								selected: !city ? true : false,
								isOpen: false,
								neighborhoods: [],
							},
							...Array.from(uniqueCities.values()),
						],
					}
				})
			)
			.subscribe({
				next: filter => {
					this.setFilter({ address: filter.address, initialCity: city })
				},
			})
	}

	setFilter(filter: Filter) {
		const currentValue = this.filter.getValue()
		this.filter.next({ ...filter, location: currentValue.location })
	}

	updateVenues() {
		// Mosaic001 and Mosaic001 depend on filter to fetch new venues.
		// emitting new values to the filter will trigger a new http request for venues
		const value = this.filter.value
		this.filter.next(value)
	}

	getAddressByPartner() {
		return this.httpService.get<Array<AddressApi>>(`api/v1/address`)
	}

	getCreditCardByPartnerClient(): Observable<CreditCard[]> {
		return of([]).pipe(delay(1000))
	}

	getSelectedAddress() {
		for (let city of this.filter.getValue().address) {
			for (let neighborhood of city.neighborhoods) if (neighborhood.selected) return neighborhood
			if (city.selected) return city
		}
		return null
	}

	getSelectedAddressForFilter() {
		return this.filter$.pipe(
			map(({ address, initialCity, location }) => {
				if (initialCity) {
					return { city: initialCity }
				}
				/*
					Buscar por todas as cidades e após confirmação de localização, buscar pelo geoLocation
					Quando ele seleciona a cidade, é pra filtrar por cidade, independentemente se tem localização ou não
					
					Quando buscar pela geolocalização?
						Quando o usuário aceitar ou já tiver aceito
						Quando clica para ver mais venues (verificar se location está sendo chamando)
					E se o usuário escolher uma cidade diferente de 'Todas as cidades'?
						É pra filtra por cidade.
						
				*/
				for (let city of address) {
					if (city.selected && city.name === 'Todas as cidades' && location?.state !== 'granted') return {}

					for (let neighborhood of city.neighborhoods)
						if (neighborhood.selected) return { neighborhood: neighborhood.name }
					if (city.selected && city.name !== 'Todas as cidades') return { city: city.name }
				}
				if (location?.state === 'granted') {
					const coords = {
						latitude: location.position?.latitude,
						longitude: location.position?.longitude,
						maxDistance: 42_500_000,
					}

					return {
						location: coords,
					}
				}
				return {}
			})
		)
		// const { address, initialCity, location } = this.filter.getValue()
		// console.log('getselectedAddress', { address, initialCity, location })
		// if (initialCity) {
		// 	return { city: initialCity }
		// }

		// if (location) {
		// 	const coords = {
		// 		latitude: location.position?.latitude,
		// 		longitude: location.position?.longitude,
		// 		maxDistance: 42_500_000,
		// 	}

		// 	return {
		// 		location: coords,
		// 	}
		// }
		// for (let city of address) {
		// 	if (city.selected && city.name === 'Todas as cidades') return {}

		// 	for (let neighborhood of city.neighborhoods)
		// 		if (neighborhood.selected) return { neighborhood: neighborhood.name }
		// 	//if (location) return {}
		// 	if (city.selected) return { city: city.name }
		// }
		// return {}
	}

	filterByCity(city: string | undefined) {
		if (!city) return
		const filter = this.filter.getValue()
		const cityItem = filter.address.find(item => item.name.includes(city))
		if (cityItem) this.setAddress(cityItem)
	}

	public setAddress(item: any) {
		const address = this.filter.getValue().address.map((city: any) => {
			city.neighborhoods = [...(city.neighborhoods || [])].map((neighborhood: any) => {
				neighborhood.selected = item.id === neighborhood.id
				return neighborhood
			})

			city.selected = item.id === city.id
			return city
		})
		this.setFilter({ address })
	}

	/*async getAddressByGeoLocation(address: Array<any>) {
		await this.geoLocationService.getGeoLocation().subscribe(async (location: any) => {
			// const { latitude, longitude } = location
			const { latitude, longitude } = { latitude: -22.906847, longitude: -43.172897 }

			this.geoLocationService.getAddressByGeolocation(latitude, longitude).subscribe(
				res => {
					if (!res.address) return this.setFilter({ address, initialCity: '' })

					for (let [cityIndex, city] of this.filter.getValue().address.entries()) {
						address[cityIndex].selected = false

						for (let [neighborhoodIndex, neighborhood] of city.neighborhoods.entries()) {
							address[cityIndex].neighborhoods[neighborhoodIndex].selected = false

							if (neighborhood.name.toLowerCase() === res.address.suburb.toLowerCase()) {
								address[cityIndex].neighborhoods[neighborhoodIndex].selected = true
								break
							}
						}

						if (res.address.city && city.name.toLowerCase() === res.address.city.toLowerCase()) {
							address[cityIndex].selected = true
							break
						}

						if (
							res.address.city_district &&
							city.name.toLowerCase() === res.address.city_district.toLowerCase()
						) {
							address[cityIndex].selected = true
							break
						}
					}
					this.setFilter({ address })
				},
				error => {
					this.setFilter({ address })
				}
			)
		})
	}*/
}

import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class LoginEmailService {
	public email = new Subject<string>()
	public tab = new Subject<string>()

	constructor() {}

	sendEmail(email: string) {
		this.email.next(email)
	}

	receiveEmail() {
		return this.email.asObservable()
	}

	sendTab(tab: string) {
		this.tab.next(tab)
	}

	receiveTab() {
		return this.tab.asObservable()
	}
}

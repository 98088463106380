import { Component, OnInit } from '@angular/core'
import { take } from 'rxjs'
import { Client } from 'src/app/component/client/client.interface'
import { SharedService } from 'src/app/component/helper/shared.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { Reservation } from 'src/app/component/reservation/reservation.interface'
import { ReservationService } from 'src/app/component/reservation/reservation.service'
import { Venue } from 'src/app/component/venue/venue.interface'
import { ClientAuthService } from 'src/app/helper/client-auth.service'

@Component({
	selector: 'ch-reservation-data',
	templateUrl: './reservation-data.component.html',
	styleUrls: ['./reservation-data.component.scss'],
})
export class ReservationDataComponent implements OnInit {
	public venue: Venue | null = this.reservationService.reservation.getValue().venue
	public reservation: Reservation = this.reservationService.reservation.getValue()
	public voucherValue = this.reservation.voucherValue

	constructor(
		public navigationService: NavigationService,
		public reservationService: ReservationService,
		public sharedService: SharedService,
		public clientAuthService: ClientAuthService
	) {}

	ngOnInit(): void {}

	getFormatedDate(date: string) {
		return date.split('-').reverse().join('/')
	}

	nextNavigationChecker() {
		const hasAcceptedTerms = this.clientAuthService.getClient()?.agreements.reservation

		return this.navigationService.setActivatedRightbarBySlug(
			hasAcceptedTerms ? 'reservation-confirm' : 'reservation-terms'
		)
	}
}

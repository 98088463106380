<div class="search001 {{ isOpen ? 'search001--opened' : '' }}">
	<form [formGroup]="form" (ngSubmit)="handleSubmit()" class="search001__content">
		<div class="search001__wrapper wrapper">
			<div class="search001__wrapper wrapper small">
				<div class="search001__row">
					<div class="search001__column">
						<p class="search001__question">Qual restaurante você procura?</p>
					</div>
					<div class="search001__column">
						<div class="search001__form">
							<div class="search001__field">
								<input
									class="search001__field__input input"
									type="text"
									formControlName="name"
									ngModel
									#venueName
								/>
								<!-- 
								<p class="search001__field__notFound" *ngIf="isSearching && !mosaicService.getMosaicBySlug('search_result')?.venues?.length">
									Não encontramos resultado para sua busca.<br />
									Tente uma nova busca.
								</p> 
                				-->
							</div>
							<button class="search001__form__button button buttonInverse">Buscar</button>
						</div>
					</div>
				</div>
			</div>
			<div class="search001__close" (click)="closeModal()">
				<i class="search001__close__icon fa fa-close"></i>
			</div>
		</div>
	</form>
</div>

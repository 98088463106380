import { Component, Input, OnInit } from '@angular/core'
import { Venue } from './venue.interface'

@Component({
	selector: 'ch-venue',
	templateUrl: './venue.component.html',
	styleUrls: ['./venue.component.scss'],
})
export class VenueComponent implements OnInit {
	@Input() slug: string = '001'
	@Input() venue: Venue | null = null
	@Input() voucherValue = ''

	constructor() {}

	ngOnInit(): void {}
}

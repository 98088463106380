<section class="rightbar {{ navigationService.getActivatedRightBar() ? 'rightbar--opened' : '' }}">
	<ch-login-rightbar *ngIf="allowed('login-rightbar')"></ch-login-rightbar>
	<ch-register-rightbar *ngIf="allowed('register-rightbar')"></ch-register-rightbar>

	<!-- profile -->
	<ch-profile *ngIf="allowed('profile')"></ch-profile>
	<ch-about-tagme *ngIf="allowed('about-tagme')"></ch-about-tagme>
	<ch-add-card *ngIf="allowed('add-card')"></ch-add-card>
	<ch-my-cards *ngIf="allowed('my-cards')"></ch-my-cards>
	<ch-new-card *ngIf="allowed('new-card')"></ch-new-card>
	<ch-my-profile *ngIf="allowed('my-profile')"></ch-my-profile>
	<ch-edit-profile *ngIf="allowed('edit-profile')"></ch-edit-profile>
	<ch-logout *ngIf="allowed('logout')"></ch-logout>

	<!-- waitlist -->
	<ch-waitlist-form *ngIf="allowed('waitlist-form')"></ch-waitlist-form>
	<ch-waitlist-terms *ngIf="allowed('waitlist-terms')"></ch-waitlist-terms>
	<ch-waitlist-confirm *ngIf="allowed('waitlist-confirm')"></ch-waitlist-confirm>

	<!-- reservation -->
	<ch-reservation-form *ngIf="allowed('reservation-form')"></ch-reservation-form>
	<ch-reservation-data *ngIf="allowed('reservation-data')"></ch-reservation-data>
	<ch-reservation-terms *ngIf="allowed('reservation-terms')"></ch-reservation-terms>
	<ch-reservation-confirm *ngIf="allowed('reservation-confirm')"></ch-reservation-confirm>

	<!-- celebration reservation -->
	<ch-celebration-reservation-form
		*ngIf="allowed('celebration-reservation-form')"
	></ch-celebration-reservation-form>
	<ch-celebration-reservation-data
		*ngIf="allowed('celebration-reservation-data')"
	></ch-celebration-reservation-data>
	<ch-celebration-reservation-terms
		*ngIf="allowed('celebration-reservation-terms')"
	></ch-celebration-reservation-terms>
	<ch-celebration-reservation-about
		*ngIf="allowed('celebration-reservation-about')"
	></ch-celebration-reservation-about>
	<ch-celebration-reservation-confirm
		*ngIf="allowed('celebration-reservation-confirm')"
	></ch-celebration-reservation-confirm>

	<!-- Check-in -->
	<ch-check-in-form *ngIf="allowed('check-in-form')"> </ch-check-in-form>
	<ch-check-in-confirm *ngIf="allowed('check-in-confirm')"> </ch-check-in-confirm>
	<ch-check-in-terms *ngIf="allowed('check-in-terms')"> </ch-check-in-terms>

	<!-- Payment -->
	<ch-payment-bill *ngIf="allowed('payment-bill')"></ch-payment-bill>
	<ch-payment-card *ngIf="allowed('payment-card')"></ch-payment-card>
	<ch-payment-confirm *ngIf="allowed('payment-confirm')"></ch-payment-confirm>
</section>

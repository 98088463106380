import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core'
import { SwiperComponent } from 'swiper/angular'
import SwiperCore, { SwiperOptions } from 'swiper'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { ModalService } from 'src/app/component/modal/modal.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { ConciergeCelebrationBenefitService } from './concierge-celebration-benefit.service'
import { Client } from 'src/app/component/client/client.interface'
import { CENTURION_CATEGORY_ID } from 'src/app/helper/client-auth.service'

type VOUCHER_STATUS = 'active' | 'used' | 'validated' | 'expired' | 'canceled'

@Component({
	selector: 'app-concierge-celebration-benefit',
	templateUrl: './concierge-celebration-benefit.component.html',
	styleUrls: ['./concierge-celebration-benefit.component.scss'],
})
export class ConciergeCelebrationBenefitComponent implements OnInit {
	@ViewChild('swiper', { static: false }) swiper?: SwiperComponent

	public client: any = { benefit: [] }
	public haveCpf: boolean = false
	public historyConsumption: any
	public fullRegistration: any
	public celebrationBenefit: string = 'Não'
	public userName: any
	public historyBenefits: any
	public partnerId: any
	public clientId: any
	public clientCpf: any
	public conciergeId: any
	public ids: any = {
		partnerId: {},
		clientId: {},
	}
	public clients!: any
	public activeVouchers: any[] = []
	public clientHasCenturionCategory = false

	public createNewBenefit: any = {
		partnerId: {},
		clientId: {},
		status: '',
		type: null,
		validFrom: '',
		validUntil: '',
		description: '',
		conciergeUser: {},
	}

	public config: SwiperOptions = {
		slidesPerView: 1,
		spaceBetween: 20,
		navigation: false,
		speed: 1000,
		allowTouchMove: false,
		breakpoints: {
			'576': {
				slidesPerView: 3,
				spaceBetween: 20,
				navigation: false,
			},
		},
	}

	public searchForm: FormGroup = new FormGroup({
		cpf: new FormControl(null, [Validators.required]),
	})

	public benefitForm: FormGroup = new FormGroup({
		description: new FormControl(null, [Validators.required]),
		value: new FormControl('Selecione o valor', [Validators.required]),
		started_at: new FormControl(null, [Validators.required]),
		ended_at: new FormControl(null, [Validators.required]),
	})

	public benefitFormErrors: any = {
		required: 'Campo obrigatório.',
	}

	constructor(
		public loadingService: LoadingService,
		public modalService: ModalService,
		public sharedService: SharedService,
		public conciergeCelebrationBenefitService: ConciergeCelebrationBenefitService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.conciergeId = this.sharedService.localStorage.get('concierge')._id
	}

	getByCPF() {
		this.loadingService.setIsLoadingBySlug('generic', true)
		this.conciergeCelebrationBenefitService.getByCPF(this.searchForm.value.cpf).subscribe({
			next: res => {
				this.clients = res
				if (res && res.length) {
					this.setCelebrationClient(res[0])
				}
				this.loadingService.setIsLoadingBySlug('generic', false)
				this.cdr.detectChanges()
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
		})
	}

	getCategoriesNames(client: Client) {
		return client?.categories.map(item => item.name).join('<br>')
	}

	public selectClient(client: Client) {
		this.setCelebrationClient(client)
	}

	private setCelebrationClient(client: Client) {
		const { _id, cpf, name, lastName, email, phone, vouchers, categories, partnerId } = client
		this.haveCpf = true
		this.client = client
		this.clientCpf = cpf
		this.userName = name + ' ' + lastName
		this.fullRegistration =
			!name || !email || !phone || !categories[0]?.name || !categories[0]?.digits ? 'Não' : 'Sim'

		this.ids.partnerId = partnerId
		this.ids.clientId = _id
		this.createNewBenefit.partnerId = partnerId
		this.createNewBenefit.clientId = _id
		this.getHistory()
		this.swiper?.swiperRef.update()
		this.clientHasCenturionCategory = client?.categories.some(
			category => category.categoryId === CENTURION_CATEGORY_ID
		)
	}

	getHistory() {
		this.conciergeCelebrationBenefitService.getHistoryBenefits(this.ids).subscribe({
			next: res => {
				this.historyBenefits = res
				this.activeVouchers = res?.filter(
					(voucher: any) =>
						voucher.status === 'active' && new Date().getTime() <= new Date(voucher.validUntil).getTime()
				)
				this.celebrationBenefit = this.activeVouchers.length ? 'Sim' : 'Não'
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
		})
	}

	getHistoryConsumption(cpf: any, partner: any) {
		this.conciergeCelebrationBenefitService.getHistoryConsumptionService(cpf, partner).subscribe({
			next: res => {
				this.historyConsumption = res
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
		})
	}

	onSwiper(swiper: any) {}

	onSlideChange(swiper: any) {}

	onReachBeginning(swiper: any) {}

	onReachEnd(swiper: any) {}

	slideNext() {
		this.swiper?.swiperRef.slideNext(1000)
	}

	slidePrev() {
		this.swiper?.swiperRef.slidePrev(1000)
	}

	sliderGoTo(item: number) {
		this.swiper?.swiperRef.slideTo(item)
	}

	getErrorBySlug(slug: string) {
		if (!this.benefitForm.controls[slug].touched) return

		const error = Object.keys(this.benefitForm.controls[slug].errors || {})
		return error.length ? this.benefitFormErrors[error[0]] : ''
	}

	handleSearchSubmit() {
		if (this.searchForm.invalid || !this.searchForm.value)
			return this.sharedService.swal.error('Preencha o formulário corretamente.')

		return this.getByCPF()
	}

	handleSubmit() {
		if (this.benefitForm.invalid) return this.sharedService.swal.error('Preencha o formulário corretamente.')

		return this.modalService.setIsOpenBySlug(true)
	}

	confirmBenefitCreation() {
		if (this.benefitForm.invalid) return this.sharedService.swal.error('Preencha o formulário corretamente.')

		this.createNewBenefit.validFrom = this.benefitForm.value.started_at
		this.createNewBenefit.validUntil = this.benefitForm.value.ended_at
		this.createNewBenefit.description = this.benefitForm.value.description
		this.createNewBenefit.conciergeUser = this.conciergeId
		this.createNewBenefit.status = 'active'
		this.createNewBenefit.value = Number(this.benefitForm.controls['value'].value.amount)
		this.createNewBenefit.type = this.benefitForm.controls['value'].value.type

		return this.conciergeCelebrationBenefitService.storeBenefit(this.createNewBenefit).subscribe({
			next: () => {
				this.getByCPF()
				this.swiper?.swiperRef.update()
				this.sharedService.swal.success('Benefício adicionado com sucesso!')
				this.benefitForm.reset()
				this.benefitForm.controls['value'].setValue('Selecione o valor')

				return this.modalService.setIsOpenBySlug(false)
			},
			error: httpError => {
				this.modalService.setIsOpenBySlug(false)
				return this.sharedService.swal.error(httpError?.error?.message)
			},
		})
	}

	translateVoucherStatus(status: VOUCHER_STATUS) {
		if (!status) {
			return
		}
		const translate: Record<VOUCHER_STATUS, string> = {
			active: 'Ativo',
			used: 'Usado',
			validated: 'Validado',
			expired: 'Expirado',
			canceled: 'Cancelado',
		}
		return translate[status]
	}
}

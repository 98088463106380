<!-- é a parte da jornada em que se confirmam os dados do cartão, insere o CVV ou troca o cartão
    antes de efetuar o pagamento -->

<div class="payment">
	<!-- PAYMENT HEADER -->
	<section class="payment__header">
		<div class="payment__header__top">
			<a
				class="payment__header__return"
				(click)="navigationService.setActivatedRightbarBySlug('payment-bill')"
			>
				<i class="payment__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="payment__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="payment__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<section class="payment__body">
		<div class="payment__ticket">
			<ul class="payment__ticket__list">
				<li class="payment__ticket__list__item">
					<p class="payment__ticket__list__item__data">{{ selectedOrder.venue.name }}</p>
					<p class="payment__ticket__list__item__label">
						{{ selectedOrder.venue.address | formattedAddress }}
					</p>
				</li>
				<li class="payment__ticket__list__item">
					<p class="payment__ticket__list__item__label">Data</p>
					<p class="payment__ticket__list__item__data">
						{{ selectedOrder.reservationDay | date: 'dd/MM/yyyy' }} às {{ selectedOrder.reservationTime }} hrs
					</p>
				</li>
			</ul>
		</div>

		<div class="payment__card card">
			<div class="card__left">
				<p class="card__text">Valor a pagar</p>
				<p class="card__value">{{ totalValue | currency: 'BRL' }}</p>
			</div>
			<div class="card__right">
				<div class="card__line">
					<div class="card__cardNumber">
						<i class="card__icon fa fa-bullets"></i>
						<p class="card__cardNumber__theLast">{{ selectedCategory.lastFourDigits }}</p>
					</div>
					<div class="card__cardFlag" *ngIf="imageBrand">
						<img [src]="'/assets/images/' + imageBrand.src" [alt]="imageBrand.alt" />
					</div>
				</div>
				<p class="card__name">{{ clientFullName }}</p>
				<div class="card__cvv">
					<p class="card__cvv__text">Digite o CVV</p>
					<form [formGroup]="form">
						<input
							type="text"
							class="card__cvv__input"
							placeholder="000"
							mask="000||0000"
							formControlName="cvv"
						/>
					</form>
				</div>
			</div>
		</div>
	</section>

	<!-- PAYMENT FOOTER -->
	<section class="payment__footer">
		<button
			class="payment__footer__button button"
			[disabled]="form.invalid || loadingService.getIsLoadingBySlug('rightbar')"
			(click)="handleSubmit()"
		>
			Confirmar pagamento
			<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
		</button>
	</section>
</div>

import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
	selector: 'ch-modal-confirm-registration',
	templateUrl: './modal-confirm-registration.html',
	styleUrls: ['./modal-confirm-registration.scss'],
})
export class ModalConfirmRegistration {
	@Input() opened = false
	@Output() onConfirm = new EventEmitter()
	@Output() onClose = new EventEmitter()

	constructor() {}
}

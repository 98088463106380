import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-bradesco-profile',
	templateUrl: './bradesco-profile.component.html',
	styleUrls: ['./bradesco-profile.component.scss'],
})
export class BradescoProfileComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}

import { Injectable } from '@angular/core'
import { delay, Observable, of } from 'rxjs'
import { HttpService } from 'src/app/component/helper/http.service'
import { environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root',
})
export class ConciergeService {
	private env = environment

	constructor(private httpService: HttpService) {}

	changePassword(form: any, id: string): Observable<any> {
		return this.httpService.patch(`api/v1/clients/concierge/updatePass/${id}`, {
			...form,
			partnerId: this.env.partnerID,
		})
	}
}

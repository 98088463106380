import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
	selector: 'app-celebrations-box',
	template: `
		<div class="wrapper">
			<div class="celebrations">
				<i class="fa fa-presente celebrations__icon"></i>
				<span class="celebrations__name">Celebrations</span>
				<app-slide-toggle
					*ngIf="hasSlide"
					style="margin-left: auto;margin-bottom: 8px"
					(sliderValueChange)="sliderValueChange.emit($event)"
				></app-slide-toggle>
			</div>
			<p class="msg">
				Você ganhou um crédito de
				<span style="font-weight: 500">{{ voucherValue | currency: 'BRL' }}</span> no mês do seu aniversário.
				Aproveite :)
			</p>
		</div>
	`,
	styles: [
		`
			@import '../../../theme';
			.wrapper {
				padding-block: 12px;
				padding-top: 19px;
				background-color: var(--bg);
				border-radius: 4px;
			}

			.celebrations {
				display: flex;
				align-items: center;
				margin-bottom: 3px;
				&__icon {
					width: 32px;
					color: var(--icon-primary);
					text-shadow: 0.5px 0.5px 0px var(--shadow);
					font-size: 26px;
				}
				&__name {
					color: var(--text-quaternary);
					font-size: 14px;
					font-weight: 480;
				}
			}

			.msg {
				color: var(--text-primary);
				font-size: 14px;
				font-style: normal;
				font-weight: 300;
				line-height: 24px; /* 171.429% */
			}
		`,
	],
})
export class CelebrationsBoxComponent {
	@Input() voucherValue!: number | null
	@Input() hasSlide = false
	@Output() sliderValueChange = new EventEmitter<boolean>()
}

<!-- VENUE -->
<section class="mosaic" *ngIf="mosaic?.venues?.length || loadingService.getIsLoadingBySlug(mosaicSlug)">
	<div class="mosaic001__wrapper wrapper">
		<div class="mosaic001__cardsList">
			<h2 class="mosaic001__cardsList__title">{{ mosaic?.title }}</h2>
			<i *ngIf="mosaic?.hasStar" class="mosaic001__cardsList__icon fa fa-estrela-cheia"></i>
		</div>

		<ng-container>
			<!-- RESTAURANTE POR CATEGORIA -->
			<swiper
				#swiper
				[config]="config"
				(swiper)="onSwiper($event)"
				(slideChange)="onSlideChange()"
				(reachEnd)="onReachEnd($event)"
				(reachBeginning)="onReachBeginning($event)"
				class="venue__list"
			>
				<ng-template swiperSlide *ngFor="let cards of mosaic?.venues">
					<li class="venue__list__eachOne">
						<ch-venue slug="001" [venue]="cards"></ch-venue>
					</li>
				</ng-template>

				<ng-container *ngIf="loadingService.getIsLoadingBySlug(mosaicSlug)">
					<ng-template swiperSlide *ngFor="let item of loadingVenue; let i = index">
						<li class="venue__list__eachOne">
							<ch-loading slug="venue001"></ch-loading>
						</li>
					</ng-template>
				</ng-container>
			</swiper>

			<div class="venue__list__action">
				<button
					id="prev"
					class="venue__list__action__item {{
						swiper?.swiperRef?.isBeginning && !loadingService.getIsLoadingBySlug(mosaicSlug) ? 'hide' : ''
					}}"
					(click)="slidePrev()"
				>
					<i class="venue__list__action__icon fa fa-seta"></i>
				</button>
				<button
					id="next"
					class="venue__list__action__item {{
						swiper?.swiperRef?.isEnd && !loadingService.getIsLoadingBySlug(mosaicSlug) ? 'hide' : ''
					}}"
					(click)="slideNext()"
				>
					<i class="venue__list__action__icon fa fa-seta"></i>
				</button>
			</div>
		</ng-container>
		<ng-container *ngIf="!mosaic?.venues?.length && !loadingService.getIsLoadingBySlug(mosaicSlug)">
			<!-- VENUE NOT FOUND -->
			<div class="venue__list">
				<div class="venue__list__eachOne">
					<ng-container *ngTemplateOutlet="notFound"></ng-container>
				</div>
			</div>
		</ng-container>
	</div>
</section>

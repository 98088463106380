<!-- SLIDE -->
<section class="slide" *ngIf="slides.length">
	<div class="slide001__wrapper wrapper s-type-1" role="slider">
		<div class="slide001__control"></div>

		<swiper
			#swiper
			[config]="config"
			(swiper)="onSwiper($event)"
			(slideChange)="onSlideChange()"
			class="slide001__content"
		>
			<ng-template swiperSlide *ngFor="let item of slides">
				<li class="slide001__content__item" *ngIf="item.click && !item.router && !item.href">
					<img class="slide001__content__img desktopOnly" [alt]="item.alt" [src]="item.imageDesktop" />
					<img class="slide001__content__img mobileOnly" [alt]="item.alt" [src]="item.imageMobile" />
					<div class="slide001__content__legend">
						<h3 class="slide001__content__legend__title">{{ item.title }}</h3>
						<p class="slide001__content__legend__text">{{ item.text }}</p>
						<button class="slide001__content__legend__button button" (click)="item.click()">
							{{ item.buttonText }}
						</button>
					</div>
				</li>
				<li class="slide001__content__item" *ngIf="!item.click && !item.router && !item.href">
					<img class="slide001__content__img desktopOnly" [alt]="item.alt" [src]="item.imageDesktop" />
					<img class="slide001__content__img mobileOnly" [alt]="item.alt" [src]="item.imageMobile" />
					<div class="slide001__content__legend">
						<h3 class="slide001__content__legend__title">{{ item.title }}</h3>
						<p class="slide001__content__legend__text">{{ item.text }}</p>
						<button class="slide001__content__legend__button button">{{ item.buttonText }}</button>
					</div>
				</li>
				<li class="slide001__content__item" *ngIf="item.href && !item.router && !item.click">
					<img class="slide001__content__img desktopOnly" [alt]="item.alt" [src]="item.imageDesktop" />
					<img class="slide001__content__img mobileOnly" [alt]="item.alt" [src]="item.imageMobile" />
					<div class="slide001__content__legend">
						<h3 class="slide001__content__legend__title">{{ item.title }}</h3>
						<p class="slide001__content__legend__text">{{ item.text }}</p>
						<a [href]="item.href" class="slide001__content__legend__button button">{{ item.buttonText }}</a>
					</div>
				</li>
				<li class="slide001__content__item" *ngIf="item.router && !item.href && !item.click">
					<img class="slide001__content__img desktopOnly" [alt]="item.alt" [src]="item.imageDesktop" />
					<img class="slide001__content__img mobileOnly" [alt]="item.alt" [src]="item.imageMobile" />
					<div class="slide001__content__legend">
						<h3 class="slide001__content__legend__title">{{ item.title }}</h3>
						<p class="slide001__content__legend__text">{{ item.text }}</p>
						<button class="slide001__content__legend__button button" [routerLink]="item.router">
							{{ item.buttonText }}
						</button>
					</div>
				</li>
			</ng-template>
		</swiper>
	</div>
</section>

import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'ch-loading-button001',
	templateUrl: './loading-button001.component.html',
	styleUrls: ['./loading-button001.component.scss'],
})
export class LoadingButton001Component implements OnInit {
	@Input() top = ''
	@Input() size = '20px'
	constructor() {}

	ngOnInit(): void {}
}

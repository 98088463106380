import { Injectable } from '@angular/core'
import { HttpService } from '../component/helper/http.service'

@Injectable({ providedIn: 'root' })
export class VoucherService {
	constructor(private httpService: HttpService) {}

	getVouchers() {
		return this.httpService.get(`api/v1/vouchers/bbi/hasVoucher`)
	}
}

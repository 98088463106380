import { Injectable } from '@angular/core'
import { Observable, timeout } from 'rxjs'
import * as _ from 'lodash'
import { HttpClient } from '@angular/common/http'

const GEOLOCATION_ERRORS = {
	'errors.location.unsupportedBrowser': 'Browser does not support location services',
	'errors.location.permissionDenied': 'You have rejected access to your location',
	'errors.location.positionUnavailable': 'Unable to determine your location',
	'errors.location.timeout': 'Service timeout has been reached',
}

@Injectable({
	providedIn: 'root',
})
export class GeolocationService {
	constructor(private http: HttpClient) {}

	public getGeoLocation(): Observable<any> {
		return Observable.create((observer: any) => {
			if (window.navigator && window.navigator.geolocation) {
				window.navigator.geolocation.getCurrentPosition(
					position => {
						if (position && position.coords && position.coords.latitude && position.coords.longitude) {
							let _lat = position.coords.latitude
							let _long = position.coords.longitude
							observer.next({ latitude: _lat, longitude: _long })
						} else {
							observer.error(GEOLOCATION_ERRORS['errors.location.positionUnavailable'])
						}
					},
					error => {
						switch (error.code) {
							case 1:
								observer.error(GEOLOCATION_ERRORS['errors.location.permissionDenied'])
								break
							case 2:
								observer.error(GEOLOCATION_ERRORS['errors.location.positionUnavailable'])
								break
							case 3:
								observer.error(GEOLOCATION_ERRORS['errors.location.timeout'])
								break
						}
					}
				)
			} else {
				observer.error(GEOLOCATION_ERRORS['errors.location.unsupportedBrowser'])
			}
		})
	}

	getAddressByGeolocation(latitude: string | number, longitude: string | number): Observable<any> {
		return this.http
			.get<any>(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`)
			.pipe(timeout(5000))
	}
}

import { Location } from '@angular/common'
import { Component, OnInit, Input } from '@angular/core'

@Component({
	selector: 'ch-sub-header001',
	templateUrl: './sub-header001.component.html',
	styleUrls: ['./sub-header001.component.scss'],
})
export class SubHeader001Component implements OnInit {
	@Input() title: string = ''
	@Input() bgImage: string = ''

	constructor(public location: Location) {}

	ngOnInit(): void {}
}

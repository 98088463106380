<div class="page">
	<div class="page__header">
		<ch-search slug="001"></ch-search>
		<app-bradesco-navbar></app-bradesco-navbar>
		<ch-rightbar></ch-rightbar>
	</div>
	<div class="page__body">
		<div class="concierge">
			<div class="concierge__wrapper wrapper">
				<div class="concierge__header">
					<h1 class="concierge__header__title">Painel de controle - Concierge</h1>
					<ul class="concierge__header__action">
						<li
							class="concierge__header__action__item"
							(click)="modalService.setIsOpenBySlug(true, 'password')"
						>
							Alterar senha
						</li>
						<li
							class="concierge__header__action__item"
							(click)="modalService.setIsOpenBySlug(true, 'logout')"
						>
							Sair
						</li>
					</ul>
				</div>

				<app-pill></app-pill>

				<app-concierge-reservation
					*ngIf="pillService.getSelected()?.slug === 'reservation'"
				></app-concierge-reservation>
				<app-concierge-waitlist
					*ngIf="pillService.getSelected()?.slug === 'waitlist'"
				></app-concierge-waitlist>
				<app-concierge-client *ngIf="pillService.getSelected()?.slug === 'client'"></app-concierge-client>
				<app-concierge-celebration-benefit
					*ngIf="pillService.getSelected()?.slug === 'celebration_benefit'"
				></app-concierge-celebration-benefit>
			</div>
		</div>
	</div>
	<div class="page__footer">
		<ch-footer slug="001"></ch-footer>
	</div>
</div>

<ch-modal slug="001" modalSlug="password">
	<form class="concierge__modal__password" [formGroup]="form" (ngSubmit)="handleSubmit()" content>
		<div class="concierge__modal__password__header">
			<span class="concierge__modal__password__title">Alterar senha</span>
		</div>
		<div class="concierge__modal__password__body">
			<div class="field">
				<div class="field__wrapper">
					<i class="field__icon left tagmeicon tagmeicon-block-on"></i>
					<input
						type="password"
						class="field__input"
						ngModel
						formControlName="password"
						placeholder="Senha atual"
					/>
				</div>
				<span class="field__feedback">{{ getErrorBySlug('password') }}</span>
			</div>
			<div class="field">
				<div class="field__wrapper">
					<i class="field__icon left tagmeicon tagmeicon-block-on"></i>
					<input
						type="password"
						class="field__input"
						ngModel
						formControlName="newPassword"
						placeholder="Nova senha"
					/>
				</div>
				<span class="field__feedback">{{ getErrorBySlug('newPassword') }}</span>
			</div>
			<div class="field">
				<div class="field__wrapper">
					<i class="field__icon left tagmeicon tagmeicon-block-on"></i>
					<input
						type="password"
						class="field__input"
						data-length="5"
						lengtha="6"
						ngModel
						formControlName="passwordConfirmation"
						placeholder="Confirmar nova senha"
					/>
				</div>
				<span class="field__feedback">{{ getErrorBySlug('passwordConfirmation') }}</span>
			</div>
			<button
				class="concierge__modal__password__button button"
				[disabled]="form.invalid || loadingService.getIsLoadingBySlug('generic')"
			>
				<span class="concierge__modal__password__button__text">Salvar alterações</span>
				<ch-loading *ngIf="loadingService.getIsLoadingBySlug('generic')" slug="button001"></ch-loading>
			</button>
		</div>
	</form>
</ch-modal>

<ch-modal slug="001" modalSlug="logout">
	<div class="concierge__modal__logout" content>
		<span class="concierge__modal__logout__text">Tem certeza que deseja sair?</span>
		<div class="concierge__modal__logout__action">
			<button class="concierge__modal__logout__button button" (click)="conciergeAuthService.logout()">
				Sim
			</button>
			<button
				class="concierge__modal__logout__button buttonLight"
				(click)="modalService.setIsOpenBySlug(false, 'logout')"
			>
				Não
			</button>
		</div>
	</div>
</ch-modal>

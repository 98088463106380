<ch-benefit001
	*ngIf="slug === '001'"
	[benefit]="benefit"
	[bgColor]="bgColor"
	[venueId]="venueId"
	[isCelebration]="isCelebration"
	[voucherValue]="voucherValue"
></ch-benefit001>
<ch-benefit002
	*ngIf="slug === '002'"
	[benefit]="benefit"
	[isCelebration]="isCelebration"
	[voucherValue]="voucherValue"
></ch-benefit002>
<ch-benefit003
	*ngIf="slug === '003'"
	[benefit]="benefit"
	[isCelebration]="isCelebration"
	[venueId]="venueId"
></ch-benefit003>

import { AbstractControl, ValidatorFn } from '@angular/forms'

export function passwordMatchValidator(controlName1: string, controlName2: string) {
	return (group: AbstractControl) => {
		const control1 = group.get(controlName1)
		const control2 = group.get(controlName2)

		if (control1?.value !== control2?.value) {
			control2?.setErrors({ passwordMismatch: true })
			return { passwordMismatch: true }
		}
		return null
	}
}
export function passwordValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const value: string = control.value
		let result = {}

		// Check length
		if (value && (value.length < 8 || value.length > 20)) {
			result = { ...result, passwordLength: true }
		}

		// Check for at least one uppercase letter
		if (!/[A-Z]/.test(value)) {
			result = { ...result, passwordUppercase: true }
		}

		// Check for at least one lowercase letter
		if (!/[a-z]/.test(value)) {
			result = { ...result, passwordLowercase: true }
		}

		// Check for at least one special character
		if (!/[!@#$&*]/.test(value)) {
			result = { ...result, passwordSpecial: true }
		}

		// if has any error, show
		if (Object.values(result).length > 0) {
			return result
		}

		// All restrictions passed
		return null
	}
}

import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { environment as env } from 'src/environments/environment'
import { Benefit } from '../../benefit/benefit.interface'
import { SharedService } from '../../helper/shared.service'
import { Venue } from '../venue.interface'
import { VenueService } from '../venue.service'
import { Observable, map, tap } from 'rxjs'

@Component({
	selector: 'ch-venue001',
	templateUrl: './venue001.component.html',
	styleUrls: ['./venue001.component.scss'],
})
export class Venue001Component implements OnInit {
	public env = env

	@Input() venue: Venue | undefined
	@Input() voucherValue: string = ''
	hasCelebrationsVenue = false
	hasCelebrations$!: Observable<boolean>

	constructor(
		public venueService: VenueService,
		public sharedService: SharedService,
		public router: Router,
		public clientAuthService: ClientAuthService
	) {}

	ngOnInit(): void {
		this.hasCelebrationsVenue =
			this.venue?.partner?.types.some(item => item.type.toLowerCase() === 'celebrations') ?? false
		this.hasCelebrations$ = this.clientAuthService.voucher$.pipe(
			map(voucher => this.hasCelebrationsVenue && Boolean(voucher?._id))
		)
		this.removeDiscountBenefitFromAspiracionais()
	}

	removeDiscountBenefitFromAspiracionais() {
		if (!this.venue) return
		const hasTypeAspiracional = this.venue?.partner.types.some(
			item => item.type.toLowerCase() === 'aspiracional'
		)
		if (!hasTypeAspiracional) return
		this.venue.benefits = this.venue.benefits.filter(item => item.type.toLowerCase() !== 'discount')
	}

	getFormatedCousines(cousines: Array<{ _id: string; name: string }>) {
		if (!cousines) return []
		return cousines.map(item => item.name).join(', ')
	}

	getSortedBenefits(benefits: Array<any>) {
		return benefits.sort((a, b) => a.order - b.order)
	}

	isFavorited(): boolean {
		if (!this.venue) return false
		const client = this.clientAuthService.getClient()
		if (!client) return false
		return client.favorites.some(id => id == this.venue?._id)
	}

	favoriteToggler(el: any) {
		el.stopPropagation()
		if (!this.venue) return

		this.venueService.favoriteToggler(this.venue)
	}

	get converURL() {
		if (!this.venue) return
		return env.tagmepub + '/pubimg/' + this.venue.partner.images.thumb[0]?.coverUrl
	}
}

import { Component, EventEmitter, Output, Input } from '@angular/core'

@Component({
	selector: 'app-slide-toggle',
	templateUrl: './slide-toggle.component.html',
	styleUrls: ['./slide-toggle.component.scss'],
})
export class SlideToggleComponent {
	@Input() line = false
	@Input() isChecked = true
	@Output() sliderValueChange = new EventEmitter<boolean>()

	sliderChanged(event: Event) {
		const target = event.target as HTMLInputElement
		this.sliderValueChange.emit(target.checked)
	}
}

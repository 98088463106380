import { Benefit } from 'src/app/component/benefit/benefit.interface'

interface GetDiscount {
	benefits: Benefit[]
	venueId: string
}

export class BenefitHelper {
	static findDiscount(benefits: Benefit[]) {
		return benefits.find((b: any) => b.type === 'discount')
	}

	static getDiscountValue(order: GetDiscount): string {
		const discount = this.findDiscount(order.benefits) as Benefit
		if (!discount) {
			return ''
		}
		const exception = discount.exceptions?.find((v: any) => v.venueId === order.venueId)
		if (exception) {
			return exception.value
		}
		return discount?.value
	}
}

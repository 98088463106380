import { Component, OnInit } from '@angular/core'
import { NavigationService } from 'src/app/component/navigation/navigation.service'

@Component({
	selector: 'ch-add-card',
	templateUrl: './add-card.component.html',
	styleUrls: ['./add-card.component.scss'],
})
export class AddCardComponent implements OnInit {
	constructor(public navigationService: NavigationService) {}

	ngOnInit(): void {}
}

<div class="page">
	<div class="page__header">
		<ch-search slug="001"></ch-search>
		<app-bradesco-navbar></app-bradesco-navbar>
		<ch-rightbar></ch-rightbar>
	</div>
	<div class="page__body">
		<ng-template #notFound>
			<div class="favorite__notFound">
				<i class="favorite__notFound__icon tagmeicon tagmeicon-favoritos-off"></i>
				<p class="favorite__notFound__txt">Você ainda não tem favoritos.</p>
			</div>
		</ng-template>

		<ch-mosaic slug="002" mosaicSlug="favorite" [notFound]="notFound"> </ch-mosaic>
	</div>
	<div class="page__footer">
		<ch-footer slug="001"></ch-footer>
	</div>
</div>

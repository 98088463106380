<div class="page">
	<div class="page__header">
		<ch-search slug="001"></ch-search>
		<app-bradesco-navbar></app-bradesco-navbar>
		<ch-filter slug="001"></ch-filter>
		<ch-rightbar></ch-rightbar>
	</div>
	<div class="page__body">
		<ng-template #notFound>
			<div class="home__notFound">
				<div class="home__notFound__content">
					<i class="home__notFound__content__icon fa fa-sad-smile"></i>
					<p class="home__notFound__content__text">
						Não encontramos restaurantes
						<!-- <span class="home__notFound____content__text__category">"fast-food"</span> -->
						na sua cidade
					</p>
				</div>
			</div>
		</ng-template>

		<!-- SLIDE -->
		<ch-slide slug="001" [slides]="slides"></ch-slide>

		<div style="margin: 0 auto; width: 100%; padding: 0 16px; max-width: 1212px" *ngIf="voucherValue">
			<div class="celebrationHome__information gradientHorizontal">
				<i class="celebrationHome__information__icon fa fa-presente"></i>
				<p class="celebrationHome__information__txt">
					<strong>
						Faça uma reserva em restaurantes selecionados com {{ voucherValue | currency: 'BRL' }} em
						créditos.
					</strong>
					Exclusivo para clientes Bradesco selecionados, válido até {{ getFormatedVoucherValidUntil() }}.
				</p>
			</div>
		</div>
		<!-- MOSAIC -->
		<div id="goMichelin">
			<ch-mosaic slug="001" mosaicSlug="guiaMichelin" [notFound]="notFound"></ch-mosaic>
		</div>
		<ng-container *ngIf="showBBICategory | async">
			<ch-mosaic slug="001" mosaicSlug="momento_bbi" [notFound]="notFound"></ch-mosaic>
		</ng-container>
		<ch-mosaic slug="001" mosaicSlug="aspiracional" [notFound]="notFound"></ch-mosaic>
		<ch-mosaic slug="001" mosaicSlug="sofisticados" [notFound]="notFound"></ch-mosaic>
		<ch-mosaic slug="001" mosaicSlug="casual" [notFound]="notFound"></ch-mosaic>
		<ch-mosaic slug="001" mosaicSlug="bares" [notFound]="notFound"></ch-mosaic>
	</div>
	<div class="page__footer">
		<ch-footer slug="001"></ch-footer>
	</div>
</div>

<ch-modal slug="001">
	<div class="news" content>
		<div class="news__img">
			<img src="./../../../assets/images/modal_celebration.png" />
		</div>
		<h1 class="news__title red">Celebrations</h1>
		<p class="news__strong">
			Parabéns! Você ganhou um crédito<br />
			de {{ voucherValue | currency: 'BRL' }} no Programa Menu!
		</p>
		<p class="news__text">
			Para utilizar a cortesia Celebrations,<br />
			faça uma <span class="destaque">reserva de mesa</span> no restaurante<br />
			que você queira celebrar! ;D
		</p>
		<button class="news__button button buttonInverse" (click)="modalService.setIsOpenBySlug(false)">
			Ok, entendi
		</button>
	</div>
</ch-modal>

import { Component, Input, OnInit } from '@angular/core'
import { ModalService } from '../modal.service'

@Component({
	selector: 'ch-modal001',
	templateUrl: './modal001.component.html',
	styleUrls: ['./modal001.component.scss'],
})
export class Modal001Component implements OnInit {
	@Input() slug: string = 'generic'
	@Input() top: string | undefined = undefined

	constructor(public modalService: ModalService) {}

	ngOnInit(): void {}
}

<section class="mosaic">
	<div class="mosaic002__wrapper wrapper">
		<div class="mosaic002__cardsList">
			<h2 class="mosaic002__cardsList__title">{{ mosaic?.title }}</h2>
			<i *ngIf="mosaic?.hasStar" class="mosaic002__cardsList__icon fa fa-estrela-cheia"></i>
			<i
				class="mosaic002__cardsList__icon fa fa-buscar"
				*ngIf="mosaic?.hasSearch"
				(click)="searchToggler = !searchToggler"
			></i>
		</div>

		<!-- BUSCA CELEBRATION -->
		<div class="mosaic002__search opened" *ngIf="mosaic?.hasSearch && searchToggler">
			<form class="mosaic002__search__content" [formGroup]="form" (ngSubmit)="resetedGetVenues()">
				<div class="mosaic002__search__wrapper wrapper">
					<div class="mosaic002__search__row">
						<div class="mosaic002__search__column">
							<div class="mosaic002__search__form">
								<div class="mosaic002__search__field">
									<input
										class="mosaic002__search__field__input input"
										formControlName="name"
										placeholder="Pesquisar restaurantes celebrations"
										type="text"
									/>
									<!--
									<p class="mosaic002__search__field__notFound" *ngIf="isSearching && !mosaicService.getMosaicBySlug('search_result')?.venues?.length">
										Não encontramos resultado para sua busca.<br />
										Tente uma nova busca.
									</p>
									-->
								</div>
								<button class="mosaic002__search__button button">Buscar</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>

		<ng-container *ngTemplateOutlet="beforeVenues"></ng-container>

		<ng-container *ngIf="!mosaic?.venues?.length && !loadingService.getIsLoadingBySlug(mosaicSlug)">
			<ng-container *ngTemplateOutlet="notFound"></ng-container>
		</ng-container>

		<!-- RESTAURANTE POR CATEGORIA -->
		<ul class="venue__list" *ngIf="mosaic?.venues?.length">
			<!-- CADA RESTAURANTE -->
			<li class="venue__list__eachOne" *ngFor="let cards of mosaic?.venues">
				<ch-venue slug="001" [venue]="cards" [voucherValue]="voucherValue"></ch-venue>
			</li>
		</ul>
		<ul class="venue__list" *ngIf="loadingService.getIsLoadingBySlug(mosaicSlug)">
			<li class="venue__list__eachOne" *ngFor="let item of loadingVenue">
				<ch-loading slug="venue001"></ch-loading>
			</li>
		</ul>
		<div class="mosaic002__more">
			<button class="mosaic002__more__button button" (click)="getVenues()" *ngIf="!reachedEnd">
				Ver mais
			</button>
		</div>
	</div>
</section>

import { Component, OnInit } from '@angular/core'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { NavigationService } from '../../navigation/navigation.service'
import { SearchService } from '../../search/search.service'

@Component({
	selector: 'ch-navbar001',
	templateUrl: './navbar001.component.html',
	styleUrls: ['./navbar001.component.scss'],
})
export class Navbar001Component implements OnInit {
	constructor(
		public clientAuthService: ClientAuthService,
		public searchService: SearchService,
		public navigationService: NavigationService
	) {}

	ngOnInit(): void {}
}

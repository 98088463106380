// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	channelsAPI: {
		token: 'ep5xycuir9s382ka258bckxdner82f4485c2y6ip9plvlhbgmh4xlxy5x1ejmjed',
		baseUrl: 'https://channelsapi-dev.tagme.com.br',
		// token: 'abc123',
		// baseUrl: 'http://localhost:3000',
	},
	waitlist: {
		url: 'https://listadeespera-staging.tagme.com.br',
	},
	liveMenu: {
		url: 'https://live-menu-staging.tagme.com.br/menu',
	},
	cryptoJSKey: '41xiur2viusekrrc',
	partnerID: '595166383625c506d4868f28',
	tagmepub: 'https://tagmepub.azureedge.net',
	googleMapsAPIToken: 'AIzaSyA1p9RdPX2xq2MKmgI2_jCxHqbDs6hGs0E',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

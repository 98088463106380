import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { ConciergeAuthService } from 'src/app/helper/concierge-auth.service'

@Component({
	selector: 'app-concierge-login',
	templateUrl: './concierge-login.component.html',
	styleUrls: ['./concierge-login.component.scss'],
})
export class ConciergeLoginComponent implements OnInit {
	public form: FormGroup = new FormGroup({
		username: new FormControl(null, [Validators.required]),
		password: new FormControl(null, [Validators.required]),
	})

	public formErrors: any = {
		required: 'Campo obrigatório.',
	}

	constructor(
		public conciergeAuthService: ConciergeAuthService,
		public router: Router,
		public loadingService: LoadingService,
		public sharedService: SharedService,
		private title: Title
	) {}

	ngOnInit() {
		if (this.conciergeAuthService.isLogged()) return this.router.navigate(['/concierge'])
		this.title.setTitle(`${PREFIX_TITLE} - Concierge Login`)

		return
	}

	getErrorBySlug(slug: string) {
		if (!this.form.controls[slug].touched) return

		const error = Object.keys(this.form.controls[slug].errors || {})
		return error.length ? this.formErrors[error[0]] : ''
	}

	handleSubmit() {
		if (this.form.invalid) {
			this.sharedService.swal.error('Preencha o formulário corretamente.')
			return
		}

		this.loadingService.setIsLoadingBySlug('generic', true)
		this.conciergeAuthService.login(this.form.value).subscribe({
			next: () => {
				this.loadingService.setIsLoadingBySlug('generic', false)
				this.router.navigate(['/concierge'])
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
		})
	}
}

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { LoadingService } from '../../loading/loading.service'
import { LoginEmailService } from '../login-email.service'

@Component({
	selector: 'ch-login-email001',
	templateUrl: './login-email001.component.html',
	styleUrls: ['./login-email001.component.scss'],
})
export class LoginEmail001Component implements OnInit {
	public form: FormGroup = new FormGroup({
		email: new FormControl(null, [Validators.required, Validators.email]),
	})

	constructor(public loginEmailService: LoginEmailService, public loadingService: LoadingService) {}

	ngOnInit(): void {}

	handleSubmit() {
		this.loadingService.setIsLoadingBySlug('generic', true)
		return this.loginEmailService.sendEmail(this.form.value.email)
	}
}

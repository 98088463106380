<nav class="subHeader001 {{ !bgImage ? 'gradientHorizontal' : '' }}">
	<img *ngIf="bgImage" class="subHeader001__bgImage" [src]="bgImage" alt="" />
	<div class="subHeader001__wrapper wrapper">
		<a class="subHeader001__link" (click)="location.back()">
			<i class="subHeader001__icon tagmeicon tagmeicon-voltar"></i>
			voltar
		</a>
		<h2 class="subHeader001__title">{{ title }}</h2>
	</div>
</nav>

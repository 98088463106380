<div class="waitlistConfirm" *ngIf="waitlist">
	<!-- WAITLIST CONFIRM HEADER -->
	<section class="waitlistConfirm__header">
		<div class="waitlistConfirm__header__box">
			<div class="waitlistConfirm__header__success">
				<p class="waitlistConfirm__header__title">Você entrou na fila de espera!</p>
				<p class="waitlistConfirm__header__text">
					Enviamos um SMS para o número {{ clientAuthService.getFormatedPhone() | mask: '(00) 00000-0000' }}
				</p>
			</div>
			<div class="waitlistConfirm__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="waitlistConfirm__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- WAITLIST CONFIRM BODY -->
	<iframe [src]="getWaitlistURL()" width="100%" height="500" frameborder="0" style="height: inherit"></iframe>

	<!-- WAITLIST CONFIRM FOOTER -->
	<section class="waitlistConfirm__footer">
		<button
			class="waitlistConfirm__footer__button buttonLight"
			(click)="navigationService.setActivatedRightbarBySlug('')"
		>
			Entendi, fechar
		</button>
	</section>
</div>

import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

type PossibleLocations = 'root' | 'reservation' | 'checkin' | 'history' | 'celebrationReservation'

@Injectable({ providedIn: 'root' })
export class NavigationHandle {
	private lastLocationSub = new BehaviorSubject<PossibleLocations | null>(null)
	lastLocation$ = this.lastLocationSub.asObservable()

	constructor() {}

	setLastLocation(location: PossibleLocations) {
		this.lastLocationSub.next(location)
	}
}

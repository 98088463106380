import { Component, OnInit } from '@angular/core'
import { combineLatest, concatMap, filter, map, take, tap } from 'rxjs'
import { BenefitHelper } from 'src/app/component/benefit/benefit.helper'
import { Client } from 'src/app/component/client/client.interface'
import { SharedService } from 'src/app/component/helper/shared.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { OrderVenue } from 'src/app/component/order-venue/order-venue.interface'
import { Reservation } from 'src/app/component/reservation/reservation.interface'
import { ReservationService } from 'src/app/component/reservation/reservation.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { OrderService } from '../../order/order.service'
import { PaymentBillHelper } from '../payment-bill/payment-bill.helper'
import { PaymentCardHelper } from '../payment-card/payment-card.helper'
import { PaymentResponse, PaymentService } from '../payment.service'

@Component({
	selector: 'ch-payment-confirm',
	templateUrl: './payment-confirm.component.html',
	styleUrls: ['./payment-confirm.component.scss'],
})
export class PaymentConfirmComponent implements OnInit {
	selectedOrder$ = this.orderService.selectedOrder$
	paymentResponse$ = this.paymentService.paymentResponse$
	totalValue$ = this.paymentService.totalValue$
	selectedCategory$ = this.clientAuthService.selectedCategory$
	imageBrand$ = this.selectedCategory$.pipe(
		map(category => PaymentCardHelper.chooseBrand(category?.brand as any))
	)

	vm$ = combineLatest([
		this.selectedOrder$,
		this.paymentResponse$,
		this.totalValue$,
		this.selectedCategory$,
		this.imageBrand$,
	]).pipe(
		map(([selectedOrder, paymentResponse, totalValue, selectedCategory, imageBrand]) => ({
			selectedOrder,
			paymentResponse,
			totalValue,
			selectedCategory,
			imageBrand,
		}))
	)

	hasDiscount$ = this.selectedOrder$.pipe(
		filter(order => Boolean(BenefitHelper.findDiscount(order.benefits)))
	)
	discountVenue$ = this.hasDiscount$.pipe(
		concatMap(() => this.selectedOrder$),
		map(order => PaymentBillHelper.getDiscountValue(order))
	)
	discount$ = this.paymentService.discount$

	constructor(
		public navigationService: NavigationService,
		private orderService: OrderService,
		private clientAuthService: ClientAuthService,
		private paymentService: PaymentService
	) {}

	ngOnInit(): void {}
}

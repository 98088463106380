<div class="page">
	<div class="page__header">
		<section class="headerLogin">
			<div class="headerLogin__wrapper wrapper">
				<h2 class="headerLogin__logo">
					<i class="headerLogin__logo__icon fa fa-logo-bradesco"></i>
				</h2>
			</div>
		</section>
	</div>
	<div class="page__body">
		<!-- STEP 1 - WELCOME -->
		<section class="box" *ngIf="getActivatedTab()?.slug === 'welcome'">
			<div class="box__wrapper wrapper">
				<p class="box__text">
					Aguarde um momento, estamos carregando o <span class="box__text__red red">Programa Menu</span> com
					os melhores restaurantes para você. ;)
				</p>
				<div class="box__loading">
					<div class="box__loading__circle"></div>
				</div>
			</div>
		</section>

		<!-- STEP 2 - CELULAR -->
		<ch-login-phone slug="001" *ngIf="getActivatedTab()?.slug === 'phone'"></ch-login-phone>

		<!-- STEP 3 - CÓDIGO DE 4 DÍGITOS -->
		<ch-login-confirm slug="001" *ngIf="getActivatedTab()?.slug === 'confirm'"></ch-login-confirm>

		<!-- STEP 4 - E-MAIL -->
		<ch-login-email slug="001" *ngIf="getActivatedTab()?.slug === 'email'"></ch-login-email>
	</div>
	<div class="page__footer">
		<footer class="footerLogin">
			<div class="footerLogin__wrapper wrapper">
				<!-- COPYRIGHT -->
				<div class="footerLogin__copyright">
					<p class="footerLogin__copyright__text">&copy; 2022 Programa Menu. Todos os direitos reservados.</p>
				</div>

				<!-- LOGO PROGRAMA MENU -->
				<div class="footerLogin__logo">
					<i class="footerLogin__logo__icon fa fa-logo-programa-menu"></i>
				</div>
			</div>
		</footer>
	</div>
</div>

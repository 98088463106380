import { Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { Subscription, combineLatest, filter, map, switchMap, tap } from 'rxjs'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'
import { CreditCardService } from 'src/app/component/credit-card/credit-card.service'
import { FilterService } from 'src/app/component/filter/filter.service'
import { ModalService } from 'src/app/component/modal/modal.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { Slide } from 'src/app/component/slide/slide.interface'
import { ClientAuthService, VERSION } from 'src/app/helper/client-auth.service'
import { GeoLocationService } from 'src/app/helper/geo-location.service'
import { VoucherService } from 'src/app/helper/voucher.service'

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
	public voucherValue: string = ''
	public slides: Slide[] = [
		{
			title: 'Guia MICHELIN',
			text: 'Descubra os restaurantes selecionados pelo guia MICHELIN na nova categoria do Programa Menu.',
			buttonText: 'Ver Categoria',
			imageDesktop:
				'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/banner-guia-michelin-desktop1.png',
			imageMobile:
				'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/banner-%20guia-michelin-v.mobile1.png',
			click: () => {
				window.scrollTo({
					top: document.getElementById('goMichelin')?.offsetTop,
					behavior: 'smooth',
				})
			},
			alt: 'imagem ilustrativa que te convida a fazer parte do Programa Menu',
		},
		{
			title: 'Faça parte do Programa Menu',
			text: 'E aproveite benefícios exclusivos nos melhores restaurantes, além de fazer suas reservas com facilidade e rapidez.',
			buttonText: 'Cadastrar',
			imageDesktop: './../../../../assets/images/slide-faca-parte.png',
			imageMobile: './../../../../assets/images/slide-faca-parte-mobile.png',
			click: () => this.navigationService.setActivatedRightbarBySlug('register-rightbar'),
			alt: 'imagem ilustrativa que te convida a fazer parte do Programa Menu',
		},
		{
			title: 'Vantagens exclusivas',
			text: 'Convidamos você a experimentar as exclusividades do Programa Menu, o programa gastronômico do Bradesco.',
			buttonText: 'Como funciona',
			imageDesktop: './../../../../assets/images/slide-vantagens.png',
			imageMobile: './../../../../assets/images/slide-vantagens-mobile.png',
			router: '/como-funciona',
			alt: 'imagem que ilustra as vantagens exclusivas do Programa Menu',
		},
		{
			title: 'Sem limites para usar',
			text: 'Faça sua reserva e aproveite descontos de até 15% em todos os restaurantes participantes, quantas vezes quiser.',
			buttonText: 'Como funciona',
			imageDesktop: './../../../../assets/images/slide-sem-limites.png',
			imageMobile: './../../../../assets/images/slide-sem-limites-mobile.png',
			router: '/como-funciona',
			alt: 'imagem ilustrando que o Programa Menu é sem limites de uso',
		},

		{
			title: 'Mais restaurantes para você',
			text: 'Seu restaurante favorito com benefícios exclusivos pode estar no Programa Menu',
			buttonText: 'Indique um restaurante',
			imageDesktop:
				'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/banner-indique-um-restautante-desktop.png',
			imageMobile:
				'https://tagmepub.blob.core.windows.net/pubimg/Channels/ProgMenu/banner-indique-um-restautante-mobile.png',
			router: '',
			click: () => {
				const email = 'canais@tagme.com.br'
				const subject = 'Sugestão novo restaurante (Programa Menu)'
				window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`
			},
			alt: 'Imagem que mostra uma mulher sorrindo e segurando um caderno.',
		},
	]

	public subs = new Subscription()
	public hasMomentBBI$ = this.clientAuth.selectedCategory$.pipe(map(category => category?.momentBBI))
	public hasVouchers$ = this.voucherService.getVouchers()

	public showBBICategory = this.hasMomentBBI$.pipe(
		filter(Boolean),
		switchMap(() => this.hasVouchers$)
	)
	constructor(
		public router: Router,
		public filterService: FilterService,
		public creditCardService: CreditCardService,
		public navigationService: NavigationService,
		public clientAuth: ClientAuthService,
		public modalService: ModalService,
		private geoLocationService: GeoLocationService,
		private voucherService: VoucherService,
		private title: Title
	) {}

	ngOnInit() {
		// mudar depois para apenas state = granted
		this.geoLocationService.getCurrentPosition()

		this.title.setTitle(PREFIX_TITLE)
		window.scrollTo(0, 0)
		// Since Client localStorage has changed so much, we need to logout to keep up to date
		// New login to get new information from back
		const version = localStorage.getItem('version')
		const isLoggedIn = this.clientAuth.isLogged()
		if (version !== VERSION && Boolean(isLoggedIn)) {
			this.clientAuth.logout()
		}
		this.subs.add(
			this.clientAuth.voucher$.subscribe({
				next: voucher => {
					if (voucher?._id && this.clientAuth.redirectTimes === 0) {
						this.modalService.setIsOpenBySlug(true)
						this.voucherValue = voucher?.value
						this.clientAuth.redirectTimes = 1
					}
				},
			})
		)

		this.navigationService.setActivatedRightbarBySlug('home')
	}

	getFormatedVoucherValidUntil() {
		const client = this.clientAuth.getClient()
		const date = client?.vouchers[0].validUntil
		return date.substring(0, 10).split('-').reverse().join('/')
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}
}

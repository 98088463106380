import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Loading } from './loading.interface'

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	public loading = new BehaviorSubject<Loading[]>([
		{ slug: 'rightbar', isLoading: false },
		{ slug: 'generic', isLoading: false },
	])

	constructor() {}

	public sendIsLoading(loading: Loading[]) {
		this.loading.next(loading)
	}

	public receiveIsLoading() {
		return this.loading.asObservable()
	}

	addLoading(loading: Loading) {
		const hasLoading = this.loading.getValue().find(item => item.slug === loading.slug)

		if (hasLoading) return

		this.loading.next([...this.loading.getValue(), loading])
	}

	setIsLoadingBySlug(slug: string, isLoading: boolean) {
		const loading = this.loading
			.getValue()
			.map(item => ({ ...item, isLoading: item.slug === slug ? isLoading : item.isLoading }))
		this.loading.next(loading)
	}

	getIsLoadingBySlug(slug: string) {
		return this.loading.getValue().find(item => item.slug === slug)?.isLoading || false
	}
}

import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class LoginConfirmService {
	public digit = new Subject<string>()
	public tab = new Subject<string>()

	constructor() {}

	sendDigit(digit: string) {
		this.digit.next(digit)
	}

	receiveDigit() {
		return this.digit.asObservable()
	}

	sendTab(tab: string) {
		this.tab.next(tab)
	}

	receiveTab() {
		return this.tab.asObservable()
	}
}

<div class="login">
	<section class="login__header">
		<div class="login__header__top">
			<a class="login__header__return" (click)="navigationService.setActivatedRightbarBySlug('profile')">
				<i class="login__header__return__icon tagmeicon tagmeicon-voltar"></i>
				Voltar
			</a>
			<div class="login__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="login__header__close__icon tagmeicon tagmeicon-close"></i>
			</div>
		</div>
	</section>

	<div class="login__top">
		<h2 class="login__top__welcome">Seja Bem-vindo(a)!</h2>
		<p class="login__top__desc" *ngIf="!newPassword">
			Faça seu Login ou crie uma conta no Programa Menu para reservar uma mesa e ter os benefícios do seu
			cartão!
		</p>
		<p class="login__top__desc" *ngIf="newPassword">
			De agora em diante o Programa Menu utiliza uma senha (obrigatória) para seu uso.

			<br />
			<br />

			Um email será enviado para sua caixa postal, e depois crie a senha através do link enviado.
		</p>
	</div>

	<section class="login__body" *ngIf="getLoginViewByRule() === 'concierge'">
		<form class="login__form" [formGroup]="conciergeForm" (ngSubmit)="handleConciergeSubmit()">
			<span class="login__form__title">Login</span>
			<div class="field">
				<div class="field__wrapper">
					<div class="field__icon left tagmeicon tagmeicon-cpf"></div>
					<input
						type="text"
						class="field__input"
						ngModel
						formControlName="cpf"
						placeholder="Número do CPF"
						mask="000.000.000-00"
					/>
				</div>
				<span class="field__feedback" *ngIf="getErrorBySlug('concierge', 'cpf')"
					>Por favor, digite um CPF.</span
				>
			</div>
			<button
				class="login__form__button buttonGhost"
				[disabled]="conciergeForm.invalid || loadingService.getIsLoadingBySlug('rightbar')"
				(click)="handleConciergeSubmit()"
				(touchstart)="handleConciergeSubmit()"
			>
				<span class="login__form__button__text">Entrar</span>
				<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
			</button>
			<p class="login__form__new_account">
				Caso não tenha feito seu cadastro,
				<span
					class="login__form__new_account__span"
					(click)="navigationService.setActivatedRightbarBySlug('register-rightbar')"
				>
					clique aqui.
				</span>
			</p>
		</form>
	</section>

	<section class="login__body">
		<form
			class="login__form"
			[formGroup]="clientForm"
			(ngSubmit)="handleClientSubmit()"
			*ngIf="getLoginViewByRule() === 'client' && !newPassword"
		>
			<span class="login__form__title">Login</span>
			<div class="field">
				<div class="field__wrapper">
					<div class="field__icon left tagmeicon tagmeicon-telefone"></div>
					<input
						type="text"
						class="field__input"
						ngModel
						formControlName="phone"
						placeholder="Número do celular"
						mask="(00) 00000-0000"
					/>
				</div>
				<span class="field__feedback" *ngIf="getErrorBySlug('client', 'phone')"
					>Por favor, digite um telefone válido.</span
				>
			</div>
			<div class="field">
				<div class="field__wrapper">
					<div class="field__icon left tagmeicon tagmeicon-email"></div>
					<input type="email" class="field__input" formControlName="email" placeholder="Seu email" />
				</div>
				<span class="field__feedback" *ngIf="getErrorBySlug('client', 'email')"
					>Por favor, digite um e-mail válido.</span
				>
			</div>
			<div class="field">
				<div class="field__wrapper">
					<div class="field__icon left tagmeicon tagmeicon-locker"></div>
					<input type="password" class="field__input" formControlName="password" placeholder="Sua senha" />
				</div>
				<span class="field__feedback" *ngIf="getErrorBySlug('client', 'password')"
					>Por favor, digite uma senha.</span
				>
			</div>
			<button
				class="login__form__button buttonWhite"
				[disabled]="clientForm.invalid || loadingService.getIsLoadingBySlug('rightbar')"
				(click)="handleClientSubmit()"
				(touchstart)="handleClientSubmit()"
			>
				<span class="login__form__button__text">Entrar</span>
				<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
			</button>
			<div class="login__form__new_account">
				<div>
					Não possui senha ainda? Ou esqueceu sua senha?
					<span class="login__form__new_account__span" (click)="showNewPasswordForm()">
						Cadastre uma nova aqui!
					</span>
				</div>
				<br />
				<div>
					Caso não tenha feito seu cadastro,
					<span
						class="login__form__new_account__span"
						(click)="navigationService.setActivatedRightbarBySlug('register-rightbar')"
					>
						clique aqui.
					</span>
				</div>
			</div>
		</form>
		<form
			class="login__form"
			[formGroup]="newPasswordForm"
			(ngSubmit)="handleNewPasswordSubmit()"
			*ngIf="newPassword"
		>
			<span class="login__form__title">Definir senha</span>
			<div class="field">
				<div class="field__wrapper">
					<div class="field__icon left tagmeicon tagmeicon-telefone"></div>
					<input
						type="text"
						class="field__input"
						formControlName="phone"
						placeholder="Número do celular"
						mask="(00) 00000-0000"
					/>
				</div>
				<span class="field__feedback" *ngIf="getErrorBySlug('password', 'phone')"
					>Por favor, digite um telefone válido.</span
				>
			</div>
			<div class="field">
				<div class="field__wrapper">
					<div class="field__icon left tagmeicon tagmeicon-email"></div>
					<input type="email" class="field__input" formControlName="email" placeholder="Seu email" />
				</div>
				<span class="field__feedback" *ngIf="getErrorBySlug('password', 'email')"
					>Por favor, digite um e-mail válido.</span
				>
			</div>
			<br />
			<button
				class="login__form__button buttonWhite"
				[disabled]="newPasswordForm.invalid || loadingService.getIsLoadingBySlug('rightbar')"
				(click)="handleNewPasswordSubmit()"
				(touchstart)="handleNewPasswordSubmit()"
			>
				<span class="login__form__button__text">Receber link por e-mail</span>
				<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
			</button>
		</form>
	</section>

	<section class="login__footer">
		<i class="login__footer__icon bradesco fa fa-logo-bradesco"></i>
		<p class="login__footer__txt">powered by</p>
		<i class="login__footer__icon tagme fa fa-logo-tagme"></i>
	</section>
</div>

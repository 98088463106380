<div class="filter">
	<div class="filter__wrapper wrapper">
		<!-- BENEFÍCIOS DO CARTÃO LISTA -->
		<div
			class="filter__creditCard"
			*ngIf="clientAuthService.isLogged() && !benefitService.isAcessingFromCelebration.getValue()"
		>
			<div class="filter__creditCard hasPadding">
				<div class="filter__creditCard__selected" (click)="isOpen = !isOpen">
					<img src="../../../../assets/images/img-cartao.svg" class="filter__creditCard__selected__imgCard" />
					<div class="filter__creditCard__selected__typeOfCard" for="credit_card">
						<p class="filter__creditCard__selected__title">Benefícios do seu Cartão</p>
						<p class="filter__creditCard__selected__card">
							{{ clientAuthService.getSelectedCategory()?.name }}
						</p>
					</div>
				</div>
				<ul class="filter__creditCard__select {{ isOpen ? 'filter__creditCard__select--opened' : '' }}">
					<li
						class="filter__creditCard__options"
						*ngFor="let item of clientAuthService.getClient()?.categories; let i = index"
						(click)="setSelectedCategory(item)"
					>
						<!-- <img src="../../../../assets/images/img-cartao.png" class="filter__creditCard__imgCard" /> -->
						<p class="filter__creditCard__typeOfCard">{{ item.name }}</p>
					</li>
				</ul>
			</div>
		</div>

		<!-- LOCALIZAÇÃO -->
		<div class="filter__person__location">
			<p class="filter__person__location__desc">
				Você está em<br />
				<span class="filter__person__location__city">{{ filterService.getSelectedAddress()?.name }}</span>
			</p>
			<button
				class="filter__person__location__button button gradientHorizontal"
				(click)="filterCities = !filterCities"
			>
				Editar
			</button>

			<ul class="filter__city {{ filterCities ? 'filter__city--active' : '' }}">
				<li class="filter__city__item" *ngFor="let city of filter.address" (click)="setAddress($event, city)">
					<input type="checkbox" [id]="'checkbox_' + city.id" class="filter__neighborhood__toggler" />
					<span class="filter__city__item__left">{{ city.name | lowercase }}</span>
					<label
						[for]="'checkbox_' + city.id"
						class="filter__city__item__right"
						[attr.data-city]="'checkbox_' + city.id"
						*ngIf="city.neighborhoods.length"
						(click)="openLocation(city.id)"
					>
						<label
							[for]="city.id"
							[ngClass]="
								numberCity == city.id && isOpenLocation
									? 'filter__city__item__icon__down fa fa-seta'
									: 'filter__city__item__icon fa fa-seta'
							"
						></label>
					</label>
					<ul
						class="filter__neighborhood"
						*ngIf="numberCity == city.id && isOpenLocation && city.neighborhoods.length"
					>
						<li
							class="filter__neighborhood__item"
							*ngFor="let neighborhood of city.neighborhoods"
							(click)="setAddress($event, neighborhood)"
						>
							<span class="filter__neighborhood__item__left">{{ neighborhood.name | lowercase }}</span>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</div>

import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { DomSanitizer, Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { Venue } from 'src/app/component/venue/venue.interface'
import { VenuePageService } from '../venue-page/venue-page.service'
import { environment } from '../../../environments/environment'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
	public venue: Venue | null = null

	constructor(
		public activatedRoute: ActivatedRoute,
		public location: Location,
		public venuePageService: VenuePageService,
		public domSanitizer: DomSanitizer,
		private title: Title,
		private router: Router
	) {}

	ngOnInit(): void {
		window.scrollTo(0, 0)
		const id = this.activatedRoute.snapshot.params['id']
		this.venuePageService.getByID(id).subscribe(res => {
			this.venue = res
			if (this.venue?.name) {
				this.title.setTitle(`${PREFIX_TITLE} - ${this.venue.name} - Menu Digital`)
			}
		})
	}

	get liveMenuURL() {
		const alternateMenu = this.venue?.partner?.alternateMenu
		if (alternateMenu) {
			return this.domSanitizer.bypassSecurityTrustResourceUrl(
				`${environment.liveMenu.url}/${alternateMenu}?origin=channels`
			)
		}
		return this.domSanitizer.bypassSecurityTrustResourceUrl(
			`${environment.liveMenu.url}/${this.venue?._id}?origin=channels`
		)
	}

	goBack() {
		this.router.navigate(['../'], { relativeTo: this.activatedRoute })
	}
}

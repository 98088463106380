<!-- SLIDE VENUE -->
<section class="slide002">
	<div class="slide002__wrapper wrapper s-type-1" role="slider">
		<div class="slide002__control"></div>

		<swiper
			#swiper
			[config]="config"
			(swiper)="onSwiper($event)"
			(slideChange)="onSlideChange()"
			class="slide002__content"
		>
			<ng-template swiperSlide *ngFor="let item of slides">
				<li class="slide002__content__item" *ngIf="!item.router && !item.href">
					<picture>
						<source
							media="(min-width: 768px)"
							type="image/jpg"
							[srcset]="item.imageDesktop | optimizeBannerDesktopSrcSet: 'jpg'"
						/>
						<source type="image/jpg" [srcset]="item.imageDesktop | optimizeBannerMobileSrcSet: 'jpg'" />

						<!-- Imagens JPG para mobile (fallback) -->
						<img
							[src]="item.imageDesktop | optimizeBannerMobile: 'jpg'"
							class="slide002__content__img"
							alt=""
							[srcset]="item.imageDesktop | optimizeBannerMobileSrcSet: 'jpg'"
						/>
					</picture>
				</li>
				<li class="slide002__content__item" *ngIf="item.href && !item.router">
					<a class="slide002__content__link" [href]="item.href" target="_blank">
						<picture>
							<source
								media="(min-width: 768px)"
								type="image/jpg"
								[srcset]="item.imageDesktop | optimizeBannerDesktopSrcSet: 'jpg'"
							/>
							<source type="image/jpg" [srcset]="item.imageDesktop | optimizeBannerMobileSrcSet: 'jpg'" />

							<!-- Imagens JPG para mobile (fallback) -->
							<img
								[src]="item.imageDesktop | optimizeBannerMobile: 'jpg'"
								class="slide002__content__img"
								alt=""
								[srcset]="item.imageDesktop | optimizeBannerMobileSrcSet: 'jpg'"
							/>
						</picture>
					</a>
				</li>
				<li class="slide002__content__item" *ngIf="item.router && !item.href" [routerLink]="item.router">
					<picture>
						<source
							media="(min-width: 768px)"
							type="image/jpg"
							[srcset]="item.imageDesktop | optimizeBannerDesktopSrcSet: 'jpg'"
						/>
						<source type="image/jpg" [srcset]="item.imageDesktop | optimizeBannerMobileSrcSet: 'jpg'" />

						<!-- Imagens JPG para mobile (fallback) -->
						<img
							[src]="item.imageDesktop | optimizeBannerMobile: 'jpg'"
							class="slide002__content__img"
							alt=""
							[srcset]="item.imageDesktop | optimizeBannerMobileSrcSet: 'jpg'"
						/>
					</picture>
				</li>
			</ng-template>
		</swiper>
	</div>
</section>

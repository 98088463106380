import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { take } from 'rxjs'
import { Client } from 'src/app/component/client/client.interface'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { OrderVenue } from 'src/app/component/order-venue/order-venue.interface'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { environment } from 'src/environments/environment'
import { OrderService } from '../../order/order.service'
import { CreatePaymentDto } from '../../profile/new-card/new-card.interfaces'
import { NewCardService } from '../../profile/new-card/new-card.service'
import { PaymentService } from '../payment.service'
import { PaymentCardHelper } from './payment-card.helper'

@Component({
	selector: 'ch-payment-card',
	templateUrl: './payment-card.component.html',
	styleUrls: ['./payment-card.component.scss'],
})
export class PaymentCardComponent implements OnInit {
	client = this.clientAuthService.getClient() as Client

	form!: FormGroup
	selectedOrder!: OrderVenue
	selectedCategory!: any
	imageBrand!: { src: string; alt: string }
	totalValue!: number
	gratuityValue!: number
	initialValue!: number

	constructor(
		public navigationService: NavigationService,
		private orderService: OrderService,
		private clientAuthService: ClientAuthService,
		private paymentService: PaymentService,
		private fb: FormBuilder,
		private newCardService: NewCardService,
		private sharedService: SharedService,
		public loadingService: LoadingService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			cvv: [null, Validators.required],
		})
		// pipe(take(1)) will automatically complete and no need to unsubscribe
		this.orderService.selectedOrder$.pipe(take(1)).subscribe(order => (this.selectedOrder = order))
		this.clientAuthService.selectedCategory$.pipe(take(1)).subscribe(category => {
			this.selectedCategory = category
			this.imageBrand = PaymentCardHelper.chooseBrand(category?.brand as any)
		})
		this.paymentService.totalValue$.pipe(take(1)).subscribe(totalValue => (this.totalValue = totalValue))
		this.paymentService.initialValue$.pipe(take(1)).subscribe(initValue => (this.initialValue = initValue))
		this.paymentService.gratuityValue$.pipe(take(1)).subscribe(gratValue => (this.gratuityValue = gratValue))
	}

	get clientFullName() {
		return `${this.client.name} ${this.client.lastName}`
	}

	handleSubmit() {
		if (this.form.invalid) {
			return
		}

		this.loadingService.setIsLoadingBySlug('rightbar', true)

		const body: CreatePaymentDto = {
			tableOrder: this.selectedOrder._id,
			orderId: this.selectedOrder._id,
			clientId: this.client._id,
			venueId: environment.production ? this.selectedOrder.venueId : '56c776ff0896b3cd13c6012b', // dev only works with Bistro
			gatewayId: this.selectedCategory.gatewayId,
			value: Math.round(this.initialValue * 100), // Em centavos
			gratuityValue: Math.round(this.gratuityValue * 100),
			cpf: PaymentCardHelper.formatCPF(this.client.cpf),
			dynamicCVV: this.form.value.cvv,
		}

		this.newCardService.createPayment(body).subscribe({
			next: (res: any) => {
				this.paymentService.setPaymentResponse(res)
				this.loadingService.setIsLoadingBySlug('rightbar', false)
				this.navigationService.setActivatedRightbarBySlug('payment-confirm')
				this.orderService.updateOrder()
			},
			error: error => {
				this.sharedService.swal.error(error.error.message)
				this.loadingService.setIsLoadingBySlug('rightbar', false)
			},
		})

		// Only for tests
		// const mockResponse = {
		// 	success: true,
		// 	status: 'Paid',
		// 	paidAt: '2022-10-28T17:47:04.278Z',
		// 	code: 'WM2PCZTVEM',
		// }

		// this.paymentService.setPaymentResponse(mockResponse)
		// this.navigationService.setActivatedRightbarBySlug('payment-confirm')
	}
}

<form [formGroup]="form" (ngSubmit)="handleSubmit()" class="waitlist" *ngIf="venue">
	<!-- WAITLIST HEADER -->
	<section class="waitlist__header">
		<div class="waitlist__header__top">
			<a class="waitlist__header__return" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="waitlist__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="waitlist__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="waitlist__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- WAITLIST TOP -->
	<div class="waitlist__top">
		<h2 class="waitlist__top__venue">{{ venue.name }}</h2>
		<p class="waitlist__top__address">{{ venue.address.address }}</p>
		<p class="waitlist__top__title">Fila de espera</p>
	</div>

	<!-- WAITLIST BODY -->
	<section class="waitlist__body">
		<div class="waitlist__body__form">
			<!-- SELECT PEOPLE AMOUNT -->
			<div tabindex="1" class="waitlist__body__selectBox">
				<div class="waitlist__body__selectBox__current" (click)="toggleOpen()">
					<div class="waitlist__body__selectBox__value {{ isOpen ? 'selectBox__value--opened' : '' }}">
						<i class="waitlist__body__selectBox__icon fa fa-perfil"></i>
						<span class="waitlist__body__selectBox__label">{{ titlePeopleLine }}</span>
					</div>
					<ul class="waitlist__body__selectBox__options {{ isOpen ? 'selectBox__options--opened' : '' }}">
						<li
							tabindex="1"
							class="waitlist__body__selectBox__options__item"
							*ngFor="let item of brands"
							(click)="peopleInLine(item.title, item.id)"
						>
							{{ item.title }}
						</li>
					</ul>
				</div>
			</div>

			<!-- PEOPLE AMOUNT -->
			<!-- <div tabindex="1" class="waitlist__body__textBox">
				<div class="waitlist__body__textBox__current">
					<div class="waitlist__body__textBox__value">
						<i class="waitlist__body__textBox__icon fa fa-perfil"></i>
						<input
							class="waitlist__body__textBox__input"
              				placeholder="Digite o numero de pessoas"
							mask="9999"
							formControlName="people_amount"
							ngModel
						/>
					</div>
				</div>
			</div> -->
			<!-- <ch-credit-card slug="001"></ch-credit-card> -->
			<!-- <ch-benefit slug="001" [benefit]="venue.benefits"></ch-benefit> -->
			<div class="message">
				<i class="message__icon fa fa-waitlist"></i>
				<span class="message__bold"
					>A entrada na Fila de espera não dá direito <br />aos benefícios do Programa Menu.</span
				><br />
				Os benefícios estão disponíveis somente mediante reserva ou checkin.
			</div>
		</div>
	</section>

	<!-- WAITLIST FOOTER -->
	<section class="waitlist__footer">
		<button
			class="waitlist__footer__button button gradientHorizontal"
			[disabled]="form.invalid || loadingService.getIsLoadingBySlug('rightbar')"
		>
			<span class="waitlist__footer__button__text">Continuar</span>
			<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
		</button>
	</section>
</form>

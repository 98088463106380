import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { switchMap } from 'rxjs'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'
import { SharedService } from 'src/app/component/helper/shared.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { OrderVenue } from 'src/app/component/order-venue/order-venue.interface'
import { OrderService } from './order.service'
import { ModalService } from '../../component/modal/modal.service'

@Component({
	selector: 'app-order',
	templateUrl: './order.component.html',
	styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
	public orders: OrderVenue[] | undefined
	public selectedOrder: OrderVenue | undefined
	private updateOrders$ = this.orderService.updateOrders$
	public loading = true
	public voucherValue: string | null = null
	public modalDetails = 'order_details_id'

	constructor(
		public modalService: ModalService,
		public orderService: OrderService,
		public sharedService: SharedService,
		public navigationService: NavigationService,
		private title: Title
	) {}

	ngOnInit(): void {
		window.scrollTo(0, 0)
		this.title.setTitle(`${PREFIX_TITLE} - Histórico de Pedidos`)
		this.navigationService.setActivatedRightbarBySlug('')
		this.orderService.getByPartner().subscribe({
			next: res => {
				this.orders = res
				this.loading = false
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
				this.loading = false
			},
		})
		this.updateOrders$.pipe(switchMap(() => this.orderService.getByPartner())).subscribe({
			next: res => {
				this.orders = res
			},
			error: error => this.sharedService.swal.error(error.error?.message),
		})
	}

	handleSeeDetails(order: OrderVenue) {
		this.selectedOrder = order
		this.voucherValue = order?.voucher?.value ? order.voucher.value : null
		this.modalService.setIsOpenBySlug(true, this.modalDetails)
	}

	getFormatedPartySize(partySize: number) {
		return partySize <= 1 ? `${partySize} pessoa` : `${partySize} pessoas`
	}

	getFormatedDate(dateInput: string) {
		if (!dateInput) {
			return 'Sem data'
		}
		const [year, month, day] = dateInput.split('T')[0].split('-')
		const date = new Date(+year, +month - 1, +day)
		const dateFormatted = new Intl.DateTimeFormat('pt-BR', {
			weekday: 'long',
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		}).format(date)
		const captalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
		return captalizeFirstLetter(dateFormatted)
	}
}

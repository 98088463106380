import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ModalService } from 'src/app/component/modal/modal.service'
import { Subscription, take } from 'rxjs'
import { CelebrationReservationService } from 'src/app/component/celebration-reservation/celebration-reservation.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { Venue } from 'src/app/component/venue/venue.interface'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { NavigationHandle } from '../../profile/new-card/navigation-handle.service'
import { VenuePageService } from '../../venue-page/venue-page.service'

@Component({
	selector: 'ch-celebration-reservation-form',
	templateUrl: './celebration-reservation-form.component.html',
	styleUrls: ['./celebration-reservation-form.component.scss'],
})
export class CelebrationReservationFormComponent implements OnInit {
	public availabilities: any = []
	public selectedReservationDay: any | undefined
	public selectedSection: any | undefined
	public selectedSchedule: any | undefined
	public selectedReservationTime: any | undefined
	public subs: Array<Subscription> = []
	public venue: Venue | null = this.celebrationReservationService.reservation.getValue().venue
	public openRegisterCard = false
	public voucherValue = false

	public form: FormGroup = new FormGroup({
		id: new FormControl(null, [Validators.required]),
		conciergeUser: new FormControl(null, []),
		people_amount: new FormControl(null, [Validators.required]),
		environment: new FormControl(null, [Validators.required]),
		date: new FormControl(null, [Validators.required]),
		time: new FormControl(null, [Validators.required]),
		credit_card: new FormControl(null, [Validators.required]),
		voucherId: new FormControl(null, []),
	})

	constructor(
		public navigationService: NavigationService,
		public celebrationReservationService: CelebrationReservationService,
		public loadingService: LoadingService,
		public sharedService: SharedService,
		public venuePageService: VenuePageService,
		public clientAuthService: ClientAuthService,
		private modalService: ModalService,
		private navigationHandle: NavigationHandle
	) {}

	ngOnInit(): void {
		const stored = this.sharedService.localStorage.get('celebration-reservation')
		const isSameVenueAsStoredOne =
			stored?.venue?._id === this.celebrationReservationService.reservation.getValue()?.venue?._id

		const reservation =
			stored && isSameVenueAsStoredOne ? stored : this.celebrationReservationService.reservation.getValue()

		const concierge = this.sharedService.localStorage.get('concierge')

		const body = {
			venueId: reservation.venue._id,
			id: reservation.id,
			conciergeUser: concierge._id || null,
			voucherId: this.clientAuthService.getClient()?.vouchers[0]._id || null,
		}

		this.celebrationReservationService.getCheckAvailability(body).subscribe(
			res => {
				this.availabilities = res.availabilities || []

				this.form.patchValue({ id: res.order._id })
				this.form.patchValue({ voucherId: res.order.voucherId })

				// Inicio: Preenchimento automatico do form
				const reservationDay = (this.availabilities || []).find(
					(item: any) => item.reservationDay === reservation.date
				)

				if (reservationDay) this.handleDateField(null, reservationDay)

				const section = (this.selectedReservationDay?.sections || []).find(
					(item: any) => item.id === reservation.environment
				)

				if (section) this.handleSectionField(null, section)

				const schedule = (this.selectedSection?.schedules || []).find(
					(item: any) => item.partySize === reservation.people_amount
				)

				if (schedule) this.handleScheduleField(null, schedule)

				const reservationTime = (this.selectedSchedule?.reservationTimes || []).find(
					(item: any) => item.reservationTime === reservation.time
				)

				if (reservationTime) this.handleReservationTimeField(null, reservationTime)
				// Fim
			},
			error => {
				this.navigationService.setActivatedRightbarBySlug('')
				this.sharedService.swal.error(error.error?.message)
			}
		)

		this.subs.push(
			this.celebrationReservationService.receiveReservation().subscribe(res => {
				this.form.patchValue({ id: res['id'] })
				this.form.patchValue({ voucherId: res['voucherId'] })
				this.form.patchValue({ people_amount: res['people_amount'] })
				this.form.patchValue({ environment: res['environment'] })
				this.form.patchValue({ date: res['date'] })
				this.form.patchValue({ time: res['time'] })
				this.form.patchValue({ credit_card: res['credit_card'] })
			})
		)

		this.subs.push(
			this.clientAuthService.receiveClient().subscribe(res => {
				this.handleCreditCardField(null, this.clientAuthService.getSelectedCategory()?.categoryId)
			})
		)

		this.subs.push(
			this.clientAuthService.voucher$.subscribe(voucher => {
				this.voucherValue = voucher?.value
			})
		)

		this.subs.push(
			this.venuePageService.receiveVenue().subscribe(res => {
				this.celebrationReservationService.setReservationByKey('venue', res)
				this.venue = res
			})
		)
	}

	getFormatedDate(date: string) {
		return date ? date.split('-').reverse().join('/') : ''
	}

	handleDateField(el: any, item: any) {
		if (item.reservationDay !== this.form.value.date) {
			this.form.patchValue({ environment: null })
			this.form.patchValue({ people_amount: null })
			this.form.patchValue({ time: null })
			this.selectedSection = null
			this.selectedSchedule = null
			this.selectedReservationTime = null
		}

		this.selectedReservationDay = item
		this.form.patchValue({ date: item.reservationDay })
		this.celebrationReservationService.sendReservation(this.form.value)
		if (el) el.target.blur()
	}

	handleSectionField(el: any, item: any) {
		if (item.id !== this.form.value.environment) {
			this.form.patchValue({ people_amount: null })
			this.form.patchValue({ time: null })
			this.selectedSchedule = null
			this.selectedReservationTime = null
		}

		this.selectedSection = { ...item, schedules: item.schedules.filter((section: any) => section.available) }
		this.form.patchValue({ environment: item.id })
		this.celebrationReservationService.sendReservation(this.form.value)
		if (el) el.target.blur()
	}

	handleScheduleField(el: any, item: any) {
		if (item.partySize !== this.form.value.people_amount) {
			this.form.patchValue({ time: null })
			this.selectedReservationTime = null
		}

		this.selectedSchedule = {
			...item,
			reservationTimes: item.reservationTimes.filter((reservationTime: any) => reservationTime.available),
		}
		this.form.patchValue({ people_amount: item.partySize })
		this.celebrationReservationService.sendReservation(this.form.value)
		if (el) el.target.blur()
	}

	handleReservationTimeField(el: any, item: any) {
		this.selectedReservationTime = item
		this.form.patchValue({ time: item.reservationTime })
		this.celebrationReservationService.sendReservation(this.form.value)
		if (el) el.target.blur()
	}

	handleCreditCardField(el: any, value: any) {
		this.form.patchValue({ credit_card: value })
		this.celebrationReservationService.sendReservation(this.form.value)
		if (el) el.target.blur()
	}

	// handleSetValue(el: any, key: string, value: any) {
	// 	if (el && el.key) {
	// 		if (el.key.toLowerCase() === 'enter') {
	// 			const nextSelectBox = el.target
	// 				.closest('.reservation__body__selectBox')
	// 				.getAttribute('data-nextSelectBox')
	// 			const nextElement: any = document.querySelector(`[data-currentSelectBox='${nextSelectBox}']`)
	// 			if (nextElement) nextElement.focus()
	// 			this.form.patchValue({ [key]: value })
	// 			return this.celebrationReservationService.sendReservation(this.form.value)
	// 		}
	// 		return
	// 	}

	// 	if (el) el.target.blur()
	// 	this.form.patchValue({ [key]: value })
	// 	return this.celebrationReservationService.sendReservation(this.form.value)
	// }

	handleSubmit() {
		if (this.form.invalid) return

		this.clientAuthService.hasRealCard$.pipe(take(1)).subscribe(value => {
			if (!value) {
				this.openRegisterCard = true
			} else {
				const concierge = this.sharedService.localStorage.get('concierge')

				this.celebrationReservationService.sendReservation({
					...this.form.value,
					environment_label: this.selectedSection.label,
					conciergeUser: concierge._id || null,
				})
				this.navigationService.setActivatedRightbarBySlug('celebration-reservation-data')
			}
		})
	}

	closeModal() {
		this.openRegisterCard = false
	}

	handleConfirmRegisration() {
		this.navigationHandle.setLastLocation('celebrationReservation')
		this.navigationService.setActivatedRightbarBySlug('new-card')
		this.closeModal()
	}
}

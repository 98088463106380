<div class="concierge_client">
	<div class="concierge_client__sub_header">
		<div class="concierge_client__sub_header__item">
			<form class="concierge_client__modal__form" [formGroup]="searchForm" (ngSubmit)="handleSearchSubmit()">
				<div class="field">
					<div class="field__wrapper">
						<button class="field__icon right tagmeicon tagmeicon-buscar"></button>
						<input type="text" class="field__input" ngModel formControlName="term" placeholder="Pesquisar" />
					</div>
					<span class="field__helper">Pesquise pelo nome, cpf ou telefone do cliente.</span>
				</div>
			</form>
		</div>
		<div class="concierge_client__sub_header__item" *ngIf="false" f>
			<button class="concierge_client__sub_header__button" (click)="addClient()">Adicionar cliente +</button>
		</div>
	</div>
	<div class="table">
		<table class="table__main">
			<thead class="table__thead">
				<tr class="table__tr">
					<th class="table__th">Nome</th>
					<th class="table__th">CPF</th>
					<th class="table__th">Telefone</th>
					<th class="table__th">E-mail</th>
					<th class="table__th">Cartão do cliente</th>
					<th class="table__th">Ações</th>
				</tr>
			</thead>
			<tbody class="table__tbody">
				<tr class="table__tr" *ngFor="let item of client">
					<td class="table__td">{{ item.name }} {{ item.lastName }}</td>
					<td class="table__td">{{ item.cpf | mask: '000.000.000-00' }}</td>
					<td class="table__td">{{ item.phone | mask: '+00 (00) 00000-0000' }}</td>
					<td class="table__td">{{ item.email }}</td>
					<td class="table__td" [innerHTML]="getCategoriesNames(item)"></td>
					<td class="table__td">
						<button class="button table__button" (click)="loginAsClient(item)">Selecionar</button>
						<button class="button table__button" (click)="editClient(item)">Editar</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<ch-modal slug="001">
	<form class="concierge_client__modal" [formGroup]="clientForm" (ngSubmit)="handleSubHeaderForm()" content>
		<div class="concierge_client__modal__header">
			<span class="concierge_client__modal__title">{{
				editing ? 'Editar perfil' : 'Adicionar cliente'
			}}</span>
		</div>
		<div class="concierge_client__modal__body">
			<div class="field">
				<div class="field__wrapper">
					<i class="field__icon left tagmeicon tagmeicon-nome"></i>
					<input
						type="text"
						class="field__input"
						ngModel
						formControlName="name"
						placeholder="Nome completo"
					/>
				</div>
				<span class="field__feedback">{{ getErrorBySlug('name') }}</span>
			</div>
			<div class="field">
				<div class="field__wrapper">
					<i class="field__icon left tagmeicon tagmeicon-email"></i>
					<input type="text" class="field__input" ngModel formControlName="email" placeholder="E-mail" />
				</div>
				<span class="field__feedback">{{ getErrorBySlug('email') }}</span>
			</div>
			<div class="field">
				<div class="field__wrapper">
					<i class="field__icon left tagmeicon tagmeicon-buscar"></i>
					<input
						type="text"
						class="field__input"
						ngModel
						formControlName="phone"
						placeholder="Telefone"
						mask="(00) 00000-0000"
					/>
				</div>
				<span class="field__feedback">{{ getErrorBySlug('phone') }}</span>
			</div>
			<div class="field">
				<div class="field__wrapper">
					<i class="field__icon left tagmeicon tagmeicon-cpf"></i>
					<input
						type="text"
						class="field__input"
						ngModel
						formControlName="cpf"
						placeholder="Encontrar cliente por cpf"
						mask="000.000.000-00"
						[readonly]="editing"
					/>
				</div>
				<span class="field__feedback">{{ getErrorBySlug('cpf') }}</span>
			</div>
			<div class="field">
				<div class="field__wrapper">
					<i class="field__icon left tagmeicon tagmeicon-cartao-de-credito"></i>
					<i class="field__icon right tagmeicon tagmeicon-mais add_credit_card" (click)="addCreditCard()"></i>
					<input
						type="text"
						class="field__input"
						ngModel
						formControlName="digit"
						placeholder="6 primeiros digitos do cartão"
						mask="000000"
					/>
				</div>
				<span class="field__feedback">{{ getErrorBySlug('digit') }}</span>
				<div class="table">
					<table class="table__main">
						<thead class="table__thead">
							<tr class="table__tr">
								<th class="table__th">N°</th>
								<th class="table__th">Tipo</th>
							</tr>
						</thead>
						<tbody class="table__tbody">
							<ng-container *ngFor="let item of clientForm.value.card">
								<tr class="table__tr">
									<td class="table__td">{{ item?.digits }}</td>
									<td class="table__td">
										<div class="table__td__wrapper">
											<div class="table__credit_card" *ngIf="false" f>
												<img src="#" alt="" class="table__image" />
											</div>
											{{ item?.name }}
										</div>
									</td>
								</tr>
								<tr class="table__tr" *ngIf="item.isNew">
									<td class="table__td" colspan="2">
										<div
											class="confirm {{ item.isConfirmed ? 'confirmed' : '' }}"
											(click)="confirmCreditCard(item)"
										>
											<div class="confirm__check"></div>
											<span class="confirm__label"
												>Confirma adicionar o cartão
												<span class="confirm__label__card_name">“Bradesco Visa Infinite”</span>?</span
											>
										</div>
									</td>
								</tr>
							</ng-container>
						</tbody>
					</table>
				</div>
				<span class="field__feedback">{{ getErrorBySlug('card') }}</span>
			</div>
			<button
				class="concierge_client__modal__button button"
				[disabled]="clientForm.invalid || loadingService.getIsLoadingBySlug('generic')"
			>
				<span class="concierge_client__modal__button__text">Salvar alterações</span>
				<ch-loading *ngIf="loadingService.getIsLoadingBySlug('generic')" slug="button001"></ch-loading>
			</button>
		</div>
	</form>
</ch-modal>

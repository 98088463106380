<ul class="benefit003">
	<ng-container *ngIf="!benefitService.isAcessingFromCelebration.getValue()">
		<!-- <li class="benefit003__item" *ngFor="let item of getSortedBenefits(benefit); let i = index"> -->
		<li class="benefit003__item" *ngFor="let item of sortedBenefits; let i = index">
			<div class="benefit003__image">
				<img class="benefit003__icon" [src]="env.tagmepub + '/pubimg/' + item.icon" />
			</div>
			<div class="benefit003__details">
				<span class="benefit003__name">{{ getFormatedBenefitNameByType(item) }} </span>
			</div>
		</li>
	</ng-container>
	<ng-container *ngIf="benefitService.isAcessingFromCelebration.getValue() || isCelebration">
		<li class="benefit003__item">
			<div class="benefit003__image">
				<img class="benefit003__icon" src="./../../../../assets/images/icon-presente.svg" />
			</div>
			<div class="benefit003__details">
				<span class="benefit003__name">Celebrations</span>
			</div>
		</li>
	</ng-container>
</ul>

<div class="singleCreditCard001">
	<div
		class="singleCreditCard001__card {{ singleCreditCardService.isFlipped.getValue() ? 'flipped' : '' }} {{
			singleCreditCardService.brand.getValue()
		}}"
	>
		<div class="singleCreditCard001__front gradientHorizontal">
			<p class="singleCreditCard001__front__credit">Crédito</p>
			<p class="singleCreditCard001__front__number">
				<span *ngIf="number">
					<ng-container *ngIf="number.length === 16">
						{{ number | mask: '0000 0000 0000 0000' }}
					</ng-container>
					<ng-container *ngIf="number.length < 16">
						{{ number | mask: '0000 000000 00000' }}
					</ng-container>
				</span>
				<!-- <span *ngIf="number">{{ number | mask: '0000 0000 0000 0000' }}</span> -->
				<span *ngIf="!number">0000 0000 0000 0000</span>
				<!-- •• •••• •••• -->
			</p>
			<div class="singleCreditCard001__front__infos">
				<p class="singleCreditCard001__front__infos__name">{{ name || 'Nome impresso no cartão' }}</p>
				<p class="singleCreditCard001__front__infos__validity" *ngIf="validity">
					{{ validity | mask: '00/00' }}
				</p>

				<p class="singleCreditCard001__front__infos__validity" *ngIf="!validity">MM/AA</p>
			</div>
		</div>
		<div class="singleCreditCard001__back gradientHorizontal">
			<span class="singleCreditCard001__back__cvv">{{ cvv || '000' }}</span>
		</div>
	</div>
</div>

import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import {
	map,
	catchError,
	Subject,
	throwError,
	takeWhile,
	Observable,
	startWith,
	timer,
	switchMap,
} from 'rxjs'
import { CreditCardService } from 'src/app/component/credit-card/credit-card.service'
import { FilterService } from 'src/app/component/filter/filter.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { ConciergeAuthService } from 'src/app/helper/concierge-auth.service'
import { passwordMatchValidator, passwordValidator } from '../update-password/update-password.helper'
import { Validation, Helper } from './register-rightbar.helper'
import { RegisterRightbarService } from './register-rightbar.service'

interface RegisterRightbar {
	slug: string
	isActive: boolean
}
function countdownFrom(start: number): Observable<number> {
	return timer(0, 1000).pipe(
		map(index => start - index),
		takeWhile<number>(Boolean, true)
	)
}

@Component({
	selector: 'ch-register-rightbar',
	templateUrl: './register-rightbar.component.html',
	styleUrls: ['./register-rightbar.component.scss'],
})
export class RegisterRightbarComponent implements OnInit {
	private readonly resendSubject = new Subject<void>()
	readonly resend$ = this.resendSubject.asObservable()
	readonly countdown$ = this.resend$.pipe(
		startWith(0),
		switchMap(() => countdownFrom(60))
	)

	resendMessage: string | undefined
	public client: any = null

	private cardNameSubject = new Subject<string | undefined>()
	cardName$ = this.cardNameSubject.asObservable()

	public step: RegisterRightbar[] = [
		{ slug: 'first', isActive: true },
		{ slug: 'second', isActive: false },
		{ slug: 'third', isActive: false },
	]

	public firstStepForm!: FormGroup
	public secondStepForm!: FormGroup
	public thirdStepForm!: FormGroup

	constructor(
		public clientAuthService: ClientAuthService,
		public conciergeAuthService: ConciergeAuthService,
		public navigationService: NavigationService,
		public loadingService: LoadingService,
		public sharedService: SharedService,
		public registerRightbarService: RegisterRightbarService,
		public router: Router,
		public filterService: FilterService,
		public creditCardService: CreditCardService,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.firstStepForm = this.fb.group({
			phone: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
		})

		this.secondStepForm = this.fb.group({
			code: [null, [Validators.required]],
			passwordGroup: this.fb.group(
				{
					password: ['', [Validators.required, passwordValidator()]],
					confirmPassword: ['', [Validators.required]],
				},
				{ validators: passwordMatchValidator('password', 'confirmPassword') }
			),
		})

		this.thirdStepForm = this.fb.group({
			name: ['', [Validators.required, Validation.nameIsValid]],
			cpf: [null, [Validators.required]],
			category: [null, [Validators.required]],
			eula: [null, [Validators.required]],
			password: ['', [Validators.required]],
		})
	}

	getLoginViewByRule() {
		if (this.conciergeAuthService.concierge.getValue()) return 'concierge'
		if (!this.clientAuthService.getClient()) return 'client'

		return ''
	}

	getErrorBySlug(slugForm: 'first' | 'second' | 'third', formControl: string) {
		const chooseStepForm = {
			first: this.firstStepForm,
			second: this.secondStepForm,
			third: this.thirdStepForm,
		}
		const stepForm = chooseStepForm[slugForm]

		if (!stepForm?.controls[formControl].touched) return false

		const error = Object.keys(stepForm?.controls[formControl].errors || {})
		return error.length ? true : false
	}

	activateStepBySlug(slug: 'first' | 'second' | 'third') {
		this.step = this.step.map(item => ({ ...item, isActive: item.slug === slug }))
	}

	handleFirstSubmit() {
		if (this.firstStepForm.invalid) {
			this.sharedService.swal.error('Preencha o formulário corretamente.')
			return
		}
		this.loadingService.setIsLoadingBySlug('rightbar', true)

		const body = {
			phone: Helper.normalizePhone(this.firstStepForm.value.phone),
			email: Helper.normalizeEmail(this.firstStepForm.value.email),
			step: 'first' as const,
		}

		this.registerRightbarService
			.postClients(body)
			.pipe(catchError(this.handleError))
			.subscribe({
				next: res => {
					// Ja possui a conta validada.
					if (res.agreements?.eula) {
						// return this.doLogin(res)
					}
					this.activateStepBySlug('second')
					this.resendSubject.next()
					this.loadingService.setIsLoadingBySlug('rightbar', false)
				},
				error: error => {
					if (error.error?.nextStep === 'second') {
						this.resendCode()
					}
				},
			})
	}

	handleSecondSubmit() {
		if (this.secondStepForm.invalid) {
			this.sharedService.swal.error('Preencha o formulário corretamente.')
			return
		}
		this.loadingService.setIsLoadingBySlug('rightbar', true)

		const password = this.secondStepForm.get('passwordGroup')?.get('password')?.value

		const body = {
			phone: Helper.normalizePhone(this.firstStepForm.value.phone),
			email: Helper.normalizeEmail(this.firstStepForm.value.email),
			registrationCode: this.secondStepForm.value.code,
			password: password,
			step: 'second' as const,
		}
		this.registerRightbarService
			.postClients(body)
			.pipe(catchError(this.handleError))
			.subscribe({
				next: client => {
					if (client.cpf) {
						this.thirdStepForm.patchValue({ cpf: client.cpf })
					}
					if (client?.categories[0]?.digits) {
						this.thirdStepForm.patchValue({ category: client.categories[0].digits })
					}
					if (client.name && client.lastName) {
						this.thirdStepForm.patchValue({ name: `${client.name} ${client.lastName}` })
					}
					this.activateStepBySlug('third')
					this.loadingService.setIsLoadingBySlug('rightbar', false)
				},
			})
	}

	resendCode() {
		const body = {
			phone: Helper.normalizePhone(this.firstStepForm.value.phone),
			email: Helper.normalizeEmail(this.firstStepForm.value.email),
		}
		this.resendSubject.next()
		this.clientAuthService.resendCode(body).pipe(catchError(this.handleError)).subscribe()
	}

	handleThirdSubmit() {
		if (this.thirdStepForm.invalid) {
			this.sharedService.swal.error('Preencha o formulário corretamente.')
			return
		}
		this.loadingService.setIsLoadingBySlug('rightbar', true)

		const body = {
			email: Helper.normalizeEmail(this.firstStepForm.value.email),
			phone: Helper.normalizePhone(this.firstStepForm.value.phone),
			name: this.thirdStepForm.value.name,
			cpf: this.thirdStepForm.value.cpf,
			agreements: { eula: this.thirdStepForm.value.eula },
			categories: [parseInt(this.thirdStepForm.value.category)],
			password: this.thirdStepForm.value.password,
			step: 'third' as const,
		}
		this.registerRightbarService
			.postClients(body)
			.pipe(catchError(this.handleError))
			.subscribe({
				next: () => {
					this.sharedService.swal.success('Cadastro concluído com sucesso!')
					this.navigationService.setActivatedRightbarBySlug('login-rightbar')
					this.loadingService.setIsLoadingBySlug('rightbar', false)
				},
				error: () => {
					this.loadingService.setIsLoadingBySlug('rightbar', false)
				},
			})
	}

	private handleError = (error: any) => {
		if (error.error?.nextStep) {
			this.loadingService.setIsLoadingBySlug('rightbar', false)
			this.activateStepBySlug(error.error?.nextStep)
			if (error?.error?.error) {
				this.sharedService.swal.error(error?.error?.error)
			}
		} else {
			this.sharedService.swal.error(error?.error?.error)
			this.loadingService.setIsLoadingBySlug('rightbar', false)
		}
		return throwError(() => error)
	}
}

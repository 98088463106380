import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { ConciergeAuthService } from 'src/app/helper/concierge-auth.service'
import { BenefitService } from '../benefit/benefit.service'
import { Client } from '../client/client.interface'
import { NavigationService } from '../navigation/navigation.service'
import { SearchService } from '../search/search.service'
import { environment } from 'src/environments/environment'
@Component({
	selector: 'app-bradesco-navbar',
	templateUrl: './bradesco-navbar.component.html',
	styleUrls: ['./bradesco-navbar.component.scss'],
})
export class BradescoNavbarComponent implements OnInit, OnDestroy {
	public client: Client | null = null
	public subs = new Subscription()
	public isAccessingFromCelebration: boolean = false
	public isSearch: boolean = false

	constructor(
		public clientAuthService: ClientAuthService,
		public conciergeAuthService: ConciergeAuthService,
		public searchService: SearchService,
		public navigationService: NavigationService,
		public benefitService: BenefitService
	) {}

	ngOnInit(): void {
		this.subs.add(this.clientAuthService.receiveClient().subscribe(res => (this.client = res)))
		this.subs.add(
			this.benefitService
				.getIsAcessingFromCelebration()
				.subscribe(res => (this.isAccessingFromCelebration = res))
		)

		const path = window.location.href
		this.isSearch =
			path.includes('resultado-de-busca') ||
			path.includes('favoritos') ||
			path.includes('historico-de-pedidos') ||
			path.includes('menu-digital')
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}
}

import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'
import { NavigationService } from 'src/app/component/navigation/navigation.service'

@Component({
	selector: 'app-how-it-works',
	templateUrl: './how-it-works.component.html',
	styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksComponent implements OnInit {
	constructor(public navigationService: NavigationService, private title: Title) {}

	ngOnInit(): void {
		window.scrollTo(0, 0)
		this.title.setTitle(`${PREFIX_TITLE} - Como funciona`)
		this.navigationService.setActivatedRightbarBySlug('')
	}
}

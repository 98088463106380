<div class="page">
	<div class="page__header">
		<div class="concierge_login__header">
			<div class="concierge_login__wrapper wrapper">
				<ul class="concierge_login__menu">
					<li class="concierge_login__menu__item">
						<a class="concierge_login__menu__link">
							<i class="concierge_login__menu__logo_bradesco fa fa-logo-bradesco"></i>
							<!-- <img
								class="concierge_login__menu__logo_bradesco"
								src="./../../../../assets/images/logo-bradesco-branca-horizontal.png"
								alt=""
							/> -->
						</a>
					</li>
					<li class="concierge_login__menu__item">
						<a class="concierge_login__menu__link">
							<i class="concierge_login__menu__logo_programa_menu fa fa-logo-programa-menu"></i>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="page__body">
		<div class="concierge_login__wrapper wrapper">
			<form class="concierge_login__form" [formGroup]="form" (ngSubmit)="handleSubmit()">
				<span class="concierge_login__title">Concierge Programa Menu</span>
				<span class="concierge_login__text">Login para acessar o painel de controle do concierge</span>
				<div class="field">
					<div class="field__wrapper">
						<div class="field__icon left tagmeicon tagmeicon-nome"></div>
						<input type="text" class="field__input" formControlName="username" placeholder="usuário" />
					</div>
					<span class="field__feedback">{{ getErrorBySlug('username') }}</span>
				</div>
				<div class="field">
					<div class="field__wrapper">
						<div class="field__icon left tagmeicon tagmeicon-block-on"></div>
						<input
							type="password"
							class="field__input"
							ngModel
							formControlName="password"
							placeholder="senha"
						/>
					</div>
					<span class="field__feedback">{{ getErrorBySlug('password') }}</span>
				</div>
				<button
					class="concierge_login__button button"
					[disabled]="form.invalid || loadingService.getIsLoadingBySlug('generic')"
				>
					<span class="concierge_login__button__text">Acessar</span>
					<ch-loading *ngIf="loadingService.getIsLoadingBySlug('generic')" slug="button001"></ch-loading>
				</button>
				<div class="concierge_login__powered">
					<span class="concierge_login__powered__text">Powered by</span>
					<i class="concierge_login__powered__logo fa fa-logo-tagme"></i>
				</div>
			</form>
		</div>
	</div>
	<div class="page__footer"></div>
</div>

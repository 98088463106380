import { Component, Input } from '@angular/core'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { environment } from 'src/environments/environment'
import { Benefit } from '../benefit.interface'
import { BenefitService } from '../benefit.service'

@Component({
	selector: 'ch-benefit002',
	templateUrl: './benefit002.component.html',
	styleUrls: ['./benefit002.component.scss'],
})
export class Benefit002Component {
	public env = environment
	@Input() voucherValue: any
	@Input() benefit: Benefit[] = []
	@Input() isCelebration: boolean = false

	constructor(public benefitService: BenefitService, private clientAuth: ClientAuthService) {}

	getSortedBenefits(benefits: Array<any>) {
		return benefits.sort((a, b) => a.order - b.order)
	}

	getFormatedBenefitNameByType(benefit: Benefit) {
		if (benefit.type === 'discount') {
			return this.getFormattedDiscount(benefit)
		}
		return benefit.name
	}

	getFormattedDiscount(benefit: Benefit) {
		if (this.clientAuth.isLogged()) {
			return `${benefit.value} de desconto`
		}
		return `Desconto`
	}
}

<form [formGroup]="form" (ngSubmit)="handleSubmit()" class="celebrationReservation" *ngIf="venue">
	<!-- RESERVATION HEADER -->
	<section class="celebrationReservation__header">
		<div class="celebrationReservation__header__top">
			<a
				class="celebrationReservation__header__return"
				(click)="navigationService.setActivatedRightbarBySlug('')"
			>
				<i class="celebrationReservation__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div
				class="celebrationReservation__header__close"
				(click)="navigationService.setActivatedRightbarBySlug('')"
			>
				<i class="celebrationReservation__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- RESERVATION TOP -->
	<div class="celebrationReservation__top">
		<h2 class="celebrationReservation__top__venue">{{ venue.name }}</h2>
		<p class="celebrationReservation__top__address">
			{{ venue.address.address }}
		</p>
		<p class="celebrationReservation__top__title">Reserva de mesa</p>
	</div>

	<!-- RESERVATION BODY -->
	<section class="celebrationReservation__body">
		<!-- FORM -->
		<div class="celebrationReservation__body__form">
			<!-- SELECT DATE -->
			<div *ngIf="!(availabilities || []).length" class="loading001">
				<div class="loading001__circle"></div>
			</div>
			<div *ngIf="(availabilities || []).length" tabindex="1" class="celebrationReservation__body__selectBox">
				<div class="celebrationReservation__body__selectBox__current">
					<div class="celebrationReservation__body__selectBox__value">
						<i class="celebrationReservation__body__selectBox__icon fa fa-data"></i>
						<span class="celebrationReservation__body__selectBox__label">{{
							getFormatedDate(form.value.date) || 'Escolha a data'
						}}</span>
					</div>
					<ul class="celebrationReservation__body__selectBox__options">
						<ng-container *ngFor="let item of availabilities">
							<li
								tabindex="1"
								class="celebrationReservation__body__selectBox__options__item"
								*ngIf="item.available"
								(click)="handleDateField($event, item)"
								(keyup)="handleDateField($event, item)"
							>
								{{ getFormatedDate(item.reservationDay) }}
							</li>
						</ng-container>
					</ul>
				</div>
			</div>

			<!-- SELECT ENVIRONMENT -->
			<div
				*ngIf="(selectedReservationDay?.sections || []).length"
				tabindex="1"
				class="celebrationReservation__body__selectBox"
			>
				<div class="celebrationReservation__body__selectBox__current">
					<div class="celebrationReservation__body__selectBox__value">
						<i class="celebrationReservation__body__selectBox__icon fa fa-mesa"></i>
						<span class="celebrationReservation__body__selectBox__label">{{
							selectedSection?.label || 'Selecione o salão'
						}}</span>
					</div>
					<ul class="celebrationReservation__body__selectBox__options">
						<li
							tabindex="1"
							class="celebrationReservation__body__selectBox__options__item"
							*ngFor="let item of selectedReservationDay.sections"
							(click)="handleSectionField($event, item)"
							(keyup)="handleSectionField($event, item)"
						>
							{{ item.label }}
						</li>
					</ul>
				</div>
			</div>

			<!-- SELECT PEOPLE AMOUNT -->
			<div
				*ngIf="(selectedSection?.schedules || []).length"
				tabindex="1"
				class="celebrationReservation__body__selectBox"
			>
				<div class="celebrationReservation__body__selectBox__current">
					<div class="celebrationReservation__body__selectBox__value">
						<i class="celebrationReservation__body__selectBox__icon fa fa-perfil"></i>
						<span class="celebrationReservation__body__selectBox__label" *ngIf="form.value.people_amount"
							>Mesa para {{ form.value.people_amount }}
							{{ form.value.people_amount >= 2 ? 'pessoas' : 'pessoa' }}</span
						>
						<span class="celebrationReservation__body__selectBox__label" *ngIf="!form.value.people_amount"
							>Selecione a qtd de pessoas</span
						>
					</div>
					<ul class="celebrationReservation__body__selectBox__options">
						<li
							tabindex="1"
							class="celebrationReservation__body__selectBox__options__item"
							*ngFor="let item of selectedSection.schedules"
							(click)="handleScheduleField($event, item)"
							(keyup)="handleScheduleField($event, item)"
						>
							Mesa para {{ item.partySize }} {{ item.partySize >= 2 ? 'pessoas' : 'pessoa ' }}
						</li>
					</ul>
				</div>
			</div>

			<!-- SELECT TIME -->
			<div
				class="celebrationReservation__body__selectBox"
				tabindex="0"
				*ngIf="(selectedSchedule?.reservationTimes || []).length"
			>
				<p class="celebrationReservation__body__selectBox__time__text">
					Exibindo horários disponíveis entre {{ selectedSchedule.reservationTimes[0].reservationTime }} e
					{{
						selectedSchedule.reservationTimes[selectedSchedule.reservationTimes.length - 1].reservationTime
					}}
				</p>
				<div class="celebrationReservation__body__selectBox__time__options" tabindex="1">
					<label
						tabindex="1"
						class="celebrationReservation__body__selectBox__time__label {{
							form.value.time == item.reservationTime
								? 'celebrationReservation__body__selectBox__time__label--active'
								: ''
						}}"
						for="{{ item.reservationTime }}"
						*ngFor="let item of selectedSchedule.reservationTimes"
						(click)="handleReservationTimeField($event, item)"
						(keyup)="handleReservationTimeField($event, item)"
						>{{ item.reservationTime }}</label
					>
				</div>
			</div>
			<ch-credit-card slug="001"></ch-credit-card>
			<!-- <ch-benefit slug="001"></ch-benefit> -->
			<ch-benefit slug="001" [benefit]="venue.benefits" [voucherValue]="voucherValue"></ch-benefit>
		</div>
	</section>

	<!-- RESERVATION FOOTER -->
	<section class="celebrationReservation__footer">
		<button
			class="celebrationReservation__footer__button button gradientHorizontal"
			[disabled]="form.invalid || loadingService.getIsLoadingBySlug('rightbar')"
		>
			<span class="celebrationReservation__footer__button__text">Continuar</span>
			<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
		</button>
	</section>
</form>

<ch-modal-confirm-registration
	[opened]="openRegisterCard"
	(onConfirm)="handleConfirmRegisration()"
	(onClose)="closeModal()"
></ch-modal-confirm-registration>

<nav class="navBar">
	<div class="navBar__wrapper wrapper">
		<!-- LOGO -->
		<h1 class="navBar__logo">
			<a routerLink="/" class="navBar__logo__link">
				<i class="navBar__logo__link__icon fa fa-logo-programa-menu"></i>
				Programa Menu Bradesco
			</a>
		</h1>

		<!-- BOAS VINDAS & LOCALIZAÇÃO -->
		<div class="navBar__person">
			<div class="navBar__person__welcome" *ngIf="clientAuthService.client.getValue()">
				<p class="navBar__person__welcome__name">Olá, {{ clientAuthService.client.getValue()?.name }}.</p>
			</div>
		</div>

		<!-- MENU -->
		<ul class="navBar__menu">
			<ng-container *ngFor="let item of navigationService.getNavbar()">
				<li
					*ngIf="!item.needAuth || clientAuthService.client.getValue()"
					class="navBar__menu__item 
					{{ item.navbar?.desktop?.enable ? 'desktop' : '' }} {{ item.navbar?.mobile?.enable ? 'mobile' : '' }}
					{{ item.navbar?.desktop?.order ? 'desktop__order--' + item.navbar?.desktop?.order : '' }}
					{{ item.navbar?.mobile?.order ? 'mobile__order--' + item.navbar?.mobile?.order : '' }}
					"
				>
					<a
						*ngIf="item.slug != 'search' && item.slug != 'profile' && item.slug !== 'celebration'"
						(click)="navigationService.setActivatedNavbarBySlug(item.slug)"
						id="{{ item.slug }}"
						class="navBar__menu__link {{ item.navbar?.isActive ? 'navBar__menu__link--active' : '' }}"
					>
						<i class="navBar__menu__icon {{ item.navbar?.icon }}"></i>
						<span class="navBar__menu__title">{{ item.navbar?.label }}</span>
					</a>
					<a
						*ngIf="item.slug === 'celebration' && (clientAuthService.getClient()?.vouchers || []).length"
						(click)="navigationService.setActivatedNavbarBySlug(item.slug)"
						id="{{ item.slug }}"
						class="navBar__menu__link {{ item.navbar?.isActive ? 'navBar__menu__link--active' : '' }}"
					>
						<i class="navBar__menu__icon {{ item.navbar?.icon }}"></i>
						<span class="navBar__menu__title">{{ item.navbar?.label }}</span>
					</a>
					<a
						*ngIf="item.slug == 'search'"
						(click)="searchService.sendIsOpen(true)"
						id="{{ item.slug }}"
						class="navBar__menu__link"
					>
						<i class="navBar__menu__icon {{ item.navbar?.icon }}"></i>
						<span class="navBar__menu__title">{{ item.navbar?.label }}</span>
					</a>
					<a
						*ngIf="item.slug == 'profile'"
						(click)="navigationService.setActivatedRightbarBySlug('profile')"
						id="{{ item.slug }}"
						class="navBar__menu__link {{ item.navbar?.isActive ? 'navBar__menu__link--active' : '' }}"
					>
						<i class="navBar__menu__icon {{ item.navbar?.icon }}"></i>
						<span class="navBar__menu__title">{{ item.navbar?.label }}</span>
					</a>
				</li>
			</ng-container>
		</ul>
	</div>
</nav>

<nav
	class="navBarMobile {{ navigationService.getActivatedRightBar() ? 'navBarMobile--rightbarIsOpen' : '' }}"
>
	<div class="navBarMobile__top">
		<div class="navBarMobile__top__wrapper wrapper">
			<section class="navBarMobile__top__line">
				<div class="navBarMobile__top__line__wrapper">
					<!-- LOGO -->
					<h1 class="navBarMobile__top__logo">
						<a href="" class="navBarMobile__top__logo__link">
							<i class="navBarMobile__top__logo__link__icon fa fa-logo-programa-menu"></i>
							Programa Menu Bradesco
						</a>
					</h1>

					<!-- BOAS VINDAS -->
					<div class="navBarMobile__top__person">
						<div class="navBarMobile__top__person__welcome" *ngIf="clientAuthService.client.getValue()">
							<p class="navBarMobile__top__person__name">
								Olá, {{ clientAuthService.client.getValue()?.name }}.
							</p>
						</div>
					</div>
				</div>

				<!-- BUSCA -->
				<article class="navBarMobile__top__search">
					<a (click)="searchService.sendIsOpen(true)" id="openSearch" class="navBarMobile__top__search__link">
						<i class="navBarMobile__top__search__icon fa fa-buscar"></i>
					</a>
				</article>
			</section>
		</div>
	</div>
	<div class="navBarMobile__bottom">
		<section class="navBarMobile__bottom__wrapper wrapper">
			<ul class="navBarMobile__bottom__menu">
				<ng-container *ngFor="let item of navigationService.getNavbar()">
					<li
						*ngIf="!item.needAuth || clientAuthService.client.getValue()"
						class="navBarMobile__bottom__menu__item 
              {{ item.navbar?.desktop?.enable ? 'desktop' : '' }} {{
							item.navbar?.mobile?.enable ? 'mobile' : ''
						}}
              {{ item.navbar?.desktop?.order ? 'desktop__order--' + item.navbar?.desktop?.order : '' }}
              {{ item.navbar?.mobile?.order ? 'mobile__order--' + item.navbar?.mobile?.order : '' }}"
					>
						<a
							*ngIf="item.slug === 'celebration' && (clientAuthService.getClient()?.vouchers || []).length"
							(click)="navigationService.setActivatedNavbarBySlug(item.slug)"
							id="{{ item.slug }}"
							class="navBarMobile__bottom__menu__link {{
								item.navbar?.isActive ? 'navBarMobile__bottom__menu__link--active' : ''
							}}"
						>
							<i class="{{ item.navbar?.icon }} navBarMobile__bottom__menu__icon"></i>
							<span class="navBarMobile__bottom__menu__title">{{ item.navbar?.label }}</span>
						</a>
						<a
							*ngIf="item.slug != 'profile' && item.slug !== 'celebration'"
							(click)="navigationService.setActivatedNavbarBySlug(item.slug)"
							id="{{ item.slug }}"
							class="navBarMobile__bottom__menu__link {{
								item.navbar?.isActive ? 'navBarMobile__bottom__menu__link--active' : ''
							}}"
						>
							<i class="{{ item.navbar?.icon }} navBarMobile__bottom__menu__icon"></i>
							<span class="navBarMobile__bottom__menu__title">{{ item.navbar?.label }}</span>
						</a>
						<a
							*ngIf="item.slug == 'profile'"
							(click)="navigationService.setActivatedRightbarBySlug('profile')"
							id="{{ item.slug }}"
							class="navBarMobile__bottom__menu__link {{
								item.navbar?.isActive ? 'navBarMobile__bottom__menu__link--active' : ''
							}}"
						>
							<i class="{{ item.navbar?.icon }} navBarMobile__bottom__menu__icon"></i>
							<span class="navBarMobile__bottom__menu__title">{{ item.navbar?.label }}</span>
						</a>
					</li>
				</ng-container>
			</ul>
		</section>
	</div>
</nav>

<div class="page">
	<div class="page__header">
		<ch-search slug="001"></ch-search>
		<app-bradesco-navbar></app-bradesco-navbar>
		<ch-rightbar></ch-rightbar>
	</div>
	<div class="page__body">
		<ch-sub-header
			slug="001"
			title="Como Funciona"
			bgImage="./../../../assets/images/como-funciona-image.jpg"
		></ch-sub-header>
		<!-- COMO FUNCIONA -->
		<section class="comoFunciona">
			<div class="comoFunciona__wrapper wrapper">
				<div class="comoFunciona__content">
					<ul class="comoFunciona__content__list">
						<li class="comoFunciona__content__list__item">
							<span class="comoFunciona__content__list__number">1</span>
							<div class="comoFunciona__content__list__txt">
								<strong>Cadastre-se no Programa Menu com o seu Cartão de Crédito Bradesco</strong><br />
								E comece a aproveitar diferenciais especiais em uma seleção de restaurantes conceituados.
							</div>
						</li>
						<li class="comoFunciona__content__list__item">
							<span class="comoFunciona__content__list__number">2</span>
							<div class="comoFunciona__content__list__txt">
								<strong>Após a realização do cadastro, você pode:</strong>
								<ul class="comoFunciona__content__list__subList">
									<li class="comoFunciona__content__list__subItem">
										Fazer suas reservas pelo site ou Concierge.
									</li>
									<li class="comoFunciona__content__list__subItem">
										Fazer check-in ao chegar no restaurante e aproveitar os benefícios.
									</li>
									<!-- <li class="comoFunciona__content__list__subItem">
										Entrar na fila de espera antes de chegar ao local
									</li> -->
									<li class="comoFunciona__content__list__subItem">
										Realizar o pagamento utilizando o seu cartão Bradesco, diretamente pelo Programa Menu
									</li>
								</ul>
							</div>
						</li>
						<li class="comoFunciona__content__list__item">
							<div class="comoFunciona__content__list__txt">
								<strong>Obs:</strong> Desfrute do desconto de até 15% no valor da conta nos restaurantes
								participantes, entre outros benefícios exclusivos, basta realizar o pagamento do valor total
								da conta com o cartão utilizado na reserva.
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	</div>
	<div class="page__footer">
		<ch-footer slug="001"></ch-footer>
	</div>
</div>

import { formatCurrency } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { map, tap, Subscription, Observable, combineLatest, concatMap, merge, filter } from 'rxjs'
import { BenefitHelper } from 'src/app/component/benefit/benefit.helper'
import { ModalService } from 'src/app/component/modal/modal.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { OrderService } from '../../order/order.service'
import { PaymentService } from '../payment.service'
import { PaymentBillHelper } from './payment-bill.helper'

const defaultTip = 10

@Component({
	selector: 'ch-payment-bill',
	templateUrl: './payment-bill.component.html',
	styleUrls: ['./payment-bill.component.scss'],
})
export class PaymentBillComponent implements OnInit, OnDestroy {
	selectedOrder$ = this.orderService.selectedOrder$
	hasDiscount$ = this.selectedOrder$.pipe(
		filter(order => Boolean(BenefitHelper.findDiscount(order.benefits)))
	)
	discountVenue$ = this.hasDiscount$.pipe(
		concatMap(() => this.selectedOrder$),
		map(order => PaymentBillHelper.getDiscountValue(order))
	)

	paymentForm!: FormGroup

	discountBill$!: Observable<number>
	billTip$!: Observable<number>
	totalValue$!: Observable<number>

	hasTipChanged$!: Observable<boolean>

	subs = new Subscription()
	suggestedTip = defaultTip

	constructor(
		public navigationService: NavigationService,
		public modalService: ModalService,
		private orderService: OrderService,
		private paymentService: PaymentService,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.paymentForm = this.fb.group({
			bill: [null, [Validators.required, Validators.min(1)]],
			tip: [null, Validators.required],
		})

		this.subs.add(this.updateSuggestTipForm())
		this.discountBill$ = combineLatest([this.paymentForm.valueChanges, this.discountVenue$]).pipe(
			map(([form, discountVenue]) => (form.bill * discountVenue) / 100),
			tap(discount => this.paymentService.setDiscount(discount))
		)
		this.billTip$ = this.paymentForm.valueChanges.pipe(
			map(form => (form.bill * form.tip) / 100),
			tap(res => this.paymentService.setGratuityValue(res))
		)
		this.totalValue$ = this.calculateTotalValue$
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	get updateFormBill() {
		return this.paymentForm.valueChanges.subscribe(form => this.paymentService.setInitialValue(form.bill))
	}

	get calculateTotalValue$() {
		const totalValueWithDiscount$ = combineLatest([
			this.paymentForm.valueChanges,
			this.discountBill$,
			this.billTip$,
		]).pipe(
			map(([form, discountBill, billTip]) =>
				PaymentBillHelper.round2decimals(form.bill - discountBill + billTip)
			)
		)

		const totalValueWithoutDiscount$ = combineLatest([this.paymentForm.valueChanges, this.billTip$]).pipe(
			map(([form, billTip]) => PaymentBillHelper.round2decimals(form.bill + billTip))
		)

		return merge(totalValueWithoutDiscount$, totalValueWithDiscount$).pipe(
			tap(value => this.paymentService.setTotalValue(value))
		)
	}

	private updateSuggestTipForm() {
		return this.orderService.selectedOrder$.subscribe(order => {
			this.suggestedTip = order?.gratuityPercent ?? defaultTip
			this.paymentForm.patchValue({ tip: this.suggestedTip })
			this.hasTipChanged$ = this.paymentForm.valueChanges.pipe(map(form => form.tip !== this.suggestedTip))
		})
	}

	handleSubmit() {
		this.navigationService.setActivatedRightbarBySlug('payment-card')
	}
}

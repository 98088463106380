<div class="page">
	<div class="page__header">
		<ch-search slug="001"></ch-search>
		<app-bradesco-navbar></app-bradesco-navbar>
		<ch-filter slug="001"></ch-filter>
		<ch-rightbar></ch-rightbar>
	</div>
	<div class="page__body">
		<ch-modal slug="001">
			<div class="news" content>
				<div class="news__img">
					<img src="./../../../assets/images/modal_celebration.png" />
				</div>
				<h1 class="news__title red">Celebrations</h1>
				<p class="news__strong">
					Parabéns! Você ganhou um crédito<br />
					de {{ voucherValue | currency: 'BRL' }} no Programa Menu!
				</p>
				<p class="news__text">
					Para utilizar a cortesia Celebrations,<br />
					faça uma <span class="destaque">reserva de mesa</span> no restaurante<br />
					que você queira celebrar! ;D
				</p>
				<button class="news__button button buttonInverse" (click)="modalService.setIsOpenBySlug(false)">
					Ok, entendi
				</button>
			</div>
		</ch-modal>

		<ng-template #beforeVenues>
			<div class="celebrationHome__information gradientHorizontal">
				<i class="celebrationHome__information__icon fa fa-presente"></i>
				<p class="celebrationHome__information__txt">
					<strong
						>Faça uma reserva em restaurantes selecionados com {{ voucherValue | currency: 'BRL' }} em
						créditos.</strong
					>
					Exclusivo para clientes Bradesco selecionados, válido até {{ getFormatedVoucherValidUntil() }}.
				</p>
			</div>
		</ng-template>

		<ng-template #notFound>
			<div class="celebrationHome__notFound">
				<div class="celebrationHome__notFound__content">
					<i class="celebrationHome__notFound__content__icon fa fa-sad-smile"></i>
					<p class="celebrationHome__notFound__content__text">
						Não encontramos o restaurante que você procura. Por favor, faça uma nova busca.
					</p>
				</div>
			</div>
		</ng-template>

		<ng-template #beforeVenues>
			<div class="celebrationHome__information gradientHorizontal">
				<i class="celebrationHome__information__icon fa fa-presente"></i>
				<p class="celebrationHome__information__txt">
					<strong
						>Faça uma reserva em restaurantes selecionados com {{ voucherValue | currency: 'BRL' }} em
						créditos.</strong
					>
					Exclusivo para clientes Bradesco selecionados, válido até {{ getFormatedVoucherValidUntil() }}.
				</p>
			</div>
		</ng-template>

		<ch-mosaic
			slug="002"
			mosaicSlug="celebration"
			[notFound]="notFound"
			[beforeVenues]="beforeVenues"
			[voucherValue]="voucherValue"
		></ch-mosaic>
	</div>
	<div class="page__footer">
		<ch-footer slug="001"></ch-footer>
	</div>
</div>

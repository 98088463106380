import { Component, Input, OnInit } from '@angular/core'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { environment } from 'src/environments/environment'
import { BenefitHelper } from '../benefit.helper'
import { Benefit } from '../benefit.interface'
import { BenefitService } from '../benefit.service'

@Component({
	selector: 'ch-benefit003',
	templateUrl: './benefit003.component.html',
	styleUrls: ['./benefit003.component.scss'],
})
export class Benefit003Component implements OnInit {
	public env = environment
	@Input() benefit: Benefit[] = []
	@Input() isCelebration: boolean = false
	@Input() venueId!: string
	discountValue!: string

	constructor(public benefitService: BenefitService, private clientAuth: ClientAuthService) {}

	get sortedBenefits() {
		return this.benefit.sort((a, b) => a.order - b.order)
	}

	ngOnInit(): void {
		this.discountValue = BenefitHelper.getDiscountValue({ benefits: this.benefit, venueId: this.venueId })
	}

	getFormatedBenefitNameByType(benefit: Benefit) {
		if (benefit.type === 'discount') {
			return this.getFormattedDiscount()
		}
		return benefit.name
	}

	getFormattedDiscount() {
		if (this.clientAuth.isLogged()) {
			return `${this.discountValue} de desconto`
		}
		return `Desconto`
	}
}

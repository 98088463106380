import { Component, OnInit } from '@angular/core'
import { take } from 'rxjs'
import { CelebrationReservationService } from 'src/app/component/celebration-reservation/celebration-reservation.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { Reservation } from 'src/app/component/reservation/reservation.interface'
import { Venue } from 'src/app/component/venue/venue.interface'
import { ClientAuthService } from 'src/app/helper/client-auth.service'

@Component({
	selector: 'ch-celebration-reservation-data',
	templateUrl: './celebration-reservation-data.component.html',
	styleUrls: ['./celebration-reservation-data.component.scss'],
})
export class CelebrationReservationDataComponent implements OnInit {
	public venue: Venue | null = this.celebrationReservationService.reservation.getValue().venue
	public reservation: Reservation = this.celebrationReservationService.reservation.getValue()
	public voucherValue: string | null = null

	constructor(
		public navigationService: NavigationService,
		public celebrationReservationService: CelebrationReservationService,
		public sharedService: SharedService,
		public clientAuthService: ClientAuthService
	) {}

	ngOnInit(): void {
		this.clientAuthService.voucher$.pipe(take(1)).subscribe({
			next: voucher => {
				this.voucherValue = voucher?.value
			},
		})
	}

	getFormatedDate(date: string) {
		return date.split('-').reverse().join('/')
	}

	nextNavigationChecker() {
		const hasAcceptedTerms = this.clientAuthService.getClient()?.agreements.reservation

		return this.navigationService.setActivatedRightbarBySlug(
			hasAcceptedTerms ? 'celebration-reservation-confirm' : 'celebration-reservation-terms'
		)
	}
}

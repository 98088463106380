import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { LOCAL_CLIENT_ID } from 'src/app/helper/client-auth.service'
import { environment as env } from 'src/environments/environment'

@Injectable({
	providedIn: 'root',
})
export class HttpService {
	constructor(private http: HttpClient) {}

	get<Type>(url: string, params?: any): Observable<Type> {
		params = params ? '?' + new URLSearchParams(params).toString() : ''
		const clientId = window.localStorage.getItem(LOCAL_CLIENT_ID)
		return this.http.get<any>(env.channelsAPI.baseUrl + '/' + url + params, {
			headers: { partnerId: env.partnerID, ...(clientId ? { clientId } : {}) },
		})
	}

	post<Type>(url: string, body: Object): Observable<Type> {
		const clientId = window.localStorage.getItem(LOCAL_CLIENT_ID) as string
		return this.http.post<any>(env.channelsAPI.baseUrl + '/' + url, body, {
			headers: { partnerId: env.partnerID, ...(clientId ? { clientId } : {}) },
		})
	}

	patch<Type>(url: string, body: Object): Observable<Type> {
		const clientId = window.localStorage.getItem(LOCAL_CLIENT_ID) as string
		return this.http.patch<any>(env.channelsAPI.baseUrl + '/' + url, body, {
			headers: { partnerId: env.partnerID, ...(clientId ? { clientId } : {}) },
		})
	}

	delete<Type>(url: string): Observable<Type> {
		const clientId = window.localStorage.getItem(LOCAL_CLIENT_ID) as string
		return this.http.delete<any>(env.channelsAPI.baseUrl + '/' + url, {
			headers: { partnerId: env.partnerID, ...(clientId ? { clientId } : {}) },
		})
	}
}

import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'

export type LocationType = {
	state: 'idle' | 'prompt' | 'denied' | 'granted'
	position: GeolocationCoordinates | null
}
@Injectable({
	providedIn: 'root',
})
export class GeoLocationService {
	private locationSubject = new BehaviorSubject<LocationType>({ state: 'idle', position: null })
	public location$ = this.locationSubject.asObservable()

	public firstTime = true

	getCurrentPosition(): void {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				position => {
					this.locationSubject.next({ state: 'granted', position: position.coords })
				},
				() => this.locationSubject.next({ state: 'denied', position: null }),
				{ enableHighAccuracy: true, timeout: 5_000, maximumAge: 0 }
			)
		} else {
			console.warn('Geolocation is not supported by this browser.')
			this.locationSubject.next({ state: 'denied', position: null })
		}
	}

	getValue() {
		return this.locationSubject.getValue()
	}
}

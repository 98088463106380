<nav class="navBar">
	<div class="navBar__wrapper wrapper">
		<!-- LOGO -->
		<h1 class="navBar__logo">
			<a routerLink="/" class="navBar__logo__link">
				<i class="navBar__logo__link__icon fa fa-logo-programa-menu"></i>
				Programa Menu Bradesco
			</a>
		</h1>

		<!-- BOAS VINDAS & LOCALIZAÇÃO -->
		<div class="navBar__person">
			<div class="navBar__person__welcome" *ngIf="client">
				<p class="navBar__person__welcome__name">Olá, {{ client?.name }}.</p>
			</div>
		</div>

		<!-- MENU -->
		<ul class="navBar__menu">
			<li class="navBar__menu__item" *ngIf="!isAccessingFromCelebration">
				<a class="navBar__menu__link" (click)="searchService.sendIsOpen(true)">
					<i class="navBar__menu__icon fa fa-buscar"></i>
					<span class="navBar__menu__title">Buscar</span>
				</a>
			</li>
			<li class="navBar__menu__item">
				<a class="navBar__menu__link" routerLink="/como-funciona">
					<i class="navBar__menu__icon fa fa-info-e-como-funciona"></i>
					<span class="navBar__menu__title">Como funciona</span>
				</a>
			</li>
			<li class="navBar__menu__item" (click)="navigationService.setActivatedNavbarBySlug('favorite')">
				<a class="navBar__menu__link">
					<i class="navBar__menu__icon fa fa-favoritos-off"></i>
					<span class="navBar__menu__title">Favoritos</span>
				</a>
			</li>
			<li class="navBar__menu__item" (click)="navigationService.setActivatedNavbarBySlug('order')">
				<a class="navBar__menu__link">
					<i class="navBar__menu__icon fa fa-data"></i>
					<span class="navBar__menu__title">Histórico</span>
				</a>
			</li>
			<li class="navBar__menu__item">
				<a class="navBar__menu__link" (click)="navigationService.setActivatedRightbarBySlug('profile')">
					<i class="navBar__menu__icon fa fa-perfil"></i>
					<span class="navBar__menu__title">Perfil</span>
				</a>
			</li>
			<li class="navBar__menu__item" *ngIf="conciergeAuthService.isLogged()">
				<a class="navBar__menu__link" routerLink="/concierge">
					<i class="navBar__menu__icon fa fa-login"></i>
					<span class="navBar__menu__title">Concierge</span>
				</a>
			</li>
		</ul>
	</div>
</nav>

<nav
	class="navBarMobile {{ navigationService.getActivatedRightBar() ? 'navBarMobile--rightbarIsOpen' : '' }}"
>
	<div class="navBarMobile__top">
		<div
			*ngIf="isSearch"
			class="navBarMobile__top__wrapper wrapper {{ client ? 'navBarMobile__top__wrapper' : '' }} "
		>
			<section class="navBarMobile__top__line">
				<div class="navBarMobile__top__line__wrapper">
					<!-- LOGO -->
					<h1 class="navBarMobile__top__logo">
						<a routerLink="/" class="navBarMobile__top__logo__link">
							<i class="navBarMobile__top__logo__link__icon fa fa-logo-programa-menu"></i>
							Programa Menu Bradesco
						</a>
					</h1>

					<!-- BOAS VINDAS -->
					<div class="navBarMobile__top__person">
						<div class="navBarMobile__top__person__welcome" *ngIf="client">
							<p class="navBarMobile__top__person__name">Olá, {{ client?.name }}.</p>
						</div>
					</div>
				</div>

				<!-- BUSCA -->
				<article class="navBarMobile__top__search" *ngIf="!isAccessingFromCelebration">
					<a (click)="searchService.sendIsOpen(true)" id="openSearch" class="navBarMobile__top__search__link">
						<i class="navBarMobile__top__search__icon fa fa-buscar"></i>
					</a>
				</article>
			</section>
		</div>
		<div
			*ngIf="!isSearch"
			class="navBarMobile__top__wrapper wrapper {{ client ? 'navBarMobile__top__wrapper--logged' : '' }} "
		>
			<section class="navBarMobile__top__line">
				<div class="navBarMobile__top__line__wrapper">
					<!-- LOGO -->
					<h1 class="navBarMobile__top__logo">
						<a routerLink="/" class="navBarMobile__top__logo__link">
							<i class="navBarMobile__top__logo__link__icon fa fa-logo-programa-menu"></i>
							Programa Menu Bradesco
						</a>
					</h1>

					<!-- BOAS VINDAS -->
					<div class="navBarMobile__top__person">
						<div class="navBarMobile__top__person__welcome" *ngIf="client">
							<p class="navBarMobile__top__person__name">Olá, {{ client?.name }}.</p>
						</div>
					</div>
				</div>

				<!-- BUSCA -->
				<article class="navBarMobile__top__search" *ngIf="!isAccessingFromCelebration">
					<a (click)="searchService.sendIsOpen(true)" id="openSearch" class="navBarMobile__top__search__link">
						<i class="navBarMobile__top__search__icon fa fa-buscar"></i>
					</a>
				</article>
			</section>
		</div>
	</div>
	<div class="navBarMobile__bottom">
		<section class="navBarMobile__bottom__wrapper wrapper">
			<ul class="navBarMobile__bottom__menu">
				<li class="navBarMobile__bottom__menu__item">
					<a class="navBarMobile__bottom__menu__link" routerLink="/">
						<i class="navBarMobile__bottom__menu__icon fa fa-home"></i>
						<span class="navBarMobile__bottom__menu__title">Home</span>
					</a>
				</li>
				<li class="navBarMobile__bottom__menu__item" *ngIf="client && (client?.vouchers || []).length">
					<a
						class="navBarMobile__bottom__menu__link"
						(click)="navigationService.setActivatedNavbarBySlug('celebration')"
					>
						<i class="navBarMobile__bottom__menu__icon fa fa-presente"></i>
						<span class="navBarMobile__bottom__menu__title">Celebrations</span>
					</a>
				</li>
				<li
					class="navBarMobile__bottom__menu__item"
					(click)="navigationService.setActivatedNavbarBySlug('favorite')"
				>
					<a class="navBarMobile__bottom__menu__link">
						<i class="navBarMobile__bottom__menu__icon fa fa-favoritos-off"></i>
						<span class="navBarMobile__bottom__menu__title">Favoritos</span>
					</a>
				</li>
				<li
					class="navBarMobile__bottom__menu__item"
					(click)="navigationService.setActivatedNavbarBySlug('order')"
				>
					<a class="navBarMobile__bottom__menu__link">
						<i class="navBarMobile__bottom__menu__icon fa fa-data"></i>
						<span class="navBarMobile__bottom__menu__title">Histórico</span>
					</a>
				</li>
				<li class="navBarMobile__bottom__menu__item">
					<a
						class="navBarMobile__bottom__menu__link"
						(click)="navigationService.setActivatedRightbarBySlug('profile')"
					>
						<i class="navBarMobile__bottom__menu__icon fa fa-perfil"></i>
						<span class="navBarMobile__bottom__menu__title">Perfil</span>
					</a>
				</li>
				<li class="navBarMobile__bottom__menu__item" *ngIf="conciergeAuthService.isLogged()">
					<a class="navBarMobile__bottom__menu__link" routerLink="/concierge">
						<i class="navBarMobile__bottom__menu__icon fa fa-login"></i>
						<span class="navBarMobile__bottom__menu__title">Concierge</span>
					</a>
				</li>
			</ul>
		</section>
	</div>
</nav>

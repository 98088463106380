import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	public isOpen = new BehaviorSubject<boolean>(false)

	constructor() {}

	public sendIsOpen(isOpen: boolean) {
		this.isOpen.next(isOpen)
	}

	public receiveIsOpen() {
		return this.isOpen.asObservable()
	}
}

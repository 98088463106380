<ch-add-card *ngIf="false"></ch-add-card>

<div class="myCards" *ngIf="true">
	<!-- MY CARDS HEADER -->
	<section class="myCards__header">
		<div class="myCards__header__top">
			<a class="myCards__header__return" (click)="navigationService.setActivatedRightbarBySlug('profile')">
				<i class="myCards__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="myCards__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="myCards__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- MY CARDS BODY -->
	<section class="myCards__body">
		<!-- breadcrumb 
		<ul class="myCards__body__breadcrumb">
			<li class="myCards__body__breadcrumb__item">
				<p class="myCards__body__breadcrumb__txt red">Perfil /</p>
			</li>
			<li class="myCards__body__breadcrumb__item">
				<p class="myCards__body__breadcrumb__txt red">Meus cartões</p>
			</li>
		</ul>
		-->

		<!-- title -->
		<div class="myCards__body__title">
			<p class="myCards__body__title__this">Meus cartões</p>
			<p class="myCards__body__title__link" (click)="isRemovingCards = !isRemovingCards">
				{{ !isRemovingCards ? 'Excluir cartão' : 'Voltar' }}
			</p>
		</div>

		<div class="myCards__body__remove {{ removingCard ? 'opened' : '' }}">
			<div class="myCards__body__remove__content">
				<p class="myCards__body__remove__question">Tem certeza que deseja excluir o cartão?</p>
				<div class="myCards__body__remove__action">
					<button class="myCards__body__remove__button buttonLight" (click)="removeCard()">Sim</button>
					<button class="myCards__body__remove__button button" (click)="removingCard = null">Não</button>
				</div>
			</div>
		</div>

		<!-- o cartão cadastrado -->

		<div class="myCards__body__remove {{ favoriteCardModal ? 'opened' : '' }}">
			<div class="myCards__body__remove__content">
				<p class="myCards__body__remove__question">Deseja marcar este cartão como favorito?</p>
				<div class="myCards__body__remove__action">
					<button
						class="myCards__body__remove__button buttonLight"
						(click)="confirmFavoriteCard(favoriteCardModal?.lastFourDigits)"
					>
						Sim
					</button>
					<button class="myCards__body__remove__button button" (click)="favoriteCardModal = null">Não</button>
				</div>
			</div>
		</div>

		<div class="myCards__body__aboutCard" *ngIf="client$ | async as client">
			<div class="myCards__body__card" *ngFor="let item of client?.categories">
				<i *ngIf="item.mainCard" class="fa fa-favoritos-on myCards__body__card__favorite"></i>
				<i
					*ngIf="!item.mainCard"
					class="fa fa-favoritos-off myCards__body__card__favorite"
					(click)="favoriteCard(item)"
				></i>

				<img [src]="item.image" class="myCards__body__card__flagCard" />
				<div class="myCards__body__card__cardData">
					<p class="myCards__body__card__cardData__title">{{ item.name }}</p>
					<p class="myCards__body__card__cardData__number" *ngIf="!hasToken(item)">
						{{ item.digits | mask: '0000 00' }}...
					</p>
					<p class="myCards__body__card__cardData__number" *ngIf="hasToken(item)">
						•••• •••• ••••
						{{ item.lastFourDigits }}
					</p>
					<button
						class="myCards__body__card__cardData__delete"
						*ngIf="isRemovingCards && (client?.categories || []).length > 0"
						(click)="removingCard = item"
					>
						<i class="myCards__body__card__cardData__delete__icon fa fa-menos-micro"></i>
					</button>
				</div>
			</div>
			<div class="myCards__body__addCard">
				<button class="myCards__body__addCard__button button gradientHorizontal" (click)="goToNewCards()">
					Adicionar novo cartão
				</button>
			</div>
		</div>
	</section>

	<!-- MY CARDS FOOTER -->
	<section class="myCards__footer">
		<i class="myCards__footer__icon bradesco fa fa-logo-bradesco"></i>
		<p class="myCards__footer__txt">powered by</p>
		<i class="myCards__footer__icon tagme fa fa-logo-tagme"></i>
	</section>
</div>

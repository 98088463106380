<div class="newCard">
	<!-- MY CARDS HEADER -->
	<section class="newCard__header">
		<div class="newCard__header__top">
			<a class="newCard__header__return" (click)="handleGoBack()">
				<i class="newCard__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="newCard__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="newCard__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- MY CARDS BODY -->
	<section class="newCard__body">
		<!-- breadcrumb 
		<ul class="newCard__body__breadcrumb">
			<li class="newCard__body__breadcrumb__item">
				<p class="newCard__body__breadcrumb__txt red">Perfil /</p>
			</li>
			<li class="newCard__body__breadcrumb__item">
				<p class="newCard__body__breadcrumb__txt red">Meus cartões</p>
			</li>
		</ul>
		-->

		<!-- title -->
		<div class="newCard__body__title">Meus cartões</div>

		<!-- adicionar cartão -->
		<div class="newCard__body__newCard {{ getCreditCardBrand() }}">
			<ch-single-credit-card
				slug="001"
				[number]="creditCardForm.value.number"
				[name]="creditCardForm.value.name"
				[validity]="creditCardForm.value.validity"
				[cvv]="creditCardForm.value.cvv"
			>
			</ch-single-credit-card>
			<form [formGroup]="creditCardForm" class="newCard__body__form" (ngSubmit)="handleSubmit()">
				<label class="newCard__body__form__label">
					<input
						class="newCard__body__form__input"
						placeholder="Número do cartao"
						mask="0000 0000 0000 0000||0000 000000 00000"
						formControlName="number"
					/>
				</label>
				<label class="newCard__body__form__label">
					<input
						class="newCard__body__form__input"
						placeholder="Nome igual do cartao"
						type="text"
						pattern="[^()/><\][\\\x22,;|.]+"
						formControlName="name"
					/>
				</label>
				<div class="newCard__body__form__line">
					<label class="newCard__body__form__label">
						<input
							class="newCard__body__form__input"
							placeholder="MM/YY"
							mask="00/00"
							[dropSpecialCharacters]="false"
							formControlName="validity"
						/>
					</label>
					<label class="newCard__body__form__label">
						<input
							required
							class="newCard__body__form__input"
							placeholder="CVV"
							type="text"
							mask="000||0000"
							formControlName="cvv"
							(focus)="handleCVVFocus(true)"
							(focusout)="handleCVVFocus(false)"
						/>
						<i class="newCard__body__form__icon fa fa-faq" (click)="toggleCVV()"></i>
					</label>
				</div>

				<h1 class="newCard__body__form__billing-address">Endereço de cobrança</h1>
				<div formGroupName="billingAddress">
					<label class="newCard__body__form__label">
						<input
							class="newCard__body__form__input"
							placeholder="CEP"
							mask="00000-000"
							formControlName="zipCode"
							type="text"
						/>
					</label>

					<label class="newCard__body__form__label">
						<input class="newCard__body__form__input" placeholder="Endereço" formControlName="address" />
					</label>

					<div class="newCard__body__form__line">
						<div tabindex="1" class="selectBox">
							<div class="selectBox__current" (click)="toggleOpenSelectBox()">
								<div class="selectBox__value" [ngClass]="{ 'selectBox__value--opened': openSelectedBox }">
									<span class="selectBox__label">{{ selectedBoxTitle }}</span>
								</div>
								<ul class="selectBox__options" [ngClass]="{ 'selectBox__options--opened': openSelectedBox }">
									<li
										tabindex="1"
										class="selectBox__options__item"
										*ngFor="let item of states"
										(click)="selectState(item.name, item.id)"
									>
										{{ item.name }}
									</li>
								</ul>
							</div>
						</div>
						<label class="newCard__body__form__label">
							<input
								required
								class="newCard__body__form__input"
								placeholder="Cidade"
								formControlName="city"
							/>
						</label>
					</div>
				</div>

				<div class="newCard__body__favorite">
					<input
						class="newCard__body__favorite__check"
						type="checkbox"
						id="status"
						formControlName="favorite"
					/>
					<label for="status" class="newCard__body__favorite__info">
						<i class="fa fa-favoritos-off newCard__body__favorite__off" aria-hidden="true"></i>
						<i class="fa fa-favoritos-on newCard__body__favorite__on" aria-hidden="true"></i>
						Usar esse cartão como favorito
					</label>
				</div>

				<div class="newCard__body__info">
					<p class="newCard__body__info__text">
						Para validação do seu cartão será feita uma cobrança de até R$5,00, em instantes será cancelada e
						o valor estornado.
					</p>
				</div>

				<div class="newCard__body__saveCard">
					<button
						class="newCard__body__saveCard__button button gradientHorizontal"
						[disabled]="creditCardForm.invalid || loadingService.getIsLoadingBySlug('rightbar')"
					>
						<span class="editProfile__body__saveCard__button__text">Cadastrar</span>
						<!-- Este button abrirá o modal de confirmação do cadastro, que está abaixo. -->
						<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
					</button>
				</div>
			</form>
		</div>
	</section>

	<!-- MY CARDS FOOTER -->
	<section class="newCard__footer">
		<i class="newCard__footer__icon bradesco fa fa-logo-bradesco"></i>
		<p class="newCard__footer__txt">powered by</p>
		<i class="newCard__footer__icon tagme fa fa-logo-tagme"></i>
	</section>
</div>

<!-- MODAL CVV -->
<div class="newCard__modal cvv" [ngClass]="{ opened: openCVV }">
	<div class="newCard__modal__content">
		<div class="newCard__modal__header">
			<h2 class="newCard__modal__title">O que é o código CVV?</h2>
		</div>
		<div class="newCard__modal__body">
			<img
				src="./../../../../assets/images/cvv.png"
				alt="onde encontrar seu código CVV no cartão de crédito"
				class="newCard__modal__img"
			/>
			<p class="newCard__modal__text">São os 3 números que aparecem na parte de trás do seu cartão.</p>
		</div>
		<div class="newCard__modal__footer">
			<button class="newCard__modal__button button" (click)="toggleCVV()">Ok</button>
		</div>
	</div>
</div>

<!-- MODAL CONFIRMAÇÃO CADASTRO -->
<div class="newCard__modal" [ngClass]="{ opened: openRegisteredCard }">
	<div class="newCard__modal__content">
		<div class="newCard__modal__header">
			<h2 class="newCard__modal__title">Cartão cadastrado com sucesso</h2>
		</div>
		<div class="newCard__modal__body">
			<p class="newCard__modal__text">
				Agora você pode usá-lo para pagar a conta no restaurante e ganhar os benefícios!
			</p>
		</div>
		<div class="newCard__modal__footer">
			<button class="newCard__modal__button button" (click)="closeRegisteredCard()">Ok</button>
		</div>
	</div>
</div>

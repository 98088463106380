import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthService } from './helper/auth.service'
import { ConciergeAuthService } from './helper/concierge-auth.service'
import { GlobalMiddlewareService } from './helper/global-middleware.service'
import { CelebrationHomeComponent } from './page/celebration-home/celebration-home.component'
import { ConciergeLoginComponent } from './page/concierge/concierge-login/concierge-login.component'
import { ConciergeComponent } from './page/concierge/concierge.component'
import { FaqComponent } from './page/faq/faq.component'
import { FavoriteComponent } from './page/favorite/favorite.component'
import { HomeComponent } from './page/home/home.component'
import { HowItWorksComponent } from './page/how-it-works/how-it-works.component'
// import { LoginComponent } from './page/login/login.component'
import { MenuComponent } from './page/menu/menu.component'
import { OrderComponent } from './page/order/order.component'
import { PrivacyPolicyComponent } from './page/privacy-policy/privacy-policy.component'
import { SearchResultComponent } from './page/search-result/search-result.component'
import { TermsOfUserComponent } from './page/terms-of-user/terms-of-user.component'
import { VenuePageComponent } from './page/venue-page/venue-page.component'
import { UpdatePasswordPage } from './page/update-password/update-password.component'

const routes: Routes = [
	// { path: 'login', component: LoginComponent },
	{ canActivate: [GlobalMiddlewareService], path: '', component: HomeComponent },
	{
		canActivate: [GlobalMiddlewareService, AuthService],
		path: 'celebrations/restaurante/:id',
		component: VenuePageComponent,
	},
	{ canActivate: [GlobalMiddlewareService], path: 'faq', component: FaqComponent },
	{ canActivate: [GlobalMiddlewareService, AuthService], path: 'favoritos', component: FavoriteComponent },
	{
		canActivate: [GlobalMiddlewareService, AuthService],
		path: 'favoritos/restaurante/:id',
		component: VenuePageComponent,
	},
	{
		canActivate: [GlobalMiddlewareService, AuthService],
		path: 'historico-de-pedidos',
		component: OrderComponent,
	},
	{
		canActivate: [GlobalMiddlewareService],
		path: 'politica-de-privacidade',
		component: PrivacyPolicyComponent,
	},
	{ canActivate: [GlobalMiddlewareService], path: 'como-funciona', component: HowItWorksComponent },
	{ canActivate: [GlobalMiddlewareService], path: 'termos-de-uso', component: TermsOfUserComponent },
	{ canActivate: [GlobalMiddlewareService], path: 'restaurante/:id', component: VenuePageComponent },
	{ canActivate: [GlobalMiddlewareService], path: 'restaurante/:id/menu-digital', component: MenuComponent },
	{ canActivate: [GlobalMiddlewareService], path: 'resultado-de-busca', component: SearchResultComponent },
	{
		canActivate: [GlobalMiddlewareService],
		path: 'resultado-de-busca/restaurante/:id',
		component: VenuePageComponent,
	},
	{ path: 'concierge/login', component: ConciergeLoginComponent },
	{ canActivate: [ConciergeAuthService], path: 'concierge', component: ConciergeComponent },
	{ path: 'atualizar-senha/:passwordToken', component: UpdatePasswordPage },
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}

<div
	class="modal001 {{ modalService.getIsOpenBySlug(slug)?.isOpen ? 'opened' : '' }}"
	[ngStyle]="{ top: top }"
>
	<div class="modal001__wrapper">
		<div class="modal001__close" (click)="modalService.setIsOpenBySlug(false)">
			<i class="modal001__close__icon tagmeicon tagmeicon-close"></i>
		</div>
		<ng-content select="[content]"></ng-content>
	</div>
</div>

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { ModalService } from 'src/app/component/modal/modal.service'
import { environment } from 'src/environments/environment'
import { ConciergeReservationService } from './concierge-reservation.service'
import { OrderVenue } from './../../../component/order-venue/order-venue.interface'

@Component({
	selector: 'app-concierge-reservation',
	templateUrl: './concierge-reservation.component.html',
	styleUrls: ['./concierge-reservation.component.scss'],
})
export class ConciergeReservationComponent implements OnInit {
	public env = environment
	public reservation: OrderVenue[] | undefined = []
	public cancelingReservation: any = null
	public form: FormGroup = new FormGroup({
		cpf: new FormControl(null, [Validators.required]),
	})

	constructor(
		public conciergeReservationService: ConciergeReservationService,
		public loadingService: LoadingService,
		public sharedService: SharedService,
		public modalService: ModalService
	) {}

	ngOnInit(): void {}

	getReservationHistory() {
		let form = {
			cpf: this.form.value.cpf || '',
			intent: 'reservation',
		}
		this.conciergeReservationService.getReservation(form).subscribe({
			next: res => {
				this.reservation = res
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
		})
	}

	getFormatedCreatedAtDay(reservation: any) {
		const date = new Date(reservation!.createdAt)
		return date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
	}

	getFormatedStatus(reservation: any) {
		switch (reservation?.status) {
			case 'CHK_DONE':
				return 'Confirmado'
			case 'VALIDATED':
				return 'Validado'
			case 'CANCELED':
				return 'Cancelado'
			default:
				return ''
		}
	}

	getFormatedPartySize(reservation: any) {
		const partySize = reservation!.partySize
		return partySize <= 1 ? `${partySize} pessoa` : `${partySize} pessoas`
	}

	getFormatedDate(reservation: any) {
		if (!reservation?.reservationDay) return 'Sem data'
		const [year, month, day] = reservation.reservationDay.split('T')[0].split('-')
		return `${day}/${month}/${year}`
	}

	getFormatedTime(reservation: any) {
		const date = new Date(reservation!.createdAt)
		const hour = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`
		const minute = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`
		return `${hour}:${minute}`
	}

	getFormatedBenefits(benefits: Array<any>) {
		return benefits.map(item => item.name).join(', ')
	}

	handleCancelingReservation() {
		if (!this.cancelingReservation) return

		return this.conciergeReservationService
			.cancelReservation({ _id: this.cancelingReservation._id })
			.subscribe({
				next: () => {
					this.getReservationHistory()
					this.loadingService.setIsLoadingBySlug('generic', false)
					this.modalService.setIsOpenBySlug(false)
					this.sharedService.swal.success('Reserva cancelada com sucesso!')
				},
				error: error => {
					this.sharedService.swal.error(error.error?.message)
					this.loadingService.setIsLoadingBySlug('generic', false)
					this.modalService.setIsOpenBySlug(false)
				},
			})
	}

	handleSubHeaderForm() {
		if (!this.form.value.cpf) return this.sharedService.swal.error('Preencha o formulário corretamente.')

		let form = {
			cpf: this.form.value.cpf,
			intent: 'reservation',
		}
		return this.conciergeReservationService.getReservation(form).subscribe({
			next: res => {
				this.reservation = res
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
		})
	}
}

import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'
import { CreditCardService } from 'src/app/component/credit-card/credit-card.service'
import { FilterService } from 'src/app/component/filter/filter.service'
import { MosaicService } from 'src/app/component/mosaic/mosaic.service'

@Component({
	selector: 'ch-search-result',
	templateUrl: './search-result.component.html',
	styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent implements OnInit {
	constructor(
		public mosaicService: MosaicService,
		public creditCardService: CreditCardService,
		public filterService: FilterService,
		private title: Title
	) {}

	ngOnInit() {
		window.scrollTo(0, 0)
		this.title.setTitle(`${PREFIX_TITLE} - Resultado da busca`)
	}
}

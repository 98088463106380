<div class="page">
	<div class="page__header">
		<ch-search slug="001"></ch-search>
		<app-bradesco-navbar></app-bradesco-navbar>
		<ch-rightbar></ch-rightbar>
	</div>
	<div class="page__body">
		<ch-sub-header slug="001" title="Termos de Uso"></ch-sub-header>
		<div class="statics">
			<div class="statics__wrapper wrapper">
				<p class="statics__content">
					Para usufruir dos diferenciais divulgados, ao chegar ao restaurante, mencione que a reserva foi
					feita pelo Programa Menu Bradesco Cartões e o pagamento deve ser feito integralmente com os cartões
					The Platinum Card®, American Express® Gold Card, American Express® Green, American Express® Platinum
					Credit, American Express® Gold Credit, American Express® Blue, Bradesco Platinum American Express®,
					Bradesco Gold American Express®, American Express® Gold Corporate, American Express® Corporate,
					American Express® Business Platinum, American Express® Business, Bradesco Elo Nanquim Diners Club,
					Bradesco Elo Nanquim, Bradesco Elo Grafite, Bradesco Elo Mais, Bradesco Elo Plus, Bradesco Elo
					Internacional, Bradesco Elo Nacional, Bradesco Seguros Elo Internacional, Fundação Amazonas
					Sustentável Bradesco Elo Internacional, Bradesco Corporate Elo Nanquim Diners Club, Bradesco
					Empresarial Elo Grafite, Bradesco Visa Aeternum, Bradesco Visa Infinite, Bradesco Visa Platinum,
					Bradesco Visa Like, Bradesco Visa Gold, Bradesco Visa Internacional, Bradesco Visa Nacional, Cartão
					Smiles Visa Infinite, Cartão Smiles Visa Platinum, Cartão Smiles Visa Gold, Cartão Smiles Visa
					Internacional, Bradesco NEO Visa Internacional, Bradesco Corporate Visa Infinite, Bradesco
					Empresarial Visa Platinum, Bradesco MasterCard® Black, Bradesco MasterCard® Platinum, Bradesco
					MasterCard® Gold, Bradesco MasterCard® Internacional, Bradesco MasterCard® Nacional, Bradesco Smiles
					MasterCard Platinum, Bradesco Smiles MasterCard Gold, Bradesco Smiles MasterCard Internacional,
					Bradesco Victoria TAP MasterCard Gold, Bradesco Corporativo Mastercard® Black. Os diferenciais podem
					variar de acordo com cada restaurante, são válidos apenas nas unidades indicadas no site e não são
					cumulativos com outras promoções. Estacionamento, valet e taxas não estão incluídos no desconto
					oferecido no valor da conta, exceto para os cartões corporativos elegíveis, que podem usufruir do
					valet grátis para contas acima de R$300,00. As reservas estão sujeitas a disponibilidade do
					restaurante. O Banco Bradesco S.A. não se responsabiliza por serviços prestados por terceiros. O
					benefício pode ser cancelado a qualquer momento, sem aviso prévio.
				</p>
				<p class="statics__content">
					Fone Fácil Bradesco: 4002 0022 / 0800 570 0022. Atendimento 24 horas, 7 dias por semana. Consultas,
					informações e serviços transacionais. Acesso do exterior: 55 11 4002 0022. SAC Bradesco Cartões:
					0800 727 9988. SAC Deficiência Auditiva ou de Fala: 0800 722 0099. Atendimento 24 horas, 7 dias por
					semana. Reclamações, cancelamentos e informações gerais. Ouvidoria: 0800 727 9933. Atendimento das
					8h às 18h, de 2ª a 6ª, exceto feriados. Se não ficar satisfeito com a solução apresentada, contate a
					Ouvidoria. Banco Bradesco S.A., CNPJ 60.746.948/0001-12 – Núcleo Cidade de Deus s/nº – Vila Yara –
					Osasco – SP – CEP 06029-900. American Express é uma marca registrada da American Express, licenciada
					para utilização pelo Banco Bradesco S.A.
				</p>
			</div>
		</div>
	</div>
	<div class="page__footer">
		<ch-footer slug="001"></ch-footer>
	</div>
</div>

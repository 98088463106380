import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { interval, map, Observable, Subscription, take, takeUntil } from 'rxjs'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { OrderService } from 'src/app/page/order/order.service'
import { NavigationHandle } from 'src/app/page/profile/new-card/navigation-handle.service'
import { environment } from 'src/environments/environment'
import { SharedService } from '../helper/shared.service'
import { ModalService } from '../modal/modal.service'
import { NavigationService } from '../navigation/navigation.service'
import { ReservationService } from '../reservation/reservation.service'
import { OrderDetails, OrderVenue, ReservationOrder } from './order-venue.interface'

export enum INTENT {
	WAITLIST = 'waitlist',
	RESERVATION = 'reservation',
	CHECKIN = 'checkin',
}

@Component({
	selector: 'ch-order-venue',
	templateUrl: './order-venue.component.html',
	styleUrls: ['./order-venue.component.scss'],
})
export class OrderVenueComponent implements OnInit, OnDestroy {
	public env = environment
	public reservation: ReservationOrder | undefined
	public checkin: OrderDetails | undefined
	public voucherValue: string | null = null

	@Input() order: OrderVenue | undefined
	@Output() seeDetailsClicked = new EventEmitter<OrderVenue>()

	private twelveHour = 1000 * 60 * 60 * 12 // in miliseconds
	wasPaid!: boolean
	nowEverySecond$ = interval(1000).pipe(map(() => Date.now()))

	canPay$: Observable<boolean> = this.nowEverySecond$
		.pipe(
			map(
				NOW =>
					this.isOrderEligible && this.reservationTime <= NOW && NOW <= this.reservationTime + this.twelveHour
			)
		)
		.pipe(map(value => (this.wasPaid ? false : value)))

	private subModal = new Subscription()

	constructor(
		public modalService: ModalService,
		public orderService: OrderService,
		public sharedService: SharedService,
		public reservationService: ReservationService,
		public clientAuthService: ClientAuthService,
		public navigationService: NavigationService,
		private navigationHandle: NavigationHandle
	) {}

	ngOnInit(): void {
		this.wasPaid = Boolean(this.order?.paidAt)
		this.removeDiscountBenefitFromAspiracionais()
		this.voucherValue = this.order?.voucher?.value ? this.order.voucher.value : null
	}

	ngOnDestroy(): void {
		this.subModal.unsubscribe()
	}

	seeDetails() {
		if (!this.order) return
		this.seeDetailsClicked.emit(this.order)
	}
	private get reservationTime(): number {
		if (!this.order) {
			// reservationTime always in the future for return FALSE in canDay$
			return Infinity
		}
		const [day, _] = this.order?.reservationDay.split('T')
		return Date.parse(`${day}T${this.order.reservationTime}`)
	}

	private get isOrderEligible(): boolean {
		if (!this.order) {
			return false
		}
		// Rule for Check-In
		if (this.order.intent === INTENT.CHECKIN) {
			return this.order.status === 'VALIDATED'
		}
		// Rule for Reservation
		return this.order.status === 'CHK_DONE' || this.order.status === 'VALIDATED'
	}

	isCelebration() {
		// Não pode avaliar isso por this.order.benefits.length <= 0
		// Pois quando o restaurante é tipo Aspiracional, dependendo do cartão também vem com beneficios vazio
		return !!this.reservation?.benefits.benefits.find(
			item => item.benefit.name.pt === 'Voucher Bradesco Celebrations'
		)
	}

	removeDiscountBenefitFromAspiracionais() {
		if (!this.order?.venue) return
		const hasTypeAspiracional = (this.order?.venue.partner.types || []).find(
			item => item.type.toLowerCase() === 'aspiracional'
		)
		if (!hasTypeAspiracional) return
		this.order.benefits = (this.order.benefits || []).filter(item => item.type.toLowerCase() !== 'discount')
	}

	getFormatedIntent() {
		switch (this.order?.intent) {
			case INTENT.RESERVATION:
				return 'Reserva de mesa'
			case INTENT.WAITLIST:
				return 'Fila de espera'
			case INTENT.CHECKIN:
				return 'Check-In'
			default:
				return ''
		}
	}

	get day() {
		if (!this.order) {
			return '00'
		}
		if (!this.order.reservationDay) return '00'
		const [year, month, day] = this.order?.reservationDay?.split('T')[0]?.split('-')
		return day
	}

	get month() {
		if (!this.order) {
			return '00'
		}
		if (!this.order.reservationDay) return '00'
		const [year, month, day] = this.order.reservationDay.split('T')[0].split('-')

		return {
			'01': 'JAN',
			'02': 'FEV',
			'03': 'MAR',
			'04': 'ABR',
			'05': 'MAI',
			'06': 'JUN',
			'07': 'JUL',
			'08': 'AGO',
			'09': 'SET',
			'10': 'OUT',
			'11': 'NOV',
			'12': 'DEZ',
		}[month]
	}

	getFormatedTime() {
		return this.reservation!.reservationTime
	}

	handlePayment(order: any) {
		this.orderService.setOrder(order)
		this.clientAuthService.setSelectedCategoryId(order.categoryId)
		this.subModal.add(this.showModal())
	}

	private showModal() {
		return this.clientAuthService.hasRealCard$.pipe(take(1)).subscribe(value => {
			if (value) {
				return this.navigationService.setActivatedRightbarBySlug('payment-bill')
			}
			this.navigationHandle.setLastLocation('history')
			this.modalService.setIsOpenBySlug(true, `modal-confirm-registration_${this.order?._id}`)
		})
	}

	closeModal() {
		this.modalService.setIsOpenBySlug(false, `modal-confirm-registration_${this.order?._id}`)
		this.subModal.unsubscribe()
	}

	handleConfirmRegisration() {
		this.navigationService.setActivatedRightbarBySlug('new-card')
		this.closeModal()
	}

	handlePaymentAndCloseModal(order: any) {
		this.modalService.setIsOpenBySlug(false, `order_${this.order?._id}`)
		this.modalService.setIsOpenBySlug(false, `order_checkin_${this.order?._id}`)
		this.handlePayment(order)
	}
}

<div *ngIf="!confirmedReservation" class="loading001">
	<div class="loading001__circle"></div>
</div>

<div class="reservationConfirm" *ngIf="confirmedReservation">
	<!-- RESERVATION CONFIRM HEADER -->
	<section class="reservationConfirm__header">
		<div class="reservationConfirm__header__box">
			<div class="reservationConfirm__header__success">
				<p class="reservationConfirm__header__title">Reserva concluída com sucesso!</p>
				<p class="reservationConfirm__header__text">Aproveite seus benefícios e bom apetite! ;)</p>
			</div>
			<div
				class="reservationConfirm__header__close"
				(click)="navigationService.setActivatedRightbarBySlug('')"
			>
				<i class="reservationConfirm__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- RESERVATION CONFIRM BODY -->
	<section class="reservationConfirm__body">
		<p class="reservationConfirm__body__text">
			Enviamos todos os dados desta reserva para seu email cadastrado.
		</p>
		<p class="reservationConfirm__body__text">
			Você também pode acessar esses dados na aba
			<strong>“Histórico de pedidos"</strong> no menu principal.
		</p>

		<div class="reservationConfirm__body__ticket">
			<div class="reservationConfirm__body__ticket__top">
				<i class="reservationConfirm__body__ticket__icon fa fa-logo-programa-menu"></i>
				<!-- <img
					class="reservationConfirm__body__ticket__logo"
					alt="logo programa Menu Bradesco"
					src="./../../../../assets/images/logo-programa-menu.svg"
				/> -->
				<img
					class="reservationConfirm__body__ticket__card"
					alt="thumbnail do cartão utilizado na compra"
					src="./../../../../assets/images/img-cartao.svg"
				/>
			</div>
			<ul class="reservationConfirm__body__ticket__list">
				<!-- <li class="reservationConfirm__body__ticket__list__item" *ngIf="false">
					<p class="reservationConfirm__body__ticket__list__item__label">Código do Voucher</p>
					<p class="reservationConfirm__body__ticket__list__item__data">M3NU8R4D35C0</p>
				</li> -->
				<li class="reservationConfirm__body__ticket__list__item">
					<p class="reservationConfirm__body__ticket__list__item__label">Restaurante</p>
					<p class="reservationConfirm__body__ticket__list__item__data">
						{{ confirmedReservation.venue.name }}
					</p>
				</li>
				<li class="reservationConfirm__body__ticket__list__item">
					<p class="reservationConfirm__body__ticket__list__item__label">Mesa para</p>
					<p class="reservationConfirm__body__ticket__list__item__data">
						{{ confirmedReservation.people_amount }}
						{{
							confirmedReservation.people_amount && confirmedReservation.people_amount >= 2
								? 'pessoas'
								: 'pessoa'
						}}
					</p>
				</li>
				<li class="reservationConfirm__body__ticket__list__item">
					<p class="reservationConfirm__body__ticket__list__item__label">Ambiente</p>
					<p class="reservationConfirm__body__ticket__list__item__data">
						{{ confirmedReservation.environment_label }}
					</p>
				</li>
				<li class="reservationConfirm__body__ticket__list__item">
					<p class="reservationConfirm__body__ticket__list__item__label">Dia</p>
					<p class="reservationConfirm__body__ticket__list__item__data">
						{{ getFormatedDate(confirmedReservation.date || '') }}
					</p>
				</li>
				<li class="reservationConfirm__body__ticket__list__item">
					<p class="reservationConfirm__body__ticket__list__item__label">Horário</p>
					<p class="reservationConfirm__body__ticket__list__item__data">{{ confirmedReservation.time }}</p>
				</li>
				<li class="reservationConfirm__body__ticket__list__item">
					<p class="reservationConfirm__body__ticket__list__item__label">Nome</p>
					<p class="reservationConfirm__body__ticket__list__item__data name">
						{{ clientAuthService.getClient()?.name }} {{ clientAuthService.getClient()?.lastName }}
					</p>
				</li>
				<li class="reservationConfirm__body__ticket__list__item">
					<p class="reservationConfirm__body__ticket__list__item__label">Benefícios do seu cartão</p>
					<p class="reservationConfirm__body__ticket__list__item__data name">
						{{ clientAuthService.getSelectedCategory()?.name }}
					</p>
				</li>
			</ul>

			<!-- RESERVATION CONFIRM BENEFITS -->
			<ch-benefit
				slug="001"
				[benefit]="confirmedReservation.venue.benefits"
				[voucherValue]="voucherValue"
			></ch-benefit>
		</div>
	</section>

	<!-- RESERVATION CONFIRM FOOTER -->
	<section class="reservationConfirm__footer">
		<button
			class="reservationConfirm__footer__button buttonLight"
			(click)="navigationService.setActivatedRightbarBySlug('')"
		>
			Entendi, fechar
		</button>
	</section>
</div>

import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'ch-venue-not-found001',
	templateUrl: './venue-not-found001.component.html',
	styleUrls: ['./venue-not-found001.component.scss'],
})
export class VenueNotFound001Component implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}

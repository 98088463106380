import { Component, OnInit } from '@angular/core'
import { CreditCard } from 'src/app/component/credit-card/credit-card.interface'
import { CreditCardService } from 'src/app/component/credit-card/credit-card.service'
import { FilterService } from 'src/app/component/filter/filter.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { NavigationHandle } from '../new-card/navigation-handle.service'

@Component({
	selector: 'ch-my-cards',
	templateUrl: './my-cards.component.html',
	styleUrls: ['./my-cards.component.scss'],
})
export class MyCardsComponent implements OnInit {
	public isRemovingCards: boolean = false
	public removingCard: CreditCard | null = null
	public client$ = this.clientAuthService.receiveClient()
	public favoriteCardModal: CreditCard | null = null

	constructor(
		public navigationService: NavigationService,
		public creditCardService: CreditCardService,
		public filterService: FilterService,
		public sharedService: SharedService,
		public clientAuthService: ClientAuthService,
		public loadingService: LoadingService,
		private navigationHandle: NavigationHandle
	) {}

	ngOnInit(): void {}

	hasToken(item: { gatewayId?: string }): boolean {
		return Boolean(item?.gatewayId)
	}

	goToNewCards() {
		this.navigationHandle.setLastLocation('root')
		this.navigationService.setActivatedRightbarBySlug('new-card')
	}

	favoriteCard(item: CreditCard) {
		this.favoriteCardModal = item
	}

	confirmFavoriteCard(lastFourDigits: any) {
		this.clientAuthService.setMainCard(lastFourDigits).subscribe({
			next: () => {
				this.favoriteCardModal = null
			},
			error: () => {
				this.favoriteCardModal = null
			},
		})
	}

	removeCard() {
		if (!this.removingCard) return

		const form = {
			categories: (this.clientAuthService.getClient()?.categories || [])
				.filter((item: any) => parseInt(item.digits) !== this.removingCard?.digits)
				.map((item: any) => parseInt(item.digits)),
		}

		this.clientAuthService.update(form, this.clientAuthService.getClient()?._id || '').subscribe(
			res => {
				this.removingCard = null
				this.clientAuthService.setClient(res)
				this.loadingService.setIsLoadingBySlug('rightbar', false)
				this.navigationService.setActivatedRightbarBySlug('my-cards')
			},
			error => {
				this.sharedService.swal.error(error.error?.message)
			}
		)
	}
}

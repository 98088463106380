import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { ReservationService } from 'src/app/component/reservation/reservation.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'

@Component({
	selector: 'ch-reservation-terms',
	templateUrl: './reservation-terms.component.html',
	styleUrls: ['./reservation-terms.component.scss'],
})
export class ReservationTermsComponent implements OnInit, AfterViewInit {
	public form: FormGroup = this.fb.group({
		reservation: [null, [Validators.required]],
	})

	constructor(
		public navigationService: NavigationService,
		public reservationService: ReservationService,
		public loadingService: LoadingService,
		public fb: FormBuilder,
		public sharedService: SharedService,
		public clientAuthService: ClientAuthService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.form.patchValue({ reservation: true })
		this.cdr.detectChanges()
	}

	handleSubmit() {
		if (this.form.invalid || !this.form.value.reservation)
			return this.sharedService.swal.error('Preencha o formulário corretamente.')

		const client = this.sharedService.localStorage.get('client')
		const form = { agreements: { ...client.agreements, reservation: this.form.value.reservation } }

		return this.reservationService.updateClientById(form, client._id).subscribe(
			res => {
				this.clientAuthService.setClient(res)
				this.navigationService.setActivatedRightbarBySlug('reservation-confirm')
			},
			error => {
				return this.sharedService.swal.error(error.error?.message)
			}
		)
	}
}

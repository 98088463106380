<div class="venueNotFound">
	<div class="venueNotFound__content">
		<i class="venueNotFound__content__icon fa fa-sad-smile"></i>
		<p class="venueNotFound__content__text">
			Não encontramos restaurantes
			<span class="venueNotFound__content__text__category">"fast-food"</span>
			na sua cidade
		</p>
	</div>
</div>

import { Component, Input, OnInit } from '@angular/core'
import { SingleCreditCardService } from '../single-credit-card.service'

@Component({
	selector: 'ch-single-credit-card001',
	templateUrl: './single-credit-card001.component.html',
	styleUrls: ['./single-credit-card001.component.scss'],
})
export class SingleCreditCard001Component implements OnInit {
	@Input() number!: string
	@Input() name!: string
	@Input() validity!: string
	@Input() cvv!: string

	constructor(public singleCreditCardService: SingleCreditCardService) {}

	ngOnInit(): void {}

	// getNumber(number: string) {
	// 	return number.match(/.{1,4}/g)?.join(' ')
	// }

	getNumber() {
		if (!this.number) {
			return '0000 0000 0000 0000'
		}
		return this.number
	}

	getValidity() {
		if (!this.validity) {
			return '00/00'
		}
		return this.validity
	}
}

import { Component, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { ModalService } from './../../../component/modal/modal.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { Client } from 'src/app/component/client/client.interface'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { CreditCardService } from 'src/app/component/credit-card/credit-card.service'

@Component({
	selector: 'app-concierge-client',
	templateUrl: './concierge-client.component.html',
	styleUrls: ['./concierge-client.component.scss'],
})
export class ConciergeClientComponent implements OnInit {
	public editing: boolean = false

	public client: Client[] = []

	public searchForm: FormGroup = new FormGroup({
		term: new FormControl(null, [Validators.required]),
	})

	public clientForm: FormGroup = new FormGroup({
		_id: new FormControl(null, []),
		name: new FormControl(null, [Validators.required]),
		email: new FormControl(null, [Validators.required, Validators.email]),
		phone: new FormControl(null, [Validators.required, this.phoneLength]),
		cpf: new FormControl(null, [Validators.required, this.cpfLength]),
		digit: new FormControl(null, [this.digitValidation]),
		card: new FormControl([], [this.cardValidation]),
	})

	public clientFormErrors: any = {
		required: 'Campo obrigatório.',
		email: 'Digite um e-mail valido.',
		digit: 'Preencha 6 números exatos.',
		card: 'Adicione pelo menos 1 cartão.',
		phoneLength: 'Telefone deve ter exatamente 11 digitos.',
		cpfLength: 'CPF deve ter exatamente 11 digitos.',
	}

	constructor(
		public modalService: ModalService,
		public loadingService: LoadingService,
		public sharedService: SharedService,
		public clientAuthService: ClientAuthService,
		public creditCardService: CreditCardService
	) {}

	ngOnInit(): void {}

	getClientByTerm() {
		this.loadingService.setIsLoadingBySlug('generic', true)

		this.clientAuthService.getClientByTerm({ value: this.searchForm.value.term || '' }).subscribe({
			next: res => {
				this.client = res
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
		})
	}

	addClient() {
		this.editing = false
		this.clientForm.reset()
		this.modalService.setIsOpenBySlug(true)
	}

	editClient(client: Client) {
		this.editing = true

		const phone = client?.phone.substring(2)
		const categories = client?.categories.map(item => ({ ...item, isConfirmed: true, isNew: false }))

		this.clientForm.patchValue({ _id: client?._id })
		this.clientForm.patchValue({ name: `${client?.name} ${client?.lastName}` })
		this.clientForm.patchValue({ email: client?.email })
		this.clientForm.patchValue({ phone: phone })
		this.clientForm.patchValue({ cpf: client?.cpf })
		this.clientForm.patchValue({ card: categories })

		this.modalService.setIsOpenBySlug(true)
	}

	addCreditCard() {
		if (this.clientForm.controls['digit'].errors || this.clientForm.value.digit.length !== 6) return

		return this.creditCardService.getByDigit(this.clientForm.value.digit).subscribe({
			next: res => {
				const alreadyInCategories = this.clientForm.value.card.find(
					(item: any) => item.digits === this.clientForm.value.digit
				)

				if (alreadyInCategories) return this.sharedService.swal.error('Cartão ja cadastrado.')

				this.clientForm.patchValue({
					card: [
						...(this.clientForm.value.card || []),
						{ ...res, digits: this.clientForm.value.digit, isNew: true, isConfirmed: false },
					],
				})
				this.clientForm.patchValue({ digit: '' })

				return
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
			},
		})
	}

	confirmCreditCard(card: any) {
		this.clientForm.patchValue({
			card: this.clientForm.value.card.map((item: any) =>
				item.digits === card.digits ? { ...item, isConfirmed: !item.isConfirmed } : item
			),
		})
	}

	getCategoriesNames(client: Client) {
		return client?.categories.map(item => item.name).join('<br>')
	}

	getErrorBySlug(slug: string) {
		if (!this.clientForm.controls[slug].touched) return

		const error = Object.keys(this.clientForm.controls[slug].errors || {})
		return error.length ? this.clientFormErrors[error[0]] : ''
	}

	digitValidation(control: AbstractControl): { [key: string]: any } | null {
		if (!control.value) return null
		if (control.value && control.value.length === 6) return null
		return { digit: true }
	}

	cardValidation(control: AbstractControl): { [key: string]: any } | null {
		const hasUnconfirmedCard = [...(control.value || [])].find((card: any) => !card.isConfirmed)

		if (control.value && control.value.length > 0 && !hasUnconfirmedCard) return null

		return { card: true }
	}

	phoneLength(control: AbstractControl): { [key: string]: any } | null {
		if (!control.value || control.value.length !== 11) return { phoneLength: true }
		return null
	}

	cpfLength(control: AbstractControl): { [key: string]: any } | null {
		if (!control.value || control.value.length !== 11) return { cpfLength: true }
		return null
	}

	loginAsClient(client: Client) {
		this.clientAuthService.login({ email: client.email, phone: client.phone }).subscribe({
			next: () => {
				window.location.href = '/'
			},
			error: error => this.sharedService.swal.error(error.error?.message),
		})
	}

	handleSubHeaderForm() {
		if (this.clientForm.invalid) return

		this.loadingService.setIsLoadingBySlug('generic', true)

		const categories = this.clientForm.value.card.map((item: any) => parseInt(item.digits))
		const form = { ...this.clientForm.value, phone: `55${this.clientForm.value.phone}`, categories }
		const { _id: id, digit, card, ...body } = form

		this.clientAuthService.update(body, id).subscribe({
			next: () => {
				this.modalService.setIsOpenBySlug(false)
				this.getClientByTerm()
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
		})
	}

	handleSearchSubmit() {
		if (this.searchForm.invalid) return

		return this.getClientByTerm()
	}
}

import { Injectable } from '@angular/core'
import { Observable, of, delay } from 'rxjs'
import { HttpService } from 'src/app/component/helper/http.service'

@Injectable({
	providedIn: 'root',
})
export class LoginService {
	constructor(private httpService: HttpService) {}

	generateFourSecretDigit(username: string): Observable<any> {
		return this.httpService.post(`login/generateFourSecretDigit`, { username }).pipe(delay(3000))
	}

	verifyFourSecretDigit(username: string, digit: string): Observable<any> {
		return this.httpService.post(`login/verifyFourSecretDigit`, { username, digit }).pipe(delay(3000))
	}
}

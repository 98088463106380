<!-- STEP 4 - E-MAIL -->
<section class="box">
	<div class="box__wrapper wrapper">
		<p class="box__title">Digite seu melhor e-mail</p>
		<form class="box__form" [formGroup]="form" (ngSubmit)="handleSubmit()">
			<label class="box__form__label">
				<i class="box__form__icon fa fa-email"></i>
				<i *ngIf="!form.controls['email'].errors" class="box__form__icon--checked fa fa-check-confirm"> </i>
				<input
					class="box__form__input"
					placeholder="ex.: junior@seuemail.com.br"
					formControlName="email"
					ngModel
				/>
			</label>
			<p class="box__form__text">Ao preencher, você receberá em SMS de confirmação.</p>
			<button
				class="box__form__button button"
				[disabled]="form.invalid || loadingService.getIsLoadingBySlug('generic')"
			>
				<span class="box__form__button__text">Continuar</span>
				<ch-loading *ngIf="loadingService.getIsLoadingBySlug('generic')" slug="button001"></ch-loading>
			</button>
			<a (click)="loginEmailService.sendTab('phone')" class="box__form__link">Entrar com telefone</a>
		</form>
	</div>
</section>

import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class SingleCreditCardService {
	public isFlipped = new BehaviorSubject<boolean>(false)
	public brand = new BehaviorSubject<string>('')

	constructor() {}

	sendIsFlipped(isFlipped: boolean) {
		this.isFlipped.next(isFlipped)
	}

	receiveIsFlipped() {
		return this.isFlipped.asObservable()
	}

	sendBrand(brand: string) {
		this.brand.next(brand)
	}

	receiveBrand() {
		return this.brand.asObservable()
	}
}

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { FilterService } from 'channels-components'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { SingleCreditCardService } from 'src/app/component/single-credit-card/single-credit-card.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { NewCardService } from '../profile/new-card/new-card.service'

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
	public form: FormGroup = new FormGroup({
		number: new FormControl(null, Validators.required),
	})

	constructor(
		public sharedService: SharedService,
		public navigationService: NavigationService,
		public loadingService: LoadingService,
		public newCardService: NewCardService,
		public singleCreditCardService: SingleCreditCardService,
		public clientAuthService: ClientAuthService,
		public filterService: FilterService
	) {}

	ngOnInit(): void {}

	// getCreditCardBrand() {
	// 	if (!this.form.value.number || this.form.value.number.trim().length < 1) return ''

	// 	const brand = creditCardBrandChecker(this.form.value.number)

	// 	if (brand.length > 1 || brand.length < 1) return ''

	// 	this.singleCreditCardService.sendBrand(brand[0].type)
	// 	return ''
	// }

	handleCVVFocus() {
		this.singleCreditCardService.sendIsFlipped(true)
	}

	handleCVVFocusout() {
		this.singleCreditCardService.sendIsFlipped(false)
	}

	handleSubmit() {
		this.loadingService.setIsLoadingBySlug('rightbar', true)

		const form = {
			categories: [
				this.form.value.number,
				...(this.clientAuthService.getClient()?.categories || []).map((item: any) => parseInt(item.digits)),
			],
		}

		this.clientAuthService.update(form, this.clientAuthService.getClient()?._id || '').subscribe(
			res => {
				this.clientAuthService.setClient(res)
				this.loadingService.setIsLoadingBySlug('rightbar', false)
				this.navigationService.setActivatedRightbarBySlug('payment')
			},
			error => {
				this.loadingService.setIsLoadingBySlug('rightbar', false)
				this.sharedService.swal.error(error.error?.message)
			}
		)
	}
}

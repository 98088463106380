<form [formGroup]="form" (ngSubmit)="handleSubmit()" class="reservationTerms">
	<!-- RESERVATION TERMS HEADER -->
	<section class="reservationTerms__header">
		<div class="reservationTerms__header__top">
			<a
				class="reservationTerms__header__return"
				(click)="navigationService.setActivatedRightbarBySlug('reservation-data')"
			>
				<i class="reservationTerms__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="reservationTerms__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="reservationTerms__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- RESERVATION TERMS CONTENT -->
	<div class="reservationTerms__content">
		<div class="reservationTerms__content__dynamic">
			<p class="reservationTerms__content__title">Termos de serviço e Reserva</p>
			<p class="reservationTerms__content__text">
				<strong>Informações gerais</strong><br />

				A reserva de mesa é válida, conforme a tolerância do tempo informado na mensagem de confirmação da
				reserva.<br />
				O dia, horário e quantidade de pessoas deve ser respeitado e selecionado mediante a disponibilidade do
				restaurante. Lembre-se de preencher corretamente a informação de telefone e email, pois enviaremos por
				SMS e Email todos os dados necessários para a sua reserva.<br />
			</p>
			<p class="reservationTerms__content__text">
				<strong>Informações de segurança</strong><br />

				Ao utilizar o serviço você reconhece que nenhum sistema de informações é inviolável, no entanto, a
				Tagme se compromete a manter a segurança e a confidencialidade de seus dados pessoais, através da
				adoção, por si, seus parceiros, clientes e fornecedores, de medidas de segurança, técnicas e
				administrativas suficientes à proteger seus dados de acessos não autorizados e de situações acidentais
				ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou
				ilícito.<br />
				Você pode entrar em contato com a Tagme através do e-mail
				<a mailto="lgpd@tagme.com.br" target="_blank">lgpd@tagme.com.br</a> para tirar dúvidas e também
				exercer quaisquer de seus direitos, incluindo, mas não limitado à (i) confirmação de tratamento de
				dados, (ii) acesso, correção, portabilidade e eliminação dos dados e (iii) informações sobre
				compartilhamento de dados com terceiros. A Tagme se obriga a responder sua solicitação em um tempo
				razoável, de forma transparente e fundamentada.<br />
				O uso do sistema implica no aceite e concordância de realização de tratamento de seus dados pessoais
				pela Tagme, incluindo dados de consumo, navegação e geolocalização para realizar e administrar a
				reserva, espera e pedidos nos restaurantes, bem como gerenciar e administrar programas de fidelidade
				da Tagme e de seus parceiros, criar e gerenciar o registro de uma conta e perfil de usuário,
				desenvolver os serviços da Tagme, dos restaurantes e de terceiros autorizados, aprimorar os produtos,
				serviços e site dos restaurantes parceiros e da Tagme, personalizar a sua experiência como cliente,
				incluindo a oferta de benefícios e comunicação de marketing dos parceiros da TagMe, convites para
				participação de estudos e pesquisas, responder solicitações de autoridades legais e conduzir as
				atividades empresariais da Tagme, dos restaurantes e parceiros utilizando estritamente a quantidade de
				dados necessária por um prazo razoável, sujeito ainda à revogação de sua autorização a qualquer
				tempo.<br />
				A Tagme não compartilha seus dados com outras redes de restaurantes, porém não responsabiliza pelo uso
				indevido das informações captadas diretamente pelo restaurante sem a aprovação formal e expressa da
				Tagme no momento da captura.<br />
				<strong
					>Ao continuar, você aceita os Termos de Serviço neste estabelecimento com a Tagme, e especificamente
					aceita que a Tagme compartilhe suas informações com este estabelecimento para realizar a transação
					solicitada.</strong
				>
			</p>
		</div>
		<div class="reservationTerms__content__checkbox">
			<input
				class="reservationTerms__content__input"
				id="terms"
				type="checkbox"
				[checked]="form.value.reservation || false"
				formControlName="reservation"
				ngModel
			/>
			<label class="reservationTerms__content__label" for="terms">
				<span class="reservationTerms__content__label__span">Li e concordo</span> com os termos e condições da
				reserva no Menu Bradesco.
			</label>
		</div>
	</div>

	<!-- RESERVATION TERMS FOOTER -->
	<section class="reservationTerms__footer">
		<button
			class="reservationTerms__footer__button button gradientHorizontal"
			[disabled]="!form.value.reservation || form.invalid || loadingService.getIsLoadingBySlug('rightbar')"
		>
			<span class="waitlist__footer__button__text">Continuar</span>
			<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
		</button>
	</section>
</form>

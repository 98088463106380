import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import * as creditCardBrandChecker from 'credit-card-type'
import { filter, Subscription, switchMap, take } from 'rxjs'
import { Client } from 'src/app/component/client/client.interface'
import { FilterService } from 'src/app/component/filter/filter.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { SingleCreditCardService } from 'src/app/component/single-credit-card/single-credit-card.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { PaymentCardHelper } from '../../payment/payment-card/payment-card.helper'
import { NavigationHandle } from './navigation-handle.service'
import { EncryptCardDataDto } from './new-card.interfaces'
import { NewCardService } from './new-card.service'
import { DEFAULT_STATE, STATES_NAME_ID } from './states'

@Component({
	selector: 'ch-new-card',
	templateUrl: './new-card.component.html',
	styleUrls: ['./new-card.component.scss'],
})
export class NewCardComponent implements OnInit, OnDestroy {
	public creditCardForm!: FormGroup
	public openCVV = false
	public openRegisteredCard = false
	public openSelectedBox = false
	public selectedBoxTitle = DEFAULT_STATE
	public states = STATES_NAME_ID

	private subs = new Subscription()
	public lastNavigation$ = this.navigationHandle.lastLocation$

	constructor(
		public sharedService: SharedService,
		public navigationService: NavigationService,
		public loadingService: LoadingService,
		public singleCreditCardService: SingleCreditCardService,
		public clientAuthService: ClientAuthService,
		public filterService: FilterService,
		private newCardService: NewCardService,
		private fb: FormBuilder,
		private navigationHandle: NavigationHandle
	) {}

	ngOnInit(): void {
		this.creditCardForm = this.createCreditCardForm()
		this.subs.add(this.updateAddressFromCep())
		this.subs.add(this.updateBin())
		// this.patchTest()
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	private updateBin() {
		return this.clientAuthService.selectedCategory$.pipe(take(1)).subscribe(category => {
			this.creditCardForm.patchValue({ number: category?.digits.toString() })
		})
	}

	private createCreditCardForm(): FormGroup {
		return this.fb.group({
			number: [null, Validators.required],
			name: ['', Validators.required],
			validity: [null, Validators.required],
			cvv: ['', Validators.required],
			billingAddress: this.fb.group({
				zipCode: ['', Validators.required],
				state: ['', Validators.required],
				city: ['', Validators.required],
				address: ['', Validators.required],
			}),
			favorite: [false],
		})
	}

	private updateAddressFromCep() {
		return this.creditCardForm
			.get('billingAddress.zipCode')
			?.valueChanges.pipe(
				filter(zipCode => zipCode.toString().length >= 8),
				switchMap(zipCode => this.newCardService.getCEP(zipCode))
			)
			.subscribe(address => {
				if (address?.erro) return

				this.selectedBoxTitle = this.findStateByUF(address.uf) || DEFAULT_STATE
				this.creditCardForm.patchValue({
					billingAddress: {
						state: address.uf,
						city: address.localidade,
						address: address.logradouro,
					},
				})
			})
	}

	private removeSpecialCaractere(str: string) {
		return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
	}

	private findStateByUF(uf: string) {
		return this.states.find(state => state.id === uf)?.name
	}

	toggleCVV() {
		this.openCVV = !this.openCVV
	}

	toggleRegisteredCard() {
		this.openRegisteredCard = !this.openRegisteredCard
	}

	toggleOpenSelectBox() {
		this.openSelectedBox = !this.openSelectedBox
	}

	handleCVVFocus(bool: boolean) {
		this.singleCreditCardService.sendIsFlipped(bool)
	}

	selectState(title: string, id: string) {
		this.selectedBoxTitle = title
		this.creditCardForm.patchValue({
			billingAddress: {
				state: id,
			},
		})
	}

	handleSubmit() {
		this.loading(true)

		const client = this.clientAuthService.getClient() as Client

		if (!client) {
			this.sharedService.swal.error('Cliente não existe')
		}

		this.newCardService
			.createCardToken({
				clientId: client._id,
				// cpf: PaymentCardHelper.formatCPF(client.cpf),
				cardProperties: { ...this.encryptData, billingAddress: { ...this.billingAddress, country: 'BR' } },
				mainCard: this.creditCardForm.value.favorite,
			})
			.pipe(switchMap(() => this.clientAuthService.updateClientFront()))
			.subscribe({
				next: () => {
					this.openRegisteredCard = true
					this.clientAuthService.setSelectedCategoryByLastFourDigits(
						this.creditCardForm.value.number.slice(-4)
					)
					this.loading(false)
				},
				error: error => {
					this.sharedService.swal.error(error.error?.message)
					this.loading(false)
				},
			})
	}

	private get billingAddress() {
		return { ...this.creditCardForm.value.billingAddress, country: 'BR' }
	}

	private get encryptData(): EncryptCardDataDto {
		const [expMonth, expYear] = this.creditCardForm.value.validity.split('/')
		const encryptCard = {
			number: this.creditCardForm.value.number,
			holder: this.removeSpecialCaractere(this.creditCardForm.value.name),
			cvv: this.creditCardForm.value.cvv,
			expMonth: +expMonth,
			expYear: +`20${expYear}`,
		}
		return encryptCard
	}

	private loading(bool: boolean) {
		this.loadingService.setIsLoadingBySlug('rightbar', bool)
	}

	handleGoBack() {
		const chooseGoBack = {
			root: () => this.navigationService.setActivatedRightbarBySlug('my-cards'),
			history: () => this.navigationService.setActivatedRightbarBySlug(''),
			reservation: () => this.navigationService.setActivatedRightbarBySlug('reservation-form'),
			checkin: () => this.navigationService.setActivatedRightbarBySlug('check-in-form'),
			celebrationReservation: () =>
				this.navigationService.setActivatedRightbarBySlug('celebration-reservation-form'),
		}

		this.lastNavigation$.pipe(take(1)).subscribe(location => {
			if (!location) {
				console.error('Verifique se usou o navigationHandle na página que quer vir aqui.')
				return
			}
			chooseGoBack[location]()
		})
	}

	closeRegisteredCard() {
		this.openRegisteredCard = false
		this.handleGoBack()
	}

	getCreditCardBrand() {
		if (!this.creditCardForm.value.number || this.creditCardForm.value.number.trim().length < 1) return ''

		const brand = creditCardBrandChecker(this.creditCardForm.value.number)

		if (brand.length > 1 || brand.length < 1) return ''

		this.singleCreditCardService.sendBrand(brand[0].type)
		return ''
	}

	// patchTest() {
	// 	this.creditCardForm.patchValue({
	// 		name: 'Teste teste',
	// 		number: '5454109734562043',
	// 		validity: '08/24',
	// 		cvv: '223',
	// 		billingAddress: {
	// 			zipCode: '88888888',
	// 			state: 'RJ',
	// 			city: 'RJ',
	// 			address: 'RJ',
	// 		},
	// 	})
	// }
}

import { Injectable } from '@angular/core'
import { BehaviorSubject, ReplaySubject, shareReplay, Subject, tap } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class PaymentService {
	private initialValueSub = new BehaviorSubject<number>(0)
	private discountSub = new BehaviorSubject<number>(0)
	private paymentResponseSub = new ReplaySubject<PaymentResponse>()
	private gratuityValueSub = new BehaviorSubject<number>(0)
	public totalValue$ = this.initialValueSub.asObservable()
	public discount$ = this.discountSub.asObservable()
	public paymentResponse$ = this.paymentResponseSub.asObservable()
	public gratuityValue$ = this.gratuityValueSub.asObservable()
	public initialValue$ = this.initialValueSub.asObservable()

	constructor() {}

	setGratuityValue(num: number) {
		this.gratuityValueSub.next(num)
	}

	setInitialValue(num: number) {
		this.initialValueSub.next(num)
	}

	setTotalValue(num: number) {
		this.initialValueSub.next(num)
	}

	setDiscount(num: number) {
		this.discountSub.next(num)
	}

	setPaymentResponse(paymentRes: PaymentResponse) {
		this.paymentResponseSub.next(paymentRes)
	}
}

export interface PaymentResponse {
	paidAt: string // Date
	code: string
}

<div class="editProfile">
	<!-- EDIT PROFILE HEADER -->
	<section class="editProfile__header">
		<div class="editProfile__header__top">
			<a
				class="editProfile__header__return"
				(click)="navigationService.setActivatedRightbarBySlug('my-profile')"
			>
				<i class="editProfile__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="editProfile__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="editProfile__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- EDIT PROFILE BODY -->
	<section class="editProfile__body">
		<!-- breadcrumb 
		<ul class="editProfile__body__breadcrumb">
			<li class="editProfile__body__breadcrumb__item">
				<p class="editProfile__body__breadcrumb__txt red">Perfil /</p>
			</li>
			<li class="editProfile__body__breadcrumb__item">
				<p class="editProfile__body__breadcrumb__txt red">Meu perfil</p>
			</li>
		</ul>
		-->

		<!-- title -->
		<div class="editProfile__body__title">Meu perfil</div>

		<form class="editProfile__body__form" [formGroup]="form" (ngSubmit)="handleSubmit()">
			<label class="editProfile__body__form__label">
				<i class="editProfile__body__form__icon fa fa-nome"></i>
				<i
					*ngIf="!form.controls['name'].errors"
					class="editProfile__body__form__icon--checked fa fa-check-confirm"
				>
				</i>
				<input
					class="editProfile__body__form__input"
					placeholder="João Silva"
					formControlName="name"
					ngModel
				/>
			</label>
			<label class="editProfile__body__form__label">
				<i class="editProfile__body__form__icon fa fa-celular"></i>
				<i
					*ngIf="!form.controls['phone'].errors"
					class="editProfile__body__form__icon--checked fa fa-check-confirm"
				>
				</i>
				<input
					class="editProfile__body__form__input"
					placeholder="ex.: (21) 99312-0210"
					mask="(00) 00000-0000"
					formControlName="phone"
					ngModel
				/>
			</label>
			<label class="editProfile__body__form__label">
				<i class="editProfile__body__form__icon fa fa-email"></i>
				<i
					*ngIf="!form.controls['email'].errors"
					class="editProfile__body__form__icon--checked fa fa-check-confirm"
				>
				</i>
				<input
					class="editProfile__body__form__input"
					placeholder="ex.: joão silva@gmail.com"
					formControlName="email"
					ngModel
				/>
			</label>
			<div class="editProfile__body__save">
				<button
					class="editProfile__body__save__button button gradientHorizontal"
					[disabled]="form.invalid || loadingService.getIsLoadingBySlug('rightbar')"
				>
					<span class="editProfile__body__save__button__text">Salvar dados</span>
					<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
				</button>
			</div>
		</form>
	</section>

	<!-- EDIT PROFILE FOOTER -->
	<section class="editProfile__footer">
		<i class="editProfile__footer__icon bradesco fa fa-logo-bradesco"></i>
		<p class="editProfile__footer__txt">powered by</p>
		<i class="editProfile__footer__icon tagme fa fa-logo-tagme"></i>
	</section>
</div>

import { Injectable } from '@angular/core'
import { BehaviorSubject, delay, Observable, of } from 'rxjs'
import { environment } from 'src/environments/environment'
import { HttpService } from '../helper/http.service'
import { Term } from '../term/term.interface'
import { Waitlist } from './waitlist.interface'

@Injectable({
	providedIn: 'root',
})
export class WaitlistService {
	public env = environment
	public waitlist = new BehaviorSubject<Waitlist>({
		venue: null,
		client: null,
		people_amount: null,
		credit_card: null,
	})

	public term: Term = { content: '' }

	constructor(private httpService: HttpService) {
		if (!this.term.content.length) this.getTerms().subscribe(res => (this.term = res))
	}

	public sendWaitlist(waitlist: Waitlist) {
		waitlist = { ...this.waitlist.getValue(), ...waitlist }
		this.waitlist.next(waitlist)
	}

	public setWaitlistByKey(key: string, value: any) {
		this.waitlist.next({ ...this.waitlist.getValue(), [key]: value })
	}

	public receiveWaitlist() {
		return this.waitlist.asObservable()
	}

	public getTerms(): Observable<Term> {
		return of({ content: '' }).pipe(delay(1000))
	}

	public agreeWithWaitlistTerms(form: any, id: string): Observable<any> {
		return this.httpService.patch(`api/v1/clients/${id}`, form)
	}

	public enterWaitlist(body: any): Observable<any> {
		body.partySize = parseInt(body.partySize)
		return this.httpService.post(`api/v1/orders/waitlist`, body)
	}
}

import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

interface Modal {
	slug: string
	isOpen: boolean
}
@Injectable({
	providedIn: 'root',
})
export class ModalService {
	public modal = new BehaviorSubject<Modal[]>([{ slug: 'generic', isOpen: false }])

	constructor() {}

	setIsOpenBySlug(isOpen: boolean, slug: string = 'generic') {
		this.addModalBySlug(slug)
		this.modal.next(
			this.modal
				.getValue()
				.map(item => (item.slug === slug ? { ...item, isOpen } : { ...item, isOpen: false }))
		)
	}

	getIsOpenBySlug(slug: string = 'generic') {
		return this.modal.getValue().find(item => item.slug === slug)
	}

	addModalBySlug(slug: string) {
		const hasSlugAlready = this.modal.getValue().find(item => item.slug === slug)
		if (hasSlugAlready) return
		this.modal.next([...this.modal.getValue(), { slug, isOpen: false }])
	}
}

import { Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { Subject, takeUntil } from 'rxjs'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'
import { CreditCardService } from 'src/app/component/credit-card/credit-card.service'
import { FilterService } from 'src/app/component/filter/filter.service'
import { ModalService } from 'src/app/component/modal/modal.service'
import { AuthService } from 'src/app/helper/auth.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'

@Component({
	selector: 'app-celebration-home',
	templateUrl: './celebration-home.component.html',
	styleUrls: ['./celebration-home.component.scss'],
})
export class CelebrationHomeComponent implements OnInit, OnDestroy {
	destroy$ = new Subject<void>()
	voucherValue: string = ''
	constructor(
		public modalService: ModalService,
		public authService: AuthService,
		public creditCardService: CreditCardService,
		public filterService: FilterService,
		public clientAuthService: ClientAuthService,
		public router: Router,
		private title: Title
	) {}

	ngOnInit() {
		this.title.setTitle(`${PREFIX_TITLE} - Celebrations Home`)
		window.scrollTo(0, 0)
		this.hasCelebration()
		this.modalService.setIsOpenBySlug(true)
		this.clientAuthService.voucher$.pipe(takeUntil(this.destroy$)).subscribe(voucher => {
			this.voucherValue = voucher?.value
		})
	}
	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	hasCelebration() {
		const client = this.clientAuthService.getClient()
		if (!client || !(client.vouchers || []).length) this.router.navigate([`/`])
	}

	getFormatedVoucherValidUntil() {
		const client = this.clientAuthService.getClient()
		const date = client?.vouchers[0].validUntil
		return date.substring(0, 10).split('-').reverse().join('/')
	}
}

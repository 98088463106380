import { Component, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { Client } from 'src/app/component/client/client.interface'
import { SharedService } from 'src/app/component/helper/shared.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'

@Component({
	selector: 'ch-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
	public $subs: Subscription[] = []
	public client: Client | null = null
	public showingLogoutModal: boolean = false

	constructor(
		public navigationService: NavigationService,
		public clientAuthService: ClientAuthService,
		public sharedService: SharedService
	) {}

	ngOnInit(): void {
		this.$subs.push(this.clientAuthService.receiveClient().subscribe(res => (this.client = res)))
	}
}

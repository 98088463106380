import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { AuthService } from 'src/app/helper/auth.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { BenefitService } from '../../benefit/benefit.service'
import { CreditCard } from '../../credit-card/credit-card.interface'
import { GeolocationService } from '../../helper/geolocation.service'
import { SharedService } from '../../helper/shared.service'
import { LoadingService } from '../../loading/loading.service'
import { MosaicService } from '../../mosaic/mosaic.service'
import { VenueService } from '../../venue/venue.service'
import { FilterService } from '../filter.service'
import { Filter } from '../filter.interface'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'ch-filter001',
	templateUrl: './filter001.component.html',
	styleUrls: ['./filter001.component.scss'],
})
export class Filter001Component implements OnInit, OnDestroy {
	public filter: Filter = { address: [] }
	public filterCities: boolean = false
	public isOpen: boolean = false
	public isOpenLocation: boolean = false
	public numberCity = '0'
	public $subs: Subscription[] = []

	constructor(
		public sharedService: SharedService,
		public loadingService: LoadingService,
		public mosaicService: MosaicService,
		public filterService: FilterService,
		public venueService: VenueService,
		public benefitService: BenefitService,
		public authService: AuthService,
		public geoLocationService: GeolocationService,
		public clientAuthService: ClientAuthService,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		const city = this.route.snapshot.queryParams['cidade']
		this.filterService.loadFilter(false, city)
		this.$subs.push(
			this.filterService.filter$.subscribe(res => {
				this.filter = res

				const cities = document.querySelectorAll('.filter__city__item__right')
				if (cities) {
					const filterCity: any = document.querySelector('.filter__city')
					cities.forEach((city: any) => {
						city.addEventListener('click', (e: any) => {
							const checkbox: any = document.querySelector(`#${city.getAttribute('data-city')}`)
							if (!checkbox.checked) filterCity.scrollTop = city.offsetTop
						})
					})
				}
			})
		)

		document.addEventListener('click', (e: any) => {
			const dontCloseClassesList: any[] = []

			for (let item of dontCloseClassesList) if (e.target.closest(item)) return

			if (!e.target.closest('.filter__creditCard')) {
				this.isOpen = false
			}
		})

		document.addEventListener('click', (e: any) => {
			const dontCloseClassesList = [
				'.filter__person__location__button',
				'.filter__city__item__right',
				'.filter__neighborhood__toggler',
			]

			for (let item of dontCloseClassesList) if (e.target.closest(item)) return

			if (!e.target.closest('.filter__city')) {
				this.filterCities = false
			}
		})
	}

	ngOnDestroy(): void {
		this.$subs.forEach(sub => sub.unsubscribe())
	}

	public openLocation(cityid: any) {
		if (cityid == this.numberCity) {
			this.isOpenLocation = !this.isOpenLocation
		} else {
			this.numberCity = cityid
			this.isOpenLocation = true
		}
	}

	public setAddress(el: any, item: any) {
		el.stopPropagation()

		if (
			el.target.closest('.filter__city__item__right') ||
			el.target.classList.contains('filter__neighborhood__toggler')
		)
			return

		this.filterService.setAddress(item)
		this.filterCities = false
	}

	setSelectedCategory(category: CreditCard) {
		this.clientAuthService.setSelectedCategory(category)
		this.filterService.updateVenues()
	}
}

import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { EditProfileService } from './edit-profile.service'

@Component({
	selector: 'ch-edit-profile',
	templateUrl: './edit-profile.component.html',
	styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
	public form: FormGroup = new FormGroup({
		name: new FormControl(null, [Validators.required]),
		phone: new FormControl(null, [Validators.required]),
		email: new FormControl(null, [Validators.required, Validators.email]),
	})
	constructor(
		public navigationService: NavigationService,
		public loadingService: LoadingService,
		private editProfileService: EditProfileService,
		public sharedService: SharedService,
		public clientAuthService: ClientAuthService
	) {}

	ngOnInit(): void {
		this.editProfileService.getByLogged().subscribe(res => {
			const phone = res.phone.substring(2)
			this.form.patchValue({ phone: phone })
			this.form.patchValue({ name: `${res?.name} ${res?.lastName}` })
			this.form.patchValue({ email: res?.email })
		})
	}

	handleSubmit() {
		this.loadingService.setIsLoadingBySlug('rightbar', true)

		const form = { ...this.form.value, phone: `55${this.form.value.phone}` }

		this.editProfileService.update(form).subscribe(
			res => {
				this.clientAuthService.setClient(res)
				this.loadingService.setIsLoadingBySlug('rightbar', false)
				this.navigationService.setActivatedRightbarBySlug('my-profile')
			},
			error => {
				this.loadingService.setIsLoadingBySlug('rightbar', false)
				this.sharedService.swal.error(error.error?.message)
			}
		)
	}
}

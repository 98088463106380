import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { HttpService } from 'src/app/component/helper/http.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { Venue } from 'src/app/component/venue/venue.interface'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root',
})
export class VenuePageService {
	public env = environment
	public venue = new BehaviorSubject<Venue | null>(null)

	constructor(
		private httpService: HttpService,
		private clientAuthService: ClientAuthService,
		private sharedService: SharedService
	) {}

	public getByID(id: string): Observable<Venue> {
		const client = this.sharedService.localStorage.get('client')

		const body: any = {
			partner: this.env.partnerID,
			clientId: client._id || '',
			categoryId: this.clientAuthService.getSelectedCategory()?.categoryId || '',
		}

		if (!body.categoryId) delete body.categoryId
		if (!body.clientId) delete body.clientId

		return this.httpService.post<Venue>(`api/v1/venues/${id}`, body)
	}

	public sendVenue(venue: Venue | null) {
		this.venue.next(venue)
	}

	public receiveVenue() {
		return this.venue.asObservable()
	}

	public getVenue() {
		return this.venue.getValue()
	}

	public hasCelebrations() {
		const venue = this.getVenue()
		return venue?.partner?.types.some(item => item.type.toLowerCase() === 'celebrations') ?? false
	}

	favoriteToggler(venue: Venue | null) {
		if (!venue || !this.venue.getValue()) return null

		const client = this.sharedService.localStorage.get('client')

		const isFavorited = (client.favorites || []).find((venueID: string) => venueID === venue._id)

		const favorites = !isFavorited
			? [...(client.favorites || []), venue._id]
			: (client.favorites || []).filter((venueID: string) => venueID !== venue._id)

		return this.clientAuthService.update({ favorites }, client._id).subscribe({
			next: res => {
				this.clientAuthService.setClient(res)
				this.sendVenue({ ...venue, favorite: !venue.favorite })
			},
			error: error => {
				this.sharedService.swal.error(error.error?.message)
			},
		})
	}
}

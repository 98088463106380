<div class="page">
	<div class="page__header">
		<ch-search slug="001"></ch-search>
		<app-bradesco-navbar></app-bradesco-navbar>
		<ch-rightbar></ch-rightbar>
	</div>
	<div class="page__body">
		<!-- CONTENT ORDER -->
		<section class="order">
			<div class="order__wrapper wrapper">
				<div class="order__cardsList desktopOnly">
					<h2 class="order__cardsList__title">Histórico de pedidos</h2>
				</div>

				<div *ngIf="loading" class="loading001">
					<div class="loading001__circle"></div>
				</div>

				<div *ngIf="!orders?.length && !loading">
					<div class="order__notFound">
						<i class="order__notFound__icon fa fa-data"></i>
						<p class="order__notFound__txt">Você ainda não tem histórico de pedidos.</p>
					</div>
				</div>

				<ul class="venue__list">
					<li class="venue__list__eachOne" *ngFor="let item of orders">
						<div class="venue__list__eachOne__box">
							<ch-order-venue [order]="item" (seeDetailsClicked)="handleSeeDetails($event)"></ch-order-venue>
						</div>
					</li>
				</ul>
			</div>
		</section>
	</div>
	<div class="page__footer">
		<ch-footer slug="001"></ch-footer>
	</div>
</div>

<ch-modal slug="001" [modalSlug]="modalDetails" *ngIf="selectedOrder">
	<div class="ticket" content>
		<div class="ticket__content">
			<div class="ticket__top">
				<i class="ticket__logo fa fa-logo-programa-menu"></i>
				<img
					class="ticket__card"
					alt="thumbnail do cartão utilizado na compra"
					src="./../../../../assets/images/img-cartao.svg"
				/>
			</div>
			<ul class="ticket__list">
				<li class="ticket__list__item" *ngIf="selectedOrder?.venue?.name">
					<p class="ticket__list__item__label">Restaurante</p>
					<p class="ticket__list__item__date">{{ selectedOrder.venue.name || '' }}</p>
				</li>
				<li class="ticket__list__item" *ngIf="selectedOrder.partySize">
					<p class="ticket__list__item__label">Mesa para</p>
					<p class="ticket__list__item__date">{{ getFormatedPartySize(selectedOrder.partySize) || '' }}</p>
				</li>
				<li class="ticket__list__item" *ngIf="selectedOrder?.sectionLabel">
					<p class="ticket__list__item__label">Ambiente</p>
					<p class="ticket__list__item__date">{{ selectedOrder.sectionLabel }}</p>
				</li>
				<li class="ticket__list__item">
					<p class="ticket__list__item__label">Dia</p>
					<p class="ticket__list__item__date">{{ getFormatedDate(selectedOrder.reservationDay) }}</p>
				</li>
				<li class="ticket__list__item">
					<p class="ticket__list__item__label">Horário</p>
					<p class="ticket__list__item__date">{{ selectedOrder.reservationTime }}</p>
				</li>
				<li class="ticket__list__item" *ngIf="selectedOrder?.client?.name && selectedOrder?.client?.lastName">
					<p class="ticket__list__item__label">Nome</p>
					<p class="ticket__list__item__date">
						{{ selectedOrder.client.name }} {{ selectedOrder.client.lastName }}
					</p>
				</li>
				<li class="ticket__list__item" *ngIf="selectedOrder.categoryName">
					<p class="ticket__list__item__label">Benefícios do seu cartão</p>
					<p class="ticket__list__item__date">{{ selectedOrder.categoryName || '' }}</p>
				</li>
			</ul>

			<!-- CHECKIN CONFIRM BENEFITS -->
			<ch-benefit slug="001" [benefit]="selectedOrder.benefits" [voucherValue]="voucherValue"></ch-benefit>
			<!-- <button
				class="ticket__button button"
				(click)="handlePaymentAndCloseModal(selectedOrder)"
				*ngIf="canPay$ | async"
			>
				Pagar a conta
			</button> -->
			<!-- este botão levará ao pagmento da conta, payment-bill, a não ser que o cadastro do cartão
			não esteja completo, neste caso, abrirá um modal informando a necessidade  de completar este cadastro. -->
		</div>
	</div>
</ch-modal>

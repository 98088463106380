import { Component, Input, OnInit } from '@angular/core'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { BenefitService } from '../../benefit/benefit.service'
import { SharedService } from '../../helper/shared.service'

@Component({
	selector: 'ch-credit-card001',
	templateUrl: './credit-card001.component.html',
	styleUrls: ['./credit-card001.component.scss'],
})
export class CreditCard001Component implements OnInit {
	public isOpen: boolean = false
	@Input() hasPadding: boolean = false

	constructor(
		public benefitService: BenefitService,
		public clientAuthService: ClientAuthService,
		public sharedService: SharedService
	) {}

	ngOnInit(): void {
		document.addEventListener('click', (e: any) => {
			const dontCloseClassesList: any[] = []

			for (let item of dontCloseClassesList) if (e.target.closest(item)) return

			if (!e.target.closest('.creditCard001')) {
				this.isOpen = false
			}
		})
	}
}

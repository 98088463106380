import { Component, OnInit } from '@angular/core'
import { SharedService } from 'src/app/component/helper/shared.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { WaitlistService } from 'src/app/component/waitlist/waitlist.service'
import { Client } from 'src/app/component/client/client.interface'
import { DomSanitizer } from '@angular/platform-browser'
import { environment } from 'src/environments/environment'
import { ClientAuthService } from 'src/app/helper/client-auth.service'

@Component({
	selector: 'ch-waitlist-confirm',
	templateUrl: './waitlist-confirm.component.html',
	styleUrls: ['./waitlist-confirm.component.scss'],
})
export class WaitlistConfirmComponent implements OnInit {
	public env = environment
	public waitlist: any

	constructor(
		public navigationService: NavigationService,
		public waitlistService: WaitlistService,
		public sharedService: SharedService,
		public domSanitizer: DomSanitizer,
		public clientAuthService: ClientAuthService
	) {}

	ngOnInit(): void {
		const waitlist = this.waitlistService.waitlist.getValue()

		const body = {
			clientId: this.clientAuthService.getClient()?._id,
			venueId: waitlist.venue?._id,
			partySize: waitlist.people_amount,
		}

		this.waitlistService.enterWaitlist(body).subscribe({
			next: res => {
				this.waitlistService.sendWaitlist({
					venue: null,
					client: null,
					people_amount: null,
					credit_card: null,
				})
				this.waitlist = res
			},
			error: error => {
				this.navigationService.setActivatedRightbarBySlug('')
				return this.sharedService.swal.error(error.error?.message)
			},
		})
	}

	getWaitlistURL() {
		return this.waitlist
			? this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.waitlist.waitlistUrl}`)
			: ''
	}
}

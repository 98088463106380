import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { Subscription } from 'rxjs'
import { Navigation } from '../navigation/navigation.interface'
import { NavigationService } from '../navigation/navigation.service'

@Component({
	selector: 'ch-rightbar',
	templateUrl: './rightbar.component.html',
	styleUrls: ['./rightbar.component.scss'],
})
export class RightbarComponent implements OnInit, OnDestroy {
	public $subs: Array<Subscription> = []
	public navigation: Navigation | undefined

	constructor(public navigationService: NavigationService) {}

	ngOnInit(): void {
		this.$subs.push(
			this.navigationService.receiveNavigation().subscribe(res => {
				this.navigation = this.navigationService.getActivatedRightBar()
			})
		)
	}

	ngOnDestroy(): void {
		this.$subs.forEach(sub => sub.unsubscribe())
	}

	allowed(slug: string) {
		return this.navigation?.slug === slug
	}
}

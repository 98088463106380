<ul class="benefit002">
	<ng-container *ngIf="!voucherValue">
		<li class="benefit002__item" *ngFor="let item of getSortedBenefits(benefit); let i = index">
			<div class="benefit002__image">
				<!-- <i class="benefit002__icon purple fa fa-desconto"></i> -->
				<img class="benefit002__icon" [src]="env.tagmepub + '/pubimg/' + item.icon" />
			</div>
			<div class="benefit002__details">
				<span class="benefit002__name"
					>{{ getFormatedBenefitNameByType(item) }}
					<span class="benefit002__number">{{ i + 1 }}</span>
				</span>
				<div class="benefit002__link">
					<p class="benefit002__text">{{ item.description }}</p>

					<input type="checkbox" id="benefit002_{{ i + 1 }}" class="benefit002__input" />
					<label class="benefit002__label" for="benefit002_{{ i + 1 }}">+ detalhes</label>
					<p class="benefit002__textDetails">{{ item.descriptionLong }}</p>
				</div>
			</div>
		</li>
	</ng-container>
	<ng-container *ngIf="voucherValue">
		<li class="benefit002__item">
			<div class="benefit002__image">
				<!-- <i class="benefit002__icon purple fa fa-presente"></i> -->
				<img class="benefit002__icon" src="./../../../../assets/images/icon-presente.svg" />
			</div>
			<div class="benefit002__details">
				<span class="benefit002__name">Celebrations</span>
				<div class="benefit002__link">
					<!--
						<input type="checkbox" id="chck2" class="benefit002__input" />
						<label class="benefit002__label" for="chck2">Ver detalhes</label>
					-->
					<p class="benefit002__text">
						você ganhou um crédito de {{ voucherValue | currency: 'BRL' }} no mês do seu aniversário.<br />
						Aproveite :)
					</p>
				</div>
			</div>
		</li>
	</ng-container>
</ul>

import { Component, Input, OnInit, TemplateRef } from '@angular/core'
import { Mosaic } from './mosaic.interface'

@Component({
	selector: 'ch-mosaic',
	templateUrl: './mosaic.component.html',
	styleUrls: ['./mosaic.component.scss'],
})
export class MosaicComponent implements OnInit {
	@Input() slug: string = '001'
	@Input() mosaicSlug: string = ''
	@Input() mosaic: Mosaic | undefined
	@Input() notFound: TemplateRef<any> | null = null
	@Input() beforeVenues: TemplateRef<any> | null = null
	@Input() voucherValue = ''

	constructor() {}

	ngOnInit(): void {}
}

<div class="concierge_waitlist">
	<div class="concierge_waitlist__sub_header">
		<div class="concierge_waitlist__sub_header__item">
			<form class="concierge_waitlist__modal__form" [formGroup]="form" (ngSubmit)="handleSubHeaderForm()">
				<div class="field">
					<div class="field__wrapper">
						<i class="field__icon right tagmeicon tagmeicon-buscar"></i>
						<input
							type="text"
							class="field__input"
							ngModel
							formControlName="cpf"
							placeholder="Encontrar cliente por cpf"
							mask="000.000.000-00"
						/>
					</div>
				</div>
			</form>
		</div>
	</div>
	<ng-container *ngFor="let item of waitlist">
		<div class="concierge_waitlist__item" *ngIf="item?.venue">
			<div class="concierge_waitlist__item__left">
				<img
					class="concierge_waitlist__item__image"
					[src]="env.tagmepub + '/pubimg/' + item.venue.partner.images.thumb[0]?.coverUrl"
					alt=""
				/>
			</div>
			<div class="concierge_waitlist__item__right">
				<span class="concierge_waitlist__item__name">{{ item.venue?.name || '' }}</span>
				<div class="concierge_waitlist__item__row">
					<div class="concierge_waitlist__item__column">
						<span class="concierge_waitlist__item__desc"
							><strong class="concierge_waitlist__item__desc">Data:</strong> {{ getFormatedDate(item) }}</span
						><br />
						<span class="concierge_waitlist__item__desc"
							><strong class="concierge_waitlist__item__desc">Mesa </strong>para:
							{{ getFormatedPartySize(item) }}</span
						>
					</div>
					<div class="concierge_waitlist__item__column">
						<span class="concierge_waitlist__item__desc"
							><strong class="concierge_waitlist__item__desc">Nome: </strong> {{ item.client?.name || '' }}
							{{ item.client?.lastName || '' }}</span
						><br />
						<span class="concierge_waitlist__item__desc"
							><strong class="concierge_waitlist__item__desc">Telefone: </strong>
							{{ item.client?.phone || '' | mask: '+00 (00) 00000-0000' }}</span
						><br />
						<span class="concierge_waitlist__item__desc"
							><strong class="concierge_waitlist__item__desc">CPF: </strong>
							{{ item.client?.cpf || '' | mask: '000.000.000-00' }}</span
						>
						<strong class="concierge_waitlist__item__desc">{{ item.categoryName || '' }}</strong>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>

<ul class="benefit001 {{ bgColor }}">
	<ng-container *ngIf="!voucherValue">
		<li class="benefit001__item" *ngFor="let item of getSortedBenefits(benefit); let i = index">
			<div class="benefit001__image">
				<!-- <i class="benefit001__icon purple fa fa-mesa-vip"></i> -->
				<img class="benefit001__icon" [src]="env.tagmepub + '/pubimg/' + item.icon" />
			</div>
			<div class="benefit001__details">
				<span class="benefit001__name"
					>{{ getFormatedBenefitNameByType(item) }}
					<span class="benefit001__number">{{ i + 1 }}</span>
				</span>
				<div class="benefit001__link">
					<input type="checkbox" id="benefit001_{{ i + 1 }}" class="benefit001__input" />
					<label class="benefit001__label" for="benefit001_{{ i + 1 }}">Ver detalhes</label>
					<p class="benefit001__text">
						{{ item.description }}<br /><br />
						{{ item.descriptionLong }}
					</p>
				</div>
			</div>
		</li>
	</ng-container>
	<ng-container *ngIf="voucherValue">
		<li class="benefit001__item">
			<div class="benefit001__image">
				<!-- <i class="benefit001__icon fa fa-presente"></i> -->
				<img class="benefit001__icon" src="./../../../../assets/images/icon-presente.svg" />
			</div>
			<div class="benefit001__details">
				<span class="benefit001__name">Celebrations</span>
				<div class="benefit001__link">
					<input type="checkbox" id="benefit001_celebration" class="benefit001__input" />
					<label class="benefit001__label" for="benefit001_celebration">Ver detalhes</label>
					<p class="benefit001__text">
						você ganhou um crédito de {{ voucherValue | currency: 'BRL' }} no mês do seu aniversário.<br />
						Aproveite :)
					</p>
				</div>
			</div>
		</li>
	</ng-container>
</ul>

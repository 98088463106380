import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { Client } from 'src/app/component/client/client.interface'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { LoginConfirmService } from 'src/app/component/login-confirm/login-confirm.service'
import { LoginEmailService } from 'src/app/component/login-email/login-email.service'
import { LoginPhoneService } from 'src/app/component/login-phone/login-phone.service'
import { Mosaic } from 'src/app/component/mosaic/mosaic.interface'
import { MosaicService } from 'src/app/component/mosaic/mosaic.service'
import { VenueService } from 'src/app/component/venue/venue.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { Tab } from './login.interface'
import { LoginService } from './login.service'

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
	private username: string = ''
	public $subs: Array<Subscription> = []
	public mosaic: Mosaic[] = this.mosaicService.getMosaicByPage('home')

	private tab: Tab[] = [
		{ slug: 'phone', isActive: true },
		{ slug: 'email', isActive: false },
		{ slug: 'confirm', isActive: false },
		{ slug: 'welcome', isActive: false },
	]

	constructor(
		private sharedService: SharedService,
		public loadingService: LoadingService,
		private loginService: LoginService,
		private loginPhoneService: LoginPhoneService,
		private loginEmailService: LoginEmailService,
		private loginConfirmService: LoginConfirmService,
		private venueService: VenueService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private mosaicService: MosaicService,
		private clientAuthService: ClientAuthService
	) {}

	ngOnInit(): void {
		if (this.sharedService.localStorage.get('client')) this.router.navigate(['/'])

		this.loadingService.setIsLoadingBySlug('generic', false)

		// Foi necessário fazer isso por services com Subject pois a ideia é
		// modularizar todos os componentes mais pra frente. Dessa forma não teremos acesso direto ao core deles.
		this.$subs.push(this.loginPhoneService.receivePhone().subscribe(res => this.generateFourSecretDigit(res)))
		this.$subs.push(this.loginEmailService.receiveEmail().subscribe(res => this.generateFourSecretDigit(res)))
		this.$subs.push(this.loginConfirmService.receiveDigit().subscribe(res => this.verifyFourSecretDigit(res)))
		this.$subs.push(this.loginPhoneService.receiveTab().subscribe(res => this.setTab(res)))
		this.$subs.push(this.loginEmailService.receiveTab().subscribe(res => this.setTab(res)))
		this.$subs.push(this.loginConfirmService.receiveTab().subscribe(res => this.setTab(res)))
	}

	ngOnDestroy(): void {
		this.$subs.forEach(sub => sub.unsubscribe())
	}

	generateFourSecretDigit(username: string) {
		this.username = username
		this.loginService.generateFourSecretDigit(username).subscribe(
			res => {
				this.setTab('confirm')
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
			error => {
				this.sharedService.swal.error(error.error?.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			}
		)
	}

	verifyFourSecretDigit(digit: any) {
		this.loginService.verifyFourSecretDigit(this.username, digit).subscribe(
			async (res: Client) => {
				this.clientAuthService.setClient({
					...res,
					hasCelebration: true,
				})

				const route = this.activatedRoute.snapshot.queryParams['returnURL'] || '/'
				this.router.navigate([route])
			},
			error => {
				// Erro na verificação dos 4 digitos
				this.sharedService.swal.error(error.error?.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			}
		)
	}

	getActivatedTab() {
		return this.tab.find(item => item.isActive)
	}

	setTab(slug: string) {
		this.tab = this.tab.map(item => ({
			...item,
			isActive: item.slug === slug,
		}))
	}
}

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { HttpService } from '../helper/http.service'
import { SharedService } from '../helper/shared.service'
import { Benefit } from './benefit.interface'

@Injectable({
	providedIn: 'root',
})
export class BenefitService {
	public benefit = new BehaviorSubject<Benefit[]>([])
	public isAcessingFromCelebration = new BehaviorSubject<boolean>(false)

	constructor(public sharedService: SharedService, public httpService: HttpService, public router: Router) {
		this.setIsAcessingFromCelebration(this.router.url.includes('/celebrations'))
	}

	setIsAcessingFromCelebration(isAcessingFromCelebration: boolean) {
		this.isAcessingFromCelebration.next(isAcessingFromCelebration)
	}

	getIsAcessingFromCelebration() {
		return this.isAcessingFromCelebration.asObservable()
	}

	// public initByPartner() {
	// 	this.getByPartner().subscribe(res => this.benefit.next(res))
	// }

	// public getByPartner(): Observable<Benefit[]> {
	// 	return of([
	// 		{
	// 			icon: 'fa fa-desconto',
	// 			name: '20% de desconto',
	// 			desc: 'no valor da conta, mediante reserva.',
	// 		},
	// 		{
	// 			icon: 'fa fa-mesa-vip',
	// 			name: 'Mesa VIP',
	// 			desc: '2 taças de espumante, 1 garrafa de água importada e 2 cafés, OU uma entrada à escolha do restaurante, mediante reserva.',
	// 		},
	// 		{ icon: 'fa fa-sobremesas', name: 'Uma sobremesa', desc: 'da escolha do restaurante.' },
	// 	]).pipe(delay(1000))
	// }
}

import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Benefit } from './benefit.interface'
import { BenefitService } from './benefit.service'

@Component({
	selector: 'ch-benefit',
	templateUrl: './benefit.component.html',
	styleUrls: ['./benefit.component.scss'],
})
export class BenefitComponent implements OnInit {
	@Input() slug: string = '001'
	@Input() bgColor: string = ''
	@Input() benefit: Benefit[] = []
	@Input() isCelebration: boolean = false
	@Input() venueId: string = ''
	@Input() voucherValue: any

	constructor(public benefitService: BenefitService) {}

	ngOnInit(): void {}
}

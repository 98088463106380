<div class="addCard">
	<!-- ADD CARD HEADER -->
	<section class="addCard__header">
		<div class="addCard__header__top">
			<a class="addCard__header__return" (click)="navigationService.setActivatedRightbarBySlug('profile')">
				<i class="addCard__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="addCard__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="addCard__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- ADD CARD BODY -->
	<section class="addCard__body">
		<!-- breadcrumb 
		<ul class="addCard__body__breadcrumb">
			<li class="addCard__body__breadcrumb__item">
				<p class="addCard__body__breadcrumb__txt red">Perfil /</p>
			</li>
			<li class="addCard__body__breadcrumb__item">
				<p class="addCard__body__breadcrumb__txt red">Meus cartões</p>
			</li>
		</ul>
		-->

		<!-- title -->
		<div class="addCard__body__title">Meus cartões</div>

		<!-- content -->
		<div class="addCard__body__content">
			<img src="./../../../../assets/images/add-card.svg" class="addCard__body__content__image" />

			<h2 class="addCard__body__content__title">Adicione um cartão no Programa Menu</h2>

			<p class="addCard__body__content__text">
				É prático, seguro e você consegue finalizar<br />
				seus pedidos rapidamente.
			</p>

			<div class="addCard__body__content__addCard">
				<button
					class="addCard__body__content__addCard__button button gradientHorizontal"
					(click)="navigationService.setActivatedRightbarBySlug('new-card')"
				>
					Adicionar cartão
				</button>
			</div>
		</div>
	</section>

	<!-- ADD CARD FOOTER -->
	<section class="addCard__footer">
		<i class="addCard__footer__icon bradesco fa fa-logo-bradesco"></i>
		<p class="addCard__footer__txt">powered by</p>
		<i class="addCard__footer__icon tagme fa fa-logo-tagme"></i>
	</section>
</div>

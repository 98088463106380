import { Component, Input, OnInit } from '@angular/core'
import { ModalService } from './modal.service'

@Component({
	selector: 'ch-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
	@Input() slug: string = '001'
	@Input() top: string | undefined = undefined
	@Input() modalSlug: string = 'generic'

	constructor() {}

	ngOnInit(): void {}
}

<!-- FOOTER -->
<footer class="footer gradientHorizontal">
	<div class="footer__wrapper wrapper">
		<!-- MENU FOOTER -->
		<ul class="footer__menu">
			<ng-container *ngFor="let item of navigationService.getFooter()">
				<li
					*ngIf="!item.needAuth || clientAuthService.getClient()"
					class="footer__menu__item 
          {{ item.footer?.desktop?.enable ? 'desktop' : '' }} {{
						item.footer?.mobile?.enable ? 'mobile' : ''
					}}
          {{ item.footer?.desktop?.order ? 'desktop__order--' + item.footer?.desktop?.order : '' }}
          {{ item.footer?.mobile?.order ? 'mobile__order--' + item.footer?.mobile?.order : '' }}
          "
				>
					<a (click)="navigationService.setActivatedFooterBySlug(item.slug)" class="footer__menu__link">{{
						item.footer?.label
					}}</a>
				</li>
			</ng-container>
			<li class="footer__menu__item desktop">
				<a
					class="footer__menu__link"
					href="mailto:canais@tagme.com.br?subject=Sugestão novo restaurante (Programa Menu)"
				>
					Indique um restaurante
				</a>
			</li>
		</ul>

		<!-- COPYRIGHT -->
		<section class="footer__copyright">
			<p class="footer__copyright__text">&copy; {{ year }} Programa Menu. Todos os direitos reservados.</p>
		</section>

		<!-- LOGO BRADESCO -->
		<section class="footer__logo">
			<i class="footer__logo__icon fa fa-logo-bradesco"></i>
		</section>
	</div>
</footer>

<div class="page">
	<div class="page__header">
		<div class="update-password__header">
			<div class="update-password__wrapper wrapper">
				<ul class="update-password__menu">
					<li class="update-password__menu__item">
						<a class="update-password__menu__link">
							<i class="update-password__menu__logo_bradesco fa fa-logo-bradesco"></i>
						</a>
					</li>
					<li class="update-password__menu__item">
						<a class="update-password__menu__link">
							<i class="update-password__menu__logo_programa_menu fa fa-logo-programa-menu"></i>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="page__body">
		<div class="update-password__wrapper wrapper" *ngIf="client$ | async as client">
			<form class="update-password__form" [formGroup]="form" (ngSubmit)="handleSubmit()">
				<span class="update-password__title">Atualização de senha</span>
				<span class="update-password__text"
					>Olá {{ client.name }}, por favor, atualize a sua senha abaixo.</span
				>
				<div class="field">
					<div class="field__wrapper">
						<label for="password" class="field__icon left tagmeicon tagmeicon-block-on"></label>
						<input
							type="password"
							id="password"
							class="field__input"
							formControlName="password"
							placeholder="senha"
							[minlength]="8"
							[maxlength]="20"
						/>
					</div>
					<div
						class="field__feedback"
						*ngIf="
							form.get('password')?.invalid && (form.get('password')?.dirty || form.get('password')?.touched)
						"
					>
						<div *ngIf="form.get('password')?.hasError('required')">A senha é obrigatória.</div>
						<div *ngIf="form.get('password')?.hasError('passwordLength')">
							A senha precisa ter entre 8 a 20 caracteres.
						</div>
						<div *ngIf="form.get('password')?.hasError('passwordUppercase')">
							A senha precisa ter no mínimo um digito em letra maiúscula.
						</div>
						<div *ngIf="form.get('password')?.hasError('passwordLowercase')">
							A senha precisa ter no mínimo uma letra minúscula.
						</div>
						<div *ngIf="form.get('password')?.hasError('passwordSpecial')">
							A senha precisa ter no mínimo um caractere especial (!, @, #, $, &, *)
						</div>
					</div>
				</div>
				<div class="field">
					<div class="field__wrapper">
						<label for="confirmPassword" class="field__icon left tagmeicon tagmeicon-block-on"></label>
						<input
							type="password"
							class="field__input"
							id="confirmPassword"
							formControlName="confirmPassword"
							placeholder="confirmar senha"
							[minlength]="8"
							[maxlength]="20"
						/>
					</div>
					<div
						class="field__feedback"
						*ngIf="
							form.get('confirmPassword')?.invalid &&
							(form.get('confirmPassword')?.dirty || form.get('confirmPassword')?.touched)
						"
					>
						<div *ngIf="form.get('confirmPassword')?.hasError('required')">
							A confirmação de senha é obrigatória.
						</div>
						<div *ngIf="form.get('confirmPassword')?.hasError('passwordMismatch')">
							As senhas não coincidem.
						</div>
					</div>
				</div>
				<button
					class="update-password__button button"
					[disabled]="form.invalid || loadingService.getIsLoadingBySlug('generic')"
				>
					<span class="update-password__button__text">Atualizar senha</span>
					<ch-loading *ngIf="loadingService.getIsLoadingBySlug('generic')" slug="button001"></ch-loading>
				</button>
				<div class="update-password__powered">
					<span class="update-password__powered__text">Powered by</span>
					<i class="update-password__powered__logo fa fa-logo-tagme"></i>
				</div>
			</form>
		</div>
		<ch-loading-button001 top="200px" size="50px" *ngIf="loading"></ch-loading-button001>
	</div>
	<div class="page__footer"></div>
</div>

<ch-modal slug="001" [modalSlug]="modalId" [top]="'150px'">
	<div class="modalContent" content>
		<div class="modalContent__header">
			<h2 class="modalContent__title">Senha atualizada com sucesso!</h2>
		</div>
		<!-- <div class="modalContent__body">
			<p class="modalContent__text"></p>
		</div> -->
		<div class="modalContent__footer">
			<button class="modalContent__button button" (click)="goHome()">Voltar à tela principal</button>
			<!-- <button class="modalContent__buttonGhost buttonGhost" (click)="closeModal()">
				Não quero os benefícios
			</button> -->
		</div>
	</div>
</ch-modal>

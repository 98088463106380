import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { LoadingService } from '../../loading/loading.service'
import { LoginConfirmService } from '../login-confirm.service'

@Component({
	selector: 'ch-login-confirm001',
	templateUrl: './login-confirm001.component.html',
	styleUrls: ['./login-confirm001.component.scss'],
})
export class LoginConfirm001Component implements OnInit {
	public form: FormGroup = new FormGroup({
		code01: new FormControl(null, [Validators.required, Validators.maxLength(1)]),
		code02: new FormControl(null, [Validators.required, Validators.maxLength(1)]),
		code03: new FormControl(null, [Validators.required, Validators.maxLength(1)]),
		code04: new FormControl(null, [Validators.required, Validators.maxLength(1)]),
	})

	constructor(private loginConfirmService: LoginConfirmService, public loadingService: LoadingService) {}

	ngOnInit(): void {}

	public async handleKeyup(el: any) {
		// On Paste
		if (el.ctrlKey && el.key.toLowerCase() === 'v') {
			const clipboard = await await navigator.clipboard.readText()

			if (typeof clipboard !== 'string' || clipboard.length !== 4) return

			this.form.reset()
			this.form.setValue({
				code01: clipboard.split('')[0],
				code02: clipboard.split('')[1],
				code03: clipboard.split('')[2],
				code04: clipboard.split('')[3],
			})

			return
		}

		// Single Key
		// this.code[index] = el.target.value;

		if (el.key.toLowerCase() !== 'backspace') {
			return el.srcElement.nextElementSibling && el.srcElement.nextElementSibling.focus()
		} else {
			return el.srcElement.previousElementSibling && el.srcElement.previousElementSibling.focus()
		}
	}

	handleSubmit() {
		this.loadingService.setIsLoadingBySlug('generic', true)
		const code = `${this.form.value.code01}${this.form.value.code02}${this.form.value.code03}${this.form.value.code04}`
		return this.loginConfirmService.sendDigit(code)
	}
}

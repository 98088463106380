import { Injectable } from '@angular/core'
import { delay, Observable, of } from 'rxjs'
import { HttpService } from 'src/app/component/helper/http.service'
import { environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root',
})
export class ConciergeWaitlistService {
	public env = environment

	constructor(private httpService: HttpService) {}

	getWaitlist(form: any): Observable<any> {
		return this.httpService.get(`api/v1/orders/admin/history`, { ...form, partnerId: this.env.partnerID })
	}
}

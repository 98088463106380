import { Pipe, PipeTransform } from '@angular/core'

const TAGME_IMAGE_SERVICE = 'https://tagmeimages.azureedge.net'

@Pipe({ name: 'optimizeCardSrc' })
export class OptimizeCardSrc implements PipeTransform {
	transform(url: string | undefined, output: 'jpg' | 'avif' | 'webp' = 'avif') {
		if (!url) {
			return ''
		}

		return `${TAGME_IMAGE_SERVICE}/?w=482&h=482&output=${output}&dpr=1&url=${url} 1x, ${TAGME_IMAGE_SERVICE}/?w=241&h=241&output=${output}&dpr=2&url=${url} 2x`
	}
}

@Pipe({ name: 'optimizeCard' })
export class OptimizeCard implements PipeTransform {
	transform(url: string | undefined, output: 'jpg' | 'avif' | 'webp' = 'avif') {
		if (!url) {
			return ''
		}

		return `${TAGME_IMAGE_SERVICE}/?w=482&h=482&output=${output}&dpr=2&url=${url} 1x`
	}
}

// Banner SRCset
@Pipe({ name: 'optimizeBannerDesktopSrcSet' })
export class OptimizeBannerDesktopSrcSet implements PipeTransform {
	transform(url: string | undefined | null, output: 'jpg' | 'avif' | 'webp' = 'jpg') {
		if (!url) {
			return ''
		}
		return `${TAGME_IMAGE_SERVICE}/?w=1180&h=300&output=${output}&dpr=1&url=${url} 1x, ${TAGME_IMAGE_SERVICE}/?w=1180&h=300&output=${output}&dpr=2&url=${url} 2x`
	}
}

@Pipe({ name: 'optimizeBannerMobileSrcSet' })
export class OptimizeBannerMobileSrcSet implements PipeTransform {
	transform(url: string | null | undefined, output: 'jpg' | 'avif' | 'webp' = 'avif') {
		if (!url) {
			return ''
		}
		return `${TAGME_IMAGE_SERVICE}/?w=750&h=540&output=${output}&dpr=1&url=${url} 1x, ${TAGME_IMAGE_SERVICE}/?w=750&h=540&output=${output}&dpr=2&url=${url} 2x`
	}
}

@Pipe({ name: 'optimizeBannerMobile' })
export class OptimizeBannerMobile implements PipeTransform {
	transform(url: string | null | undefined, output: 'jpg' | 'avif' | 'webp' = 'avif') {
		if (!url) {
			return ''
		}
		return `${TAGME_IMAGE_SERVICE}/?w=750&h=540&output=${output}&dpr=2&url=${url} 1x`
	}
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { FilterService } from '../../filter/filter.service'
import { SharedService } from '../../helper/shared.service'
import { LoadingService } from '../../loading/loading.service'
import { Mosaic } from '../../mosaic/mosaic.interface'
import { MosaicService } from '../../mosaic/mosaic.service'
import { VenueService } from '../../venue/venue.service'
import { SearchService } from '../search.service'

@Component({
	selector: 'ch-search001',
	templateUrl: './search001.component.html',
	styleUrls: ['./search001.component.scss'],
})
export class Search001Component implements OnInit, OnDestroy {
	public mosaicSlug: string = 'search_result'
	public mosaic: Mosaic | undefined
	public $subs: Array<Subscription> = []
	public isOpen: boolean = this.searchService.isOpen.getValue()
	public form: FormGroup = new FormGroup({
		name: new FormControl(null, [Validators.required]),
	})
	@ViewChild('venueName') venueName: any

	constructor(
		public searchService: SearchService,
		public mosaicService: MosaicService,
		public router: Router,
		public sharedService: SharedService,
		public venueService: VenueService,
		public loadingService: LoadingService,
		public filterService: FilterService
	) {}

	ngOnInit(): void {
		this.mosaic = this.mosaicService.getMosaicBySlug(this.mosaicSlug)

		this.$subs.push(
			this.searchService.receiveIsOpen().subscribe(res => {
				this.isOpen = res

				this.isOpen &&
					setTimeout(() => {
						this.venueName.nativeElement.focus()
					}, 200)
			})
		)

		document.addEventListener('click', (e: any) => {
			const dontCloseClassesList = ['.search001', '.navBar__menu__item', '.navBarMobile__top__search__link']

			for (let item of dontCloseClassesList) if (e.target.closest(item)) return

			this.searchService.sendIsOpen(false)
		})
	}

	ngOnDestroy(): void {
		this.$subs.forEach(sub => sub.unsubscribe())
	}

	public closeModal() {
		this.searchService.sendIsOpen(false)
	}

	async handleSubmit() {
		if (this.form.invalid) return

		if (this.mosaic?.slug !== this.mosaicSlug) return

		this.mosaicService.setMosaicBySlug(this.mosaicSlug, {
			...this.mosaic!,
			venuesAlreadyInView: [],
			...this.form.value,
		})

		await this.router.navigate(['resultado-de-busca'])
		this.searchService.sendIsOpen(false)
		this.filterService.updateVenues()
	}
}

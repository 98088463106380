<form [formGroup]="form" (ngSubmit)="handleSubmit()" class="checkIn" *ngIf="venue$ | async as venue">
	<!-- CHECK IN HEADER -->
	<section class="checkIn__header">
		<div class="checkIn__header__top">
			<a class="checkIn__header__return" (click)="goBack()">
				<i class="checkIn__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="checkIn__header__close" (click)="goBack()">
				<i class="checkIn__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- CHECK IN TOP -->
	<div class="checkIn__top">
		<h2 class="checkIn__top__venue">{{ venue.name }}</h2>
		<p class="checkIn__top__address">{{ venue?.address?.address }}</p>
		<p class="checkIn__top__title red">
			Check-in
			<img src="assets/images/info-mini.svg" class="checkIn__top__title__icon" />
			<!-- <i class="checkIn__top__title__icon fa fa-info-mini"></i> -->
		</p>
	</div>

	<!-- CHECK IN BODY -->
	<section class="checkIn__body">
		<!-- FORM -->
		<div class="checkIn__body__form">
			<div *ngIf="!partySizes" class="loading001">
				<div class="loading001__circle"></div>
			</div>
			<!-- SELECT PEOPLE AMOUNT -->
			<div class="checkIn__body__selectBox" tabindex="1" *ngIf="partySizes">
				<div class="checkIn__body__selectBox__current">
					<div class="checkIn__body__selectBox__value">
						<i class="checkIn__body__selectBox__icon fa fa-perfil"></i>
						<span class="checkIn__body__selectBox__label" *ngIf="form.value.partySize"
							>Mesa para {{ form.value.partySize }}
							{{ form.value.partySize >= 2 ? 'pessoas' : 'pessoa' }}</span
						>
						<span class="checkIn__body__selectBox__label" *ngIf="!form.value.partySize"
							>Selecione a qtd de pessoas</span
						>
					</div>
					<ul class="checkIn__body__selectBox__options">
						<li
							class="checkIn__body__selectBox__options__item"
							*ngFor="let partySize of partySizes"
							tabindex="1"
							(click)="selectPartySize($event, partySize)"
						>
							Mesa para {{ partySize }} {{ partySize >= 2 ? 'pessoas' : 'pessoa ' }}
						</li>
					</ul>
				</div>
			</div>

			<div class="checkIn__body__selectBox">
				<div class="checkIn__body__selectBox__current">
					<div class="checkIn__body__selectBox__value2">
						<i class="checkIn__body__selectBox__icon fa fa-data"></i>
						<span class="checkIn__body__selectBox__label">{{ today | date: 'dd/MM/YYYY' }}</span>
					</div>
				</div>
			</div>

			<!-- BENFEÍCIOS -->
			<ng-container *ngIf="voucherValue && this.venueHasCelebrations">
				<app-celebrations-box
					[voucherValue]="voucherValue"
					[hasSlide]="true"
					(sliderValueChange)="toggleUseCelebrations($event)"
				></app-celebrations-box>
				<app-or-divisor *ngIf="!useCelebrations"></app-or-divisor>
			</ng-container>

			<ng-container *ngIf="!useCelebrations || !venueHasCelebrations">
				<ch-credit-card slug="001"></ch-credit-card>
				<!-- <ch-benefit slug="001"></ch-benefit> -->
				<ch-benefit slug="001" [benefit]="venue.benefits"></ch-benefit>
			</ng-container>

			<!--CONFIRMAÇÃO DE ESTAR SENTADO NA MESA-->
			<div class="checkIn__body__selectBox" style="margin: 20px 0">
				<div class="checkIn__body__selectBox__current">
					<div class="checkIn__body__selectBox__value2">
						<input formControlName="confirmSeat" id="seat" type="checkbox" />
						<label for="seat" style="font-size: 12px; margin-left: 10px"
							>Confirmo que estou acomodado em uma mesa
						</label>
					</div>
				</div>
			</div>
		</div>
	</section>

	<!-- CHECK IN FOOTER -->
	<section class="checkIn__footer">
		<button
			class="checkIn__footer__button button gradientHorizontal"
			[disabled]="form.invalid || loadingService.getIsLoadingBySlug('rightbar')"
		>
			<span class="checkIn__footer__button__text">Continuar</span>
			<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
		</button>
	</section>
</form>

<ch-modal-confirm-registration
	[opened]="openRegisterCard"
	(onConfirm)="handleConfirmRegistration()"
	(onClose)="closeModal()"
></ch-modal-confirm-registration>

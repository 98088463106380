import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Pill } from './pill.interface'

@Injectable({
	providedIn: 'root',
})
export class PillService {
	public pill = new BehaviorSubject<Pill[]>([
		{ slug: 'reservation', label: 'Reservas', selected: true },
		{ slug: 'waitlist', label: 'Fila de espera', selected: false },
		{ slug: 'client', label: 'Clientes', selected: false },
		{ slug: 'celebration_benefit', label: 'Benefícios Celebrations', selected: false },
	])
	constructor() {}

	getSelected() {
		return this.pill.getValue().find(item => item.selected)
	}

	setSelectedBySlug(slug: string) {
		this.pill.next(this.pill.getValue().map(item => ({ ...item, selected: item.slug === slug })))
	}
}

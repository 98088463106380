<div class="checkInTerms">
	<!-- CHECK IN TERMS HEADER -->
	<section class="checkInTerms__header">
		<div class="checkInTerms__header__top">
			<a
				class="checkInTerms__header__return"
				(click)="navigationService.setActivatedRightbarBySlug('checkIn-form')"
			>
				<i class="checkInTerms__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="checkInTerms__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="checkInTerms__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- CHECK IN TERMS CONTENT -->
	<div class="checkInTerms__content">
		<p class="checkInTerms__content__title">Termos de serviço e Check-In</p>
		<p class="checkInTerms__content__text">
			<strong>Informações gerais</strong><br />
			A check-in é valido se o cliente já se encontra dentro das dependências do restaurante.<br />
			A quantidade de pessoas deve ser respeitada e selecionada mediante a disponibilidade do restaurante. O
			check-in deve ser validado pelo restaurante para que se possa usufruir dos benefícios oferecidos pelo
			cartão.<br />
		</p>
		<p class="checkInTerms__content__text">
			<strong>Informações de segurança</strong><br />
			Ao utilizar o serviço você reconhece que nenhum sistema de informações é inviolável, no entanto, a Tagme
			se compromete a manter a segurança e a confidencialidade de seus dados pessoais, através da adoção, por
			si, seus parceiros, clientes e fornecedores, de medidas de segurança, técnicas e administrativas
			suficientes à proteger seus dados de acessos não autorizados e de situações acidentais ou ilícitas de
			destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.<br />
			Você pode entrar em contato com a Tagme através do e-mail
			<a mailto="lgpd@tagme.com.br" target="_blank">lgpd@tagme.com.br</a> para tirar dúvidas e também exercer
			quaisquer de seus direitos, incluindo, mas não limitado à (i) confirmação de tratamento de dados, (ii)
			acesso, correção, portabilidade e eliminação dos dados e (iii) informações sobre compartilhamento de
			dados com terceiros. A Tagme se obriga a responder sua solicitação em um tempo razoável, de forma
			transparente e fundamentada.<br />
			O uso do sistema implica no aceite e concordância de realização de tratamento de seus dados pessoais
			pela Tagme, incluindo dados de consumo, navegação e geolocalização para realizar e administrar a
			reserva, espera e pedidos nos restaurantes, bem como gerenciar e administrar programas de fidelidade da
			Tagme e de seus parceiros, criar e gerenciar o registro de uma conta e perfil de usuário, desenvolver os
			serviços da Tagme, dos restaurantes e de terceiros autorizados, aprimorar os produtos, serviços e site
			dos restaurantes parceiros e da Tagme, personalizar a sua experiência como cliente, incluindo a oferta
			de benefícios e comunicação de marketing dos parceiros da TagMe, convites para participação de estudos e
			pesquisas, responder solicitações de autoridades legais e conduzir as atividades empresariais da Tagme,
			dos restaurantes e parceiros utilizando estritamente a quantidade de dados necessária por um prazo
			razoável, sujeito ainda à revogação de sua autorização a qualquer tempo.<br />
			A Tagme não compartilha seus dados com outras redes de restaurantes, porém não responsabiliza pelo uso
			indevido das informações captadas diretamente pelo restaurante sem a aprovação formal e expressa da
			Tagme no momento da captura.<br />
			<strong
				>Ao continuar, você aceita os Termos de Serviço neste estabelecimento com a Tagme, e especificamente
				aceita que a Tagme compartilhe suas informações com este estabelecimento para realizar a transação
				solicitada.</strong
			>
		</p>
		<div class="checkInTerms__content__checkbox">
			<ng-container [formGroup]="form">
				<input class="checkInTerms__content__input" id="terms" type="checkbox" formControlName="checkin" />
				<label class="checkInTerms__content__label" for="terms">
					<span class="checkInTerms__content__label__span">Li e concordo</span> com os termos e condições da
					reserva no Menu Bradesco.
				</label>
			</ng-container>
		</div>
	</div>

	<!-- CHECK IN TERMS FOOTER -->
	<section class="checkInTerms__footer">
		<button
			class="checkInTerms__footer__button button gradientHorizontal"
			(click)="handleSubmit()"
			[disabled]="!form.value.checkin || loadingService.getIsLoadingBySlug('rightbar')"
		>
			Confirmar check-in

			<ch-loading *ngIf="loadingService.getIsLoadingBySlug('rightbar')" slug="button001"></ch-loading>
		</button>
	</section>
</div>

import { Injectable } from '@angular/core'
import { delay, Observable, of } from 'rxjs'
import { HttpService } from 'src/app/component/helper/http.service'
import { environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root',
})
export class ConciergeCelebrationBenefitService {
	private env = environment
	constructor(private httpService: HttpService) {}

	getByCPF(cpf: string): Observable<any> {
		return this.httpService.get(`api/v1/clients/cpf/${cpf}`)
	}

	getHistoryBenefits(form: any): Observable<any> {
		return this.httpService.post(`api/v1/vouchers/history`, form)
	}

	getHistoryConsumptionService(cpf: any, partner: any): Observable<any> {
		return this.httpService.get(`api/v1/orders/admin/history?cpf=${cpf}&partnerId=${partner}`)
	}

	storeBenefit(benefit: any): Observable<any> {
		return this.httpService.post(`api/v1/vouchers`, benefit)
	}
}

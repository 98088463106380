import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { ReservationService } from 'src/app/component/reservation/reservation.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'

@Component({
	selector: 'ch-check-in-terms',
	templateUrl: './check-in-terms.component.html',
	styleUrls: ['./check-in-terms.component.scss'],
})
export class CheckInTermsComponent implements OnInit {
	form!: FormGroup
	constructor(
		public navigationService: NavigationService,
		public reservationService: ReservationService,
		public loadingService: LoadingService,
		private sharedService: SharedService,
		private fb: FormBuilder,
		private clientAuth: ClientAuthService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			checkin: [false, Validators.required],
		})
	}

	handleSubmit() {
		if (!this.form.value.checkin) {
			this.sharedService.swal.error('Preencha o formulário corretamente.')
			return
		}

		const client = this.sharedService.localStorage.get('client')
		const form = { agreements: { ...client.agreements, checkin: this.form.value.checkin } }

		this.loadingService.setIsLoadingBySlug('rightbar', true)
		this.reservationService.updateClientById(form, client._id).subscribe({
			next: res => {
				this.clientAuth.setClient(res)
				this.loadingService.setIsLoadingBySlug('rightbar', false)
				this.navigationService.setActivatedRightbarBySlug('check-in-confirm')
			},
			error: error => {
				this.loadingService.setIsLoadingBySlug('rightbar', false)
				this.sharedService.swal.error(error.error?.message)
			},
		})
	}
}

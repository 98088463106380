<div class="page">
	<div class="page__header">
		<ch-search slug="001"></ch-search>
		<app-bradesco-navbar></app-bradesco-navbar>
		<ch-rightbar></ch-rightbar>
	</div>
	<div class="page__body">
		<ng-template #notFound>
			<div class="searchResult__notFound">
				<div class="searchResult__notFound__content">
					<i class="searchResult__notFound__content__icon fa fa-sad-smile"></i>
					<p class="searchResult__notFound__content__text">
						Não encontramos o restaurante que você procura. Por favor, faça uma nova busca.
					</p>
				</div>
			</div>
		</ng-template>

		<!-- MOSAIC -->
		<ch-mosaic slug="002" mosaicSlug="search_result" [notFound]="notFound"></ch-mosaic>
	</div>
	<div class="page__footer">
		<ch-footer slug="001"></ch-footer>
	</div>
</div>

<div class="concierge_celebration_benefit">
	<div class="concierge_celebration_benefit__sub_header">
		<div class="concierge_celebration_benefit__sub_header__item">
			<form
				class="concierge_celebration_benefit__modal__form"
				[formGroup]="searchForm"
				(ngSubmit)="handleSearchSubmit()"
			>
				<div class="field">
					<div class="field__wrapper">
						<button class="field__icon right tagmeicon tagmeicon-buscar"></button>
						<input
							type="text"
							class="field__input"
							ngModel
							formControlName="cpf"
							placeholder="Encontrar cliente por cpf"
							mask="000.000.000-00"
						/>
					</div>
				</div>
			</form>
		</div>
	</div>
	<ng-container *ngIf="clients && clients.length > 1">
		<div class="table">
			<table class="table__main">
				<thead class="table__thead">
					<tr class="table__tr">
						<th class="table__th">Nome</th>
						<th class="table__th">CPF</th>
						<th class="table__th">Telefone</th>
						<th class="table__th">E-mail</th>
						<th class="table__th">Cartão do cliente</th>
						<th class="table__th">Ações</th>
					</tr>
				</thead>
				<tbody class="table__tbody">
					<tr class="table__tr" *ngFor="let client of clients">
						<td class="table__td">{{ client.name }} {{ client.lastName }}</td>
						<td class="table__td">{{ client.cpf | mask: '000.000.000-00' }}</td>
						<td class="table__td">{{ client.phone | mask: '+00 (00) 00000-0000' }}</td>
						<td class="table__td">{{ client.email }}</td>
						<td class="table__td" [innerHTML]="getCategoriesNames(client)"></td>
						<td class="table__td">
							<button class="button table__button" (click)="selectClient(client)">Selecionar</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</ng-container>
	<ng-container *ngIf="haveCpf">
		<div class="section">
			<div class="section__top">
				<span class="section__title">Dados pessoais</span>
			</div>
			<div class="section__body">
				<div class="concierge_celebration_benefit__row">
					<div class="concierge_celebration_benefit__column">
						<div class="box">
							<ul class="data">
								<li class="data__item"><span class="data__strong">Cpf: </span>{{ client.cpf }}</li>
								<li class="data__item"><span class="data__strong">Nome: </span>{{ userName }}</li>
								<li class="data__item"><span class="data__strong">E-mail: </span>{{ client.email }}</li>
								<li class="data__item"><span class="data__strong">Telefone: </span>{{ client.phone }}</li>
								<li class="data__item" *ngFor="let card of client.categories">
									<span class="data__strong">Cartões: </span>{{ card.name }} - {{ card.digits }}
									<span class="data__item data__strong" *ngIf="card.gatewayId"> (Cartão completo) </span>
								</li>
								<li class="data__item">
									<span class="data__strong">Cadastro Completo: </span>{{ fullRegistration }}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section">
			<div class="section__top">
				<span class="section__title">Celebrations</span>
			</div>
			<div class="section__body">
				<div class="box">
					<ul class="data">
						<li class="data__item" style="margin-bottom: 20px">
							<span class="data__strong">Possui benefício Celebrations: </span>{{ celebrationBenefit }}
						</li>
						<ng-container *ngIf="activeVouchers?.length">
							<ng-container *ngFor="let voucher of activeVouchers">
								<li class="data__item">
									<span class="data__strong">Data início: </span
									>{{ voucher.validFrom.slice(0, -14) | date: 'dd/MM/yyyy' }}
								</li>
								<li class="data__item">
									<span class="data__strong">Data final: </span
									>{{ voucher.validUntil.slice(0, -14) | date: 'dd/MM/yyyy' }}
								</li>
							</ng-container>
						</ng-container>
					</ul>
				</div>
			</div>
		</div>
		<div class="section">
			<div class="section__top">
				<span class="section__title">Histórico de benefícios</span>
				<div class="slide__arrow">
					<div
						class="slide__arrow__item prev {{ swiper?.swiperRef?.isBeginning ? 'disabled' : '' }}"
						(click)="slidePrev()"
					>
						<i class="slide__arrow__icon tagmeicon tagmeicon-seta"></i>
					</div>
					<div
						class="slide__arrow__item next {{ swiper?.swiperRef?.isEnd ? 'disabled' : '' }}"
						(click)="slideNext()"
					>
						<i class="slide__arrow__icon tagmeicon tagmeicon-seta"></i>
					</div>
				</div>
			</div>
			<div class="section__body">
				<swiper
					#swiper
					[config]="config"
					(swiper)="onSwiper($event)"
					(slideChange)="onSlideChange($event)"
					(reachEnd)="onReachEnd($event)"
					(reachBeginning)="onReachBeginning($event)"
					class="slide001__content"
				>
					<ng-template swiperSlide *ngFor="let item of historyBenefits">
						<div class="box padded">
							<ul class="data">
								<li class="data__item">
									<span class="data__strong">Data de criação: </span
									>{{ item.createdAt.slice(0, -14) | date: 'dd/MM/yyyy':'America/Sao_Paulo' }}
								</li>

								<li class="data__item" *ngIf="item.status">
									<span class="data__strong">Status: </span>{{ translateVoucherStatus(item.status) }}
								</li>
								<li class="data__item">
									<span class="data__strong">Horário: </span>{{ item.createdAt.slice(11, -5) }}
								</li>
								<li class="data__item"><span class="data__strong">Tipo: </span> Bônus</li>
								<li class="data__item"><span class="data__strong">Usuário: </span>{{ userName }}</li>
								<li class="data__item"><span class="data__strong">Motivo:</span> {{ item.description }}</li>
								<li class="data__item"><span class="data__strong">Valor:</span> {{ item.value }}</li>
								<li class="data__item">
									<span class="data__strong">Data início: </span>
									{{ item.validFrom.slice(0, -14) | date: 'dd/MM/yyyy' }}
								</li>
								<li class="data__item">
									<span class="data__strong">Data final: </span>
									{{ item.validUntil.slice(0, -14) | date: 'dd/MM/yyyy' }}
								</li>
							</ul>
						</div>
					</ng-template>
				</swiper>
			</div>
		</div>
		<div class="section consumed_history" *ngIf="false">
			<div class="section__top">
				<span class="section__title">Histórico de consumo</span>
			</div>
			<div class="section__body">
				<div class="box">
					<ul class="data">
						<li class="data__item"><span class="data__strong">Quantidade de reservas:</span> 2</li>
					</ul>
				</div>
				<div class="box padded">
					<div class="table">
						<table class="table__main">
							<thead class="table__thead">
								<tr class="table__tr">
									<th class="table__th">Status</th>
									<th class="table__th">Tipo</th>
									<th class="table__th">Nota</th>
									<th class="table__th">Restaurante</th>
									<th class="table__th">Data/Hora</th>
									<th class="table__th">Pessoas</th>
								</tr>
							</thead>
							<tbody class="table__tbody">
								<tr class="table__tr">
									<td class="table__td">Sentada</td>
									<td class="table__td">Celebrations</td>
									<td class="table__td">Nenhum</td>
									<td class="table__td">Terra Madre Ristorante</td>
									<td class="table__td">13/03/2022 12:30</td>
									<td class="table__td">2</td>
								</tr>
								<tr class="table__tr">
									<td class="table__td">Cancelada</td>
									<td class="table__td">Celebrations</td>
									<td class="table__td">Usuário cancelou pelo aplicativo do bradesco</td>
									<td class="table__td">Jamie's Italian Curitiba</td>
									<td class="table__td">26/02/2022 19:30</td>
									<td class="table__td">2</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="section">
			<div class="section__top">
				<span class="section__title">Adicionar benefício</span>
			</div>
			<div class="section__body">
				<span class="text"><span class="text__strong">Atenção:</span></span>
				<ul class="list">
					<li class="list__item">
						O nome do Concierge ficará associado à concessão de benefício para auditoria posterior.
					</li>
					<li class="list__item">
						Certifique-se de que não há reservas/solicitações ativas antes de conceder novo benefício
					</li>
				</ul>

				<div class="concierge_celebration_benefit__row">
					<div class="concierge_celebration_benefit__column">
						<form
							class="concierge_celebration_benefit__form"
							[formGroup]="benefitForm"
							(ngSubmit)="handleSubmit()"
						>
							<div class="box padded">
								<span>Motivo</span>
								<div class="field">
									<div class="field__wrapper">
										<input
											type="text"
											class="field__input"
											formControlName="description"
											placeholder="Qual o motivo?"
										/>
									</div>
									<span class="field__feedback">{{ getErrorBySlug('description') }}</span>
								</div>
								<span>Valor</span>
								<div class="field">
									<div class="field__wrapper">
										<select class="field__input" required formControlName="value">
											<option selected disabled>Selecione o valor</option>
											<option [ngValue]="{ amount: 300, type: 'celebrations' }" selected>R$ 300,00</option>
											<option
												[ngValue]="{ amount: 1000, type: 'centurion' }"
												*ngIf="clientHasCenturionCategory"
											>
												R$ 1000,00
											</option>
										</select>
									</div>
									<span class="field__feedback">{{ getErrorBySlug('value') }}</span>
								</div>
								<span>Data inicial</span>
								<div class="field">
									<div class="field__wrapper">
										<input type="date" class="field__input" ngModel formControlName="started_at" />
									</div>
									<span class="field__feedback">{{ getErrorBySlug('started_at') }}</span>
								</div>
								<span>Data final</span>
								<div class="field">
									<div class="field__wrapper">
										<input type="date" class="field__input" ngModel formControlName="ended_at" />
									</div>
									<span class="field__feedback">{{ getErrorBySlug('ended_at') }}</span>
								</div>
								<button
									class="box__form__button button"
									[disabled]="benefitForm.invalid || loadingService.getIsLoadingBySlug('generic')"
								>
									<span class="box__form__button__text">Adicionar benefício</span>
									<ch-loading
										*ngIf="loadingService.getIsLoadingBySlug('generic')"
										slug="button001"
									></ch-loading>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<ch-modal slug="001">
		<div class="concierge_celebration_benefit__modal" content>
			<span class="concierge_celebration_benefit__modal__text">
				Tem certeza que deseja adicionar um benefício celebration?
			</span>
			<div class="concierge_celebration_benefit__modal__action">
				<button
					class="concierge_celebration_benefit__modal__action__button button"
					[disabled]="benefitForm.invalid || loadingService.getIsLoadingBySlug('generic')"
					(click)="confirmBenefitCreation()"
				>
					<span class="box__form__button__text">Sim</span>
					<ch-loading *ngIf="loadingService.getIsLoadingBySlug('generic')" slug="button001"></ch-loading>
				</button>
				<button
					class="concierge_celebration_benefit__modal__action__button buttonLight"
					(click)="modalService.setIsOpenBySlug(false)"
				>
					Não
				</button>
			</div>
		</div>
	</ch-modal>
</div>

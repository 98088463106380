import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'ch-loading-venue001',
	templateUrl: './loading-venue001.component.html',
	styleUrls: ['./loading-venue001.component.scss'],
})
export class LoadingVenue001Component implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}

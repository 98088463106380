<div
	class="creditCard001 {{ hasPadding ? 'hasPadding' : '' }} {{
		benefitService.isAcessingFromCelebration.getValue() ? 'acessingFromCelebration' : ''
	}}"
	*ngIf="clientAuthService.isLogged() && !benefitService.isAcessingFromCelebration.getValue()"
>
	<div class="creditCard001__selected" (click)="isOpen = !isOpen">
		<img src="../../../../assets/images/img-cartao.svg" class="creditCard001__selected__imgCard" />
		<div class="creditCard001__selected__typeOfCard" for="credit_card">
			<p class="creditCard001__selected__title">Benefícios do seu Cartão</p>
			<p class="creditCard001__selected__card">{{ clientAuthService.getSelectedCategory()?.name }}</p>
		</div>
	</div>
	<ul
		class="creditCard001__select {{ isOpen ? 'creditCard001__select--opened' : '' }}"
		*ngIf="!benefitService.isAcessingFromCelebration.getValue()"
	>
		<li
			class="creditCard001__options"
			*ngFor="let item of clientAuthService.getClient()?.categories; let i = index"
			(click)="clientAuthService.setSelectedCategory(item)"
		>
			<!-- <img src="../../../../assets/images/img-cartao.png" class="creditCard001__imgCard" /> -->
			<p class="creditCard001__typeOfCard">{{ item.name }}</p>
		</li>
	</ul>
</div>

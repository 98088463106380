import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { SwiperModule } from 'swiper/angular'

import { NgxMaskModule, IConfig } from 'ngx-mask'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HomeComponent } from './page/home/home.component'
import { FavoriteComponent } from './page/favorite/favorite.component'
import { MenuComponent } from './page/menu/menu.component'
import { LoginComponent } from './page/login/login.component'
import { OrderComponent } from './page/order/order.component'
import { TermsOfUserComponent } from './page/terms-of-user/terms-of-user.component'
import { PrivacyPolicyComponent } from './page/privacy-policy/privacy-policy.component'
import { FaqComponent } from './page/faq/faq.component'
import { HowItWorksComponent } from './page/how-it-works/how-it-works.component'
import { CelebrationHomeComponent } from './page/celebration-home/celebration-home.component'
import { ProfileComponent } from './page/profile/profile.component'
import { MyProfileComponent } from './page/profile/my-profile/my-profile.component'
import { MyCardsComponent } from './page/profile/my-cards/my-cards.component'
import { AboutTagmeComponent } from './page/profile/about-tagme/about-tagme.component'
import { LogoutComponent } from './page/profile/logout/logout.component'
import { VenueComponent } from './component/venue/venue.component'
import { Venue001Component } from './component/venue/venue001/venue001.component'
import { NavbarComponent } from './component/navbar/navbar.component'
import { Navbar001Component } from './component/navbar/navbar001/navbar001.component'
import { SearchComponent } from './component/search/search.component'
import { Search001Component } from './component/search/search001/search001.component'
import { SlideComponent } from './component/slide/slide.component'
import { Slide001Component } from './component/slide/slide001/slide001.component'
import { FooterComponent } from './component/footer/footer.component'
import { Footer001Component } from './component/footer/footer001/footer001.component'
import { LoginPhoneComponent } from './component/login-phone/login-phone.component'
import { LoginPhone001Component } from './component/login-phone/login-phone001/login-phone001.component'
import { LoginEmailComponent } from './component/login-email/login-email.component'
import { LoginEmail001Component } from './component/login-email/login-email001/login-email001.component'
import { LoginConfirmComponent } from './component/login-confirm/login-confirm.component'
import { LoginConfirm001Component } from './component/login-confirm/login-confirm001/login-confirm001.component'
import { Footer002Component } from './component/footer/footer002/footer002.component'
import { MosaicComponent } from './component/mosaic/mosaic.component'
import { Mosaic001Component } from './component/mosaic/mosaic001/mosaic001.component'
import { Mosaic002Component } from './component/mosaic/mosaic002/mosaic002.component'
import { LoadingComponent } from './component/loading/loading.component'
import { LoadingVenue001Component } from './component/loading/loading-venue001/loading-venue001.component'
import { RightbarComponent } from './component/rightbar/rightbar.component'
import { NewCardComponent } from './page/profile/new-card/new-card.component'
import { EditProfileComponent } from './page/profile/edit-profile/edit-profile.component'
import { ReservationFormComponent } from './page/reservation/reservation-form/reservation-form.component'
import { ReservationDataComponent } from './page/reservation/reservation-data/reservation-data.component'
import { ReservationConfirmComponent } from './page/reservation/reservation-confirm/reservation-confirm.component'
import { ReservationTermsComponent } from './page/reservation/reservation-terms/reservation-terms.component'
import { WaitlistFormComponent } from './page/waitlist/waitlist-form/waitlist-form.component'
import { WaitlistTermsComponent } from './page/waitlist/waitlist-terms/waitlist-terms.component'
import { WaitlistConfirmComponent } from './page/waitlist/waitlist-confirm/waitlist-confirm.component'
import { LoadingButton001Component } from './component/loading/loading-button001/loading-button001.component'
import { AddCardComponent } from './page/profile/add-card/add-card.component'
import { ModalComponent } from './component/modal/modal.component'
import { Modal001Component } from './component/modal/modal001/modal001.component'
import { Slide002Component } from './component/slide/slide002/slide002.component'
import { CreditCard001Component } from './component/credit-card/credit-card001/credit-card001.component'
import { CreditCardComponent } from './component/credit-card/credit-card.component'
import { BenefitComponent } from './component/benefit/benefit.component'
import { Benefit001Component } from './component/benefit/benefit001/benefit001.component'
import { Benefit002Component } from './component/benefit/benefit002/benefit002.component'
import { SingleCreditCardComponent } from './component/single-credit-card/single-credit-card.component'
import { SingleCreditCard001Component } from './component/single-credit-card/single-credit-card001/single-credit-card001.component'
import { CelebrationReservationFormComponent } from './page/celebration-reservation/celebration-reservation-form/celebration-reservation-form.component'
import { CelebrationReservationDataComponent } from './page/celebration-reservation/celebration-reservation-data/celebration-reservation-data.component'
import { CelebrationReservationConfirmComponent } from './page/celebration-reservation/celebration-reservation-confirm/celebration-reservation-confirm.component'
import { CelebrationReservationTermsComponent } from './page/celebration-reservation/celebration-reservation-terms/celebration-reservation-terms.component'
import { FilterComponent } from './component/filter/filter.component'
import { Filter001Component } from './component/filter/filter001/filter001.component'
import { OrderVenueComponent } from './component/order-venue/order-venue.component'
import { CelebrationReservationAboutComponent } from './page/celebration-reservation/celebration-reservation-about/celebration-reservation-about.component'
import { VenueNotFoundComponent } from './component/venue-not-found/venue-not-found.component'
import { VenueNotFound001Component } from './component/venue-not-found/venue-not-found001/venue-not-found001.component'
import { SearchResultComponent } from './page/search-result/search-result.component'
import { CheckInFormComponent } from './page/check-in/check-in-form/check-in-form.component'
import { CheckInTermsComponent } from './page/check-in/check-in-terms/check-in-terms.component'
import { CheckInConfirmComponent } from './page/check-in/check-in-confirm/check-in-confirm.component'
import { SubHeaderComponent } from './component/sub-header/sub-header.component'
import { SubHeader001Component } from './component/sub-header/sub-header001/sub-header001.component'
import { environment } from 'src/environments/environment'
import { VenuePageComponent } from './page/venue-page/venue-page.component'
import { ConciergeComponent } from './page/concierge/concierge.component'
import { ConciergeReservationComponent } from './page/concierge/concierge-reservation/concierge-reservation.component'
import { ConciergeWaitlistComponent } from './page/concierge/concierge-waitlist/concierge-waitlist.component'
import { ConciergeClientComponent } from './page/concierge/concierge-client/concierge-client.component'
import { ConciergeCelebrationBenefitComponent } from './page/concierge/concierge-celebration-benefit/concierge-celebration-benefit.component'
import { PillComponent } from './component/pill/pill.component'
import { LoginRightbarComponent } from './page/login-rightbar/login-rightbar.component'
import { ConciergeLoginComponent } from './page/concierge/concierge-login/concierge-login.component'
import { RegisterRightbarComponent } from './page/register-rightbar/register-rightbar.component'
import { BradescoNavbarComponent } from './component/bradesco-navbar/bradesco-navbar.component'
import { BradescoProfileComponent } from './component/bradesco-profile/bradesco-profile.component'
import { PaymentBillComponent } from './page/payment/payment-bill/payment-bill.component'
import { PaymentCardComponent } from './page/payment/payment-card/payment-card.component'
import { PaymentConfirmComponent } from './page/payment/payment-confirm/payment-confirm.component'
import { PaymentComponent } from './page/payment/payment.component'
import { getFormattedAdress } from './helper/get-formatted-address.pipe'
import { Benefit003Component } from './component/benefit/benefit003/benefit003.component'

import { LOCALE_ID } from '@angular/core'
import { registerLocaleData } from '@angular/common'
import localePt from '@angular/common/locales/pt'
import { NgxCurrencyModule } from 'ngx-currency'
import { TokenInterceptor } from './component/helper/auth.interceptor'
import { UpdatePasswordPage } from './page/update-password/update-password.component'
import {
	OptimizeBannerMobileSrcSet,
	OptimizeBannerDesktopSrcSet,
	OptimizeCard,
	OptimizeCardSrc,
	OptimizeBannerMobile,
} from './helper/optmize-images.pipe'
import { ModalConfirmRegistration } from './component/modal/modal-confirm-registration/modal-confirm-registration'
import { OrDivisorComponent } from './component/or-divisor/or-divisor.component'
import { CelebrationsBoxComponent } from './component/celebrations-box/celebrations-box.component'
import { SlideToggleComponent } from './component/slide-toggle/slide-toggle.component'
registerLocaleData(localePt)

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		FavoriteComponent,
		MenuComponent,
		LoginComponent,
		OrderComponent,
		TermsOfUserComponent,
		PrivacyPolicyComponent,
		FaqComponent,
		HowItWorksComponent,
		CelebrationHomeComponent,
		ProfileComponent,
		MyProfileComponent,
		MyCardsComponent,
		AboutTagmeComponent,
		LogoutComponent,
		VenueComponent,
		Venue001Component,
		NavbarComponent,
		Navbar001Component,
		SearchComponent,
		Search001Component,
		SlideComponent,
		Slide001Component,
		FooterComponent,
		Footer001Component,
		LoginPhoneComponent,
		LoginPhone001Component,
		LoginEmailComponent,
		LoginEmail001Component,
		LoginConfirmComponent,
		LoginConfirm001Component,
		Footer002Component,
		MosaicComponent,
		Mosaic001Component,
		Mosaic002Component,
		LoadingComponent,
		LoadingVenue001Component,
		RightbarComponent,
		NewCardComponent,
		EditProfileComponent,
		ReservationFormComponent,
		ReservationDataComponent,
		ReservationConfirmComponent,
		ReservationTermsComponent,
		WaitlistFormComponent,
		WaitlistTermsComponent,
		WaitlistConfirmComponent,
		LoadingButton001Component,
		AddCardComponent,
		ModalComponent,
		Modal001Component,
		VenuePageComponent,
		Slide002Component,
		CreditCard001Component,
		CreditCardComponent,
		BenefitComponent,
		Benefit001Component,
		Benefit002Component,
		Benefit003Component,
		SingleCreditCardComponent,
		SingleCreditCard001Component,
		CelebrationReservationFormComponent,
		CelebrationReservationDataComponent,
		CelebrationReservationConfirmComponent,
		CelebrationReservationTermsComponent,
		FilterComponent,
		Filter001Component,
		OrderVenueComponent,
		CelebrationReservationAboutComponent,
		VenueNotFoundComponent,
		VenueNotFound001Component,
		SearchResultComponent,
		CheckInFormComponent,
		CheckInTermsComponent,
		CheckInConfirmComponent,
		SubHeaderComponent,
		SubHeader001Component,
		PillComponent,
		ConciergeComponent,
		ConciergeReservationComponent,
		ConciergeWaitlistComponent,
		ConciergeClientComponent,
		ConciergeCelebrationBenefitComponent,
		LoginRightbarComponent,
		ConciergeLoginComponent,
		RegisterRightbarComponent,
		BradescoNavbarComponent,
		BradescoProfileComponent,
		PaymentBillComponent,
		PaymentCardComponent,
		PaymentConfirmComponent,
		PaymentComponent,
		ModalConfirmRegistration,
		getFormattedAdress,
		UpdatePasswordPage,
		OptimizeCard,
		OptimizeCardSrc,
		OptimizeBannerMobileSrcSet,
		OptimizeBannerDesktopSrcSet,
		OptimizeBannerMobile,
		OrDivisorComponent,
		CelebrationsBoxComponent,
		SlideToggleComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		SwiperModule,
		ReactiveFormsModule,
		FormsModule,
		NgxCurrencyModule,
		NgxMaskModule.forRoot(),
		SweetAlert2Module.forRoot(),
	],
	providers: [
		{ provide: 'environment', useValue: environment },
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

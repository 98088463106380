import { Component, OnInit } from '@angular/core'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { NavigationService } from '../../navigation/navigation.service'

@Component({
	selector: 'ch-footer001',
	templateUrl: './footer001.component.html',
	styleUrls: ['./footer001.component.scss'],
})
export class Footer001Component implements OnInit {
	year = new Date().getFullYear()
	constructor(public navigationService: NavigationService, public clientAuthService: ClientAuthService) {}

	ngOnInit(): void {}
}

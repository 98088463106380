<div class="venue" *ngIf="venue" routerLink="restaurante/{{ venue._id }}">
	<div class="venue__header">
		<header class="venue__header__picture">
			<ng-container *ngIf="venue.partner.images.thumb[0]?.coverUrl">
				<picture>
					<source type="image/jpg" [srcset]="converURL | optimizeCardSrc: 'jpg'" />
					<img
						[src]="converURL | optimizeCard: 'jpg'"
						class="venue__header__image"
						[srcset]="converURL | optimizeCardSrc: 'jpg'"
					/>
				</picture>
			</ng-container>
		</header>
	</div>
	<div class="venue__content">
		<h3 class="venue__content__name" [title]="venue.name">{{ venue.name || '' }}</h3>
		<i
			class="venue__content__favorite {{ isFavorited() ? 'venue__content__favorite--favorited' : '' }}"
			*ngIf="clientAuthService.isLogged()"
			(click)="favoriteToggler($event)"
		></i>

		<p class="venue__content__localization" [title]="venue.address.neighborhood + ' - ' + venue.address.city">
			<span *ngIf="venue.address.address">{{ venue.address.address }}, </span>
			<span *ngIf="venue.address.number">{{ venue.address.number }}, </span>
			<span *ngIf="venue.address.complement">{{ venue.address.complement }}, </span>
			{{ venue.address.neighborhood }} - {{ venue.address.city }}
		</p>
		<p class="venue__content__cuisine" [title]="getFormatedCousines(venue.cousines)">
			{{ getFormatedCousines(venue.cousines) }}
		</p>

		<!-- CORTESIAS -->
		<ul class="venue__benefit">
			<li class="venue__benefit__item" *ngFor="let benefit of getSortedBenefits(venue.benefits)">
				<div class="venue__benefit__option">
					<!-- <i class="fa fa-mesa-vip venue__benefit__option__i" *ngIf="benefit.name === 'Mesa VIP'"></i>
					<i class="fa fa-sobremesas venue__benefit__option__i" *ngIf="benefit.name === 'Sobremesa'"></i>
					<i class="fa fa-desconto venue__benefit__option__i" *ngIf="benefit.name === 'Desconto'"></i> -->
					<img style="width: 26px" [src]="env.tagmepub + '/pubimg/' + benefit.icon" />
				</div>
				<!--<div class="venue__benefit__image">
					<img class="venue__benefit__icon" [src]="env.tagmepub + '/pubimg/' + benefit.icon" />
				</div>-->
				<span class="venue__benefit__name">{{ benefit.name }}</span>
			</li>
			<div *ngIf="hasCelebrations$ | async">
				<div style="margin-top: -8px"></div>
				<app-or-divisor></app-or-divisor>
				<div class="venue__benefit__item">
					<div class="venue__benefit__image">
						<i class="fa fa-presente venue__benefit__option__i"></i>
					</div>
					<span class="venue__benefit__name">Celebrations</span>
				</div>
			</div>
		</ul>
	</div>
</div>

<!-- é a parte da jornada em que se coloca o valor da conta, a gorjeta do garçom,
    tem o total a ser pago, os pontos ganhos e segue para pagamento -->
<div class="payment">
	<!-- PAYMENT HEADER -->
	<section class="payment__header">
		<div class="payment__header__top">
			<a class="payment__header__return" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="payment__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="payment__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="payment__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<section class="payment__body" *ngIf="selectedOrder$ | async as selectedOrder">
		<form [formGroup]="paymentForm">
			<div class="payment__ticket">
				<ul class="payment__ticket__list">
					<li class="payment__ticket__list__item">
						<p class="payment__ticket__list__item__data">{{ selectedOrder.venue.name }}</p>
						<p class="payment__ticket__list__item__label">
							{{ selectedOrder.venue.address | formattedAddress }}
						</p>
					</li>
					<li class="payment__ticket__list__item">
						<p class="payment__ticket__list__item__label">Data</p>
						<p class="payment__ticket__list__item__data">
							{{ selectedOrder.reservationDay | date: 'dd/MM/yyyy' }} às
							{{ selectedOrder.reservationTime }} hrs
						</p>
					</li>
				</ul>

				<!-- PAYMENT BENEFITS -->
				<ch-benefit
					slug="003"
					[benefit]="selectedOrder.benefits"
					[venueId]="selectedOrder.venueId"
				></ch-benefit>
			</div>

			<section class="payment__form">
				<div class="payment__form__line">
					<label for="bill" class="payment__form__label">Digite o valor da conta</label>
					<input
						id="bill"
						class="payment__form__input"
						currencyMask
						[options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
						placeholder="R$ 0,00"
						type="text"
						formControlName="bill"
					/>
				</div>
				<div class="payment__form__line" *ngIf="hasDiscount$ | async">
					<label for="" class="payment__form__label">Desconto na conta ({{ discountVenue$ | async }}%)</label>
					<p class="payment__form__input payment__form__input--red">
						{{ discountBill$ | async | currency: 'BRL' }}
					</p>
				</div>
				<div class="payment__form__line">
					<label for="bill_tip" class="payment__form__label"
						>Gorjeta do garçom ({{ paymentForm.value.tip }}%)</label
					>
					<p id="bill_tip" class="payment__form__input" mask="000,00">
						{{ billTip$ | async | currency: 'BRL' }}
					</p>
					<i
						class="payment__form__label__icon fa fa-info-e-como-funciona"
						(click)="modalService.setIsOpenBySlug(true, 'modal-tip')"
					>
					</i>
				</div>

				<ch-modal slug="001" modalSlug="modal-tip">
					<div class="modalContent" content>
						<div class="modalContent__header">
							<h2 class="modalContent__title">Alterar a gorjeta do garçom?</h2>
						</div>
						<div class="modalContent__body">
							<div class="modalContent__line">
								<p class="modalContent__text">Valor sugerido: {{ suggestedTip }}%</p>
								<p id="rangeValue" class="modalContent__percent">{{ paymentForm.get('tip')?.value }}%</p>
							</div>
							<div class="modalContent__adjustPercent">
								<input
									type="range"
									[min]="suggestedTip"
									max="25"
									[defaultValue]="suggestedTip"
									class="modalContent__range"
									formControlName="tip"
								/>
							</div>
							<p class="modalContent__text" *ngIf="hasTipChanged$ | async">
								<i class="modalContent__text__icon fa fa-happy-smile"></i>
								Nossa equipe ficará muito feliz!
							</p>
						</div>
						<div class="modalContent__footer">
							<button
								type="button"
								class="modalContent__button button"
								(click)="modalService.setIsOpenBySlug(false, 'modal-tip')"
							>
								Aplicar
							</button>
						</div>
					</div>
				</ch-modal>

				<div class="payment__form__sum">
					<p class="payment__form__label">Valor total a pagar</p>
					<p class="payment__form__value">{{ totalValue$ | async | currency: 'BRL' }}</p>
				</div>
			</section>
		</form>
	</section>

	<!-- PAYMENT FOOTER -->
	<section class="payment__footer">
		<button class="payment__footer__button button" [disabled]="!paymentForm.valid" (click)="handleSubmit()">
			Ir para pagamento
		</button>
	</section>
</div>

import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'formattedAddress',
})
export class getFormattedAdress implements PipeTransform {
	transform(fullAddress: { address: string; neighborhood: string; city: string; state: string }) {
		return `${fullAddress.address}, ${fullAddress.neighborhood}, ${fullAddress.city} - ${fullAddress.state}`
	}
}

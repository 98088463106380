<div class="reservationData" *ngIf="reservation.venue">
	<!-- RESERVATION DATA HEADER -->
	<section class="reservationData__header">
		<div class="reservationData__header__top">
			<a
				class="reservationData__header__return"
				(click)="navigationService.setActivatedRightbarBySlug('reservation-form')"
			>
				<i class="reservationData__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="reservationData__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="reservationData__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- RESERVATION DATA TOP -->
	<div class="reservationData__top">
		<p class="reservationData__top__title red">Dados da reserva</p>
	</div>

	<!-- RESERVATION DATA BODY -->
	<section class="reservationData__body">
		<ul class="reservationData__body__list">
			<li class="reservationData__body__list__item">
				<div class="reservationData__body__list__info">
					<i class="reservationData__body__list__icon fa fa-cutlery"></i>
					<p class="reservationData__body__list__txt">
						{{ reservation.venue.name }}
						<span class="reservationData__body__list__detail">{{ reservation.venue.address.address }}</span>
					</p>
				</div>
			</li>
			<li class="reservationData__body__list__item">
				<div class="reservationData__body__list__info">
					<i class="reservationData__body__list__icon fa fa-perfil"></i>
					<p class="reservationData__body__list__txt">
						{{ reservation.people_amount }}
						{{ reservation.people_amount && reservation.people_amount >= 2 ? 'pessoas' : 'pessoa' }}
					</p>
				</div>
			</li>
			<li class="reservationData__body__list__item">
				<div class="reservationData__body__list__info">
					<i class="reservationData__body__list__icon fa fa-mesa"></i>
					<p class="reservationData__body__list__txt">{{ reservation.environment_label }}</p>
				</div>
			</li>
			<li class="reservationData__body__list__item">
				<div class="reservationData__body__list__info">
					<i class="reservationData__body__list__icon fa fa-data"></i>
					<p class="reservationData__body__list__txt">{{ getFormatedDate(reservation.date || '') }}</p>
				</div>
			</li>
			<li class="reservationData__body__list__item">
				<div class="reservationData__body__list__info">
					<i class="reservationData__body__list__icon fa fa-time"></i>
					<p class="reservationData__body__list__txt">{{ reservation.time }}</p>
				</div>
			</li>
			<li class="reservationData__body__list__item">
				<div class="reservationData__body__list__info">
					<i class="reservationData__body__list__icon fa fa-login"></i>
					<p class="reservationData__body__list__txt name">
						{{ clientAuthService.getClient()?.name }} {{ clientAuthService.getClient()?.lastName }}
					</p>
				</div>
			</li>
			<li class="reservationData__body__list__item">
				<div class="reservationData__body__list__info">
					<i class="reservationData__body__list__icon fa fa-cartao-de-credito"></i>
					<p class="reservationData__body__list__txt">
						Benefícios do seu Cartão
						<span class="reservationData__body__list__detail">{{
							clientAuthService.getSelectedCategory()?.name
						}}</span>
					</p>
				</div>
			</li>
		</ul>

		<!-- RESERVATION DATA BENEFITS -->
		<ch-benefit slug="001" [benefit]="reservation.venue.benefits" [voucherValue]="voucherValue"></ch-benefit>
	</section>

	<!-- RESERVATION DATA FOOTER -->
	<section class="reservationData__footer">
		<button
			class="reservationData__footer__button button gradientHorizontal"
			(click)="nextNavigationChecker()"
		>
			Continuar
		</button>
	</section>
</div>

import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { BehaviorSubject, Observable, tap } from 'rxjs'
import { environment } from 'src/environments/environment'
import { HttpService } from '../component/helper/http.service'
import { SharedService } from '../component/helper/shared.service'
import { ClientAuthService } from './client-auth.service'

@Injectable({
	providedIn: 'root',
})
export class ConciergeAuthService implements CanActivate {
	private env = environment
	public concierge = new BehaviorSubject<any | null>(null)

	constructor(
		public router: Router,
		private sharedService: SharedService,
		private httpService: HttpService,
		private clientAuthService: ClientAuthService
	) {
		this.init()
	}

	canActivate(): boolean {
		const concierge = this.sharedService.localStorage.get('concierge')

		if (concierge) return true

		this.router.navigate(['/concierge/login'])
		return false
	}

	init() {
		const stored = this.sharedService.localStorage.get('concierge')
		if (stored) this.setConcierge(stored)
	}

	setConcierge(concierge: any | null) {
		this.sharedService.localStorage.set('concierge', concierge ? concierge : {})
		this.concierge.next(concierge)
	}

	login(form: any): Observable<any> {
		return this.httpService
			.post(`api/v1/auth/concierge`, {
				...form,
				partnerId: this.env.partnerID,
			})
			.pipe(tap(res => this.setConcierge(res)))
	}

	get getToken() {
		return this.concierge.getValue()?.access_token?.token
	}

	isLogged() {
		const concierge = this.sharedService.localStorage.get('concierge')
		return concierge
	}

	logout() {
		this.sharedService.localStorage.remove('client')
		this.sharedService.localStorage.remove('concierge')
		this.sharedService.localStorage.remove('filter')
		this.sharedService.localStorage.remove('reservation')
		this.sharedService.localStorage.remove('waitlist')
		this.sharedService.localStorage.remove('celebration_reservation')
		this.sharedService.localStorage.remove('creditCard')
		this.setConcierge(null)
		this.clientAuthService.setClient(null)
		this.router.navigate(['/'])
	}
}

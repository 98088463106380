<div class="celebrationReservationAbout">
	<!-- CELEBRATION ABOUT HEADER -->
	<section class="celebrationReservationAbout__header">
		<div class="celebrationReservationAbout__header__top">
			<a
				class="celebrationReservationAbout__header__return"
				(click)="navigationService.setActivatedRightbarBySlug('celebration-reservation-terms')"
			>
				<i class="celebrationReservationAbout__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div
				class="celebrationReservationAbout__header__close"
				(click)="navigationService.setActivatedRightbarBySlug('')"
			>
				<i class="celebrationReservationAbout__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- CELEBRATION ABOUT BODY -->
	<section class="celebrationReservationAbout__content">
		<div class="celebrationReservationAbout__content__box">
			<i class="celebrationReservationAbout__content__icon fa fa-presente"></i>
			<p class="celebrationReservationAbout__content__title">O que você precisa saber</p>
			<ul class="celebrationReservationAbout__content__list">
				<li class="celebrationReservationAbout__content__list__item">
					<span class="celebrationReservationAbout__content__list__number">1</span>
					<div class="celebrationReservationAbout__content__list__text">
						<strong class="celebrationReservationAbout__content__list__text--strong"
							>O benefício Celebrations</strong
						>
						não é cumulativo com outros benefícios do Programa Menu.
					</div>
				</li>
				<li class="celebrationReservationAbout__content__list__item">
					<span class="celebrationReservationAbout__content__list__number">2</span>
					<div class="celebrationReservationAbout__content__list__text">
						<strong class="celebrationReservationAbout__content__list__text--strong"
							>O benefício Celebrations</strong
						>
						só pode ser utilizado uma única vez.
					</div>
				</li>
			</ul>
			<button
				class="celebrationReservationAbout__content__button buttonWhite"
				(click)="navigationService.setActivatedRightbarBySlug('celebration-reservation-confirm')"
			>
				Ok, entendi
			</button>
		</div>
	</section>
</div>

import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class LoginPhoneService {
	public phone = new Subject<string>()
	public tab = new Subject<string>()

	constructor() {}

	sendPhone(phone: string) {
		this.phone.next(phone)
	}

	receivePhone() {
		return this.phone.asObservable()
	}

	sendTab(tab: string) {
		this.tab.next(tab)
	}

	receiveTab() {
		return this.tab.asObservable()
	}
}

import { Component, OnInit } from '@angular/core'
import { PillService } from './pill.service'

@Component({
	selector: 'app-pill',
	templateUrl: './pill.component.html',
	styleUrls: ['./pill.component.scss'],
})
export class PillComponent implements OnInit {
	constructor(public pillService: PillService) {}

	ngOnInit(): void {}
}

<div class="payment pConfirm">
	<!-- PAYMENT CONFIRM HEADER -->
	<section class="pConfirm__header">
		<p class="pConfirm__header__title">Pagamento realizado com sucesso!</p>
	</section>

	<!-- PAYMENT BODY -->
	<section class="payment__body" *ngIf="vm$ | async as vm">
		<div class="payment__ticket">
			<ul class="payment__ticket__list">
				<li class="payment__ticket__list__item">
					<p class="payment__ticket__list__item__label">Restaurante</p>
					<p class="payment__ticket__list__item__data">
						{{ vm.selectedOrder.venue.name }}
					</p>
				</li>
				<li class="payment__ticket__list__item">
					<p class="payment__ticket__list__item__label">Data</p>
					<p class="payment__ticket__list__item__data">
						{{ vm.paymentResponse.paidAt | date: 'dd/MM/yyyy' }} às
						{{ vm.paymentResponse.paidAt | date: 'HH:mm' }}
					</p>
				</li>
				<li class="payment__ticket__list__item">
					<p class="payment__ticket__list__item__label">Cartão de crédito</p>
					<div class="payment__ticket__list__item__data inline">
						<img
							*ngIf="vm.imageBrand"
							[src]="'/assets/images/' + vm.imageBrand.src"
							[alt]="vm.imageBrand.alt"
							class="payment__ticket__list__item__img"
						/>
						<i class="payment__ticket__list__item__icon fa fa-bullets"></i>
						<p class="payment__ticket__list__item__theLast">{{ vm.selectedCategory?.lastFourDigits }}</p>
					</div>
				</li>
				<li class="payment__ticket__list__item">
					<p class="payment__ticket__list__item__label">Valor pago</p>
					<p class="payment__ticket__list__item__data">{{ vm.totalValue | currency: 'BRL' }}</p>
				</li>

				<li class="payment__ticket__list__item" *ngIf="discountVenue$ | async as discountVenue">
					<p class="payment__ticket__list__item__label">Desconto na conta ({{ discountVenue }}%)</p>
					<p class="payment__ticket__list__item__data">-{{ discount$ | async | currency: 'BRL' }}</p>
				</li>
				<li class="payment__ticket__list__item">
					<p class="payment__ticket__list__item__label">Código de pagamento</p>
					<p class="payment__ticket__list__item__data">{{ vm.paymentResponse.code }}</p>
				</li>
			</ul>
		</div>
	</section>

	<!-- PAYMENT FOOTER -->
	<section class="payment__footer">
		<button
			class="payment__footer__button buttonLight"
			(click)="navigationService.setActivatedRightbarBySlug('')"
		>
			ok
		</button>
	</section>
</div>

<div class="loading001" *ngIf="loading">
	<div class="loading001__circle"></div>
</div>

<div class="checkInConfirm" *ngIf="!loading">
	<!-- CHECK IN CONFIRM HEADER -->
	<section class="checkInConfirm__header">
		<div class="checkInConfirm__header__box">
			<div class="checkInConfirm__header__success">
				<p class="checkInConfirm__header__title">Check-in concluído com sucesso!</p>
				<p class="checkInConfirm__header__text">
					Aproveite seus benefícios!<br />
					Bom apetite! ;)
				</p>
			</div>
		</div>
	</section>

	<!-- CHECK IN CONFIRM BODY -->
	<section class="checkInConfirm__body">
		<p class="checkInConfirm__body__text">
			Você também pode acessar esses dados na aba
			<strong>“Histórico"</strong> no menu principal.
		</p>

		<div class="checkInConfirm__body__ticket">
			<div class="checkInConfirm__body__ticket__top">
				<i class="checkInConfirm__body__ticket__icon fa fa-logo-programa-menu"></i>
				<!-- <img
					class="checkInConfirm__body__ticket__logo"
					alt="logo programa Menu Bradesco"
					src="./../../../../assets/images/logo-programa-menu.svg"
				/> -->
				<img
					class="checkInConfirm__body__ticket__card"
					alt="thumbnail do cartão utilizado na compra"
					src="./../../../../assets/images/img-cartao.svg"
				/>
			</div>
			<ul class="checkInConfirm__body__ticket__list">
				<li class="checkInConfirm__body__ticket__list__item">
					<p class="checkInConfirm__body__ticket__list__item__label">Restaurante</p>
					<p class="checkInConfirm__body__ticket__list__item__data" *ngIf="venue$ | async as venue">
						{{ venue.name }}
					</p>
				</li>
				<li class="checkInConfirm__body__ticket__list__item">
					<p class="checkInConfirm__body__ticket__list__item__label">Check-in para</p>
					<p class="checkInConfirm__body__ticket__list__item__data">{{ partySizeText }}</p>
				</li>
				<li class="checkInConfirm__body__ticket__list__item">
					<p class="checkInConfirm__body__ticket__list__item__label">Dia</p>
					<p class="checkInConfirm__body__ticket__list__item__data">{{ formattedDate }}</p>
				</li>
				<li class="checkInConfirm__body__ticket__list__item">
					<p class="checkInConfirm__body__ticket__list__item__label">Horário</p>
					<p class="checkInConfirm__body__ticket__list__item__data">{{ checkinTime }}</p>
				</li>
				<li class="checkInConfirm__body__ticket__list__item">
					<p class="checkInConfirm__body__ticket__list__item__label">Nome</p>
					<p class="checkInConfirm__body__ticket__list__item__data" *ngIf="client$ | async as client">
						{{ client.name }} {{ client.lastName }}
					</p>
				</li>
			</ul>

			<!-- CHECK IN CONFIRM BENEFITS -->
			<ch-benefit slug="001" [benefit]="benefits" [voucherValue]="voucherValue"></ch-benefit>
		</div>
	</section>

	<!-- CHECK IN CONFIRM FOOTER -->
	<section class="checkInConfirm__footer">
		<button
			class="checkInConfirm__footer__button button buttonLight"
			(click)="navigationService.setActivatedRightbarBySlug('')"
		>
			Entendi, fechar
		</button>
	</section>
</div>

import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { HttpService } from '../helper/http.service'
import { SharedService } from '../helper/shared.service'
import { LoadingService } from '../loading/loading.service'
import { CreditCard } from './credit-card.interface'

@Injectable({
	providedIn: 'root',
})
export class CreditCardService {
	public creditCard = new BehaviorSubject<CreditCard[]>([])

	constructor(
		private sharedService: SharedService,
		private loadingService: LoadingService,
		private clientAuthService: ClientAuthService,
		private httpService: HttpService
	) {}

	async init() {
		if (!this.clientAuthService.isLogged()) return

		const stored = this.sharedService.localStorage.get('creditCard')
		if (stored) return this.setCreditCard(stored)

		const selectedCreditCard = this.getSelectedCreditCard()
		const client = this.sharedService.localStorage.get('client')
		client.categories = (client.categories || []).map((item: CreditCard) => ({
			...item,
			selected: selectedCreditCard?.categoryId === item.categoryId,
		}))

		if (!client.categories.find((item: CreditCard) => item.selected))
			client.categories = (client.categories || []).map((item: CreditCard, i: number) => ({
				...item,
				selected: i === 0,
			}))

		this.setCreditCard(client.categories)
	}

	getSelected() {
		return this.creditCard.getValue().find((item: any) => item.selected)
	}

	setCreditCard(creditCard: CreditCard[]) {
		this.sharedService.localStorage.set('creditCard', creditCard)
		this.creditCard.next(creditCard)
	}

	receiveCreditCard() {
		return this.creditCard.asObservable()
	}

	getSelectedCreditCard() {
		return this.creditCard.getValue().find((item: any) => item.selected)
	}

	setSelected(creditCard: CreditCard) {
		if (!creditCard) return

		this.loadingService.setIsLoadingBySlug('generic', true)

		this.setCreditCard(
			this.creditCard
				.getValue()
				.map(item => ({ ...item, selected: creditCard.categoryId === item.categoryId }))
		)
	}

	getByDigit(digits: string): Observable<any> {
		return this.httpService.get(`api/v1/categories/range/${digits}`)
	}
}

import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'
import { NavigationService } from 'src/app/component/navigation/navigation.service'

@Component({
	selector: 'app-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
	constructor(public navigationService: NavigationService, private title: Title) {}

	ngOnInit(): void {
		window.scrollTo(0, 0)
		this.title.setTitle(`${PREFIX_TITLE} - Política de Privacidade`)
		this.navigationService.setActivatedRightbarBySlug('')
	}
}

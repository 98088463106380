import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'
import { CreditCardService } from 'src/app/component/credit-card/credit-card.service'
import { FilterService } from 'src/app/component/filter/filter.service'
import { AuthService } from 'src/app/helper/auth.service'

@Component({
	selector: 'app-favorite',
	templateUrl: './favorite.component.html',
	styleUrls: ['./favorite.component.scss'],
})
export class FavoriteComponent implements OnInit {
	constructor(
		public authService: AuthService,
		public creditCardService: CreditCardService,
		public filterService: FilterService,
		private title: Title
	) {}

	ngOnInit() {
		this.title.setTitle(`${PREFIX_TITLE} - Favoritos`)
		window.scrollTo(0, 0)
	}
}

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { SharedService } from '../helper/shared.service'
import { Navigation } from './navigation.interface'

const defaultRightbar = {
	label: '',
	icon: '',
	isOpen: false,
	desktop: { enable: false, order: null },
	mobile: { enable: false, order: null },
}

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	public navigation = new BehaviorSubject<Navigation[]>([
		{
			slug: 'home',
			route: '/',
			navbar: {
				label: 'Home',
				icon: 'fa fa-home',
				isActive: false,
				desktop: { enable: false, order: null },
				mobile: { enable: true, order: 1 },
			},
		},
		{
			slug: 'celebration',
			route: '/celebrations',
			navbar: {
				label: 'Celebrations',
				icon: 'fa fa-presente',
				isActive: false,
				desktop: { enable: true, order: 1 },
				mobile: { enable: true, order: 2 },
			},
		},
		{
			slug: 'search',
			navbar: {
				label: 'Buscar',
				icon: 'fa fa-buscar',
				isActive: false,
				desktop: { enable: true, order: 2 },
				mobile: { enable: false, order: null },
			},
		},
		{
			slug: 'favorite',
			route: '/favoritos',
			needAuth: true,
			navbar: {
				label: 'Favoritos',
				icon: 'fa fa-favoritos-off',
				isActive: false,
				desktop: { enable: true, order: 4 },
				mobile: { enable: true, order: 3 },
			},
		},
		{
			slug: 'login-rightbar',
			rightbar: {
				label: 'Login',
				icon: 'tagmeicon tagmeicon-perfil',
				isOpen: false,
				desktop: { enable: false, order: 1 },
				mobile: { enable: false, order: 1 },
			},
		},
		{
			slug: 'register-rightbar',
			rightbar: {
				label: 'Cadastro',
				icon: 'tagmeicon tagmeicon-perfil',
				isOpen: false,
				desktop: { enable: false, order: 1 },
				mobile: { enable: false, order: 1 },
			},
		},
		{
			slug: 'my-profile',
			needAuth: true,
			rightbar: {
				label: 'Meu Perfil',
				icon: 'fa fa-perfil',
				isOpen: false,
				desktop: { enable: true, order: 1 },
				mobile: { enable: true, order: 1 },
			},
		},
		{
			slug: 'edit-profile',
			needAuth: true,
			rightbar: {
				label: 'Editar Perfil',
				icon: 'fa fa-perfil',
				isOpen: false,
				desktop: { enable: false, order: null },
				mobile: { enable: false, order: null },
			},
		},
		{
			slug: 'add-card',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'my-cards',
			needAuth: true,
			rightbar: {
				label: 'Meus cartões',
				icon: 'fa fa-cartao-de-credito',
				isOpen: false,
				desktop: { enable: true, order: 2 },
				mobile: { enable: true, order: 2 },
			},
		},
		{
			slug: 'new-card',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'about-tagme',
			needAuth: false,
			rightbar: {
				label: 'Sobre a Tagme',
				icon: 'fa fa-cozinha',
				isOpen: false,
				desktop: { enable: true, order: 3 },
				mobile: { enable: true, order: 7 },
			},
		},
		{
			slug: 'waitlist-form',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'waitlist-terms',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'waitlist-confirm',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'reservation-form',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'reservation-data',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'reservation-terms',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'reservation-confirm',
			needAuth: true,
			rightbar: defaultRightbar,
		},

		{
			slug: 'check-in-form',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'check-in-terms',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'check-in-confirm',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'celebration-reservation-form',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'celebration-reservation-data',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'celebration-reservation-about',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'celebration-reservation-terms',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'celebration-reservation-confirm',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'how_it_works',
			route: '/como-funciona',
			navbar: {
				label: 'Como Funciona',
				icon: 'fa fa-info-e-como-funciona',
				isActive: false,
				desktop: { enable: true, order: 3 },
				mobile: { enable: false, order: null },
			},
			rightbar: {
				label: 'Como Funciona',
				icon: 'fa fa-info-e-como-funciona',
				isOpen: false,
				desktop: { enable: false, order: null },
				mobile: { enable: true, order: 3 },
			},
		},
		{
			slug: 'order',
			needAuth: true,
			route: '/historico-de-pedidos',
			navbar: {
				label: 'Histórico',
				icon: 'fa fa-data',
				isActive: false,
				desktop: { enable: true, order: 5 },
				mobile: { enable: true, order: 4 },
			},
		},
		{
			slug: 'privacy_policy',
			route: '/politica-de-privacidade',
			footer: {
				label: 'Política de privacidade',
				icon: 'fa fa-politica-privacidade',
				isActive: false,
				desktop: { enable: true, order: 1 },
				mobile: { enable: true, order: null },
			},
			rightbar: {
				label: 'Política de privacidade',
				icon: 'fa fa-politica-privacidade',
				isOpen: false,
				desktop: { enable: false, order: null },
				mobile: { enable: true, order: 5 },
			},
		},
		{
			slug: 'terms_of_use',
			route: '/termos-de-uso',
			footer: {
				label: 'Termos de uso',
				icon: 'fa fa-dados',
				isActive: false,
				desktop: { enable: true, order: 2 },
				mobile: { enable: true, order: null },
			},
			rightbar: {
				label: 'Termos de uso',
				icon: 'fa fa-dados',
				isOpen: false,
				desktop: { enable: false, order: null },
				mobile: { enable: true, order: 6 },
			},
		},
		{
			slug: 'faq',
			route: '/faq',
			needAuth: true,
			footer: {
				label: 'FAQ',
				icon: 'fa fa-faq',
				isActive: false,
				desktop: { enable: true, order: 3 },
				mobile: { enable: true, order: null },
			},
			rightbar: {
				label: 'FAQ',
				icon: 'fa fa-faq',
				isOpen: false,
				desktop: { enable: false, order: null },
				mobile: { enable: true, order: 4 },
			},
		},
		{
			slug: 'profile',
			needAuth: false,
			navbar: {
				label: 'Perfil',
				icon: 'fa fa-nome',
				isActive: false,
				desktop: { enable: true, order: 6 },
				mobile: { enable: true, order: 5 },
			},
			rightbar: {
				label: 'Perfil',
				icon: 'fa fa-nome',
				isOpen: false,
				desktop: { enable: false, order: null },
				mobile: { enable: false, order: null },
			},
		},
		{
			slug: 'payment-bill',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'payment-card',
			needAuth: true,
			rightbar: defaultRightbar,
		},
		{
			slug: 'payment-confirm',
			needAuth: true,
			rightbar: defaultRightbar,
		},
	])

	constructor(
		private router: Router,
		private clientAuthService: ClientAuthService,
		private sharedService: SharedService
	) {}

	public sendNavigation(navigation: Navigation[]) {
		this.navigation.next(navigation)
	}

	public receiveNavigation() {
		return this.navigation.asObservable()
	}

	public getNavbar() {
		const navigation = this.navigation.getValue().filter(item => item.navbar)
		return navigation
	}

	public getFooter() {
		const navigation = this.navigation.getValue().filter(item => item.footer)
		return navigation
	}

	public getRightbar() {
		const navigation = this.navigation.getValue().filter(item => item.rightbar)
		return navigation
	}

	public getActivatedRightBar(): Navigation | undefined {
		const activatedRightbar = this.navigation.getValue().find(item => item.rightbar && item.rightbar.isOpen)
		return activatedRightbar
	}

	public setActivatedRightbarBySlug(slug: string): any {
		// TODO: MUDAR PARA MIDDLEWARE
		const choosedRightbar = this.navigation.getValue().find(item => item.slug === slug)

		if (choosedRightbar?.needAuth) return this.loginMiddleware(slug, 'rightbar')

		const navigation = this.navigation.getValue().map(item => {
			return item.rightbar ? { ...item, rightbar: { ...item.rightbar, isOpen: item.slug === slug } } : item
		})

		return this.navigation.next(navigation)
	}

	public setActivatedNavbarBySlug(slug: string) {
		// TODO: MUDAR PARA MIDDLEWARE
		const choosedNavbar = this.navigation.getValue().find(item => item.slug === slug)

		if (choosedNavbar?.needAuth) return this.loginMiddleware(slug, 'navbar')

		return this.router.navigate([choosedNavbar?.route])
	}

	public setActivatedFooterBySlug(slug: string) {
		// TODO: MUDAR PARA MIDDLEWARE
		const choosedFooter = this.navigation.getValue().find(item => item.slug === slug)

		if (choosedFooter?.needAuth) return this.loginMiddleware(slug, 'navbar')

		return this.router.navigate([choosedFooter?.route])
	}

	public loginMiddleware(slug: string, type: string) {
		if (!this.clientAuthService.isLogged()) return this.setActivatedRightbarBySlug('login-rightbar')

		if (type === 'rightbar') {
			const navigation = this.navigation.getValue().map(item => {
				return item.rightbar ? { ...item, rightbar: { ...item.rightbar, isOpen: item.slug === slug } } : item
			})

			return this.navigation.next(navigation)
		}

		if (type === 'navbar') {
			const choosedNavbar = this.navigation.getValue().find(item => item.slug === slug)
			return this.router.navigate([choosedNavbar?.route])
		}

		if (type === 'footer') {
			const choosedFooter = this.navigation.getValue().find(item => item.slug === slug)
			return this.router.navigate([choosedFooter?.route])
		}
	}
}

import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { catchError, mergeMap, throwError } from 'rxjs'
import { CreditCardService } from 'src/app/component/credit-card/credit-card.service'
import { FilterService } from 'src/app/component/filter/filter.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { ConciergeAuthService } from 'src/app/helper/concierge-auth.service'
import { Helper } from '../register-rightbar/register-rightbar.helper'

@Component({
	selector: 'ch-login-rightbar',
	templateUrl: './login-rightbar.component.html',
	styleUrls: ['./login-rightbar.component.scss'],
})
export class LoginRightbarComponent implements OnInit {
	public newPassword = false

	public clientForm!: FormGroup
	public conciergeForm!: FormGroup
	public newPasswordForm!: FormGroup

	public formErrors: any = {
		required: 'Campo obrigatório.',
		email: 'Digite um e-mail valido.',
	}

	constructor(
		public clientAuthService: ClientAuthService,
		public conciergeAuthService: ConciergeAuthService,
		public navigationService: NavigationService,
		public loadingService: LoadingService,
		public sharedService: SharedService,
		public router: Router,
		public filterService: FilterService,
		public creditCardService: CreditCardService,
		public fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.clientForm = this.fb.group({
			email: [null, [Validators.required, Validators.email]],
			phone: [null, [Validators.required]],
			password: [null, [Validators.required]],
		})

		this.conciergeForm = this.fb.group({
			cpf: [null, [Validators.required]],
		})

		this.newPasswordForm = this.fb.group({
			email: [null, [Validators.required, Validators.email]],
			phone: [null, [Validators.required]],
		})
	}

	getLoginViewByRule() {
		if (this.conciergeAuthService.concierge.getValue()) return 'concierge'
		if (!this.clientAuthService.getClient()) return 'client'

		return ''
	}

	getErrorBySlug(form: 'client' | 'concierge' | 'password', formControl: string) {
		const chooseStepForm = {
			client: this.clientForm,
			concierge: this.conciergeForm,
			password: this.newPasswordForm,
		}
		const stepForm = chooseStepForm[form]

		if (!stepForm?.controls[formControl].touched) return false

		const error = Object.keys(stepForm?.controls[formControl].errors || {})
		return error.length ? true : false
	}

	handleConciergeSubmit() {
		if (this.conciergeForm.invalid) {
			this.sharedService.swal.error('Preencha o formulário corretamente.')
			return
		}
		this.loadingService.setIsLoadingBySlug('rightbar', true)

		this.clientAuthService
			.getClientByTerm({ value: this.conciergeForm.value.cpf || '' })
			.pipe(
				mergeMap(([client]) => {
					if (!client) {
						return throwError(() => ({
							error: {
								message: 'Usuário não encontrado.',
							},
						}))
					}
					return this.clientAuthService.login({ email: client.email, phone: client.phone })
				})
			)
			.subscribe({
				next: () => {
					this.loadingService.setIsLoadingBySlug('rightbar', false)
					this.navigationService.setActivatedRightbarBySlug('')
					window.location.href = this.router.url
				},
				error: error => {
					this.sharedService.swal.error(error.error?.message)
					this.loadingService.setIsLoadingBySlug('rightbar', false)
				},
			})
	}

	handleClientSubmit() {
		if (this.clientForm.invalid) {
			this.sharedService.swal.error('Preencha o formulário corretamente.')
			return
		}
		this.loadingService.setIsLoadingBySlug('rightbar', true)

		const body = {
			email: Helper.normalizeEmail(this.clientForm.value.email),
			phone: Helper.normalizePhone(this.clientForm.value.phone),
			password: this.clientForm.value.password,
		}

		this.clientAuthService
			.loginPassword(body)
			.pipe(catchError(this.handleError))
			.subscribe({
				next: () => {
					this.loadingService.setIsLoadingBySlug('rightbar', false)
					this.navigationService.setActivatedRightbarBySlug('')
					window.location.href = this.router.url
				},
			})
	}

	handleNewPasswordSubmit() {
		if (this.newPasswordForm.invalid) {
			this.sharedService.swal.error('Por favor, preencha o formulário corretamente.')
			return
		}
		this.loadingService.setIsLoadingBySlug('rightbar', true)

		const body = {
			email: Helper.normalizeEmail(this.newPasswordForm.value.email),
			phone: Helper.normalizePhone(this.newPasswordForm.value.phone),
		}

		this.clientAuthService
			.sendPasswordLink(body)
			.pipe(catchError(this.handleError))
			.subscribe({
				next: res => {
					this.loadingService.setIsLoadingBySlug('rightbar', false)
					this.sharedService.swal.success(`Email enviado para ${body.email}`)
				},
			})
	}

	showNewPasswordForm() {
		this.newPassword = true
	}

	private handleError = (error: any) => {
		let title = error.error?.message

		if (title.includes('Muitas requisições em /api/v1/auth/loginPassword. Tente novamente mais tarde.')) {
			title = 'Você fez muitas tentativas. Por favor, aguarde um momento e tente novamente mais tarde.'
		}
		this.sharedService.swal.error(title)
		this.loadingService.setIsLoadingBySlug('rightbar', false)
		return throwError(() => error)
	}
}

<div class="loading-venue001">
	<div class="loading-venue001__image"></div>
	<div class="loading-venue001__content">
		<div class="loading-venue001__title"></div>
		<div class="loading-venue001__address"></div>
		<div class="loading-venue001__cuisine"></div>
		<div class="loading-venue001__benefit">
			<div class="loading-venue001__benefit__item"></div>
			<div class="loading-venue001__benefit__item"></div>
		</div>
	</div>
</div>

import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { Observable, tap } from 'rxjs'
import { ConciergeAuthService } from 'src/app/helper/concierge-auth.service'
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(
		private clientAuthService: ClientAuthService,
		private conciergeAuthService: ConciergeAuthService
	) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.conciergeAuthService.getToken ?? this.clientAuthService.getToken
		if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
				},
			})
		}
		return next.handle(request).pipe(
			tap({
				error: (err: any) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 401) {
							this.conciergeAuthService.logout()
							this.clientAuthService.logout()
							// PREVENTS INFINITE LOOP. DOT NOT REMOVE THIS.
							window.location.reload()
						}
					}
				},
			})
		)
	}
}

import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'ch-single-credit-card',
	templateUrl: './single-credit-card.component.html',
	styleUrls: ['./single-credit-card.component.scss'],
})
export class SingleCreditCardComponent implements OnInit {
	@Input() slug: string = '001'
	@Input() number: string = ''
	@Input() name: string = ''
	@Input() validity: string = ''
	@Input() cvv: string = ''

	constructor() {}

	ngOnInit(): void {}
}

import { Component, OnInit, Renderer2 } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { NavigationService } from './component/navigation/navigation.service'
import { DataLayerService } from './gtm/data-layer.service'
import { ClientAuthService } from './helper/client-auth.service'
import { environment } from 'src/environments/environment'
import { interval, of, startWith, switchMap, take, tap } from 'rxjs'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'ProgMenuFront'
	enableChangeCenturion = !environment.production

	constructor(
		public navigationService: NavigationService,
		private router: Router,
		private dataLayerService: DataLayerService,
		private clientAuth: ClientAuthService
	) {
		this.router.events.subscribe(event => {
			// subscribe to router events
			if (event instanceof NavigationEnd) {
				// if our event is of our interest
				this.dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
			}
		})

		this.clientAuth
			.updateClientFront()
			.pipe(take(1))
			.subscribe({ error: () => {} })

		interval(60_000)
			.pipe(
				startWith(0),
				switchMap(() => {
					const client = this.clientAuth.getClient()
					if (client) {
						return this.clientAuth.isLoggedIn()
					}
					return of()
				})
			)
			.subscribe()
	}

	togglecenturionMode(event: any): void {
		if (event.target.checked) {
			document.body.classList.add('centurion-mode')
		} else {
			document.body.classList.remove('centurion-mode')
		}
	}

	ngOnInit(): void {
		this.navigationService.receiveNavigation().subscribe(() => {
			this.navigationService.getActivatedRightBar()
				? document.querySelector('body')?.classList.add('hasOpenedRightbar')
				: document.querySelector('body')?.classList.remove('hasOpenedRightbar')
		})
	}
}

<div class="myProfile">
	<!-- MY PROFILE HEADER -->
	<section class="myProfile__header">
		<div class="myProfile__header__top">
			<a class="myProfile__header__return" (click)="navigationService.setActivatedRightbarBySlug('profile')">
				<i class="myProfile__header__return__icon fa fa-voltar"></i>
				Voltar
			</a>
			<div class="myProfile__header__close" (click)="navigationService.setActivatedRightbarBySlug('')">
				<i class="myProfile__header__close__icon fa fa-close"></i>
			</div>
		</div>
	</section>

	<!-- MY PROFILE BODY -->
	<section class="myProfile__body">
		<!-- breadcrumb
		<ul class="myProfile__body__breadcrumb">
			<li class="myProfile__body__breadcrumb__item">
				<p class="myProfile__body__breadcrumb__txt red">Perfil /</p>
			</li>
			<li class="myProfile__body__breadcrumb__item">
				<p class="myProfile__body__breadcrumb__txt red">Meu perfil</p>
			</li>
		</ul>
		 -->

		<!-- title -->
		<div class="myProfile__body__title">Meu perfil</div>

		<div class="myProfile__body__data" *ngIf="clientAuthService.getClient()">
			<!-- aside -->
			<div class="myProfile__body__aside" *ngIf="false">
				<div class="myProfile__body__aside__mask"></div>
				<a href="" class="myProfile__body__aside__link">Adicionar foto</a>
			</div>

			<!-- content -->
			<div class="myProfile__body__content">
				<ul class="myProfile__body__content__list">
					<li class="myProfile__body__content__item">
						<p class="myProfile__body__content__item__txt name">
							{{ clientAuthService.getClient()?.name }} {{ clientAuthService.getClient()?.lastName }}
						</p>
						<a
							class="myProfile__body__content__item__edit"
							(click)="navigationService.setActivatedRightbarBySlug('edit-profile')"
						>
							<i class="myProfile__body__content__item__icon fa fa-editar red"></i>
						</a>
					</li>
					<li class="myProfile__body__content__item">
						<p class="myProfile__body__content__item__txt">
							{{ clientAuthService.getFormatedPhone() | mask: '(00) 00000-0000' }}
						</p>
					</li>
					<li class="myProfile__body__content__item">
						<p class="myProfile__body__content__item__txt">{{ clientAuthService.getClient()?.email }}</p>
					</li>
				</ul>
			</div>
		</div>
	</section>

	<!-- MY PROFILE FOOTER -->
	<section class="myProfile__footer">
		<i class="myProfile__footer__icon bradesco fa fa-logo-bradesco"></i>
		<p class="myProfile__footer__txt">powered by</p>
		<i class="myProfile__footer__icon tagme fa fa-logo-tagme"></i>
	</section>
</div>

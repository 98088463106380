import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'ch-venue-not-found',
	templateUrl: './venue-not-found.component.html',
	styleUrls: ['./venue-not-found.component.scss'],
})
export class VenueNotFoundComponent implements OnInit {
	@Input() slug: string = '001'

	constructor() {}

	ngOnInit(): void {}
}

import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FilterService } from '../filter/filter.service'
import { CreditCardService } from './credit-card.service'

@Component({
	selector: 'ch-credit-card',
	templateUrl: './credit-card.component.html',
	styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent implements OnInit {
	@Input() slug: string = '001'
	@Input() hasPadding: boolean = false

	constructor(
		public creditCardService: CreditCardService,
		public router: Router,
		public filterService: FilterService
	) {}

	ngOnInit(): void {}
}

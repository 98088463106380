import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { Venue } from 'src/app/component/venue/venue.interface'
import { Waitlist } from 'src/app/component/waitlist/waitlist.interface'
import { WaitlistService } from 'src/app/component/waitlist/waitlist.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { VenuePageService } from '../../venue-page/venue-page.service'
import { createBrands } from './waitlist-form.helper'

interface Brands {
	title: string
	id: number
}

@Component({
	selector: 'ch-waitlist-form',
	templateUrl: './waitlist-form.component.html',
	styleUrls: ['./waitlist-form.component.scss'],
})
export class WaitlistFormComponent implements OnInit, OnDestroy {
	public waitlist: Waitlist = this.waitlistService.waitlist.getValue()
	public venue: Venue | null = this.waitlistService.waitlist.getValue().venue
	public titlePeopleLine = 'Selecione a qtd de pessoas'
	public numberPeopleInLine: number | undefined
	public brands: Brands[] = []
	public isOpen = false

	private subWaitlist!: Subscription
	private subClientAuth!: Subscription
	private subVenuePage!: Subscription

	public form!: FormGroup

	constructor(
		public navigationService: NavigationService,
		public waitlistService: WaitlistService,
		public loadingService: LoadingService,
		public clientAuthService: ClientAuthService,
		public sharedService: SharedService,
		public venuePageService: VenuePageService,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			people_amount: [null, [Validators.required]],
			credit_card: [null, [Validators.required]],
		})

		this.subWaitlist = this.waitlistService.receiveWaitlist().subscribe(res => {
			this.form.patchValue({ people_amount: res.people_amount })
			this.form.patchValue({ credit_card: res.credit_card })
		})

		this.subClientAuth = this.clientAuthService
			.receiveClient()
			.subscribe(() => this.handleCreditCardField(this.clientAuthService.getSelectedCategory()?.categoryId))

		this.subVenuePage = this.venuePageService.receiveVenue().subscribe(res => {
			this.waitlistService.setWaitlistByKey('venue', res)
			this.venue = res
			this.brands = createBrands(this.venue?.waitlistMaxPartySize)
		})
	}

	ngOnDestroy(): void {
		this.subWaitlist.unsubscribe()
		this.subVenuePage.unsubscribe()
		this.subClientAuth.unsubscribe()
	}

	handleCreditCardField(value: any) {
		this.form.patchValue({ credit_card: value })
		this.waitlistService.sendWaitlist(this.form.value)
	}

	peopleInLine(title: string, id: number) {
		this.titlePeopleLine = title
		this.numberPeopleInLine = id
		this.form.patchValue({ people_amount: id })
	}

	toggleOpen() {
		this.isOpen = !this.isOpen
	}

	handleSubmit() {
		if (this.form.invalid) return
		this.waitlistService.sendWaitlist(this.form.value)
		const client = this.sharedService.localStorage.get('client')
		const hasAcceptedTerms = client.agreements.waitlist
		this.navigationService.setActivatedRightbarBySlug(
			hasAcceptedTerms ? 'waitlist-confirm' : 'waitlist-terms'
		)
	}
}

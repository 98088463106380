import { Component, Input, OnInit } from '@angular/core'
import { Slide } from './slide.interface'

@Component({
	selector: 'ch-slide',
	templateUrl: './slide.component.html',
	styleUrls: ['./slide.component.scss'],
})
export class SlideComponent implements OnInit {
	@Input() slug: string = '001'
	@Input() slides: Slide[] = []

	constructor() {}

	ngOnInit(): void {}
}

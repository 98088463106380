import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { SharedService } from 'src/app/component/helper/shared.service'
import { LoadingService } from 'src/app/component/loading/loading.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { passwordMatchValidator, passwordValidator } from './update-password.helper'
import { ModalService } from '../../component/modal/modal.service'

@Component({
	templateUrl: './update-password.component.html',
	styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordPage implements OnInit {
	public form!: FormGroup
	public client$ = this.clientAuthService.receiveClient()
	public modalId = 'modal_update_password'
	public loading: boolean = false

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private clientAuthService: ClientAuthService,
		private fb: FormBuilder,
		public loadingService: LoadingService,
		private sharedService: SharedService,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group(
			{
				password: ['', [Validators.required, passwordValidator()]],
				confirmPassword: ['', [Validators.required]],
			},
			{ validators: passwordMatchValidator('password', 'confirmPassword') }
		)

		const passwordToken = this.activatedRoute.snapshot.paramMap.get('passwordToken')
		const client = this.clientAuthService.getClient()
		if (passwordToken && !client) {
			this.loading = true
			this.clientAuthService.loginUUID(passwordToken).subscribe({
				next: () => {
					this.loading = false
				},
				error: error => {
					this.loading = false
					this.sharedService.swal.error(error.error.message)
				},
			})
		}
	}

	handleSubmit() {
		if (this.form.invalid) {
			this.sharedService.swal.error('Por favor, complete o formulário')
		}
		this.loadingService.setIsLoadingBySlug('generic', true)
		const clientId = this.clientAuthService.getClient()?._id as string
		const password = this.form.get('password')?.value as string
		this.clientAuthService.updatePassowrd({ clientId, password }).subscribe({
			next: () => {
				this.modalService.setIsOpenBySlug(true, this.modalId)
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
			error: error => {
				this.sharedService.swal.error(error.error.message)
				this.loadingService.setIsLoadingBySlug('generic', false)
			},
		})
	}

	goHome() {
		this.router.navigate(['/'])
	}

	closeModal() {
		this.modalService.setIsOpenBySlug(false, this.modalId)
	}
}

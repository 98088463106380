<div class="page">
	<div class="page__header">
		<ch-search slug="001"></ch-search>
		<ch-rightbar></ch-rightbar>
	</div>
	<div class="page__body" *ngIf="venue">
		<!-- MODAL HORÁRIO DE FUNCIONAMENTO -->
		<ch-modal slug="001">
			<div class="venueOne__body__opening" content>
				<p class="venueOne__body__opening__title">Horário de funcionamento</p>
				<ul class="venueOne__body__opening__list" content>
					<li
						class="venueOne__body__opening__list__item"
						*ngFor="let item of sortOperationHours(venue.operationHours)"
					>
						<strong class="venueOne__body__opening__list__item--strong"
							>{{ getWeekdayNameByWeekNumber(item.day) }}:</strong
						>
						{{ item.start }} às {{ item.end }}
					</li>
				</ul>
			</div>
		</ch-modal>

		<ng-container *ngIf="venue">
			<!-- VENUE PAGE -->
			<div class="venueOne">
				<div class="venueOne__wrapper wrapper">
					<div class="venueOne__header">
						<a class="venueOne__header__return" routerLink="/">
							<i class="venueOne__header__return__icon fa fa-voltar"></i>
							Voltar
						</a>
						<h1 class="venueOne__header__logo">
							<i class="venueOne__header__logo__icon fa fa-logo-programa-menu"></i>
						</h1>
						<!-- <button
							class="venueOne__header__button button"
							(click)="startWaitlist()"
							*ngIf="!isAcessingFromCelebration && venue.waitlistMaxPartySize"
						>
							Fila de espera
						</button> -->
					</div>
				</div>

				<!-- SLIDES -->
				<ch-slide *ngIf="slides.length" slug="002" [slides]="slides"></ch-slide>

				<div class="venueOne__wrapper wrapper">
					<div class="venueOne__infos">
						<!-- TOP -->
						<div class="venueOne__top">
							<div class="venueOne__top__logo">
								<img
									class="venueOne__top__img"
									[src]="env.tagmepub + '/pubimg/' + venue.partner.images.logo.logoUrl"
								/>
							</div>

							<h1 class="venueOne__top__name">{{ venue.name || '' }}</h1>

							<div class="venueOne__top__icons">
								<!-- INFORMAÇÔES -->
								<i
									class="venueOne__information fa fa-info-e-como-funciona mobileOnly"
									(click)="modalService.setIsOpenBySlug(true)"
								>
								</i>

								<!-- MENU -->
								<i
									*ngIf="venue.hasMenu"
									class="venueOne__menu fa fa-menu mobileOnly"
									routerLink="/restaurante/{{ venue._id }}/menu-digital"
								>
								</i>

								<!-- FAVORITE -->
								<i
									class="venueOne__favorite {{ isFavorited() ? 'venueOne__favorite--favorited' : '' }}"
									*ngIf="clientAuthService.isLogged()"
									(click)="venueService.favoriteToggler(venue)"
								>
								</i>
							</div>
						</div>

						<!-- BODY -->
						<div class="venueOne__body">
							<div class="venueOne__body__left">
								<p class="venueOne__body__city">
									{{ venue.address.city }} - {{ venue.address.state }} • {{ venue.address.neighborhood }}
								</p>
								<p class="venueOne__body__address">
									{{ venue.address.address }}
									<span *ngIf="venue.address.number">, {{ venue.address.number }}</span>
									<span *ngIf="venue.address.complement">, {{ venue.address.complement }}</span>
								</p>
								<p class="venueOne__body__phone">{{ venue.phone[0].value || '' }}</p>
							</div>
							<div class="venueOne__body__right desktopOnly">
								<ul class="venueOne__body__opening__list">
									<li
										class="venueOne__body__opening__list__item"
										*ngFor="let item of sortOperationHours(venue.operationHours)"
									>
										{{ getWeekdayNameByWeekNumber(item.day) }}: {{ item.start }} às {{ item.end }}
									</li>
								</ul>
							</div>
						</div>

						<!-- BOTTOM -->
						<div class="venueOne__bottom">
							<div class="venueOne__bottom__left">
								<button
									*ngIf="venue.hasMenu"
									class="venueOne__bottom__buttonLight buttonLight"
									routerLink="/restaurante/{{ venue._id }}/menu-digital"
								>
									Menu digital
								</button>
							</div>
							<div class="venueOne__bottom__right desktopOnly">
								<div
									style="display: flex; align-items: center; justify-content: center; flex-direction: column"
								>
									<div
										class="venueOne__bottom__item button gradientHorizontal"
										(click)="startReservation()"
										*ngIf="venue?.reservation?.enabled"
									>
										Reserva de mesa
									</div>
									<p style="margin-top: 16px; font-size: 12px">Sujeito à disponibilidade de dia e horário.</p>
								</div>
								<div
									class="venueOne__bottom__item button"
									(click)="navigationService.setActivatedRightbarBySlug('check-in-form')"
									*ngIf="venue.checkin.enabled"
									[ngStyle]="{ opacity: venue.checkin.available ? '1' : '0.5' }"
								>
									Check-in
								</div>
							</div>
							<!-- <div class="venueOne__bottom__right mobileOnly">
								<div
									class="venueOne__bottom__item button gradientHorizontal {{
										tab !== 'reservation' ? 'unselect' : ''
									}}"
									(click)="tab = 'reservation'"
								>
									Reserva de mesa
								</div>
								<div
									class="venueOne__bottom__item button {{ tab !== 'waitlist' ? 'unselect' : '' }}"
									(click)="tab = 'waitlist'"
									*ngIf="!isAcessingFromCelebration"
								>
									Fila de espera
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>

			<div class="venueTwo">
				<div class="venueTwo__wrapper wrapper">
					<div class="venueTwo__left">
						<ng-container *ngIf="voucherValue && venueHasCelebrations">
							<app-celebrations-box [voucherValue]="voucherValue"></app-celebrations-box>
							<app-or-divisor></app-or-divisor>
						</ng-container>
						<div class="venueTwo__left__creditCard">
							<div class="venueTwo__creditCard">
								<ch-credit-card slug="001"></ch-credit-card>
							</div>
						</div>

						<!-- BENEFITS -->
						<ch-benefit slug="001" class="mobileOnly" [benefit]="venue.benefits"></ch-benefit>
						<ch-benefit slug="002" class="desktopOnly" [benefit]="venue.benefits"></ch-benefit>
					</div>

					<!-- MAPA -->
					<div class="venueTwo__right" *ngIf="venue.address.location.length">
						<div class="venueTwo__map">
							<img [src]="getGoogleMapsByLocation(venue.address.location)" class="venueTwo__map__image" />
							<!-- <iframe [src]="getGoogleMapsByLocation(venue.address.location)" width="100%" height="100%" frameborder="0"></iframe> -->
						</div>
					</div>
				</div>
			</div>
		</ng-container>

		<!-- BUTTON RESERVAR MESA -->
		<section class="venueFooter mobileOnly" id="buttonFooter">
			<button
				class="venueFooter__button button"
				(click)="startReservation()"
				*ngIf="venue?.reservation?.enabled"
			>
				Reservar uma mesa
			</button>
			<button
				class="venueFooter__button button"
				(click)="navigationService.setActivatedRightbarBySlug('check-in-form')"
				*ngIf="venue.checkin.enabled"
				[ngStyle]="{ opacity: venue.checkin.available ? '1' : '0.5' }"
			>
				Check-in
			</button>
		</section>
	</div>
	<div class="page__footer">
		<ch-footer slug="001"></ch-footer>
	</div>
</div>

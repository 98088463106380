import { AfterViewInit, Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { Faq } from './faq.interface'

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit, AfterViewInit {
	constructor(public navigationService: NavigationService, private title: Title) {}

	public faq: Array<Array<Faq>> = [
		[
			{
				question: `O que é o Programa Menu?`,
				answer: `É um benefício que proporciona aos clientes dos Cartões de Crédito Bradesco, diferenciais
        exclusivos em selecionados restaurantes nas principais cidades brasileiras, ao utilizar o cartão
        como meio de reserva online e pagamento nos estabelecimentos indicados no site.`,
				isOpened: false,
			},
			{
				question: `Como o Programa Menu funciona?`,
				answer: `Basta escolher um dos restaurantes parceiros e fazer a reserva pelo site ou Concierge. Quando
        chegar ao restaurante, apresente sua reserva/nome para concessão dos benefícios do Programa. O
        desconto/benefício é concedido automaticamente na conta, que deve ser paga integralmente com o
        Cartão de Crédito Bradesco elegível ao programa, utilizado para fazer a reserva.`,
				isOpened: true,
			},
			{
				question: 'Preciso cadastrar meu cartão Bradesco?',
				answer:
					'Sim. Para utilização do Programa Menu, é obrigatório o cadastro de um cartão de crédito Bradesco elegível.',
				isOpened: false,
			},
			{
				question: 'Os dados do meu cartão estão seguros?',
				answer:
					'Sim. Os dados do cartão não ficam armazenados no Programa Menu, somente um token criptografado. Será necessário inserir o código de segurança (CVV) do cartão a cada transação.',
				isOpened: false,
			},
			{
				question: `É obrigatório fazer a reserva?`,
				answer: `Sim, para ter direito aos diferenciais do Programa Menu é obrigatório fazer a reserva pelo site
        ou Concierge.`,
				isOpened: false,
			},
			{
				question: `Como realizo uma reserva?`,
				answer: `Você poderá fazer sua reserva pelo site do Programa Menu (clientes American Express e Visa
          também poderão fazer a reserva pelo Concierge, caso o cartão disponha desse serviço). Você
          poderá verificar qual é antecedência mínima de reserva diretamente na tela de reserva do
          restaurante escolhido. Mediante reserva confirmada, a tolerância de atraso ao comparecimento é
          de 10 minutos.`,
				isOpened: false,
			},
			{
				question: `Quando faço a reserva, preciso pagar a conta com o meu cartão Bradesco?`,
				answer: `Sim, é imprescindível que a conta seja paga integralmente com o Cartão de Crédito Bradesco
        utilizado para fazer a reserva.`,
				isOpened: false,
			},
			{
				question: `Preciso ligar para o restaurante para confirmar minha reserva?`,
				answer: `Não é preciso. As reservas feitas pelo Programa Menu são agendadas automaticamente no e-mail
        cadastrado. Próximo à data da reserva você receberá novo e-mail para confirmação ou cancelamento
        da mesma. Em caso de não recebimento do e-mail de confirmação, basta comparecer ao restaurante
        no horário agendado na reserva com seus acompanhantes.`,
				isOpened: false,
			},
			// 	{
			// 		question: `Como cancelo minhas reservas?`,
			// 		anwser: `Para cancelamento você deverá acessar seu perfil no site e na opção “Minhas Reservas”,
			// identificar a desejada e clicar em “Cancelar” ou através do e-mail de confirmação e clicar em
			// “Cancelar”. Clientes American Express e Visa também poderão cancelar pelo Concierge, caso o
			// cartão disponha desse serviço.`,
			// 		isOpened: false,
			// 	},
			// {
			// 	question: `Como altero minhas reservas?`,
			// 	anwser: `Para alterar a reserva é necessário cancelar e realizar nova reserva.`,
			// 	isOpened: false,
			// },
			{
				question: `Quais são os cartões elegíveis ao Programa Menu?`,
				answer: `Participam do Programa Menu os Cartões de Crédito emitidos pelo Banco Bradesco Cartões nas
        bandeiras American Express®, Elo, Visa e Mastercard®: <br />
        The Centurion Card®, The Platinum Card®, American Express® Gold Card, American Express® GreenAmerican Express®
        Platinum Credit, American Express® Gold Credit, American Express® Blue, Bradesco Platinum
        American Express®, Bradesco Gold American Express®, American Express® Gold Corporate, American
        Express® Corporate, American Express® Business Platinum, American Express® Business, Bradesco
        Elo Nanquim Diners Club, Bradesco Elo Nanquim, Bradesco Elo Grafite, Bradesco Elo Mais, Bradesco
        Elo Plus, Bradesco Elo Internacional, Bradesco Elo Nacional, Bradesco Seguros Elo Internacional,
        Fundação Amazonas Sustentável Bradesco Elo Internacional, Bradesco Corporate Elo Nanquim Diners
        Club, Bradesco Empresarial Elo Grafite, Bradesco Visa Aeternum, Bradesco Visa Infinite, Bradesco
        Visa Platinum, Bradesco Visa Like, Bradesco Visa Gold, Bradesco Visa Internacional, Bradesco
        Visa Nacional, Cartão Smiles Visa Infinite, Cartão Smiles Visa Platinum, Cartão Smiles Visa
        Gold, Cartão Smiles Visa Internacional, Bradesco NEO Visa Internacional, Bradesco Corporate Visa
        Infinite, Bradesco Empresarial Visa Platinum, Bradesco MasterCard® Black, Bradesco MasterCard®
        Platinum, Bradesco MasterCard® Gold, Bradesco MasterCard® Internacional, Bradesco MasterCard®
        Nacional, Bradesco Smiles MasterCard Platinum, Bradesco Smiles MasterCard Gold, Bradesco Smiles
        MasterCard Internacional, Bradesco Victoria TAP MasterCard Gold, Bradesco Corporativo
        Mastercard® Black.`,
				isOpened: false,
			},
			{
				question: 'Posso usar à vontade o Programa Menu?',
				answer:
					'Sim, você pode usar o Menu quantas vezes quiser! Cada cliente pode fazer uma reserva por restaurante por dia.',
				isOpened: false,
			},
		],
		[
			{
				question: `Quais são os benefícios para cada cartão?`,
				answer: `The Centurion Card®, The Platinum Card®, American Express® Gold Card, Bradesco Elo Nanquim Diners Club, Bradesco Elo
Nanquim, Bradesco Visa Aeternum, Bradesco Visa Infinite, Ágora Visa Infinite, Cartão Smiles Visa Infinite, Bradesco MasterCard®
Black:<br />
      <strong>Descontos¹</strong> de até 15% no valor da conta, mediante reserva.<br />
      <strong>Mesa VIP²</strong>: 2 taças de espumante, 1 garrafa de água importada e 2 cafés, OU uma
      entrada à escolha do restaurante, mediante reserva.<br />
      Reservas de mesa pelo site ou Concierge.<br />
      <strong>1.</strong> Estacionamento, valet e taxas de serviço não estão incluídos no desconto
      oferecido. O desconto não é cumulativo com outras promoções e se aplica, por regra geral, em
      qualquer opção do cardápio, mas alguns restaurantes podem não oferecer no prato executivo por
      entenderem que esta já é uma ação promocional. A taxa de serviço será calculada sobre o preço
      cheio da conta, antes da aplicação do desconto.<br />
  
      <strong>2.</strong> Amenidades da Mesa VIP são limitadas a um único uso por reserva, podem variar de acordo com cada restaurante e são
      oferecidas mediante reserva pelo site ou Concierge do seu cartão. No caso de ruptura de estoque,
      o estabelecimento reserva-se o direito de oferecer produtos correspondentes disponíveis. As 2
      taças de espumante, 1 garrafa de água importada e 2 cafés são limitadas para até duas
      pessoas.<br /> <br/>
  
      American Express® Green, American Express® Platinum Credit, American Express® Gold Credit, American Express® Blue,
Bradesco Platinum American Express®, Bradesco Gold American Express®, Bradesco Elo Grafite, Bradesco Elo Mais, Bradesco Elo
Plus, Bradesco Elo Internacional, Bradesco Elo Nacional, Bradesco Seguros Elo Internacional, Fundação Amazonas Sustentável
Bradesco Elo Internacional, Bradesco Visa Signature, Visa Signature Bradesco Bank, Bradesco Visa Platinum, Bradesco Visa
Like, Bradesco Visa Gold, Bradesco Visa Internacional, Bradesco Visa Nacional, Cartão Smiles Visa Platinum, Cartão Smiles Visa
Gold, Cartão Smiles Visa Internacional, Bradesco NEO Visa Internacional, Bradesco MasterCard® Platinum, Bradesco MasterCard®
Gold, Bradesco MasterCard® Internacional, Bradesco MasterCard® Nacional, Bradesco Smiles MasterCard Platinum, Bradesco
Smiles MasterCard Gold, Bradesco Smiles MasterCard Internacional, Bradesco Victoria TAP:<br />
      <strong>Descontos¹</strong de até 15% no valor da conta, mediante reserva.<br />
      Reservas de mesa pelo site ou Concierge.<br />
      <strong>1.</strong> Estacionamento, valet e taxas de serviço não estão incluídos no desconto
      oferecido. O desconto não é cumulativo com outras promoções e se aplica, por regra geral, em
      qualquer opção do cardápio, mas alguns restaurantes podem não oferecer no prato executivo por
      entenderem que esta já é uma ação promocional. A taxa de serviço será calculada sobre o preço
      cheio da conta, antes da aplicação do desconto.<br /><br/>
  
      American Express® Gold Corporate, American Express® Corporate, American Express® Business
      Platinum, American Express® Business, Bradesco Corporate Elo Nanquim Diners Club, Bradesco
      Empresarial Elo Grafite, Bradesco Corporate Visa Infinite, Bradesco Empresarial Visa Platinum,
      Bradesco Corporativo Mastercard® Black:<br />
      <strong>Desconto¹ de até 15%</strong>
      no valor da conta.<br />
      Reservas de mesa pelo site ou Concierge.<br />
      <strong>¹.</strong> Estacionamento, valet e taxas de serviço não estão incluídos no desconto
      oferecido. O desconto não é cumulativo com outras promoções e se aplica, por regra geral, em
      qualquer opção do cardápio, mas alguns restaurantes podem não oferecer no prato executivo por
      entenderem que esta já é uma ação promocional. A taxa de serviço será calculada sobre o preço
      cheio da conta, antes da aplicação do desconto.<br
    />`,
				isOpened: false,
			},
			{
				question: `O que é o desconto mínimo de até 15%?`,
				answer: `O desconto é um benefício exclusivo para clientes portadores de um dos Cartões de Crédito
      Bradesco elegíveis, e é concedido sobre o total da conta, ou seja, incluso entrada, prato
      principal, bebidas e sobremesa. O desconto não é cumulativo com outras promoções e se aplica,
      por regra geral, em qualquer opção do cardápio, mas alguns restaurantes podem não oferecer no
      prato executivo por entenderem que esta já é uma ação promocional. Estacionamento, valet e taxas
      de serviço não estão incluídos no desconto oferecido.`,
				isOpened: false,
			},
			{
				question: `A taxa de serviço do garçom cobrada pelo restaurante será paga com base no valor do desconto
      mínimo do Programa?`,
				answer: `A taxa de serviço será calculada sobre o preço cheio da conta, ou seja, antes de ser aplicado o
      desconto mínimo do Programa. Uma vez aplicado o desconto será acrescido ao valor total da conta
      o valor previamente calculado da taxa de serviço do garçom.`,
				isOpened: false,
			},
			{
				question: `Não sou titular do meu cartão Bradesco, posso utilizar o Programa Menu?`,
				answer: `Sim, os benefícios do Programa Menu são válidos para todos os portadores dos cartões elegíveis,
      sejam eles titulares ou adicionais.`,
				isOpened: false,
			},
			{
				question: `Possuo um cartão de crédito Bradesco adicional e o cartão titular já está cadastrado no
      Programa Menu, preciso cadastrá-lo também?`,
				answer: `Sim. O cartão adicional possui um número diferente, portanto, para usufruir dos benefícios você
      deve cadastrá-lo no site do Programa Menu e pagar a conta do restaurante integralmente com o
      cartão utilizado na reserva.`,
				isOpened: false,
			},
			{
				question: `Tenho mais de um Cartão Bradesco elegível, posso cadastrá-los no Programa Menu utilizando o
      mesmo login?`,
				answer: `Sim. Ao fazer seu login, você verá que na aba “Meus Cartões” é possível inserir outros cartões.
      Após a validação, você verá a lista de todos os seus cartões cadastrados.`,
				isOpened: false,
			},
			{
				question: `Só posso me cadastrar pelo site?`,
				answer: `Sim. O cadastro poderá ser feito pelo site programamenu.com.br`,
				isOpened: false,
			},
			{
				question: `Conheço um restaurante que poderia ser um parceiro ao Programa Menu. Como posso
      indicá-lo?`,
				answer: `Você pode enviar as suas indicações para
      <a mailto="programamenu@tagme.com.br">programamenu@tagme.com.br</a>`,
				isOpened: false,
			},

			{
				question: 'Como faço para pagar a conta do restaurante pelo Programa Menu?',
				answer:
					'Localize a reserva desejada no seu histórico e clique no botão pagar a conta que estará liberado no dia e horário da sua reserva.   ',
				isOpened: false,
			},
			{
				question: 'Como utilizo a funcionalidade check-in?',
				answer:
					'Pela modalidade de benefício CHECK IN o Usuário não possui a necessidade de efetuar uma Reserva previamente, mas, apenas realizar uma busca de restaurantes participantes, verificar a disponibilidade e condições dos Benefícios do restaurante desejado, efetuar um “Check In” ao sentar-se à mesa no estabelecimento e informar à recepção ou ao garçom. ',
				isOpened: false,
			},
			{
				question: 'Sobre a funcionalidade “CHECK IN”',
				answer: `
Com o check-in na plataforma do Menu Bradesco você vai no seu tempo: é só conferir os restaurantes disponíveis, ao sentar-se à mesa no estabelecimento clicar no botão "fazer check-in" e aproveitar. <br/><br/>
Ao utilizar o CHECK IN, o Usuário terá acesso ao benefício vigente para aquele momento indicado pelo site e registrado em sua confirmação de uso, o que dependerá única e exclusivamente das regras de cada restaurante participante, as quais o Usuário deve verificar antes de comparecer e fazer o “Check In”. <br/><br/>
O Usuário está ciente de que, independentemente do benefício ou percentual de desconto, os valores referentes a gorjeta ou serviço do estabelecimento serão calculados sempre sobre o valor total da conta antes da aplicação do desconto. <br/><br/>
O Usuário está ciente, inclusive, de que o desconto/Benefício concedido pelo uso do Programa Menu Bradesco não é cumulativo com demais promoções e pode ser limitado por quaisquer exceções registradas dentro das regras no perfil do restaurante. 
				`,
				isOpened: false,
			},
		],
	]

	ngOnInit(): void {
		window.scrollTo(0, 0)
		this.title.setTitle(`${PREFIX_TITLE} - FAQ`)
		this.navigationService.setActivatedRightbarBySlug('')
	}

	ngAfterViewInit(): void {
		const faqs = document.querySelectorAll('.faq__item__header')
		faqs.forEach((item: any) => {
			item.addEventListener('click', () => {
				item = item.closest('.faq__item')
				if (item.classList.contains('opened')) return item.classList.remove('opened')
				faqs.forEach((item2: any) => item2.closest('.faq__item').classList.remove('opened'))
				item.classList.add('opened')
			})
		})
	}
}

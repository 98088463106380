<div class="concierge_reservation">
	<div class="concierge_reservation__sub_header">
		<div class="concierge_reservation__sub_header__item">
			<form class="concierge_reservation__modal__form" [formGroup]="form" (ngSubmit)="handleSubHeaderForm()">
				<div class="field">
					<div class="field__wrapper">
						<i class="field__icon right tagmeicon tagmeicon-buscar" (click)="handleSubHeaderForm()"></i>
						<input
							type="text"
							class="field__input"
							ngModel
							formControlName="cpf"
							placeholder="Encontrar cliente por cpf"
							mask="000.000.000-00"
						/>
					</div>
				</div>
			</form>
		</div>
	</div>
	<ng-container *ngFor="let item of reservation">
		<div class="concierge_reservation__item" *ngIf="item?.venue">
			<div class="concierge_reservation__item__left">
				<img
					class="concierge_reservation__item__image"
					[src]="env.tagmepub + '/pubimg/' + item.venue.partner.images.thumb[0]?.coverUrl"
					alt=""
				/>
			</div>
			<div class="concierge_reservation__item__right">
				<span class="concierge_reservation__item__name">{{ item.venue?.name || '' }}</span>
				<div class="concierge_reservation__item__row">
					<div class="concierge_reservation__item__column">
						<span class="concierge_reservation__item__desc"
							><strong class="concierge_reservation__item__desc">Status:</strong>
							{{ getFormatedStatus(item) }}</span
						>
						<span class="concierge_reservation__item__desc"
							><strong class="concierge_reservation__item__desc">Data:</strong> {{ getFormatedDate(item) }}
						</span>
						<span class="concierge_reservation__item__desc"
							><strong class="concierge_reservation__item__desc">Mesa </strong>para:
							{{ getFormatedPartySize(item) }}</span
						>
						<span class="concierge_reservation__item__desc"
							><strong class="concierge_reservation__item__desc">Horário:</strong> {{ getFormatedTime(item) }}
						</span>

						<span class="concierge_reservation__item__desc"
							><strong class="concierge_reservation__item__desc">Benefícios: </strong>
							<ng-container *ngIf="item.benefits">{{ getFormatedBenefits(item.benefits) }}</ng-container>
							<ng-container *ngIf="!item.benefits">Celebrations</ng-container>
						</span>
					</div>
					<div class="concierge_reservation__item__column">
						<span class="concierge_reservation__item__desc"
							><strong class="concierge_reservation__item__desc">Nome:</strong> {{ item.client?.name || '' }}
							{{ item.client?.lastName || '' }}
						</span>
						<span class="concierge_reservation__item__desc"
							><strong class="concierge_reservation__item__desc">Telefone:</strong>
							{{ item.client?.phone || '' | mask: '+00 (00) 00000-0000' }}
						</span>
						<span class="concierge_reservation__item__desc"
							><strong class="concierge_reservation__item__desc">CPF:</strong>
							{{ item.client?.cpf || '' | mask: '000.000.000-00' }}
						</span>
						<strong class="concierge_reservation__item__desc">{{ item.categoryName || '' }}</strong>
					</div>
				</div>
				<ul class="concierge_reservation__item__action" *ngIf="item.status == 'CHK_DONE'">
					<li
						class="concierge_reservation__item__action__item"
						(click)="cancelingReservation = item; modalService.setIsOpenBySlug(true)"
					>
						Cancelar Reserva
					</li>
				</ul>
			</div>
		</div>
	</ng-container>
	<ch-modal slug="001">
		<div class="concierge_reservation__modal" content>
			<span class="concierge_reservation__modal__text"> Tem certeza que deseja cancel a reserva? </span>
			<div class="concierge_reservation__modal__action">
				<button
					class="concierge_reservation__modal__action__button button"
					[disabled]="!cancelingReservation || loadingService.getIsLoadingBySlug('generic')"
					(click)="handleCancelingReservation()"
				>
					<span class="box__form__button__text">Sim</span>
					<ch-loading *ngIf="loadingService.getIsLoadingBySlug('generic')" slug="button001"></ch-loading>
				</button>
				<button
					class="concierge_reservation__modal__action__button buttonLight"
					(click)="cancelingReservation = null; modalService.setIsOpenBySlug(false)"
				>
					Não
				</button>
			</div>
		</div>
	</ch-modal>
</div>

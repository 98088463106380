import { Injectable } from '@angular/core'
import { BehaviorSubject, delay, Observable, of } from 'rxjs'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { environment } from 'src/environments/environment'
import { HttpService } from '../helper/http.service'
import { SharedService } from '../helper/shared.service'
import { Reservation } from './reservation.interface'

@Injectable({
	providedIn: 'root',
})
export class ReservationService {
	public env = environment
	public reservation = new BehaviorSubject<Reservation>({
		venue: null,
		client: null,
		id: null,
		conciergeUser: null,
		people_amount: null,
		environment: null,
		environment_label: null,
		date: null,
		time: null,
		credit_card: null,
	})

	constructor(
		private sharedService: SharedService,
		private httpService: HttpService,
		private clientAuthService: ClientAuthService
	) {}

	public sendReservation(reservation: Reservation) {
		reservation = { ...this.reservation.getValue(), ...reservation }
		this.reservation.next(reservation)
		this.sharedService.localStorage.set('reservation', reservation)
	}

	public setReservationByKey(key: string, value: any) {
		this.reservation.next({ ...this.reservation.getValue(), [key]: value })
	}

	public receiveReservation() {
		return this.reservation.asObservable()
	}

	// NEW FUNCTION for checkAvailability because when the client change his categoryId
	// We need to make a new request for checkAvailability
	// For RESERVATION (not FIXED) and Check-In (Fixed)
	checkAvailability(body: any): Observable<any> {
		const client = this.sharedService.localStorage.get('client')

		body = {
			...body,
			clientId: client._id,
			partnerId: this.env.partnerID,
		}

		return this.httpService.post('api/v1/orders/checkAvailability', body)
	}

	public updateClientById(form: any, id: string): Observable<any> {
		return this.httpService.patch(`api/v1/clients/${id}`, form)
	}

	confirm(body: any, id: string): Observable<any> {
		return this.httpService.post(`api/v1/orders/confirm/${id}`, body)
	}

	getByID(id: string): Observable<any> {
		return this.httpService.get<any>(`api/v1/orders/reservation/${id}`)
	}
}

import { BenefitHelper } from 'src/app/component/benefit/benefit.helper'
import { Benefit } from 'src/app/component/benefit/benefit.interface'

interface GetDiscount {
	benefits: Benefit[]
	venueId: string
}
export class PaymentBillHelper {
	static removePercentage(value: string) {
		return value.replace('%', '')
	}

	static getDiscountValue(order: GetDiscount): number {
		return +PaymentBillHelper.removePercentage(BenefitHelper.getDiscountValue(order))
	}

	static round2decimals(num: number) {
		return Math.round((num + Number.EPSILON) * 100) / 100
	}

	static BRL(num: number) {
		return `${num.toLocaleString('pt-br', {
			style: 'currency',
			currency: 'BRL',
		})}`
	}
}

import { Component, OnInit } from '@angular/core'
import { catchError, switchMap, take, tap } from 'rxjs'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { VenuePageService } from '../../venue-page/venue-page.service'
import { CheckInService } from '../check-in.service'
import { ReservationService } from '../../../component/reservation/reservation.service'
import { SharedService } from 'src/app/component/helper/shared.service'
import { ClientAuthService } from 'src/app/helper/client-auth.service'

@Component({
	selector: 'ch-check-in-confirm',
	templateUrl: './check-in-confirm.component.html',
	styleUrls: ['./check-in-confirm.component.scss'],
})
export class CheckInConfirmComponent implements OnInit {
	venue$ = this.venuePageService.receiveVenue()
	loading = true
	benefits: any[] = []
	venueName!: string
	partySize!: number
	checkinTime!: string
	checkinDay!: string
	voucherValue: any
	client$ = this.clientAuth.receiveClient()
	constructor(
		public navigationService: NavigationService,
		private venuePageService: VenuePageService,
		private checkinService: CheckInService,
		private reservationService: ReservationService,
		private sharedService: SharedService,
		private clientAuth: ClientAuthService
	) {}

	ngOnInit(): void {
		this.reservationService
			.confirm(this.checkinService.checkinData, this.checkinService.orderId)
			.pipe(
				tap(res => {
					this.voucherValue = res.voucher?.value
					this.loading = false
					this.benefits = res.benefits
				}),
				switchMap(() => this.clientAuth.updateClientFront())
			)
			.subscribe({
				error: error => {
					this.navigationService.setActivatedRightbarBySlug('')
					this.sharedService.swal.error(error.error?.message)
				},
			})
		this.venueName = this.checkinService.venueName
		this.partySize = this.checkinService.checkinData.partySize
		this.checkinTime = this.checkinService.checkinData.reservationTime
		this.checkinDay = this.checkinService.checkinData.reservationDay
	}

	get partySizeText() {
		if (this.partySize === 1) {
			return `1 pessoa`
		}
		return `${this.partySize} pessoas`
	}

	get formattedDate() {
		const [year, month, day] = this.checkinDay.split('-')
		const date = new Date(+year, +month - 1, +day)
		const dateFormatted = new Intl.DateTimeFormat('pt-BR', {
			weekday: 'long',
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		}).format(date)
		const captalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
		return captalizeFirstLetter(dateFormatted)
	}
}
